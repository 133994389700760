<template>
  <div>
    <v-select
      full-width
      :items="entries"
      label="Entries"
      v-model="localSettings.entry"
      item-value="id"
      item-text="title"
      solo
      :readonly="readonly"
    />
    <Autocompleter solo v-model="localSettings.signal" label="Signal" :readonly="readonly" />
  </div>
</template>

<script>
import componentExtends from "./_extends/Component";

export default {
  name: "Chatbot_RouterObjectPropertyEditor_GO_TO_ENTRY",
  extends: componentExtends,
  mounted() {
    this.api.Project.entries({ id: this.$route.params.projectId }).then(
      response => {
        this.entries = response.body
          .filter(e => e.info.settings.title || e.info.settings.name)
          .map(e => ({
            title: `${e.chart_name} / ${e.info.settings.title.length > 0 ? e.info.settings.title : e.info.settings.name}`,
            id: e.id
          }));
      }
    );
  },
  data() {
    return {
      entries: []
    };
  }
};
</script>
