const store = {
  namespaced: true,
  state: {
    settings: null
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    }
  },
  actions: {
    setSettings({ commit }, settings) {
      commit("setSettings", settings);
    }
  },
  getters: {
    settings(state) {
      return state.settings;
    }
  }
};

export default store;
