<template>
  <svg width="100%" height="100%">
    <rect :fill="fill" :stroke="stroke" :stroke-width="strokeWidth"
      :width="width" :height="height" :rx="rx" :x="strokeWidth / 2" :y="strokeWidth / 2" />
  </svg>
</template>

<script>
import * as d3 from "d3";
import ExtendsComponent from "../../../_extends/ObjectComponent";

export default {
  name: "Polygon_Rect",
  props: ["selected"],
  extends: ExtendsComponent,
  data () {
    return {
      fill: this.settings.backgroundColor,
      stroke: this.settings.borderColor,
      strokeWidth: this.settings.strokeWidth,
      width: this.baseObject.size.width - this.settings.strokeWidth,
      height: this.baseObject.size.height - this.settings.strokeWidth,
      rx: 0
    };
  },
  watch: {
    settings: {
      handler() {
        this.fill = this.settings.backgroundColor;
        this.stroke = this.settings.borderColor;
        this.strokeWidth = this.settings.strokeWidth;
        this.rx = this.settings.rx;
        this.width = this.baseObject.size.width - this.settings.strokeWidth;
        this.height = this.baseObject.size.height - this.settings.strokeWidth;
      },
      deep: true
    },
    'baseObject.size': {
      handler() {
        this.width = this.baseObject.size.width - this.settings.strokeWidth;
        this.height = this.baseObject.size.height - this.settings.strokeWidth;
      },
      deep: true
    },
  }
};
</script>
