<template>
  <div class="variables pa-1">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Service</th>
            <th class="text-left">Username/email</th>
            <th class="text-left">Access token</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="auth in localAuths" :key="auth.id">
            <td>{{auth.serviceName}}</td>
            <td>{{auth.username}}</td>
            <td>
              <v-text-field
                v-model="auth.token"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-1"
                hint="At least 8 characters"
                counter
                @click:append="show = !show"
              ></v-text-field>
            </td>
            <td>
              <v-btn text class="toolbar-button" @click="deleteAuth(auth.id)">
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="!localAuths.length">
            <td>No data</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn class="mt-5" color="primary" @click="addAuth">Add New</v-btn>
  </div>
</template>

<script>
import OAuthNew from '@/components/_Global/OAuthNew';

export default {
  name: "OAuthList",
  props: {
    auths: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      localAuths: [],
      show: false
    };
  },
  methods: {
    addAuth() {
      const auth = {
        id: this.guid()
      };

      this.$modal.show(
        OAuthNew,
        {
          settings: auth,
          callback: auth => {
            const index = this.localAuths.findIndex(_auth =>
              _auth.serviceName == auth.serviceName && _auth.username == auth.username);
            if (index == -1)
              this.localAuths.push(auth);
            else
              this.$set(this.localAuths, index, auth);
          },
          destroyCallback: () => {
          }
        },
        {}
      );
    },
    deleteAuth(id) {
      if (confirm("Are you sure?"))
        this.localAuths = this.localAuths.filter(v => v.id != id);
    }
  },
  watch: {
    auths: {
      handler() {
        if (JSON.stringify(this.localAuths) != JSON.stringify(this.auths)) {
          if (this.auths)
            this.localAuths = [...this.auths];
          else
            this.localAuths = [];
        }
      },
      deep: true,
      immediate: true
    },
    localAuths: {
      handler() {
        this.$emit("save", [...this.localAuths]);
      },
      deep: true
    }
  }
};
</script>

<style scoped>

.variables >>> .v-label,
.variables >>> input {
  color: #333 !important;
}
</style>
