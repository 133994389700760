<template>
  <div class="chatbot-render-template" v-text="'<Render Template />'">
  </div>
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent'

  export default {
    name: 'Chatbot_RenderTemplateObject',
    extends: ExtendsComponent
  }
</script>

<style scoped>
  .chatbot-render-template {
    border: 3px solid;
    padding: 5px;
    min-width: 200px;
    border-radius: 5px;
    cursor: default;
    background: white;
    white-space: nowrap;
    box-sizing: content-box;
    text-align: center;
  }
</style>
