<template>
  <component
    :is="wrapper"
    @submit="submit"
    title="Edit Team"
    :showSubmit="!!localParams.name"
    submitText="Save"
  >
    <ProjectForm mode="edit" :params.sync="localParams" @submit="submit" @close="close" />
  </component>
</template>


<script>
import { deepClone } from "@/lib";
import ProjectForm from "./_Form";

export default {
  name: "EditTeam",
  components: {
    ProjectForm
  },
  props: {
    wrapper: {
      type: String,
      default: "Modal"
    },
    showActions: Boolean,
    params: Object,
    callback: Function
  },
  data() {
    return {
      localParams: deepClone(this.params || {})
    };
  },
  methods: {
    submit() {
      this.api.Team.update(
        { id: this.params.id },
        { team: this.localParams }
      ).then(response => {
        if (this.callback) this.callback(response.body);
        this.close();
      });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>