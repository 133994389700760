import Button from './Button';
import CheckboxList from './CheckboxList';
import Image from './Image';
import Input from './Input';
import RadioList from './RadioList';
import Select from './Select';
import Video from './Video';
import CustomComponent from './CustomComponent';

const ObjectTypes = {
  Button,
  CheckboxList,
  Image,
  Input,
  RadioList,
  Select,
  Video,
  CustomComponent
}

function createObject({ targetField, postfix='' }) {
  const ObjectComponents = {};

  Object.keys(ObjectTypes).map(controlName => {
    ObjectComponents[`ControlElement_${controlName}${postfix}`] = ObjectTypes[controlName][targetField]
  });

  return ObjectComponents;
}

const Controls = createObject({ targetField: 'Component' }),
    PropertyEditors = createObject({ targetField: 'PropertyEditor', postfix: 'PropertyEditor' });

export {
  Controls,
  PropertyEditors
};