<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0">Condition</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <ColorPickerDialog
      v-model="localSettings.color"
      title="Select a Color"
      :presetColors="projectColors"
      :disabled="readonly"
    />

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_FilterObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpCondition = Vue.extend({
      template: `<Autocompleter filled v-model="settings.condition" :readonly="readonly" class="pb-1" />`,
      props: ["settings", "readonly"]
    });

    return {
      cmpCondition,
      openedData: 0
    };
  },

  methods: {
    onOpenCondition() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpCondition,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Condition"
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenCondition();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenCondition();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>