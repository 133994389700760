<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    :color="color"
    top
    multi-line
  >
    <div style="padding: 4px">
      <span v-html="text"></span>

      <button
        v-if="!!actionText"
        @click="doAction"
        class="action"
      >
        {{ actionText }}
      </button>
    </div>

    <template v-slot:action>
      <v-btn
        @click="snackbar = false"
        style="margin-right: 10px"
        text
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      text: "",
      snackbar: false,
      color: null,
      timeout: 5000,
      actionText: '',
      actionCallback: null
    };
  },
  mounted() {
    this.$root.$on("showSnack", data => {
      this.text = data.message ? data.message : '';
      this.color = data.color ? data.color : null;
      this.timeout = data.timeout ? data.timeout : 5000;
      this.actionText = data.actionText ? data.actionText : '';
      this.actionCallback = data.actionCallback ? data.actionCallback : null;

      this.snackbar = true;
    });
  },
  methods: {
    doAction() {
      this.snackbar = false;
      this.actionCallback();
    }
  }
};
</script>

<style scoped>
.action {
  display: block;
  margin-top: 8px;
  text-decoration: underline;
}
</style>
