<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn value="data" text style="opacity: 1" class="ma-0">Info</v-btn>

      <v-divider vertical  />

      <v-btn value="video" text style="opacity: 1" class="ma-0">Video</v-btn>

      <v-divider vertical  />

      <v-btn
        value="video_timestamps"
        text
        style="opacity: 1"
        class="ma-0"
        :disabled="!localSettings.video || !localSettings.video.body"
      >Animation</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import WayfinderVideo from "./WayfinderVideo.vue";
import WayfinderVideoTimestamps from "./WayfinderVideoTimestamps.vue";
import {copyToClipboard} from "@/lib";

export default {
  name: "Core_ObjectPickerPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  components: {WayfinderVideo, WayfinderVideoTimestamps},
  data() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-text-field
            style="font-family: monospace !important"
            :value="presentationUrl"
            hide-details
            readonly
          >
            <template v-slot:label>
              <span style="font-family: &quot;Open Sans&quot;, Helvetica, Arial, sans-serif !important">Slide Widget URL</span>
            </template>
            <template v-slot:append>
              <v-btn @click="copyUrl">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-text-field
            hide-details
            :readonly="readonly"
            class="mt-3"
            label="Title"
            v-model="settings.wayfinder_title"
          />
          <v-textarea
            hide-details
            :readonly="readonly"
            class="mt-3"
            rows="2"
            label="Description"
            v-model="settings.wayfinder_description"
          />
          <div class="mt-5"><b>Position</b></div>
          <ScreenPositionSelector v-model="settings.position" />
        </div>
      `,
      props: ["settings", "readonly", "baseObjectId"],
      computed: {
        presentationUrl() {
          if (!this.activeChart)
            return "";
          return `${window.location.origin}/widget/${this.activeChart.id}?slide=${this.baseObjectId}`;
        }
      },
      methods: {
        copyUrl() {
          let data = {
            message: "Copied URL to Clipboard",
            color: "success"
          };
          copyToClipboard(this.presentationUrl);
          this.$root.$emit("showSnack", data);
        },
      }
    });

    return {
      cmpData,
      openedData: "data"
    };
  },
  watch: {
    openedData() {
      switch (this.openedData) {
        case null:
          break;
        case "data":
          this.onOpenData();
          break;
        case "video":
          this.onOpenVideo();
          break;
        case "video_timestamps":
          this.onOpenVideoTimestamps();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },
  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenData();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  },
  methods: {
    onOpenData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Waypoint Information"
      });
    },

    onOpenVideo() {
      this.localSettings = { video: {}, ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: WayfinderVideo,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'video',
        title: "Waypoint Video"
      });
    },

    onOpenVideoTimestamps() {
      this.localSettings.video = { timestamps: [], ...this.localSettings.video };

      this.$root.$emit("DataEditor.open", {
        component: WayfinderVideoTimestamps,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Waypoint Animation"
      });
    }
  }
};
</script>