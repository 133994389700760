<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0">Value</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <ColorPickerDialog
      v-model="localSettings.color"
      title="Select a Color"
      :presetColors="projectColors"
      :disabled="readonly"
    />

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_SetValueObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpValue = Vue.extend({
      template: `
        <div style="overflow: hidden">
          <Autocompleter
            filled
            label="Model property"
            v-model="settings.modelProperty"
            :readonly="readonly"
            class="pb-1" />
          <v-textarea
            name="input-7-1"
            filled
            label="Value"
            auto-grow
            v-model="settings.value"
          />
        </div>
      `,
      props: ["settings", "readonly"]
    });

    return {
      cmpValue,
      openedData: 0
    };
  },

  methods: {
    onOpenValue() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpValue,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Value"
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenValue();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenValue();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>