<template>
  <div class="chatbot-filter" :style="filterStyle">
    <div style="padding: 5px">
      <div v-if="localSettings.condition">
        if
        <span :style="conditionStyle" class="chatbot-filter__condition">{{localSettings.condition}}</span>
        &nbsp;
        &nbsp;
      </div>
      <div v-else>Add condition</div>
    </div>
  </div>
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent'

  export default {
    name: 'Chatbot_FilterObject',
    extends: ExtendsComponent,
    computed: {
      filterStyle () {
        return {
          borderColor: this.localSettings.color || '#4CAF50'
        }
      },
      conditionStyle () {
        return {
          background: this.localSettings.color || '#4CAF50'
        }
      }
    }
  }
</script>

<style scoped>
  .chatbot-filter {
    border: 3px solid;
    padding: 5px;
    min-width: 200px;
    border-radius: 5px;
    cursor: default;
    background: white;
    white-space: nowrap;
    box-sizing: content-box;
  }

  .chatbot-filter__condition {
    box-sizing: content-box;
    padding: 2px 5px;
    color: #fff;
    background: #4CAF50;
  }
</style>
