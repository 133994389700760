<template>
  <v-toolbar dense no-padding>
    <Uploader
      width="40px"
      height="40px"
      :disabled="readonly"
      label="Bot Avatar"
      icon="image"
      class="mx-2"
      v-model="localSettings.image"
    />

    <v-divider class="mr-2" vertical />

    <v-text-field
      class="pa-0"
      hide-details
      :readonly="readonly"
      placeholder="Bot Name"
      v-model="localSettings.name"
    />

    <v-divider class="ml-2" vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_EntryPointObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend
};
</script>
