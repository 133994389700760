<template>
  <div>
    <div>
      <div v-show="emptyState" class="emptyState">
        <v-row fluid align="stretch">
        <v-img class="emptyState-image" max-height="600px" :src="image_src" alt="No Components" aspect-ratio="1.7" contain></v-img>
        </v-row>
        <v-row justify="center" class="mt-4">
          Oh dear. Seems like you haven't created any components yet!
        </v-row>
        <v-row justify="center" class="mt-4">
          <v-btn @click="setBrowseTab" class="ma-2">Browse Components</v-btn>
          <v-btn color="primary" @click="$emit('add')" class="ma-2">Create Component</v-btn>
        </v-row>
        <div class="emptyState-text">
          <h2>No Components</h2>
        </div>
      </div>
    </div>
    <div v-masonry item-selector=".component-item">
      <ComponentItem
        v-masonry-tile
        :component="component"
        v-for="component in components"
        :key="component.id"
        @edit="$emit('edit', $event)"
      />
    </div>
  </div>
</template>


<script>
import ComponentItem from "./ComponentItem";
import {mapState} from "vuex";

export default {
  name: "MyComponents",
  components: { ComponentItem },
  data() {
    return {
      show: false,
      image_src: require("./No_Products.png")
    };
  },
  computed: {
    ...mapState({
      components: state => state.component.components,
    }),
    emptyState() {
      return this.components.length == 0;
    }
  },
  methods: {
    setBrowseTab () {
      this.$emit('setTab', 1);
    }
  }
};
</script>

<style>
.component-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 200px;
}

.emptyState {
  height: 100%;
}

.emptyState-text {
  position: fixed;
  bottom: 160px;
  right: 120px;
  font-size: 2em;
  font-weight: 900;
  background: rgba(255,255,255,0.2)
}

.emptyState-text h2:after {
  content: '';
  height: 3px;
  position: absolute;
  bottom: -10px;
  left: 0;
  background: #292929;
  transition: 0.2s;
  width: 120%;
}

.component-item {
  max-width: 320px;
  width: 100%;
  margin: 20px;
  display: inline-block;
}

@media screen and (max-width: 680px) {
  .emptyState-text {
    display: none;
  }
}


</style>
