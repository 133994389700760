import Vue from "vue";

import RssFeed from "./RssFeed";
Vue.component("RssFeed", RssFeed);

import TwitterFeed from "./TwitterFeed";
Vue.component("TwitterFeed", TwitterFeed);

import CustomComponent from "./CustomComponent";
Vue.component("CustomComponent", CustomComponent);
