<template>
  <div class="wayfinder-video" :class="slide.info.settings.position">
    <video
      v-if="video && video.body"
      :src="video.body.url"
      width="100%"
      controls
      @timeupdate="onProgress"
    />
    <div class="pa-4">
      <h2 v-if="title">{{ title }}</h2>
      <div v-if="description" v-html="description" />
      <WayfinderBody
        v-if="slideContent && slideContent.info.settings.bodyControls"
        :controls="slideContent.info.settings.bodyControls"
      />
      <WayfinderActions
        v-if="slideContent && slideContent.info.settings.actionsControls"
        :controls="slideContent.info.settings.actionsControls"
      />
    </div>
  </div>
</template>

<script>
import {parseTimestamp} from '@/lib.js';
import WayfinderBody from './WayfinderBody.vue';
import WayfinderActions from './WayfinderActions.vue';

export default {
  name: "WayfinderOverlay",
  components: {WayfinderBody, WayfinderActions},
  props: {
    slide: Object,
    slideContent: Object
  },
  data() {
    return {
      lastTime: 0
    };
  },
  computed: {
    title() {
      return this.$store.getters['wayfinder/template'](this.slide.info.settings.wayfinder_title);
    },
    description() {
      return this.$store.getters['wayfinder/template'](this.slide.info.settings.wayfinder_description);
    },
    video() {
      return this.slide.info.settings.video;
    },
    timestamps() {
      if (!this.video.timestamps)
        return null;

      return this.video.timestamps.map(timestamp => {
        return {
          ...timestamp,
          time: parseTimestamp(timestamp.time)
        };
      });
    }
  },
  methods: {
    onProgress(e) {
      if (!this.timestamps)
        return;

      const currentTime = Math.floor(e.target.currentTime);
      if (this.lastTime == currentTime)
        return;
      this.lastTime = currentTime;

      this.timestamps
        .filter(stamp => stamp.time == currentTime)
        .forEach(stamp => this.$store.commit('object/addAnimation', stamp));
    }
  }
};
</script>

<style scoped>
.wayfinder-video {
  position: absolute;
  z-index: 3;
  width: 300px;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - 100px);
}

video {
  display: block;
}

.right-bottom {
  right: 0;
  bottom: 64px;
}

.left-bottom {
  left: 0;
  bottom: 64px;
}

.right-top {
  right: 0;
  top: 0;
}

.left-top {
  left: 0;
  top: 0;
}
</style>
