<template>
  <v-toolbar dense no-padding>
    <v-btn @click="duplicate" class="toolbar-button" text>
      <v-icon>file_copy</v-icon>
    </v-btn>

    <v-divider vertical />

    <v-btn @click="destroy" class="toolbar-button" text>
      <v-icon>delete</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Core_ObjectPickerPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  methods: {
    destroy() {
      this.$store.dispatch("object/delete", this.baseObject.id);
    },
    duplicate() {
      this.$store.dispatch("object/duplicate", this.baseObject.id);
    }
  }
};
</script>