<template>
  <div>
    <div class="pa-4 text-left">
      <div class="clearfix table-header">
        <div class="pull-left">
          <h2>Owner</h2>
        </div>
        <div class="pull-right">
          <v-btn @click="newTeam" color="primary">New Team</v-btn>
        </div>
      </div>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details />
      <v-data-table :headers="headers" :items="teams" :search="search" hide-default-footer>
        <template v-slot:item="{item}">
          <tr>
            <td>{{ item.name }}</td>
            <td class="text-right">{{ item.invites_count }}</td>
            <td class="text-right">{{ item.participants_count }}</td>
            <td class="text-right">
              <v-icon small class="mr-2" @click="editTeam(item)">edit</v-icon>
              <v-icon small @click="deleteTeam(item)">delete</v-icon>
            </td>
          </tr>
        </template>
        <v-alert
          v-slot:no-results
          color="error"
          icon="warning"
        >Your search for "{{ search }}" found no results.</v-alert>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import NewTeam from "./Owner/NewTeam";
import EditTeam from "./Owner/EditTeam";

export default {
  name: "OwnerTeams",
  props: {
    teams: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Team",
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: "Unseen Invites",
          align: "right",
          sortable: true,
          value: "invites"
        },
        {
          text: "Participants",
          align: "right",
          sortable: true,
          value: "participants"
        },
        {
          text: "",
          sortable: false,
          align: "right",
          value: "actions"
        }
      ],
      search: ""
    };
  },
  methods: {
    newTeam() {
      this.$modal.show(
        NewTeam,
        {
          callback: team => {
            this.$emit("update:teams", [...this.teams, team]);
          }
        },
        {}
      );
    },
    editTeam(params) {
      this.$modal.show(
        EditTeam,
        {
          params,
          callback: team => {
            const teamIndex = this.teams.findIndex(t => t.id == team.id);
            const teams = [
              ...this.teams.slice(0, teamIndex),
              team,
              ...this.teams.slice(teamIndex + 1)
            ];
            this.$emit("update:teams", teams);
          }
        },
        {}
      );
    },
    deleteTeam({ id }) {
      if (confirm("Are you sure?")) {
        this.api.Team.delete({ id }).then(response => {
          const teams = this.teams.filter(t => t.id !== team.id);
          this.$emit("update:teams", teams);
        });
      }
    }
  }
};
</script>

<style>
</style>
