<template>
  <div class="separator" >
    <div class="separator-label" :class="{'child': child}">{{label}}</div>
    <div class="separator-actions">
      <div
        v-if="localActions.indexOf('add') != -1 && !readonly"
        class="separator-action add-action"
        @click.stop="callEmit('add')"
      >
        <v-icon color="white">add</v-icon>
      </div>
      <div
        v-if="localActions.indexOf('settings') != -1"
        class="separator-action settings-action"
        @click.stop="callEmit('settings')"
      >
        <v-icon color="#333">settings</v-icon>
      </div>
      <div
        v-if="localActions.indexOf('delete') != -1 && !readonly"
        class="separator-action delete-action"
        @click.stop="callEmit('delete')"
      >
        <v-icon color="primary">delete</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Separator",
    props: {
      child: Boolean,
      label: String,
      actions: Array,
      readonly: Boolean
    },
    data () {
      return {
        localActions: this.actions || []
      }
    },
    methods: {
      callEmit (action) {
        this.$nextTick(function () {
          this.$emit(action)
        })
      }
    }
  }
</script>

<style scoped>
  .separator {
    border-top: 1px solid #e7e7e7;
    margin: 10px 0;
  }

  .separator-label,
  .separator-actions {
    margin-top: -10px;
  }

  .separator-label {
    float: left;
    background: white;
    color: #7b7b7b;
    margin-left: 20px;
    padding: 0 8px;
  }
  .separator-label.child {
    color: #e14e9d;
  }
  .separator-actions {
    float: right;
    margin-right: 12px;
    padding: 0 8px;
    background: white;
    display: flex;
  }

  .separator-action {
    height: 20px;
    width: 20px;
    border-radius: 15px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
  }

  .add-action {
    background: #6eccb9;
    border: 1px solid #76bdaf;
  }
</style>