<template>
  <div class="chatbot-entry">
    <div class="chatbot-entry__label">Entry Point</div>
    {{localSettings.name || 'EntryPoint'}}
  </div>
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent'

  export default {
    name: 'Chatbot_EntryPointObject',
    extends: ExtendsComponent,
  }
</script>

<style scoped>
  .chatbot-entry {
    border: 3px solid #8BC34A;
    padding: 5px;
    min-width: 200px;
    border-radius: 5px;
    cursor: default;
    background: white;
    white-space: nowrap;
    box-sizing: content-box;
  }

  .chatbot-entry__label {
    position: absolute;
    top: -20px;
    font-size: 12px;
    left: 0;
    font-weight: bold;
  }
</style>
