const defaultSettings = {
  name: "Image",
  icon: 'mdi-panorama',
  hotkey: `modkey + I`,
  showLabel: false,
  size: {
    width: 100,
    height: 100,
    minHeight: 12,
    minWidth: 12,
    maxHeight: 4800,
    maxWidth: 4800
  },
  resizable: true,
  draggable: true,
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: true,
  notionOption: true,
  settings: {
    locked: false,
    order: 0,
    notionEnabled: false
  }
};

export default defaultSettings;
