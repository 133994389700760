<template>
  <div class="standard-border">
    <div class="at-general-object">
      <v-sheet
        color="grey lighten-4"
        class="px-3 pt-3 pb-3"
        v-show="loading"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </v-sheet>
      <div v-show="!loading" v-html="embedContent" class="at-general-object__embed">
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_EmbedObject",
  extends: ExtendsComponent,
  data() {
    return {
      loading: true
    };
  },
  methods: {
    
  },
  computed: {
    embedContent() {
      if (!this.localSettings.embedcode) {
        return;
      } 
      this.loading = false;
      return this.localSettings.embedcode;
    }
  }
};
</script>

<style scoped>
.at-general-object {
  height: 100%;
  width: 100%;
  padding: 12px;
}
.standard-border {
  position: absolute;
}

.at-general-object__embed {
  width: 100%;
  height: 100%;
  padding: 12px;
  background-color: white;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.at-general-object__embed >>> iframe {
  border: 0;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
</style>