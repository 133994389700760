import { types } from "./menuTypes";
import { guid } from "@/lib";

const colors = [
  "white",
  "black",
  "gray",
  "brown",
  "orange",
  "yellow",
  "green",
  "blue",
  "purple",
  "pink",
  "red"
];

const menuSettings = [
  {
    id: guid(),
    name: "Delete",
    icon: "delete",
    addon: "Del",
    handler: ctx => {
      ctx.$emit("emit", { type: "DELETE" });
      ctx.menuOpened = false;
    }
  },
  {
    id: guid(),
    name: "Duplicate",
    icon: "file_copy",
    addon: "⌘ + D",
    handler: ctx => {
      ctx.$emit("emit", { type: "DUPLICATE" });
      ctx.menuOpened = false;
    }
  },
  {
    id: guid(),
    name: "Turn Into",
    icon: "compare_arrows",
    menu: types.map(type => ({
      name: type.name,
      icon: type.icon,
      handler: ctx => ctx.$emit("emit", { type: "TURN_INTO", payload: {type: type.type} })
    }))
  },
  {
    id: guid(),
    name: "Create Sticky",
    icon: "mdi-sticker-emoji",
    addon: "⌘ + S",
    handler: ctx => {
      ctx.$emit("emit", { type: "CREATE_STICKY" });
      ctx.menuOpened = false;
    }
  },
  {
    id: guid(),
    name: "Colors",
    icon: "color_lens",
    menu: [
      ...colors.map(color => ({
        id: guid(),
        name: color,
        icon: 'text_fields',
        color,
        handler: ctx => ctx.$emit("emit", { type: "SET_COLOR", payload: color })
      })),
      ...colors.map(color => ({
        id: guid(),
        name: `${color} Background`,
        icon: 'stop',
        color,
        handler: ctx => ctx.$emit("emit", { type: "SET_BACKGROUND", payload: color })
      }))
    ]
  }
];

export default menuSettings;
