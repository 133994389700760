import GeneralObject from './General'
import PersonObject from './Person'
import OrganisationObject from './Organisation'

export default {
  objects: {
    GeneralObject,
    PersonObject,
    OrganisationObject,
  },
  tab: 'Analysis'
}