<template>
  <div class="page">
    <app-header
      title="Teams"
      :tabs="tabs"
      :activeTabInd="activeTab"
      @activeTabChanged="setActiveTab"
    />

    <v-main class="page-content">
      <Owner :teams.sync="ownerTeams" v-if="activeTab == 0" />
      <Participant :teams.sync="participantTeams" v-if="activeTab == 1" />
      <Invites v-if="activeTab == 2" />
    </v-main>
  </div>
</template>

<script>
import AppHeader from "./AppHeader";
import Owner from "./TheTeams/Owner";
import Participant from "./TheTeams/Participant";
import Invites from "./TheTeams/Invites";

export default {
  name: "TheTeams",
  components: {
    AppHeader,
    Owner,
    Participant,
    Invites
  },
  data() {
    return {
      activeTab: 0,
      ownerTeams: [],
      participantTeams: [],
      teams: [],
      tabs: [{ name: "Owner" }, { name: "Participant" }, { name: "Invites" }]
    };
  },
  created() {
    this.refreshTeams();
  },
  methods: {
    setActiveTab(value) {
      this.activeTab = value;
    },
    refreshTeams() {
      this.api.Team.get().then(res => {
        this.teams = res.body;
        this.ownerTeams = this.teams.filter(t => t.is_owner);
        this.participantTeams = this.teams.filter(t => !t.is_owner);
      });
    }
  }
};
</script>

<style scoped>
.table-header h2 {
  padding: 10px;
}
</style>
