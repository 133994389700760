<template>
  <v-card class="component-item">
    <v-img v-if="component.image" :src="component.image.body.url" height="240px" />

    <v-card-title primary-title>
      <div>
        <div class="headline">{{component.name}}</div>
        <span class="grey--text">{{component.short_description}}</span>
      </div>
    </v-card-title>

    <v-card-actions>
      <v-btn @click.native="open(component)" text color="purple">{{openBtnText}}</v-btn>
      <v-spacer />
      <v-btn v-if="!!component.description" icon @click="show = !show">
        <v-icon>{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <!-- <v-slide-y-transition> -->
    <v-card-text v-if="show">{{component.description}}</v-card-text>
    <!-- </v-slide-y-transition> -->
  </v-card>
</template>

<script>
export default {
  name: "ComponentItem",
  props: {
    component: {
      type: Object,
      default() {
        return {};
      }
    },
    fork: Boolean,
    openBtnText: {
      type: String,
      default: "Customize"
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    open(component) {
      this.$emit('edit', component);
    }
  },
  watch: {
    show() {
      this.$nextTick(function() {
        this.$redrawVueMasonry();
      });
    }
  }
};
</script>

<style scoped>
.component-item {
  max-width: 320px;
  width: 100%;
  margin: 20px;
  display: inline-block;
}
.component-item {
  transition: none !important;
}
</style>
