<template>
  <div style="height: 100%">
    <div class="editor" v-if="activeProject && activeChart">
      <app-header
          :title="activeProject.name"
          :tabs="activeProject.charts"
          :moreMenuItems="moreMenuItems"
          :activeTabInd="activeTab"
          @activeTabChanged="setActiveTab"
          @tabsChanged="setTabs"
          isDraggable
          isCollapsable
      >
        <template v-slot:tab="{ tab }">
          {{ tab.name }}
          <v-icon
              class="chart-tab-action"
              color="blue-grey lighten-4"
              size="14"
              @mousedown.stop
              @click.stop="chartSettings(tab.id)"
          >settings
          </v-icon
          >
          <!-- <v-icon
            class="chart-tab-action"
            color="blue-grey lighten-4"
            size="16"
            @mousedown.stop
            @click.stop="deleteChart(tab.id)"
            >delete</v-icon
          > -->
        </template>
        <template v-slot:afterTabs>
          <v-btn text class="toolbar-button" @click="newChart">
            <v-icon color="primary">add</v-icon>
          </v-btn>
        </template>
      </app-header>

      <v-main class="editor-content" v-if="!pending">
        <Viewport
            :scale="scale"
            :translate="translate"
            :objects="objects"
            @createObject="createObject"
            ref="viewport"
            :bgImage="
            activeChart.options.icon ? activeChart.options.icon.body.url : ''
          "
            :allowedObjectTypes="localAllowedObjectTypes"
        >
          <template v-slot:html>
            <BaseObject
                v-for="object in objects"
                :key="object.id"
                v-bind="object"
                v-if="isAllowed(object) && object.info.settings.visible != false"
            />
          </template>
          <template v-slot:svg>
            <BaseConnection
                v-bind="connection"
                v-for="connection in connections"
                :key="connection.id"
                v-if="isAllowedConnection(connection)"
            />
          </template>
        </Viewport>
        <NavigationControl
            v-if="activeChart.options && activeChart.options.navigationControlsEnabled"
            :viewport="$refs.viewport"
        />
        <PropertyEditor
            :viewport="$refs.viewport"
            :scale="scale"
            :translate="translate"
        />
        <DataEditor
            :viewport="$refs.viewport"
            :scale="scale"
            :translate="translate"
        />
        <ChartSideToolbar @createObject="createObject"/>

        <PinnedContent/>
        <UList :objects="uList" :opened.sync="uListOpened"/>
        <ObjectsFilter
            :opened.sync="filterOpened"
            :objects="objects"
            :allowedObjectTypes="localAllowedObjectTypes"
        />
        <ObjectsLayer
            :opened.sync="treeOpened"
            :viewport="$refs.viewport"
            :objects="objects"
            :allowedObjectTypes="localAllowedObjectTypes"
        />

        <v-btn
            @click="createObject()"
            color="primary"
            fab
            class="add-object-btn"
            v-if="false"
        >
          <v-icon>add</v-icon>
        </v-btn>

        <v-btn
            v-if="uList.length"
            @click="uListOpened = true"
            color="default"
            fab
            class="ulist-btn"
        >
          <v-icon>list</v-icon>
        </v-btn>

        <!--
        <v-btn
          v-if="chatbotClosed"
          @click="openChatbot"
          color="default"
          fab
          class="chatbot-btn"
        >
          <v-icon>chat</v-icon>
        </v-btn>
        -->

        <ChartObjectsToolbar
            @openFilter="filterOpened=true"
            @openTree="treeOpened=true"
        />

        <ActiveMembersToolbar
            :cursors="activeChart.users_cursors"
            :viewport="$refs.viewport"
        />
      </v-main>
      <div v-else class="spinner">
        <atom-spinner
            :animation-duration="1000"
            :size="100"
            :color="'#df4e9e'"
        />
      </div>
    </div>
    <div v-else class="spinner">
      <atom-spinner :animation-duration="1000" :size="100" :color="'#df4e9e'"/>
    </div>

    <audio
        controls
        autoplay
        style="display: none"
        ref="outputAudioElm"
    >
    </audio>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import Viewport from "./TheEditor/Viewport.vue";
import BaseObject from "./TheEditor/BaseObject.vue";
import BaseConnection from "./TheEditor/BaseConnection.vue";
import AppHeader from "./AppHeader";
import ChartSideToolbar from "./TheEditor/Toolbar/ChartSideToolbar";
import ChartObjectsToolbar from "./TheEditor/Toolbar/ChartObjectsToolbar";
import UList from "./TheEditor/UList";
import ObjectsFilter from "./TheEditor/ObjectsFilter";
import ObjectsLayer from "./TheEditor/ObjectsLayer";
import NavigationControl from "./TheEditor/Toolbar/NavigationControl";
import ActiveMembersToolbar from "./TheEditor/Toolbar/ActiveMembersToolbar";
import {isMac} from "@/lib";
import EditProject from "./Project/Edit";
import EditChart from "./Chart/Edit";
import "./TheEditor/_Global";

import {screenToVulcan} from '../../../utils/common'

import {
  ObjectTypesByModule,
  defaultProperties,
} from "./TheEditor/Object/Types";
import * as _ from "underscore";
import {
  flattenPasteArray,
  handlePaste,
} from "./TheEditor/_Global/helper";

export default {
  name: "TheEditor",
  components: {
    Viewport,
    BaseObject,
    BaseConnection,
    AppHeader,
    UList,
    NavigationControl,
    ChartSideToolbar,
    ChartObjectsToolbar,
    ObjectsFilter,
    ObjectsLayer,
    ActiveMembersToolbar
  },
  channels: {
    CollaborationsChannel: {
      connected() {
        console.log("Collaborations Channel: connected");
      },
      rejected() {
        console.log("Collaborations Channel: rejected");
      },
      received(data) {
        const {session_id: sessionId, action, object, cursor} = data;
        if (!sessionId || sessionId === sessionStorage.sessionId)
          return;

        const [objectType, objectAction] = action.split("/");

        switch (objectType) {
          case "object":
            if (object.chart_id === this.activeChart.id) {
              this.$store.commit(`object/${objectAction}`, {
                ...object,
                skipSideEffect: true
              });
            }
            break;
          case "project":
            if (objectAction == "set_cursor") {
              this.$store.commit("chart/setCursor", cursor);
            }
            this.$store.commit("project/update", object);
            break;
        }
      },
      disconnected() {
        console.log("Collaborations Channel: disconnected");
      },
    },
  },
  mounted() {
    this.setProject();
    this.$cable.subscribe({
      channel: "CollaborationsChannel",
      project_id: this.projectId,
    });
    this.$store.commit('audio/setOutputAudioElm', this.$refs.outputAudioElm);

    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("mousewheel", this.onMouseWheel, {passive: false})
  },
  beforeDestroy() {
    this.$store.dispatch('audio/disconnectFromHiFiAudio');
    this.$store.commit('audio/setOutputAudioElm', null);
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener('mousewheel', this.onMouseWheel)
  },
  data() {
    return {
      activeTab: null,
      defaultProperties: defaultProperties,
      uListOpened: false,
      chatbotClosed: true,
      filterOpened: false,
      treeOpened: false,
      activeObjectTab: "Base",
      localAllowedObjectTypes: {},
      pending: false,
      viewportWidth: 0,
      viewportHeight: 0,
      moreMenuItems: [
        {
          name: "Edit Project",
          callback: () => {
            this.$modal.show(
                EditProject,
                {
                  params: this.activeProject,
                  callback: (res) => {
                    this.activeProject = {...this.activeProject, ...res};
                  },
                },
                {scrollable: true, height: "auto"}
            );
          },
        },
        {
          name: "Delete Project",
          callback: () => {
            if (confirm("Are you sure?")) {
              this.api.Project.delete({id: this.projectId});
              this.$router.push({name: "dashboard"});
            }
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      objectMode: "chart/objectMode",
      selectedObjects: "object/selected",
    }),
    ...mapState({
      allowedObjectTypes: state => state.chart.allowedObjectTypes,
      scale: state => state.chart.scale,
      translate: state => state.chart.translate,
      selectedIds: state => state.object.selectedIds
    }),
    objects: {
      get() {
        return this.$store.getters["object/list"];
      },
      set(objects) {
        this.$store.commit("object/setList", objects);
      },
    },
    connections: {
      get() {
        return this.$store.getters["connection/list"];
      },
      set(connections) {
        this.$store.commit("connection/setList", connections);
      },
    },
    projectId() {
      return this.$route.params.projectId;
    },
    chartId() {
      return this.$route.params.chartId;
    },
    uList() {
      const ulist = this.$store.getters["object/ulist"];

      if (ulist.length === 0) {
        this.uListOpened = false;
      }

      return ulist;
    }
  },
  methods: {
    deselect() {
      this.$root.$emit("PropertyEditor.close");
      this.$store.commit("object/setContentEditable", false);
      this.$store.commit("object/deselectAll");
    },
    onMouseMove({x, y}) {
      this.mouseCoordinates = {x, y}
    },
    onMouseWheel(event) {
      //event.preventDefault()
    },
    groupAction() {
      const removed = () => {
        if (this.selectedIds.length != 1)
          return false;

        const groups = this.$store.getters["object/list"].filter(
            (object) =>
                object.type == "Base_GroupObject" &&
                object.info.settings.objectIds.includes(this.selectedIds[0])
        );

        if (!groups.length)
          return false;

        groups.forEach((group) => {
          group.info.settings.objectIds = group.info.settings.objectIds.filter(
              (id) => id != ids[0]
          );
          this.$store.dispatch("object/update", group);
        });
        return true;
      };

      if (!removed())
        this.$store.dispatch("object/createGroup");
    },

    onKeyDown(event) {
      if (event.target.tagName != "BODY")
        return;

      if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey)
        return;

      //event.preventDefault();

      if (this.selectedIds.length) {
        const delta = 10;
        const directionDelta = 10;

        this.selectedObjects.forEach(object => {
          const comments = this.$store.getters["object/comments"](object);
          const arrows = this.$store.getters["object/arrows"](object.id);

          const updatePosition = (dx, dy) => {
            this.$store.dispatch(
                "object/update",
                {
                  id: object.id,
                  position: {
                    x: object.position.x + dx,
                    y: object.position.y + dy
                  }
                }
            );

            comments.forEach(comment => {
              this.$store.dispatch(
                  "object/update",
                  {
                    id: comment.id,
                    position: {
                      x: comment.position.x + dx,
                      y: comment.position.y + dy
                    }
                  }
              );
            });

            arrows.forEach(arrowStore => {
              const {arrow} = arrowStore;
              if (!arrow)
                return;

              const info = {
                ...arrow.info,
                settings: {
                  ...arrow.info.settings,
                  [`${arrowStore.which}X`]: arrow.info.settings[`${arrowStore.which}X`] + dx,
                  [`${arrowStore.which}Y`]: arrow.info.settings[`${arrowStore.which}Y`] + dy
                }
              };

              this.$store.dispatch("object/update", {
                id: arrow.id,
                info
              });
            });
          };

          let angle = isNaN(Number(object.info.settings.angle)) ? 0 : Number(object.info.settings.angle);
          const updateAngle = () => {
            this.$store.dispatch(
                "object/update",
                {
                  id: object.id,
                  info: {
                    ...object.info,
                    settings: {
                      ...object.info.settings,
                      angle
                    }
                  }
                }
            );
          };

          switch (event.key) {
            case "ArrowLeft":
              updatePosition(-delta, 0);
              break;
            case "ArrowRight":
              updatePosition(delta, 0);
              break;
            case "ArrowDown":
              updatePosition(0, delta);
              break;
            case "ArrowUp":
              updatePosition(0, -delta);
              break;

            case "a":
              angle = (angle - directionDelta + 360) % 360;
              updateAngle();
              break;
            case "d":
              angle = (angle + directionDelta) % 360;
              updateAngle();
              break;
          }
        });

      } else {

        switch (event.key) {
          case "ArrowLeft":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x + 10,
              y: this.translate.y,
            });
            break;
          case "ArrowRight":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x - 10,
              y: this.translate.y,
            });
            break;
          case "ArrowDown":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x,
              y: this.translate.y - 10,
            });
            break;
          case "ArrowUp":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x,
              y: this.translate.y + 10,
            });
            break;
        }
      }
    },

    async onKeyUp(event, position) {
      const modKey = isMac() ? event.ctrlKey : event.metaKey;

      if (event.altKey) {
        switch (event.key) {
          case "ArrowUp":
            for (let id of this.selectedIds) {
              if (event.shiftKey) this.$store.dispatch("object/orderFront", id);
              else this.$store.dispatch("object/orderForward", id);
            }
            this.deselect();
            break;

          case "ArrowDown":
            for (let id of this.selectedIds) {
              if (event.shiftKey) this.$store.dispatch("object/orderBack", id);
              else this.$store.dispatch("object/orderBackward", id);
            }
            this.deselect();
            break;
        }
        return;
      }

      if (event.target.tagName == "BODY") {
        if (event.keyCode == 8 || event.keyCode == 46) {
          this.$store.dispatch("object/deleteSelected");
          return;
        }
      }

      if (event.key == "Escape" && this.objectMode) {
        this.$store.dispatch("chart/setObjectMode", null);
      }

      this.$store.dispatch("chart/setActiveTool", null, null)

      if (modKey) {
        switch (event.key) {
          case "n":
            //this.createObject(null, "Core_ObjectPicker");
            break;
          case "g":
            this.createObject(null, "AnalysisTools_GeneralObject");
            break;
          case "G":
            if (event.shiftKey)
              this.groupAction();
            break;
          case "o":
            this.createObject(null, "AnalysisTools_OrganisationObject");
            break;
          case "p":
            this.createObject(null, "AnalysisTools_PersonObject");
            break;
          case "t":
            this.createObject(null, "Base_TextObject");
            break;
          case "T":
            if (event.shiftKey)
              this.createObject(null, "Base_TemplateObject");
            break;
          case "r":
            this.createObject(null, "Base_RectObject", defaultProperties["Base_RectObject"].size);
            break;
          case "i":
            this.createObject(null, "Base_ImageObject");
            break;
          case "a":
            this.createObject(null, "Base_ArrowObject");
            break;
          case "c":
            await navigator.clipboard.writeText(JSON.stringify(this.selectedObjects));
            break;
          case "v":
          case "V":
            try {
              const objects = JSON.parse(await navigator.clipboard.readText())

              // turning mouse coords into board coords
              const mouseVulcanCoords = screenToVulcan(this.mouseCoordinates.x, this.mouseCoordinates.y)
              const idMap = {}
              const cornerX = Math.min(...objects.map((el) => el.position.x))
              const cornerY = Math.min(...objects.map((el) => el.position.y))

              const {objects: flatList} = flattenPasteArray(objects, this.$store)

              flatList.map(async oldObject => handlePaste(this.$store, oldObject, mouseVulcanCoords, idMap, {
                    x: cornerX,
                    y: cornerY
                  })
              )
            } catch (e) {
              console.info(e, 'encountered an error while pasting from the buffer')
            }

            break;
          case "C":
            this.createObject(null, "Base_CommentObject");
            break;
          case "s":
            this.createObject(null, "Base_StickyObject");
            break;
          case "S":
            if (event.shiftKey) {
              this.createObject(null, "Base_SymbolObject");
            }
            break;
          case "e":
            this.createObject(null, "Base_EmbedObject");
            break;
          case "w":
            this.createObject(null, "Presentation_Wayfinder");
            break;

          case "l":
            for (let object of this.selectedObjects) {
              object.info.settings.locked = !object.info.settings.locked;
              this.$store.dispatch("object/update", object);
            }
            break;
          case "d":
            for (let id of this.selectedIds)
              this.$store.dispatch("object/duplicate", id);
            break;
          case "D":
            if (event.shiftKey) {
              if (!this.objectMode)
                this.$store.dispatch("chart/setObjectMode", "drawing");
            }
            break;
        }
      }
    },
    setProject(id) {
      this.$store.dispatch("chart/set", null);
      const projectId = id || this.projectId;
      this.$store
          .dispatch("project/set", projectId)
          .then(this.setChart, (err) => {
            // alert("Project not found");
            this.$router.push({name: "dashboard"});
          });
    },
    openChatbot() {
      const chatbot = new Chatbot({
        id: "8402cfa6-0a0f-d52c-ccfc-5ecc582cb67d",
        signal: {},
        Model: {
          access_token: this.$store.getters["user/token"],
          current_user: this.currentUser,
          username: this.currentUser.username,
        },
        endpointServer: "https://developers.beach.io",
        styles: {},
      });
      chatbot.run();
    },
    setChart: _.debounce(function (id) {
      const chartId = id || this.chartId;
      this.$store.dispatch("chart/set", chartId).then(
          () => {
            this.activeTab = this.activeProject.charts.findIndex(
                (c) => c.id == chartId
            );
            this.pending = false;
          },
          (err) => {
            alert("Chart not found");
            this.$router.push({name: "dashboard"});
          }
      );
    }, 100),
    isAllowed(object) {
      return this.allowedObjectTypes && this.allowedObjectTypes[object.type];
    },
    isAllowedConnection(connection) {
      return this.objects.find(
          (o) =>
              (o.id == connection.from || o.id == connection.to) &&
              this.isAllowed(o)
      );
    },
    chartSettings(chartId) {
      const chart = this.activeProject.charts.find((c) => c.id == chartId);
      this.$modal.show(
          EditChart,
          {
            params: chart,
            callback: (res) => {
              this.$store.dispatch("chart/update", res);

              // if moving chart to another project
              setTimeout(() => {
                if (res.project_id && res.project_id != this.activeProject.id)
                  this.$router.push({
                    name: "editor",
                    params: {
                      chartId: res.id,
                      projectId: res.project_id,
                    },
                  });
              }, 400);
            },
          },
          {scrollable: true, height: "auto"}
      );
    },
    deleteChart(chartId) {
      if (confirm("You are going to delete the chart. Are you sure?")) {
        const index = this.activeProject.charts.findIndex(
            (chart) => chart.id == chartId
        );
        this.activeProject.charts.splice(index, 1);
        this.$nextTick(function () {
          if (index <= this.activeTab) {
            this.activeTab = this.activeTab - 1;
          }
        });
        this.api.Chart.delete({id: chartId});
      }
    },
    newChart() {
      const id = this.guid();
      this.$modal.show(
          EditChart,
          {
            params: {id},
            callback: (res) => {
              const params = {
                ...res,
                id,
                project_id: this.projectId,
              };

              this.api.Chart.save({chart: params}).then((res) => {
                this.activeProject.charts.push(params);
                this.activeTab = this.activeProject.charts.length - 1;
              });
            },
          },
          {scrollable: true, height: "auto"}
      );
    },
    setActiveTab(val) {
      this.activeTab = val;
    },
    setTabs(val) {
      this.activeProject.charts = val;
      this.activeProject.charts.forEach((chart, ind) => {
        chart.options.order = ind;
        this.$store.dispatch("chart/update", chart);
      });
    },
    async createObject(event, type, additionalSettings = {}) {
      this.$root.$emit("PropertyEditor.close");
      this.$store.commit("object/setContentEditable", false);
      this.$store.commit("object/deselectAll");

      type = type ? type : "Base_StickyObject"

      const rect = this.$refs.viewport.$el.getBoundingClientRect();
      let position;
      if (event) {
        position = screenToVulcan(event.x, event.y, true)
      } else {
        position = {
          x: Math.round((-this.translate.x + rect.width / 2) / this.scale),
          y: Math.round((-this.translate.y + rect.height / 2) / this.scale),
        };
      }

      const objectParams = {
        type,
        position,
        info: {
          settings: {
            ...additionalSettings,
            title: "",
            image: null,
            components: ObjectTypesByModule[this.activeProject.type] || [],
          },
        },
      };

      if ((type == "Base_DrawingObject" || type == "Base_RectObject" || type == "Base_PolygonObject") && additionalSettings)
        objectParams.size = {
          width: additionalSettings.width,
          height: additionalSettings.height,
        };

      const res = await this.$store.dispatch("object/create", objectParams);
      await this.$nextTick();
      await this.$nextTick();
      const object = this.$store.getters["object/findById"](res.id);

      let x = Math.round(object.position.x - object.size.width / 2);
      const screenX = -Math.round(this.translate.x / this.scale);
      x = Math.max(x, screenX + 20);
      x = Math.min(
          x,
          screenX + rect.width / this.scale - object.size.width - 20
      );
      let y = Math.round(object.position.y - object.size.height / 2);
      const screenY = -Math.round(this.translate.y / this.scale);
      y = Math.max(y, screenY + 20);
      y = Math.min(
          y,
          screenY + rect.height / this.scale - object.size.height - 20
      );

      this.$store.dispatch("object/update", {
        id: object.id,
        position: {x, y},
      });
    },
    fillFilter() {
      this.objects.forEach((o) => {
        if (!this.localAllowedObjectTypes.hasOwnProperty(o.type))
          this.$set(this.localAllowedObjectTypes, o.type, true);
      });

      for (let type in this.localAllowedObjectTypes) {
        if (!this.objects.find((o) => o.type == type))
          this.$delete(this.localAllowedObjectTypes, type);
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.uListOpened = false;
    this.chatbotClosed = true;
    this.filterOpened = false;
    this.treeOpened = false;

    if (to.params.projectId != this.projectId) {
      this.$store.dispatch('audio/disconnectFromHiFiAudio');
      this.setProject(to.params.projectId);
    } else if (!this.activeChart || to.params.chartId != this.activeChart.id) {
      this.$store.dispatch('audio/disconnectFromHiFiAudio');
      this.pending = true;
      this.setChart(to.params.chartId);
    }
    this.$store.commit("intellisense/resetModel");
    next();
  },
  watch: {
    activeTab: _.debounce(function () {
      const activeChart =
          this.activeProject && this.activeProject.charts[this.activeTab];

      if (activeChart) {
        const projectId = this.projectId,
            chartId = activeChart.id;
        if (chartId != this.chartId) {
          this.$router.push({name: "editor", params: {projectId, chartId}});
        }
      }
    }, 100),
    objects: {
      handler() {
        this.fillFilter();
      },
    },
    allowedObjectTypes: {
      handler() {
        if (this.localAllowedObjectTypes != this.allowedObjectTypes)
          this.localAllowedObjectTypes = this.allowedObjectTypes;
        this.fillFilter();
      },
    },
    localAllowedObjectTypes: {
      handler() {
        this.$store.dispatch(
            "chart/setAllowedObjectTypes",
            this.localAllowedObjectTypes
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.editor {
  height: 100%;
}

.editor-content {
  height: 100%;
  overflow: hidden;
}

.add-object-btn,
.ulist-btn,
.chatbot-btn,
.filter-objects-btn,
.objects-tree-btn {
  position: absolute;
  z-index: 2;
  border-radius: 6px 20px 20px 20px;
}

.add-object-btn {
  left: 50px;
  top: 50px;
}

.ulist-btn {
  right: 50px;
  top: 120px;
}

.chatbot-btn {
  right: 50px;
  bottom: 50px;
}

.filter-objects-btn {
  right: 50px;
  top: 50px;
}

.objects-tree-btn {
  right: 120px;
  top: 50px;
}

.select-all-text {
  color: rgba(0, 0, 0, 0.6);
}

.v-icon.chart-tab-action {
  position: relative;
  left: 5px;
  margin-left: 4px;
}

.v-icon.chart-tab-action:hover {
  background: #e7e7e7;
}

@media screen and (max-width: 680px) {
  .add-object-btn {
    right: 10px;
    bottom: 10px;
  }
}
</style>
