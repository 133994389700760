<template>
  <div>
    <div class="pa-4 text-left">
      <div class="clearfix table-header">
        <div class="pull-left">
          <h2>Participant</h2>
        </div>
      </div>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details />
      <v-data-table :headers="headers" :items="teams" :search="search" hide-default-footer>
        <template v-slot:item="{item}">
          <tr>
            <td>{{ item.name }}</td>
            <td class="text-right">
              <v-icon small @click="deleteTeam(item)">delete</v-icon>
            </td>
          </tr>
        </template>
        <v-alert
          v-slot:no-results
          color="error"
          icon="warning"
        >Your search for "{{ search }}" found no results.</v-alert>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParticipantTeams",
  props: {
    teams: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Team",
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: "",
          sortable: false,
          align: "right",
          value: "actions"
        }
      ],
      search: ""
    };
  }
};
</script>

<style>
</style>
