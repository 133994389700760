<template>
  <div class="mt-5">
    <div v-html="settings.title" />
    <v-radio-group class="mt-0 pt-0" hide-details v-if="settings.options && settings.options.length" v-model="model">
      <v-radio
        class="mt-2 mb-0 pt-1"
        hide-details
        :disabled="disabled"
        v-for="option in settings.options"
        :key="option.id"
        :label="option.value"
        :value="option"
      />
    </v-radio-group>
  </div>
</template>

<script>
  export default {
    name: 'ControlElement_RadioList',
    props: {
      settings: Object,
      disabled: Boolean
    },
    data () {
      return {
        model: null
      }
    },
    watch: {
      model (value) {
        this.$store.commit(`wayfinder/updateCurrentScope`, {
          [this.settings.model]: {id: value.name, display_name: value.value}
        })
      }
    },
    created () {
      this.$store.commit(`wayfinder/updateCurrentScope`, {
        [this.settings.model]: null
      })
    }
  }
</script>