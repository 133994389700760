<template>
  <v-dialog v-model="dialogOpened" width="350" :disabled="disabled">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top :disabled="!tooltip">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
              class="toolbar-button"
              tile
              v-on="{ ...tooltip, ...dialog }"
          >
            <div class="color-preview" :style="backgroundPreview()"/>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title
          class="headline"
          primary-title
      >
        {{ title }}
      </v-card-title>
      <v-card-text>
        <ColorPicker class="colorPicker" @input="updateValue" :value="value" :presetColors="presetColors"/>
        <p class="dialog__text">You can create custom colour pallets in your Project Settings.</p>
        <v-btn
            @click="dialogOpened = false"
            color="secondary"
        >ok
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ColorPickerDialog",
  props: ['title', 'presetColors', 'value', 'disabled', 'secondColor', 'tooltip', 'groupColors', 'handleGroup'],

  data() {
    return {
      dialogOpened: false,
    }
  },
  computed: {
    isGroup() {
      return Array.isArray(this.groupColors) && this.groupColors.length
    },
  },

  methods: {
    updateValue(value) {
      this.$emit("input", value);

      if (this.handleGroup) {
        this.handleGroup(value)
      }
    },
    backgroundPreview() {
      return `background: ${this.getBackground()}`
    },
    getBackground() {
      if (this.groupColors && this.groupColors.length) {
        if (this.groupColors.length === 1) {
          return this.groupColors[0]
        }
        return `linear-gradient(90deg, ${this.groupColors.join(', ')})`
      } else return this.value ?? "#FFFFFF"
    }
  },
}
</script>

<style scoped>
.color-preview {
  height: 25px;
  width: 25px;
  border: 1px solid #fefefe;
  border-radius: 50%;
}

</style>
