<template>
  <div class="chatbot__set-value" :style="borderStyle">
    <div style="padding: 5px">
      <div v-if="localSettings.modelProperty">
        <span :style="valueStyle" class="chatbot__set-value__value">
          Model.{{localSettings.modelProperty}} = {{localSettings.value}}
        </span>
      </div>
      <div v-else>Set up value</div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Chatbot_SetValueObject',
  extends: ExtendsComponent,
  computed: {
    borderStyle () {
      return {
        borderColor: this.localSettings.color || '#4CAF50'
      }
    },
    valueStyle () {
      return {
        background: this.localSettings.color || '#4CAF50'
      }
    }
  }
}
</script>

<style scoped>
.chatbot__set-value {
  border: 3px solid;
  padding: 5px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
  white-space: nowrap;
  box-sizing: content-box;
}

.chatbot__set-value__value {
  box-sizing: content-box;
  padding: 2px 5px;
  color: #fff;
  background: #4CAF50;
}
</style>
