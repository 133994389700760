<template>
  <div class="wayfinder__content">
    <div>
      <Controls
        :baseObject="baseObject"
        type="body"
        label="Body"
        class="wayfinder__content__body"
        :controls.sync="localSettings.bodyControls"
        group="body"
        customComponent
        :readonly="readonly"
      />
      <br />
      <Controls
        :baseObject="baseObject"
        type="actions"
        label="Actions"
        class="wayfinder__content__actions"
        :controls.sync="localSettings.actionsControls"
        group="actions"
        :readonly="readonly"
      />
    </div>
  </div>
</template>

<script>
import Base_TextObject from "../../Base/TextObject/Component";
import Controls from "@/components/_Common/ControlElements/Controls";
import ExtendsComponent from "../../_extends/ObjectComponent";

import { deepClone } from "@/lib";

export default {
  name: "Presentation_WayfinderContentObject",
  extends: ExtendsComponent,
  components: {
    Base_TextObject,
    Controls
  },
  data() {
    const localSettings = {
      bodyControls: [],
      actionsControls: [],
      ...deepClone(this.settings)
    };
    return {
      localSettings,
      controlPickerOpened: false,
      controlPickerSectionName: null
    };
  },
  methods: {
    setModel() {
      const controls = []
        .concat(this.baseObject.info.settings.actionsControls || [])
        .concat(this.baseObject.info.settings.bodyControls || []);

      const models = controls
        .map(a => {
          if (a.settings.model)
            return a.settings.model;
        })
        .filter(a => a);

      const schema = {};
      models.forEach(e => schema[e] = true);
      this.$store.commit("intellisense/setModel", schema);
    },
    addMessage() {
      this.localSettings.messages.push({ id: this.guid(), content: '' });
    },
    deleteMessage(id) {
      const index = this.localSettings.messages.findIndex(m => m.id == id);
      this.localSettings.messages.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.wayfinder__content {
  border: 4px solid #605da5;
  border-radius: 20px;
  padding: 20px;
  background: white;
  position: relative;
  width: 380px;
}
.wayfinder__content__message {
  background: #555fa6;
  border-radius: 5px;
  margin-bottom: 10px;
}
.wayfinder__content__delete-message {
  color: white !important;
  cursor: pointer;
}
.wayfinder__content__body {
  min-height: 90px;
}
.wayfinder__content__actions {
  min-height: 58px;
}
.wayfinder__content__body,
.wayfinder__content__actions {
  margin-top: 15px;
}
</style>