import { deepClone } from '@/lib';

export default {
  props: {
    settings: Object,
    readonly: Boolean
  },
  data () {
    return {
      localSettings: deepClone(this.settings)
    }
  },
  watch: {
    settings: {
      handler () {
        if (JSON.stringify(this.localSettings) != JSON.stringify(this.settings))
          this.localSettings = this.settings;

      },
      deep: true
    },
    localSettings: {
      handler() {
        this.$emit('update:settings', this.localSettings)
      },
      deep: true
    }
  }
}