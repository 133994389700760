<template>
  <div>
    <draggable v-model="localValue" handle=".drag" :disabled="readonly" >
      <div class="form-row mb-5" v-for="(option, index) in localValue" :key="option.id">
        <v-layout align-center>
          <v-flex xs1 class="option-icon">
            <v-icon class="drag" :class="readonly ? `disabled-icon` : null">drag_handle</v-icon>
          </v-flex>
          <!-- Dimension 1 -->
          <v-flex xs11 v-if="dimension == 1">
            <v-text-field
              :autofocus="!option.value"
              @keyup.enter.native="index == localValue.length - 1 ? addOption() : null"
              v-model="option.value"
              :label="itemName || 'Option'"
              :readonly="readonly"
            />
          </v-flex>

          <!-- Dimension 2 -->
          <v-flex xs6 v-if="dimension == 2">
            <v-text-field v-model="option.name" :label="nameLabel" :readonly="readonly" />
          </v-flex>
          <v-flex xs1 v-if="dimension == 2"/>
          <v-flex xs6 v-if="dimension == 2">
            <v-text-field v-model="option.value" :label="valueLabel" :readonly="readonly" />
          </v-flex>

          <v-btn text class="button-icon" @click="deleteOption(option.id)" :disabled="readonly">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-layout>
      </div>
    </draggable>
    <div class="add" @click="addOption" v-if="!readonly">Add {{itemName || 'Option'}}</div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { deepClone } from "@/lib";

export default {
  name: "OptionsList",
  props: {
    value: Array,
    itemName: String,
    nameLabel: {
      type: String,
      default: "Name"
    },
    valueLabel: {
      type: String,
      default: "Value"
    },
    dimension: {
      type: Number,
      default: 1
    },
    readonly: Boolean
  },
  components: {
    draggable
  },
  data() {
    const localValue = deepClone(this.value || []);
    return {
      localValue
    };
  },
  methods: {
    addOption() {
      this.localValue.push({ id: this.guid() });
      this.$emit("input", this.localValue);
    },
    deleteOption(optionId) {
      this.localValue = this.localValue.filter(option => option.id != optionId);
      this.$emit("input", this.localValue);
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue = deepClone(this.value || []);
        }
      },
      deep: true
    },
    localValue: {
      handler() {
        this.$emit("input", this.localValue);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.form-row {
  height: 50px;
}

.option-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  min-width: 34px;
}

.drag {
  cursor: move;
  text-align: left;
  flex: auto;
}

.disabled-icon {
  opacity: .5;
  cursor: auto;
}

.delete {
  text-align: right;
  flex: auto;
}
</style>
