<template>
  <svg width="100%" height="100%">
    <polygon v-if="points" :fill="fill" :stroke="stroke" :stroke-width="strokeWidth"
      :points="points" />
  </svg>
</template>

<script>
import * as d3 from "d3";
import ExtendsComponent from "../../../_extends/ObjectComponent";

export default {
  name: "Polygon_Triangle",
  props: ["selected"],
  extends: ExtendsComponent,
  data () {
    return {
      points: this.path(),
      fill: this.settings.backgroundColor,
      stroke: this.settings.borderColor,
      strokeWidth: this.settings.strokeWidth
    };
  },
  methods: {
    path() {
      const points = [];
      let x, y;
      const dx = Math.round(this.settings.strokeWidth / 2) + 1, dy = Math.round(this.settings.strokeWidth / 2) + 1;
      // The first point
      x = this.baseObject.size.width / 2;
      y = dy;
      points.push(`${x},${y}`);
      // The second point
      x = dx;
      y = this.baseObject.size.height - dy;
      points.push(`${x},${y}`);
      // The third point
      x = this.baseObject.size.width - dx;
      points.push(`${x},${y}`);
      return points.join(" ")
    }
  },
  watch: {
    settings: {
      handler() {
        this.fill = this.settings.backgroundColor;
        this.stroke = this.settings.borderColor;
        this.strokeWidth = this.settings.strokeWidth;
      },
      deep: true
    },
    'baseObject.size': {
      handler() {
        this.points = this.path();
      },
      deep: true
    },
  }
};
</script>
