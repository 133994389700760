<template>
  <div class="object-picker" ref="objectPicker" id="objectPicker">
    <v-tabs grow v-model="activeTab" background-color="primary" dark>
      <v-tab
          :ripple="false"
          v-for="tab in tabs"
          :key="tab"
          class="pa-1"
          style="min-width: 60px"
      >{{ tab }}
      </v-tab>
      <v-tab-item v-for="tab in tabs" :key="tab">
        <v-card flat>
          <div
              v-for="(item, index) in tabsContent[tab]"
              :key="index"
              @click.stop="createObject(item.component, $event)"
              class="object-picker__item"
              :class="`object-picker__item--${item.component}`"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <div class="object-picker__item__text">{{ item.name }}</div>
            <v-spacer/>
            <div class="object-picker__item__hotkey">{{ item.hotkey }}</div>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import {defaultProperties} from "../../index";
import ExtendsComponent from "../../_extends/ObjectComponent";
import {deepClone, isMac} from "@/lib";
import * as _ from "underscore";

export default {
  name: "Core_ObjectPicker",
  extends: ExtendsComponent,
  data() {
    const tabs = _.uniq(
        this.settings.components
            .map(c => defaultProperties[c].tab)
            .concat(this.$store.getters['component/activeCategories'])
    );

    const modKey = isMac() ? "Ctrl" : "Win";

    const tabsContent = {};
    for (let tab of tabs) {
      tabsContent[tab] = this.settings.components
          .filter(c => defaultProperties[c].tab == tab && !!defaultProperties[c].name)
          .filter(c => !defaultProperties[c].subToolbar)
          .map(c => {
            const hotkey = defaultProperties[c].hotkey ?
                defaultProperties[c].hotkey.replace('modkey', modKey) :
                null;
            return {
              component: c,
              name: defaultProperties[c].name,
              icon: defaultProperties[c].icon,
              hotkey
            };
          });

      tabsContent[tab] = tabsContent[tab]
          .concat(this.$store.getters['component/componentsObjects']
              .filter(c => c.object_settings.category == tab)
              .map(c => ({
                component: c,
                name: c.name
              }))
          );
    }

    return {
      tabs,
      tabsContent,
      activeTab: 0,
      defaultProperties,
      localSettings: deepClone(this.settings)
    };
  },
  mounted() {
    if (this.settings.components.length == 1) {
      this.createObject(this.settings.components[0]);
    }
  },
  methods: {
    async createObject(type, event) {
      if (this.readonly)
        return;

      let position;
      if (event) {
        const {scale, translate} = this.$store.state.chart;
        const rect = this.$parent.$parent.$el.getBoundingClientRect();
        position = {
          x: Math.round((event.x - translate.x - rect.x) / scale),
          y: Math.round((event.y - translate.y - rect.y) / scale)
        };
      } else {
        position = {
          x: Math.round(this.baseObject.position.x + this.baseObject.size.width / 2),
          y: Math.round(this.baseObject.position.y + this.baseObject.size.height / 2)
        };
      }

      let componentId;
      if (typeof type != 'string') {
        componentId = type.id;
        type = "Core_CustomComponent";
      }

      const objectParams = {
        type,
        position,
        info: {
          settings: {
            title: "",
            image: null,
            componentId
          }
        }
      };

      const res = await this.$store.dispatch("object/create", objectParams);
      await this.$nextTick();
      await this.$nextTick();
      const object = this.$store.getters["object/findById"](res.id);

      if (object.size) {
        await this.$store.dispatch("object/update", {
          id: object.id,
          position: {
            x: object.position.x - object.size.width / 2,
            y: object.position.y - object.size.height / 2
          }
        });
      }

      await this.$nextTick();
      const connection = this.$store.getters["connection/find"]({
        to: this.baseObject.id
      })[0];

      if (connection) {
        await this.$store.dispatch("connection/create", {
          from: connection.from,
          to: object.id
        });
      }
      this.$store.dispatch("object/delete", this.baseObject.id);
      this.$root.$emit("PropertyEditor.close");
    }
  },
  watch: {
    activeTab() {
      this.$parent.updateSize();
    }
  }
};
</script>

<style scoped>
.object-picker {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  background: white;
  min-width: 250px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.object-picker__item {
  cursor: pointer;
  padding: 13px 25px;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  white-space: nowrap;
  text-align: left;
  display: flex;
}

.object-picker__item:last-child {
  border: none;
}

/* .object-picker__item--Base_TemplateObject {
  background: #ffe0004d;
} */

.object-picker__item__text {
  margin-left: 20px;
  color: #292929;
}

.object-picker__item__hotkey {
  color: #ccc;
  font-size: 0.8em;
  margin-top: 2%;
}
</style>
