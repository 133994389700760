<template>
  <transition name="flip">
    <div class="objects-tree-view" v-if="opened">
      <v-card class="border px-4 d-flex flex-column" style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>Layer</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click.native="toggleOpenAll">
              <v-icon>{{ openAll ? 'folder_open' : 'folder' }}</v-icon>
            </v-btn>
            <v-btn icon @click.native="$emit('update:opened', false)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <div>
          <v-text-field
            v-model="search"
            label="Quick Search Chart Objects"
            grey
            filled
            dense
            rounded
            flat
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </div>

        <div class="list">
          <v-treeview
            :open.sync="openedNodes"
            :items="items"
            activatable
            item-key="id"
            color="pink"
            hoverable
            rounded
            open-on-click
            :active.sync="active"
            :search="search"
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <draggable :list="items" group="node" :id="item.id" :data-parent="item.parentId" @move="move"
                @start="checkStart" @end="checkEnd" :fixed="item.locked" :sort="true">
                <v-hover v-slot:default="{ hover }">
                  <div>
                    <span id="item.id" >{{ item.title ? item.title : item.name }}</span>
                    <v-icon v-if="hover" class="append-right" @click.stop="toggleLock(item)">{{ item.locked ? 'lock' : 'lock_open' }}</v-icon>
                    <v-icon v-if="hover" class="append-right" @click.stop="toggleVisible(item)">{{ item.visible ? 'visibility' : 'visibility_off' }}</v-icon>
                    <v-icon v-if="hover" class="append-right" @click.stop="navigateTo(item.id)">gps_not_fixed</v-icon>
                  </div>
                </v-hover>  
              </draggable>
            </template>
          </v-treeview>
        </div>
      </v-card>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from "vuex";
  import draggable from 'vuedraggable';
  import * as _ from "underscore";
  import { defaultProperties } from "@/components/Layout/Authorized/TheEditor/Object/Types";

  const sorter = (a, b) => {
    const aOrder = isNaN(a.order) ? -1 : a.order;
    const bOrder = isNaN(b.order) ? -1 : b.order;
    return aOrder > bOrder ? 1 : -1;
  }

  const arrayCompare = (array1, array2) => {
    const array2Sorted = array2.slice().sort();
    return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
        return value === array2Sorted[index];
    });
  }


  export default {
    name: "ObjectsLayer",
    props: {
      objects: Array,
      opened: Boolean,
      viewport: Object
    },
    data: () => {
      return {
        openedNodes: [],
        items: [],
        active: [],
        draggingNode: null,
        search: null,
        openAll: false,
        parentNodes: []
      }
    },
    mounted() {
      this.generateTreeItems();
    },
    computed: {
      ...mapGetters({
        selectedIds: "object/selectedIds"
      })
    },
    methods: {
      // Map objects(prop) to tree-ready items
      generateTreeItems() {
        this.inGroups = [];
        this.parentNodes = [];
        this.items = this.objects
          .map(object => this.populateObject(object, null))
          .filter(object => this.inGroups.includes(object.id) == false)
          .sort(sorter);
      },
      // Drag Event handler
      checkStart: function (event) {
        const item = this.findTreeItem(this.items, event.from.id);
        if (item.locked) return false; // For locked item, we don't handle drag/drop too
        this.draggingNode = item;
      },
      // Core Method: Drop event handler
      // - Sort handling(Move between the same level)
      // - Remove from fromParent
      // - Add to toParent
      checkEnd: function (event) {
        const itemSelected = this.draggingNode;
        if (!itemSelected) return false;
        const fromParent = itemSelected.parentId ? this.findTreeItem(this.items, itemSelected.parentId) : null;
        const toParent = this.findTreeItem(this.items, event.to.id);
        if (!toParent || toParent.locked) return false;

        // - Sort handling(Move between the same level)
        if (itemSelected.parentId == toParent.parentId && (toParent.type != "Base_GroupObject")) {
          this.$store.dispatch("object/moveItem", {objectId: itemSelected.id, targetPositionObjectId: toParent.id});
          return;
        }

        // - Droppable check
        if (toParent.type != "Base_GroupObject") 
          return false;


        // - Remove from fromParent
        const objFrom = fromParent ? fromParent.children : this.items;
        objFrom.splice(objFrom.indexOf(itemSelected), 1);
        if (fromParent) {
          const fromParentObject = this.$store.getters["object/findById"](fromParent.id);
          fromParentObject.info.settings.objectIds = fromParentObject.info.settings.objectIds ? fromParentObject.info.settings.objectIds.filter(objId => objId != itemSelected.id) : [];
          fromParentObject.info.settings.commentIds = fromParentObject.info.settings.commentIds ? fromParentObject.info.settings.commentIds.filter(objId => objId != itemSelected.id) : [];
          this.$store.dispatch("object/update", fromParentObject);
        }

        // - Add to toParent
        if (toParent.id === itemSelected.id) {
          itemSelected.parentId = null;
          this.inGroups.splice(this.inGroups.indexOf(itemSelected.id), 1);
          this.items.push(itemSelected);
        } else {
          itemSelected.parentId = toParent.id;
          toParent.children.push(itemSelected);
          this.$store.commit("object/addToGroup", {
            groupId: toParent.id,
            objectId: itemSelected.id
          });
        }
        return false;
      },
      // Core method for building tree
      // - Populate all of its children by recursive way, to have its children and parent Id information
      // - Map object to tree node with only necessary fields 
      populateObject: function(object, parentId) {
        let children = [];
        // - Populate all of its children by recursive way, to have its children and parent Id information
        const objectIds = object.info.settings.objectIds || [];
        const commentIds = object.info.settings.commentIds || [];
        const childrenIds = [...objectIds, ...commentIds];
        if (childrenIds && childrenIds.length > 0) {
          children = _.uniq(childrenIds)
            .map(id => {
              const childObject = this.$store.getters["object/findById"](id);
              if (childObject) {
                this.inGroups.push(id);
                return this.populateObject(childObject, object.id);
              }
              return null;
            })
            .filter(obj => obj)
            .sort(sorter);
        }
        if (children.length > 0) this.parentNodes.push(object.id);
        return {
          id: object.id,
          name: object.type,
          type: object.type,
          icon: defaultProperties[object.type].icon,
          title: object.info.settings.title,
          locked: object.info.settings.locked,
          fixed: object.info.settings.locked,
          order: object.info.settings.order,
          visible: typeof object.info.settings.visible == "undefined" ? true : object.info.settings.visible, // it's a new property, others might not have
          parentId,
          children
        };
      },

      // Expand / Collapse All button click event handler
      toggleOpenAll() {
        this.openAll = !this.openAll;
        this.openedNodes = this.openAll ? [...this.parentNodes] : [];
      },

      /* Object related events, called from hoverable menu */
      navigateTo(objectId) {
        const object = this.$store.getters["object/findById"](objectId);
        if (!object)
          return;
        const { x, y } = object.position;
        if (x && y) {
          const viewportRect = this.viewport.$el.getBoundingClientRect();
          const width = viewportRect.width / 2;
          const height = viewportRect.height / 2;
          this.$store.commit("chart/setScale", 1);
          this.$store.commit("chart/setTranslate", {
            x: -parseInt(x) + width,
            y: -parseInt(y) + height
          });
        }
      },
      toggleLock(item) {
        item.locked = !item.locked;
        const object = this.$store.getters["object/findById"](item.id);
        object.info.settings.locked = item.locked;
        this.$store.dispatch('object/update', object);
      },
      toggleVisible(item) {
        item.visible = !item.visible;
        const object = this.$store.getters["object/findById"](item.id);
        object.info.settings.visible = item.visible;
        this.$store.dispatch('object/update', object);
      },


      /* Tree specific utility methods */
      findTreeItem: function (items, id) {
        if (!items) {
          return;
        }
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          // Test current object
          if (item.id === id) {
            return item;
          }
          // Test children recursively
          const child = this.findTreeItem(item.children, id);
          if (child) {
            return child;
          }
        }
      },

      openParent(itemId) {
        const item = this.findTreeItem(this.items, itemId);
        if (item && item.parentId) {
          if (!this.openedNodes.includes(item.parentId)) this.openedNodes = [...this.openedNodes, item.parentId];
          this.openParent(item.parentId);
        }
      }
      
    },
    watch: {
      objects: {
        handler() {
          this.generateTreeItems();
        },
        deep: true,
        immediate: true
      },
      // Tree item selection change
      active: {
        handler() {
          if (arrayCompare(this.active, this.selectedIds) === false ) {
            this.$store.commit("object/deselectAll");
            for (const selected of this.active) this.$store.commit("object/select", selected);
          }
        },
        deep: true,
        immediate: true
      },
      // Item selection change coming from chart
      selectedIds: {
        handler() {
          // Open parent to reveal the newly selected item in the tree
          const difference = this.selectedIds.filter(x => !this.active.includes(x));
          for (const item of difference) {
            this.openParent(item);
          }
          if (arrayCompare(this.active, this.selectedIds) === false) this.active = this.selectedIds;
        },
        deep: true,
        immediate: true
      }
    }
  };
</script>

<style scoped>
.objects-tree-view {
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
  height: 100%;
  padding: 15px;
  z-index: 8;
  transition-duration: 300ms;
  transition-property: transform, opacity;
}

.border {
  border-radius: 6px 20px 20px 20px;
}

.v-input--selection-controls {
  margin-top: 4px;
}

.list {
  overflow-y: auto;
}

.flip-enter {
  opacity: .01;
  transform: translateX(100%);
}

.flip-enter-to {
  opacity: 1;
  transform: translateX(0%);
}

.flip-leave {
  opacity: 1;
}

.flip-leave-to {
  opacity: .01;
  transform: translateX(100%);
}

.append-right {
  float: right;
  margin-left: 3px;
  margin-right: 3px;
}
</style>