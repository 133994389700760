<template>
  <div class="gradient-border">
    <div class="at-organisation-object__logo" :style="logoStyle" />
    <v-btn class="objectType__icon" fab small color="primary">
      <v-icon>account_balance</v-icon>
    </v-btn>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "AnalysisTools_OrganisationObject",
  extends: ExtendsComponent,
  computed: {
    logoStyle() {
      return {
        backgroundImage: this.localSettings.image
          ? `url(${this.localSettings.image.body.thumb.url})`
          : null
      };
    }
  }
};
</script>

<style scoped>
.gradient-border {
  position: absolute;
}

.at-organisation-object__logo {
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
}

.objectType__icon {
  position: absolute;
  margin-top: -20px;
  left: -20px;
  pointer-events: none;
  z-index: 5;
}
</style>
