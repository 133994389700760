<template>
  <Modal @submit="submit" title="Props Function" submitText="Save">
    <div>
      <editor v-model="localValue" @init="editorInit" lang="javascript" theme="chrome" height="360" />
    </div>
  </Modal>
</template>

<script>
export default {
  name: "PropsFunctionModal",
  components: {
    editor: require("vue2-ace-editor")
  },
  props: {
    value: String,
    availableProps: {
      type: Array,
      default() {
        return [];
      }
    },
    onSubmit: Function
  },
  created() {
    if (!this.value) {
    }
  },
  data() {
    const template = this.availableProps.map(e => `${e}: null`).join(",\n    ");

    const localValue =
      this.value ||
      `function props () {
  return {
    ${template}
  }
}`;

    return {
      localValue
    };
  },
  methods: {
    editorInit() {
      require("brace/ext/language_tools");
      require("brace/mode/html");
      require("brace/mode/javascript");
      require("brace/theme/chrome");
      require("brace/snippets/javascript");

      editor.setOptions({
        selectionStyle: "text",
        enableBasicAutocompletion: true,
        highlightSelectedWord: true,
        highlightActiveLine: false,
        tabSize: 2
      });
    },
    submit() {
      this.onSubmit(this.localValue);
    }
  }
};
</script>
