<template>
  <div>
    <div class="section pa-3 mb-5" v-for="stamp in timestamps" :key="stamp.id">
      <v-layout align-center>
        <v-flex xs2>
          <v-text-field
            v-model="stamp.time"
            label="Time"
            placeholder="ex. 01:30"
            hide-details
            :readonly="readonly"
          />
        </v-flex>
        <v-flex xs1/>
        <v-flex xs8>
          <v-select
            :items="animations"
            v-model="stamp.animation"
            label="Animation"
            hide-details
            :readonly="readonly"
          />
        </v-flex>

        <v-btn text class="button-icon" @click="deleteTimestamp(stamp.id)" :disabled="readonly">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-layout>
      <v-layout class="mt-2">
        <v-flex>
          <v-text-field
            v-model="stamp.objectId"
            label="Object ID"
            :placeholder="`Click on object to place its ID` + (fromPinned ? `` : ` (only in Pinned mode)`)"
            :readonly="readonly"
            hide-details
            @focus="onFocus(stamp)"
          />
        </v-flex>
      </v-layout>
    </div>
    <div class="add" @click="addTimestamp" v-if="!readonly">Add timestamp</div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "WayfinderVideoTimestamps",
  props: {
    settings: Object,
    readonly: Boolean,
    fromPinned: Boolean
  },
  data() {
    const {timestamps} = this.settings.video;

    const animations = [
      'bounce',
      'flash',
      'pulse',
      'rubberBand',
      'shakeX',
      'shakeY',
      'headShake',
      'swing',
      'tada',
      'wobble',
      'jello',
      'heartBeat'
    ];

    return {
      animations,
      timestamps,
      currentStamp: timestamps && timestamps.length ? timestamps[timestamps.length - 1] : null
    };
  },
  computed: {
    ...mapState({
      selectedObjectsIds: state => state.object.selectedIds,
    })
  },
  watch: {
    selectedObjectsIds() {
      if (this.selectedObjectsIds.length && this.currentStamp)
        this.currentStamp.objectId = this.selectedObjectsIds[0];
    }
  },
  methods: {
    addTimestamp() {
      this.timestamps.push({ id: this.guid(), objectId: "" });
      this.currentStamp = this.timestamps[0];
    },
    deleteTimestamp(id) {
      this.timestamps.splice(this.timestamps.findIndex(stamp => stamp.id == id), 1);
    },
    onFocus(stamp) {
      this.currentStamp = stamp;
    }
  }
};
</script>

<style scoped>
.form-row {
  height: 50px;
}
.section {
  margin: 10px 0;
}

.option-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  min-width: 34px;
}

.drag {
  cursor: move;
  text-align: left;
  flex: auto;
}

.disabled-icon {
  opacity: .5;
  cursor: auto;
}

.delete {
  text-align: right;
  flex: auto;
}
</style>
