import * as _ from "underscore";
import {setSlides, getNextSlide, checkNextSlide} from "@/components/Layout/NotAuthorized/Wayfinder/WayfinderManager";

const defaultState = {
  signal: null,
  Model: {},

  slides: [],
  currentSlide: null,
  currentSlideContent: null,
  currentScope: {},
  slidesHistory: [],

  parentFlow: [],
  parentFlowSlides: []
};

const store = {
  namespaced: true,
  state: defaultState,
  mutations: {
    init: (state) => {
      Object.assign(state, defaultState);
    },
    update: (state, slide) => {
      state.slides = setSlides(slide);
    },
    setCurrentSlide: (state, slide) => {
      state.currentSlide = slide;
      state.slidesHistory.push(slide);
    },
    setSignal: (state, signal) => {
      state.signal = signal;
    },
    updateModel: (state, value) => {
      state.Model = {...state.Model, ...value};
    },
    setCurrentScope: (state, value= {}) => {
      state.currentScope = value;
    },
    updateCurrentScope: (state, value= {}) => {
      state.currentScope = {...state.currentScope, ...value};
    },
    pushParentFlow: (state, value) => {
      state.slidesHistory.push({
        type: 'change_flow',
        slides: state.slides,
        parentFlow: state.parentFlow
      });
      state.parentFlow.push(value);
      state.parentFlowSlides.push(state.slides);
    },
    popParentFlow: (state) => {
      state.slidesHistory.push({
        type: 'change_flow',
        slides: state.slides,
        parentFlow: state.parentFlow
      });
      state.parentFlow.pop();
      state.slides = state.parentFlowSlides.pop();
    },
  },
  actions: {
    init({state, rootState, commit, dispatch}, slideId) {
      commit('init');

      dispatch('setCurrentSlide', slideId);
      state.slides = setSlides(state.currentSlide);
    },

    setCurrentSlide({state, rootState, commit, dispatch}, slideId) {
      commit(`updateModel`, state.currentScope);
      commit(`setCurrentScope`, {});

      const slide = rootState.object.list.find(t => t.id == slideId)
      commit('setCurrentSlide', slide);

      const nextIds = rootState.connection.list
        .filter(c => c.from == slide.id)
        .map(c => c.to);
      const nextObjects = rootState.object.list
        .filter(o => nextIds.includes(o.id))
        .filter(o => o.type == "Presentation_WayfinderContentObject");
      state.currentSlideContent = nextObjects[0];
    },
    async nextSlide({state, commit, dispatch}) {
      commit(`updateModel`, state.currentScope);
      commit(`setCurrentScope`, {});
      const nextSlide = await getNextSlide(state.currentSlide);
      if (nextSlide)
        dispatch('setCurrentSlide', nextSlide.id);
    },
    previousSlide({state, getters, dispatch}) {
      if (!getters['previousSlide'])
        return;

      state.slidesHistory.pop(); // removing current slide

      const getHistoryObject = () => {
        const object = state.slidesHistory.pop();
        if (object.type != 'change_flow')
          return object;

        state.slides = object.slides;
        state.parentFlow = object.parentFlow;
        return getHistoryObject();
      };

      dispatch('setCurrentSlide', getHistoryObject().id);
    }
  },
  getters: {
    nextSlide (state)  {
      return checkNextSlide(state.currentSlide);
    },

    previousSlide (state) {
      const {length} = state.slidesHistory;
      return length > 1;
    },

    scope: state => {
      return {
        signal: state.signal,
        Model: state.Model,
        ...state.currentScope
      };
    },

    furtherScope: state => {
      return {
        signal: state.signal,
        Model: {
          ...state.Model,
          ...state.currentScope
        }
      }
    },

    checkCondition: (state, getters) => (string, isFurther) => {
      try {
        const scope = isFurther ? getters['furtherScope'] : getters['scope'];
        return !string ||
          _.template(`{{!!(${string})}}`)(scope) == 'true';
      } catch (e) {
        return false;
      }
    },

    currentAction: (state, getters) =>
      (actions = [], { conditionField = 'condition' } = {}) => {
        return actions.find(
          a => getters['checkCondition'](a[conditionField]));
    },

    template: (state, getters) => (string = "", additionalScope) => {
      let template = string;
      const scope = getters['scope'];
      try {
        template = JSON.parse(_.template(`{{JSON.stringify(${template})}}`)({ ...scope, ...additionalScope}));
      } catch (e) {
        try {
          template = JSON.parse(_.template(`${template}`)({ ...scope, ...additionalScope}));
        } catch (_e) {
          try {
            template = _.template(`${template}`)({ ...scope, ...additionalScope });
          } catch (_e) {
            template = string;
          }
        }
      }
      return template;
    }

  }
};

export default store;