<template>
  <div>
    <slot :openSettings="openSettings" />
    <div class="control-element__checkboxlist">
      <div class="control-element__checkboxlist__title" v-html="localSettings.title" />
      <v-checkbox
        :key="option.id"
        v-for="option in localSettings.options"
        v-model="model"
        :label="option.value"
        :value="option.value"
      />
    </div>
  </div>
</template>

<script>
  import ControlComponent from '../_extends/ControlComponent';

  export default {
    name: 'ControlElement_CheckboxListControl',
    extends: ControlComponent,
    data () {
      return {
        model: null
      }
    }
  }
</script>

<style>
  .control-element__checkboxlist__title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
  .control-element__checkboxlist {
    display: block;
    margin-top: 20px;
  }

  .control-element__checkboxlist .v-input__slot {
    margin: 0 !important;
  }
  .control-element__checkboxlist .v-messages {
    display: none !important;
  }
</style>
