<template>
  <v-menu max-height="300" min-width="100" max-width="300" v-model="menuShow" :activator="activator" offset-y>
    <v-list dense subheader>
      <template v-if="!searchItems || searchItems.length == 0">
        <v-list-item>
          <v-list-item-title>
            Search your <strong>Projects, </strong>
            <strong>Charts</strong> and 
            <strong>Objects</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-else v-for="(item, index) in searchItems">
        <template v-if="item.header">
          <v-subheader :key="index">{{item.header}}</v-subheader>
        </template>
        <template v-else>
          <v-list-item :class="{active: localHighlight && item.id == localHighlight.id}">
            <v-list-item-content @click="selectItem(item)">
              <v-list-item-title v-html="item.name" />
              <v-list-item-subtitle v-if="item.subtitle">
                <i>
                  from
                  {{item.subtitle}}                  
                </i>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import * as _ from "underscore";
export default {
  name: "NotionEditorTextLinkObjectLine",
  props: {
    searchKey: String,
    active: Object,
    id: String,
    linkId: String,
    highlightIndex: Number,
    highlightItem: Object
  },
  data() {
    return {
      menuShow: true,
      searchItems: [],
      localHighlight: null,
      activator: `.notion-line__content-editable[id="${this.id}"] a[id="${this.linkId}"]`
    };
  },
  methods: {
    selectItem(item) {
      const that = this;
      this.$emit("update:active", {...item, searchKey: this.searchKey});
      this.$emit("select");
    }
  },
  watch: {
    menuShow(val) {
      if (!val)
        this.$emit("hide");
    },
    searchKey: {
      handler() {
        this.$emit("resetHighlight");
        this.api.Search.query({ query: this.searchKey }).then(({ body }) => {
          this.searchItems = body;
          this.loadingSearch = false;
        });
      },
      deep: true,
      immediate: true
    },
    highlightIndex(val) {
      const filteredItems = this.searchItems.filter(item => item.id);
      if (!filteredItems || filteredItems.length === 0) return;
      const index = val % filteredItems.length;
      this.localHighlight = filteredItems[index];
      this.$emit("update:highlightItem", this.localHighlight);
    }
  }
};
</script>

<style scoped>
.search >>> .v-subheader {
  font-weight: bold;
  color: #333 !important;
}
.v-list-item.active {
  background: #eee;
}
</style>