<template>
  <div class="chatbot-mural-action">
    {{ action }}
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Chatbot_MuralActionObject',
  extends: ExtendsComponent,
  computed: {
    action() {
      return this.localSettings.action ? this.localSettings.action : '(empty)';
    }
  }
}
</script>

<style scoped>
.chatbot-mural-action {
  border: 3px solid rgb(132, 132, 132);
  padding: 5px;
  min-width: 250px;
  border-radius: 5px;
  cursor: default;
  background: white;
}
</style>
