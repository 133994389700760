<template>
  <div class="gradient-border">
    <div class="at-person-object__avatar" :style="avatarStyle" />
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "AnalysisTools_PersonObject",
  extends: ExtendsComponent,
  computed: {
    avatarStyle() {
      return {
        backgroundImage: this.localSettings.image
          ? `url(${this.localSettings.image.body.thumb.url})`
          : null
      };
    }
  }
};
</script>

<style scoped>
.gradient-border {
  border-radius: 9999px;
  position: absolute;
}

.at-person-object__avatar {
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: white;
  background-size: cover;
}
</style>
