<template>
  <div class="comp" :style="{borderColor: localSettings.color || '#4CAF50'}">
    <div class="pa-1">
      <div v-if="localSettings.condition">
        if
        <span :style="{background: localSettings.color || '#4CAF50'}" class="condition">
          {{localSettings.condition}}
        </span>
        &nbsp;
        &nbsp;
      </div>
      <div v-else>Add condition</div>
    </div>
  </div>
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent';

  export default {
    name: 'Presentation_WayfinderFilterObject',
    extends: ExtendsComponent
  }
</script>

<style scoped>
  .comp {
    border: 3px solid;
    padding: 5px;
    min-width: 200px;
    border-radius: 5px;
    cursor: default;
    background: white;
    white-space: nowrap;
    box-sizing: content-box;
  }

  .condition {
    box-sizing: content-box;
    padding: 2px 5px;
    color: #fff;
    background: #4CAF50;
  }
</style>
