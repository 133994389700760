import Vue from "vue";
import store from "@/store";
import {guid} from "@/lib";
import {objectModeTypes} from "../../../../utils/const";

export const subToolbarProps = {
    [objectModeTypes.Analysis]: {moduleName: 'AnalysisTools', styles: {}},
    [objectModeTypes.Chatbot]: {moduleName: "Chatbot", styles: {}},
    [objectModeTypes.Presentation]: {moduleName: "Presentation", styles: {}},
    [objectModeTypes.Custom]: {moduleName: "Custom", styles: {}}
}

const Utils = {
    buildObject({position, settings}) {
        return {
            type: "AnalysisTools_GeneralObject",
            position: position,
            info: {settings}
        };
    },
    getCrawlerData(text) {
        return Vue.http.post("vulcan/v1/crawler/parse", {link: text});
    },
    youtubeId(youtubeUrl) {
        if (!youtubeUrl) return null;
        var match = youtubeUrl.match(
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
        );

        return match ? match[5] : null;
    },
    addYoutube({objectId, text, position}) {
        const object = store.getters["object/findById"](objectId);
        Utils.addCrawlerData(object, text, position);
    },
    addCrawlerData({objectId, link}) {
        Utils.getCrawlerData(link).then(response => {
            const object = store.getters["object/findById"](objectId);
            object.info.settings.image =
                object.info.settings.image || response.body.image;

            if (!object.info.settings.notesLines)
                object.info.settings.notesLines = [];
            object.info.settings.notesLines.push({
                type: "Text",
                content: response.body.notes,
                id: guid()
            });
            if (!object.info.settings.title) object.info.settings.title = "";
            object.info.settings.title += "\n" + response.body.title;
            object.info.settings.link = response.body.link;

            if (object.info.settings.addons) {
                object.info.settings.addons.concat(response.body.addons || []);
            } else {
                object.info.settings.addons = response.body.addons;
            }
            store.dispatch("object/update", object);
        });
    },
    addNotes({objectId, text}) {
        const object = store.getters["object/findById"](objectId);
        object.info.settings.notesLines.push({
            type: "Text",
            content: text,
            id: guid()
        });

        store.dispatch("object/update", object);
    },
    insertFromCrawler({link, position}) {
        Utils.getCrawlerData(link).then(response => {
            const objectParams = Utils.buildObject({
                position,
                settings: {
                    ...response.body,
                    notesLines: [{
                        id: guid(),
                        type: "Text",
                        content: response.body.notes
                    }],
                    addons: [{
                        id: guid(),
                        isLink: true,
                        type: "variable",
                        name: "Link",
                        value: link
                    }]
                }
            });
            store.dispatch("object/create", objectParams);
        });
    },
    insertNotes({text, position}) {
        const objectParams = Utils.buildObject({
            position,
            settings: {
                notesLines: [{
                    id: guid(),
                    content: text,
                    type: "Text"
                }]
            }
        });

        store.dispatch("object/create", objectParams);
    },
    async insertImage({file, position}) {
        const title = file.name;

        const imgObj = new Image();

        await new Promise((resolve, reject) => {
            imgObj.onload = resolve;
            imgObj.onerror = reject;
            imgObj.src = window.URL.createObjectURL(file);
        });

        const formData = new FormData();
        formData.append("attachment", file, file.name);

        const {body: image} = await Vue.http.post(
            "vulcan/v1/uploads/image",
            formData
        );

        const objectParams = {
            type: "Base_ImageObject",
            position,
            size: {
                width: Math.min(imgObj.width, 1500),
                height: Math.min(imgObj.height, 1500)
            },
            info: {
                settings: {
                    image,
                    title
                }
            }
        };

        store.dispatch("object/create", objectParams);

        return imgObj;
    },
    insertFile({file, position}) {
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8");

        reader.onload = function (e) {
            const text = e.target.result;
            const notesLines = text
                .split("\n")
                .map(l => ({id: guid(), content: l, type: "Text"}));

            const objectParams = Utils.buildObject({
                position,
                settings: {
                    notesLines
                }
            });

            store.dispatch("object/create", objectParams);
        };
    }
};

export default Utils;
