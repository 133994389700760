<template>
  <div>
    <slot />
    <div
      @click.stop="openSettings()"
      class="control-element__input"
      v-html="localSettings.placeholder || 'Input'"
    />
  </div>
</template>

<script>
  import ControlComponent from '../_extends/ControlComponent'

  export default {
    name: "ControlElement_InputControl",
    extends: ControlComponent,
  }
</script>

<style scoped>
  .control-element__input {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 5px;
    height: 37px;
    padding: 8px 10px;
    width: 100%;
    color: #8a8a8a;
    cursor: pointer;
  }
</style>