export const objectModeTypes = {
    Drawing: 'drawing',
    Shape: 'shape',
    Analysis: 'analysis',
    Custom: 'custom',
    Presentation: "presentation",
    Chatbot: 'chatbot',

}

export const HEADER_HEIGHT = 112

export const HANDLES = {
    TopRight: 'TopRight',
    TopLeft: 'TopLeft',
    BottomRight: 'BottomRight',
    BottomLeft: 'BottomLeft',
    TopMiddle: 'TopMiddle',
    BottomMiddle: 'BottomMiddle',
    LeftMiddle: 'LeftMiddle',
    RightMiddle: 'RightMiddle'
}
