<template>
  <svg width="100%" height="100%">
    <ellipse :cx="cx" :cy="cy" :rx="rx" :ry="ry" :style="elementStyles"
      :fill="fill" :stroke="stroke" :stroke-width="strokeWidth" />
  </svg>
</template>

<script>
import * as d3 from "d3";
import ExtendsComponent from "../../../_extends/ObjectComponent";

export default {
  name: "Polygon_Circle",
  props: ["selected"],
  extends: ExtendsComponent,
  data () {
    return {
      cx: this.baseObject.size.width / 2,
      cy: this.baseObject.size.height / 2,
      rx: this.baseObject.size.width / 2 - this.settings.strokeWidth / 2 - 1,
      ry: this.baseObject.size.height / 2 - this.settings.strokeWidth / 2 - 1,
      fill: this.settings.backgroundColor,
      stroke: this.settings.borderColor,
      strokeWidth: this.settings.strokeWidth
    };
  },
  computed: {
    elementStyles() {
      return {
        fill: this.fill || "#e7e7e7",
        stroke: this.stroke || "#555",
        strokeWidth: this.strokeWidth
      };
    }
  },
  watch: {
    'baseObject.size': {
      handler() {
        this.cx = this.baseObject.size.width / 2;
        this.cy = this.baseObject.size.height / 2;
        this.rx = this.baseObject.size.width / 2 - this.settings.strokeWidth / 2 - 1;
        this.ry = this.baseObject.size.height / 2 - this.settings.strokeWidth / 2 - 1;
      },
      deep: true
    },
    settings: {
      handler() {
        this.fill = this.settings.backgroundColor;
        this.stroke = this.settings.borderColor;
        this.strokeWidth = this.settings.strokeWidth;
      },
      deep: true
    }
  }
};
</script>
