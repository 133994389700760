<template>
  <transition name="flip">
    <div class="u-list" v-if="opened">
      <v-card class="border" style="height: 100%">
        <v-toolbar flat>
          <v-toolbar-title>Unplaced Objects</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.native="$emit('update:opened', false)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <draggable v-model="objects" v-bind="options" class="u-list__content">
          <div
            class="u-list__object"
            v-for="(object, index) in objects"
            :key="index"
            :id="`ulist_el_${object.id}`"
            @click="openPropertyEditorForObject(object.id)"
          >
            <div class="u-list__object-delete" @click="deleteObject(object)">
              <v-icon>close</v-icon>
            </div>
            <div class="u-list__object-icon" :style="iconStyle(object)" />
            <div class="u-list__object-name">{{object.info.settings.title}}</div>
          </div>
        </draggable>
      </v-card>
    </div>
  </transition>
</template>

<script>
import { ObjectPropertyEditors } from "@/components/Layout/Authorized/TheEditor/Object/Types";

export default {
  name: "UList",
  props: {
    objects: {
      type: Array,
      default() {
        return [];
      }
    },
    opened: Boolean
  },
  data() {
    return {
      options: {
        setData: (dataTransfer, dragEl) => {
          const object = this.objects.find(
            o => dragEl.id == `ulist_el_${o.id}`
          );

          const params = {
            type: "UPDATE_OBJECT",
            payload: object
          };

          dataTransfer.setData("Text", JSON.stringify(params));
        }
      }
    };
  },
  methods: {
    iconStyle(object) {
      const image = object.info.settings.image;
      const url = image ? image.body.quad.url : "";
      return {
        backgroundImage: `url(${url})`
      };
    },
    deleteObject(object) {
      this.$store.dispatch("object/delete", object.id);
    },
    openPropertyEditorForObject(objectId) {
      if (!objectId) return false;

      const baseObject = this.$store.getters["object/findById"](objectId);
      if (baseObject) {
        this.$root.$emit("PropertyEditor.open", {
          component: ObjectPropertyEditors[baseObject.type],
          props: {
            baseObject,
            settings: baseObject.info.settings
          }
        });
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.u-list {
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
  height: 100%;
  padding: 15px;
  z-index: 10;
  transition-duration: 300ms;
  transition-property: transform, opacity;
}

.border {
  border-radius: 6px 20px 20px 20px;
}

.u-list__content {
  text-align: left;
  padding: 10px 25px;
  max-height: calc(100% - 64px);
  overflow: auto;
}

.u-list__object {
  min-height: 100px;
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: move;
  user-select: none;
}

.u-list__object-icon {
  width: 50px;
  height: 50px;
  background-size: cover;
  background: #e7e7e7 center center;
}

.u-list__object-name {
  width: calc(100% - 100px);
  font-size: 18px;
}

.u-list__object-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.flip-enter {
  opacity: .01;
  transform: translateX(100%);
}

.flip-enter-to {
  opacity: 1;
  transform: translateX(0%);
}

.flip-leave {
  opacity: 1;
}

.flip-leave-to {
  opacity: .01;
  transform: translateX(100%);
}
</style>
