<template>
  <div>
    <Autocompleter solo v-model="localSettings.signal" label="Signal" :readonly="readonly" />
  </div>
</template>

<script>
  import componentExtends from './_extends/Component';

  export default {
    name: 'Chatbot_RouterObjectPropertyEditor_EMIT_SIGNAL',
    extends: componentExtends,
  }
</script>
