<template>
  <v-card id="sideToolbar" class="py-2">
    <v-tooltip
        class="pa-1"
        right
        v-for="(item, index) in toolsList"
        :key="index"
    >
      <template v-slot:activator="{ on }">
        <v-btn
            icon
            v-on="on"
            @click.stop="createObject(item.component)"
            class="p-3"
            v-if="item.hasSubToolbar != true && item.component != 'Core_CustomComponent'"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn
            icon
            v-on="on"
            @click.stop="toggleTextBar"
            class="p-3"
            v-if="item.component == 'Base_TextObject'"
            :class="{active: isTextSubMenuOpen}"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn
            icon
            v-on="on"
            @click.stop="toggleMode(objectModeTypes.Shape)"
            v-if="item.component == 'Base_PolygonObject'"
            class="p-3"
            :class="{active: isToolActive(objectModeTypes.Shape)}"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn
            icon
            v-on="on"
            @click.stop="toggleMode(objectModeTypes.Drawing)"
            class="p-3"
            v-if="item.component == 'Base_DrawingObject'"
            :class="{active: isToolActive(objectModeTypes.Drawing)}"
        >
          <svg width="32" height="32">
            <path
                d="M 22.462891 4.0078125 C 22.203865 3.9918086 21.944668 4.026709 21.701172 4.1074219 C 21.21418 4.2688475 20.788236 4.613031 20.542969 5.0996094 L 20.074219 6.0253906 L 19.621094 5.7988281 L 18.691406 7.6601562 L 17.794922 7.2109375 L 17.792969 7.2089844 C 17.491451 7.0591842 17.162626 6.9899131 16.839844 7 C 16.127565 7.022009 15.448157 7.4317821 15.111328 8.1074219 L 12.136719 14.058594 L 13.925781 14.951172 L 16.900391 9 L 17.798828 9.4492188 L 11.033203 22.996094 L 11.988281 25.537109 L 11.203125 27.107422 L 12.992188 28.001953 L 13.771484 26.445312 L 16.400391 25.677734 L 24.988281 8.4824219 L 24.546875 8.2617188 L 25.009766 7.3496094 A 1.0001 1.0001 0 0 0 25.009766 7.3476562 C 25.501632 6.3698504 25.097136 5.1481874 24.119141 4.6582031 A 1.0001 1.0001 0 0 0 24.117188 4.6582031 L 23.224609 4.2128906 C 22.980772 4.0907246 22.721917 4.0238164 22.462891 4.0078125 z M 22.328125 6 A 1.0001 1.0001 0 0 0 22.330078 6 L 23.222656 6.4472656 L 22.757812 7.3671875 L 21.863281 6.9199219 L 22.328125 6.0019531 A 1.0001 1.0001 0 0 0 22.328125 6 z M 20.515625 8.4824219 L 22.306641 9.3769531 L 15.001953 24.001953 L 13.691406 24.384766 L 13.210938 23.109375 L 20.515625 8.4824219 z M 25 12 L 25 14 L 25.5 14 C 26.327 14 27 14.673 27 15.5 C 27 16.327 26.327 17 25.5 17 L 22.970703 17 L 21.970703 19 L 25.5 19 C 27.43 19 29 17.43 29 15.5 C 29 13.57 27.43 12 25.5 12 L 25 12 z M 8.5 17 C 5.47 17 3 19.47 3 22.5 C 3 25.28 5.0798438 27.630937 7.8398438 27.960938 C 8.0598438 27.990938 8.28 28 8.5 28 L 9 28 L 9 26 L 8.5 26 C 8.36 26 8.2200781 25.990703 8.0800781 25.970703 C 6.3200781 25.770703 5 24.27 5 22.5 C 5 20.57 6.57 19 8.5 19 L 10.789062 19 L 11.789062 17 L 8.5 17 z"
                :fill="drawingIconColor"
            />
          </svg>
        </v-btn>
        <v-btn
            icon
            v-on="on"
            @click.stop="toggleMode(objectModeTypes.Analysis)"
            class="p-3"
            v-if="item.component == 'AnalysisTools_GeneralObject'"
            :class="{active: isToolActive(objectModeTypes.Analysis)}"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn icon v-on="on"
               @click.stop="toggleMode(objectModeTypes.Presentation)"
               v-if="item.component == 'Presentation_Wayfinder'"
               :class="{active: isToolActive(objectModeTypes.Presentation)}">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn icon
               v-on="on"
               @click.stop="toggleMode(objectModeTypes.Chatbot)"
               v-if="item.component == 'Chatbot_EntryPointObject'"
               :class="{active: isToolActive(objectModeTypes.Chatbot)}">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn icon
               v-on="on"
               @click.stop="toggleCustomBar"
               v-if="item.component == 'Core_CustomComponent'"
               :class="{active: isCustomToolOpen}">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>

      </template>
      <div class="tooltip-wrapper">
        <span class="tooltip-name">{{ item.name }} <span class="tooltip-hotkey">{{ item.hotkey }}</span></span>
        <v-spacer/>

      </div>
    </v-tooltip>
    <DrawingToolbar v-if="objectMode == objectModeTypes.Drawing"/>
    <ShapeToolbar v-if="objectMode == objectModeTypes.Shape" @createPolygonObject="createPolygonObject"/>
    <SubToolbar v-if="isSubToolbarOpen" :module-name="subToolProps.moduleName"
                @createObjectFromSubToolbar="createObject"/>
    <CustomComponentsToolbar v-if="isCustomToolOpen" :is-open="isCustomToolOpen"
                             :toggle-panel="toggleCustomBar"/>
    <TextToolbar v-if="isTextSubMenuOpen" :isOpen="isTextSubMenuOpen" :toggle-panel="toggleTextBar"/>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import DrawingToolbar from "./DrawingToolbar";
import ShapeToolbar from "./ShapeToolbar";
import {defaultProperties, ObjectTypesByModule,} from "../Object/Types";
import {isMac} from "@/lib";
import {subToolbarProps} from "../Utils";
import SubToolbar from "./SubToolbar";
import {objectModeTypes} from '../../../../../utils/const'
import CustomComponentsToolbar from "./CustomComponentsToolbar";
import {filterMenuTypes} from "./helper";
import TextToolbar from "./TextToolbar";

export default {
  name: "ChartSideToolbar",
  components: {
    TextToolbar,
    CustomComponentsToolbar,
    SubToolbar,
    DrawingToolbar,
    ShapeToolbar,
  },
  computed: {
    ...mapGetters({objectMode: "chart/objectMode"}),
    drawingIconColor() {
      return this.objectMode == objectModeTypes.Drawing ? "#df4e9e" : "rgba(0,0,0,0.54)";
    },
    isSubToolbarOpen() {
      return this.objectMode && this.objectMode != objectModeTypes.Drawing &&
          this.objectMode != objectModeTypes.Shape && this.objectMode != "Custom"
    },
    subToolProps() {
      return subToolbarProps[this.objectMode]
    }
  },
  data() {
    const defaultPresentation = ObjectTypesByModule['Presentation'][0]
    const defaultAnalysis = ObjectTypesByModule['AnalysisTools'][0]
    const defaultChatbot = ObjectTypesByModule['Chatbot'][0]

    const modKey = isMac() ? "Ctrl" : "Win";
    const getHotKey = (component) => defaultProperties[component].hotkey
        ? defaultProperties[component].hotkey.replace("modkey", modKey)
        : null;

    const getGenericCategory = (component, name) => ({
      component,
      name,
      hotkey: getHotKey(component),
      icon: defaultProperties[component].icon,
      hasSubToolbar: true,
      isGeneric: true,
    })

    const customTools = {
      component: "Core_CustomComponent",
      name: "Custom Tools",
      hasSubToolbar: false,
      hotkey: "",
      icon: "dashboard_customize",
      isGeneric: true,
    }

    const generalCategories = [
      getGenericCategory("Base_TextObject", "Text"),
      getGenericCategory(defaultPresentation, "Presentation"),
      getGenericCategory(defaultAnalysis, "Analysis"),
      getGenericCategory(defaultChatbot, "Chatbot"),
      customTools,
    ]

    const toolsList = ObjectTypesByModule["Base"]
        .filter((c) => filterMenuTypes(c))
        .map((c) => {
          return {
            component: c,
            name: defaultProperties[c].name,
            icon: defaultProperties[c].icon,
            hotkey: getHotKey(c),
            hasSubToolbar: defaultProperties[c].subToolbar
          };
        }).concat(generalCategories);

    return {
      toolsList,
      defaultProperties,
      objectModeTypes,
      isCustomToolOpen: false,
      isTextSubMenuOpen: false
    };
  },
  methods: {
    createObject(component) {
      this.$emit("createObject", null, component);
    },
    createPolygonObject(shape) {
      if (shape === 'Rect')
        this.$emit("createObject", null, 'Base_RectObject');
      else
        this.$emit("createObject", null, 'Base_PolygonObject', {shape});
    },
    toggleTextBar() {
      this.isTextSubMenuOpen = !this.isTextSubMenuOpen
    },
    toggleMode(mode) {
      if (this.isCustomToolOpen) {
        this.toggleCustomBar()
      }
      if (this.isTextSubMenuOpen) {
        this.toggleTextBar()
      }

      this.$store.dispatch('chart/setActiveTool', null, null)
      this.$store.dispatch("chart/setObjectMode", this.objectMode === mode ? null : mode)
    },
    isToolActive(mode) {
      return this.objectMode === mode ? 'active' : ''
    },
    toggleCustomBar() {
      if (this.objectMode !== null) {
        this.$store.dispatch("chart/setObjectMode", null)
      }
      this.isCustomToolOpen = !this.isCustomToolOpen
    }
  },
};
</script>

<style scoped>
#sideToolbar {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  width: 50px;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  background: white;
  text-align: center;
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-name {
  font-weight: bold;
}

.tooltip-hotkey {
  color: rgba(255, 255, 255, 0.6);
}

.v-btn.active i {
  color: #df4e9e;
}
</style>
