<template>
  <v-toolbar dense no-padding>
    <v-text-field
      class="pa-0 ml-2"
      hide-details
      :readonly="readonly"
      placeholder="Embed Code"
      v-model="localSettings.embedcode"
    />

    <v-divider class="ml-2" vertical />

    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0">Notes</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";

export default {
  name: "Base_ImagePropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    return {
      openedData: 0
    };
  },

  methods: {
    onOpenNotion() {
      this.localSettings = { notesLines: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenNotion();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenNotion();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>