<template>
  <Modal @submit="submit" title="Edit Project" :showSubmit="!!localParams.name" submitText="Save">
    <ProjectForm mode="edit" :params.sync="localParams" @submit="submit" @close="close" class="ma-3" />
  </Modal>
</template>

<script>
  import ProjectForm from './_Form'
  import { deepClone } from '@/lib'

  export default {
    name: 'EditProject',
    props: {
      params: Object,
      callback: Function
    },
    components: {
      ProjectForm
    },
    data () {
      return {
        localParams: deepClone(this.params || {})
      }
    },
    methods: {
      submit () {
        if (this.callback)
          this.callback(this.localParams);
        this.close();
      },
      close () {
        this.$emit('close')
      }
    }
  }
</script>