var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"dense":"","no-padding":""}},[_c('v-divider',{attrs:{"vertical":""}}),_c('ColorPickerDialog',{attrs:{"value":_vm.localSettings.backgroundColor,"title":"Select Main Colour","presetColors":_vm.projectColors,"disabled":_vm.readonly,"groupColors":_vm.objectsColor(),"handleGroup":_vm.handleColor}}),_c('v-divider',{staticClass:"mr-2",attrs:{"vertical":""}}),_c('v-select',{staticClass:"pa-0",style:('width: 120px'),attrs:{"hide-details":"","readonly":_vm.readonly,"placeholder":"Layout","items":_vm.availableLayouts},on:{"change":_vm.changeLayout},model:{value:(_vm.localSettings.layout),callback:function ($$v) {_vm.$set(_vm.localSettings, "layout", $$v)},expression:"localSettings.layout"}}),_c('v-divider',{staticClass:"ml-1",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.halign('left')}}},on),[_c('v-icon',[_vm._v("align_horizontal_left")])],1)]}}])},[_c('span',[_vm._v("Align Left")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.halign('center')}}},on),[_c('v-icon',[_vm._v("align_horizontal_center")])],1)]}}])},[_c('span',[_vm._v("Align Center")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.halign('right')}}},on),[_c('v-icon',[_vm._v("align_horizontal_right")])],1)]}}])},[_c('span',[_vm._v("Align Right")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.valign('top')}}},on),[_c('v-icon',[_vm._v("align_vertical_top")])],1)]}}])},[_c('span',[_vm._v("Align Top")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.valign('center')}}},on),[_c('v-icon',[_vm._v("align_vertical_center")])],1)]}}])},[_c('span',[_vm._v("Align Center")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.valign('bottom')}}},on),[_c('v-icon',[_vm._v("align_vertical_bottom")])],1)]}}])},[_c('span',[_vm._v("Align Bottom")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.distribute('x')}}},on),[_c('v-icon',[_vm._v("horizontal_distribute")])],1)]}}])},[_c('span',[_vm._v("Horizontal Distribute")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.distribute('y')}}},on),[_c('v-icon',[_vm._v("vertical_distribute")])],1)]}}])},[_c('span',[_vm._v("Vertical Distribute")])]),_c('v-divider',{staticClass:"ml-1",attrs:{"vertical":""}}),_vm._t("menumore")],2)}
var staticRenderFns = []

export { render, staticRenderFns }