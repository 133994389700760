<template>
  <v-toolbar dense no-padding>
    <ColorPickerDialog
      v-model="localSettings.color"
      title="Select Arrow Colour"
      :presetColors="projectColors"
      :disabled="readonly"
    />

    <v-divider class="mr-2" vertical />

    <v-select
      class="pa-0"
      hide-details
      :items="arrowTypes"
      v-model="localSettings.arrow"
      :style="'width: 120px'"
      :menu-props="{ maxHeight: '400' }"
      :readonly="readonly"
    />

    <v-divider class="ml-1 mr-2" vertical />

    <v-select
      class="pa-0"
      hide-details
      :items="strokeLineCapTypes"
      :style="'width: 120px'"
      v-model="localSettings.strokeLineCap"
      :menu-props="{ maxHeight: '400' }"
      :readonly="readonly"
    />

    <v-divider class="ml-1 mr-2" vertical />

    <v-select
      class="pa-0"
      hide-details
      :items="strokeWidths"
      :style="'width: 50px'"
      v-model="localSettings.strokeWidth"
      :menu-props="{ maxHeight: '400' }"
      :readonly="readonly"
    />

    <v-divider class="ml-1 mr-2" vertical />

    <v-select
      class="pa-0"
      hide-details
      :items="lineStyles"
      :style="'width: 120px'"
      v-model="localSettings.lineStyle"
      :menu-props="{ maxHeight: '400' }"
      :readonly="readonly"
    />

    <v-divider class="ml-1" vertical />

    <v-btn-toggle group borderless v-model="openedData" v-if="localSettings.notionEnabled">
      <v-btn text style="opacity: 1" class="ma-0">Notes</v-btn>

      <v-divider vertical />
    </v-btn-toggle>

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";

export default {
  name: "Base_ArrowObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  data() {
    return {
      openedData: undefined,

      arrowTypes: ["Start", "End", "Both", "None"],
      strokeLineCapTypes: ["Butt", "Square", "Round"],
      strokeWidths: [1, 2, 3, 4, 5, 6],
      lineStyles: ["Solid", "Dashed", "Dotted"]
    };
  },
  methods: {
    onOpenNotion() {
      this.localSettings = { notesLines: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    }
  },
  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenNotion();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      if (this.localSettings.notionEnabled)
        this.openedData = 0;
    }
  },
  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    if (this.localSettings.notionEnabled)
      this.openedData = 0;
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>