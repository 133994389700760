<template>
  <v-dialog
    width="60%"
    attach=".v--modal-overlay"
    v-model="opened"
    hide-overlay
    persistent
    :content-class="`modal ${contentClass}`"
  >
    <v-toolbar flat v-if="showToolbar" color="#f4f4f4" class="px-4">
      <v-btn icon @click.native="close()">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title class="headline">{{title}}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn color="primary" text @click.native="submit()" v-if="showSubmit">
          {{submitText}}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div class="modal-content overflow-auto">
      <slot />
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    submitText: {
      type: String,
      default: "Save"
    },
    showSubmit: {
      type: Boolean,
      default: true
    },
    showToolbar: {
      type: Boolean,
      default: true
    },
    contentClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      opened: true
    };
  },
  methods: {
    close() {
      this.$parent.$emit("close");
    },
    submit() {
      this.$emit("submit");
      this.close();
    }
  }
};
</script>

<style>
.v--modal-overlay {
  z-index: 100 !important;
}

.v--modal-box.v--modal {
  display: none !important;
}

.modal {
  background: white;
  text-align: left;
  min-width: 480px;
  min-height: 30%;
  overflow: hidden;
}

.modal-content {
  position: relative;
  padding: 0 !important;
}

@media screen and (max-width: 680px) {
  .modal {
    width: 100% !important;
    margin: 0 !important;
    height: 100%;
    max-height: 100% !important;
    min-width: auto !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .form-actions {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
  }

  .modal-content {
    -webkit-overflow-scrolling: touch;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .modal-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
</style>
