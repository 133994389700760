<template>
  <Modal @submit="submit" title="Roles">
    <v-container>
      <v-select
        v-model="roleIds"
        :items="roles"
        item-value="id"
        item-text="name"
        chips
        required
        label="Roles"
        multiple
      ></v-select>
    </v-container>
  </Modal>
</template>

<script>
export default {
  name: "AssignmentsModal",
  props: {
    callback: Function,
    assignments: Array
  },
  data() {
    const roleIds = this.assignments.map(a => a.role.id);
    return {
      localAssignments: [...this.assignments],
      roles: [],
      roleIds,
      roleIdsDump: JSON.stringify(roleIds)
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      this.api.Role.get().then(res => (this.roles = res.body));
    },
    submit() {
      if (this.roleIdsDump != JSON.stringify(this.roleIds)) {
        this.callback(this.roleIds);
      }
    },
    addAssignment() {
      this.localAssignments.push({
        role: { id: 1 },
        created: true
      });
    },
    deleteAssignment(index) {
      const assignment = this.localAssignments[index];
      this.localAssignments.splice(index, 1, { ...assignment, deleted: true });
    }
  },
  computed: {
    filteredAssignments() {
      return this.localAssignments.filter(la => !la.deleted);
    }
  }
};
</script>

<style>
.assignment-icon {
  padding: 20px 10px;
}
</style>
