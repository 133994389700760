import { guid } from '@/lib'
const store = {
  namespaced: true,
  state: {
    actions: [],
    cursor: null
  },
  mutations: {
    clear (state, action) {
      state.actions = state.actions.filter(e => {
        return !(e.type == action.type && e.payload.id == action.payload.id)
      })
    },
    push (state, action) {
      state.actions.push(action)
      state.cursor = state.actions.length
    },
    confirm (state, action) {
      const index = state.actions.findIndex(a => action.id == a.id)
      state.actions.splice(index, 1, { ...state.actions[index], confirmed: true })
    }
  },
  actions: {
    push ({ commit }, action) {
      commit('clear', action)
      commit('push', {...action, id: guid()})
      commit(action.type, action.payload, {root: true})
    },
    undo ({ dispatch, state }) {
      // const action = state.actions[state.cursor]
      // state.oppositeActions[action.type]({ dispatch }, action)
      state.cursor -= 1
    },
    confirm ({ commit }, action) {
      commit('confirm', action)
    }
  },
  getters: {
    lastAction (state) {
      if (state.cursor === null) return null
      return state.actions[state.cursor]
    },
    confirmed (state) {
      return state.actions.filter(a => a.confirmed)
    },
    unconfirmed (state) {
      return state.actions.filter(a => !a.confirmed)
    }
  }
}

export default store