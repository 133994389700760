<template>
  <div class='w-100 mb-4' align="center">

    <v-btn
      type="button"
      dark
      color="#FF4B4B"
      @click="muralAuth"
      class="no-uppercase font-weight-bold"
    >
    <div class="mural-icon-bg">
      <img height="40" src="../img/mural-icon.svg" />
    </div>
      
    
    {{btnLabel}} 
  </v-btn>
  </div>
</template>

<script>
  import { MURAL_CLIENT_ID, MURAL_CLIENT_SECRET } from "@/env";
  const ERROR_WRONG_STATE = 0;
  const MURAL_AUTH_URL= 'https://app.mural.co/api/public/v1/authorization/oauth2/';
  const MURAL_ACCES_TOKEN_URL = 'https://app.mural.co/api/public/v1/authorization/oauth2/token';
  export default {
  	name: "MuralAuth",
  	props: {
	  signIn: {
	  	default: true,
	  	type: Boolean
	  }
  	},
  	data() {
	  return {
	  	popup: null
	  }
  	},
  	computed: {
      btnLabel() {
      	return this.signIn ? 'Sign in with Mural' : 'Register with Mural';
      }
    },
  	methods: {
	  muralAuth() {
	  	try {
  		  if (this.popup && !this.popup.closed)
  		    return;
	  	}	
  		catch(e)  {
  		  return;
  		}
		const state = Math.random().toFixed(20);
		const scope = 'users:read identity:read';
		const params = new URLSearchParams({
          client_id: MURAL_CLIENT_ID,
          state,
          scope,
          response_type: 'code',
          redirect_uri: window.location.origin
        }).toString();
 
        const windowParams = 'location=yes,status=no,width=640,height=640,resizable=yes'; 
        this.popup = window.open(
          `${MURAL_AUTH_URL}?${params}`,
          `Mural Vulcan authorization ${state}`,
          windowParams
        );

        new Promise((resolve, reject) => {
    	  const interval = setInterval(() => {
    	  	try {
              if (this.popup.closed) {
                this.close();
                return;
              }
              let params = new URLSearchParams(this.popup.location.search.substring(1));
              params = Object.fromEntries(params);
              if (params.code) {
                clearInterval(interval);
                this.popup.close();
                if (params.state == state)
                  resolve({code: params.code});
                else
                  reject(ERROR_WRONG_STATE);
              }

            } catch(e) {
            }
          }, 300)
        })
        .then(({code}) => {
    	  const params = {
            client_id: MURAL_CLIENT_ID,
            client_secret: MURAL_CLIENT_SECRET,
            code: code,
            grant_type: 'authorization_code',
            redirect_uri: window.location.origin
          };
          this.api.User.muralAuth( params)
            .then(res => {
        	  console.log(res);
              const token = res.body.access_token;
              this.$store.commit("user/setToken", token);
              return this.$store.dispatch("user/load");
            })
            .then(() => {
              if (this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                this.$router.push({ name: "dashboard" });
              }
            })
            .catch(e => {
        	  console.log(e)
            })

        })
	  }
  	}

  }
</script>

<style scoped>
.mural-icon-bg {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>