import Button from './Button';
import Select from './Select';
import Input from './Input';
import * as _ from 'underscore';

const components = _.indexBy([
  Button,
  Select,
  Input
], 'name');

export default components;