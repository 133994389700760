<template>
  <div class="wrapper">
    <v-tooltip top open-delay="600">
      <template v-slot:activator="{ on }">
        <v-avatar
          class="audio-person-avatar"
          color="#e6e6e6"
          :style="avatarStyle"
          @dblclick.stop.prevent="openSettingsModal"
          v-on="on"
        >
          <img v-if="avatar" :src="avatar" />
          <h1 v-else style="margin-bottom: 2px">{{capital}}</h1>
        </v-avatar>
      </template>
      <span>{{ name }}</span>
    </v-tooltip>

    <span class="connect-indicator" :style="connectIndicatorStyle" />
    <span class="speaking-indicator" :style="speakingIndicatorStyle" />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import ExtendsComponent from "../../_extends/ObjectComponent";
import AudioSettingsModal from "@/components/Layout/Authorized/TheEditor/_Global/AudioSettingsModal";
import { generateColor, HFSA_MAPPING_COEF, HFSA_STATUS_CONNECTED, HFSA_THRESHOLD_DEFAULT } from "@/lib";
import { detectSpaceId } from "@/libData";
import * as _ from "underscore";

export default {
  name: "SpatialAudio_AudioPersonObject",
  extends: ExtendsComponent,
  data() {
    const username = this.baseObject.info?.settings?.username || this.baseObject.info?.settings?.userId; // legacy
    return {
      HFSA_STATUS_CONNECTED,

      username,
      isMine: username == this.$store.state.user.current.username,
      cursor: null,
      speakingIndicatorStyle: {display: 'none'}
    };
  },
  methods: {
    openSettingsModal() {
      this.$modal.show(
        AudioSettingsModal,
        { scrollable: true, height: "auto" }
      );
    },
    changeSpace: _.debounce(async function() {
      const spaceId = detectSpaceId(this.baseObject.position, this.baseObject.size);
      if (spaceId == this.currentSpaceId)
        return;

      await this.$store.dispatch('audio/disconnectFromHiFiAudio');
      await this.$store.dispatch('audio/connectToHiFiAudio',
        {
          spaceId,
          spaceName: this.activeChart.name,
          userData: {
            position: {
              x: this.baseObject.position.x / HFSA_MAPPING_COEF,
              y: 0,
              z: this.baseObject.position.y / HFSA_MAPPING_COEF
            },
            orientationEuler: {
              yawDegrees: - this.baseObject.info.settings.angle
            },
            hiFiGain: this.baseObject.info.settings.hiFiGain,
            volumeThreshold: this.baseObject.info.settings.muted ? 0 : HFSA_THRESHOLD_DEFAULT
          }
        }
      );
    }, 300),
  },
  computed: {
    ...mapState({
      hifiStatus: state => state.audio.hifiStatus,
      hifiCommunicator: state => state.audio.hifiCommunicator,
      currentSpaceId: state => state.audio.currentSpaceId
    }),
    ...mapGetters({
      visibleName: "user/visibleName"
    }),
    audioData() {
      return this.$store.getters['audio/getUserData'](this.username);
    },
    avatarStyle() {
      return {
        borderColor: this.color
      };
    },
    connectIndicatorStyle() {
      return {
        borderColor: this.color,
        backgroundColor: this.isConnected ? this.color : 'white'
      }
    },
    color() {
      if (this.isMine)
        return "#df4e9e";
      return generateColor(this.username || '');
    },
    avatar() {
      if (this.cursor) {
        if (this.cursor.user.avatar_url)
          //TODO: remove
          return 'https://developers.beach.io' + this.cursor.user.avatar_url;
        return null;
      }
      if (this.isMine)
        return this.currentUser.profile.avatar_url;
      return null;
    },
    name() {
      if (this.isMine)
        return this.visibleName + ' (me)';

      if (this.cursor) {
        const {user} = this.cursor;
        if (user.first_name && user.last_name)
          return user.first_name + ' ' + user.last_name;
        else if (user.first_name)
          return user.first_name;
        else if (user.last_name)
          return user.last_name;
        return user.username;
      }
    },
    capital() {
      return this.name.charAt(0).toUpperCase();
    },
    isConnected() {
      let isConnected = this.hifiStatus == HFSA_STATUS_CONNECTED;
      if (!this.isMine)
        isConnected = isConnected && this.audioData;
      return isConnected;
    }
  },
  watch: {
    'baseObject.position': {
      async handler(newV, oldV) {
        if (!this.isMine || !this.isConnected ||
            newV.x == oldV.x && newV.y == oldV.y)
          return;

        const spaceId = detectSpaceId(this.baseObject.position, this.baseObject.size);
        if (spaceId !== this.currentSpaceId) {
          this.changeSpace(spaceId);
        } else {
          this.hifiCommunicator.updateUserDataAndTransmit({
            position: {
              x: this.baseObject.position.x / HFSA_MAPPING_COEF,
              y: 0,
              z: this.baseObject.position.y / HFSA_MAPPING_COEF
            },
          });
        }
      },
      deep: true
    },
    'baseObject.info.settings.angle': {
      handler() {
        if (!this.isMine || !this.isConnected)
          return;

        this.hifiCommunicator.updateUserDataAndTransmit({
          orientationEuler: {
            yawDegrees: - this.baseObject.info.settings.angle
          }
        });
      }
    },
    'audioData.volumeDecibels': {
      handler() {
        if (!this.audioData || typeof (this.audioData.volumeDecibels) !== "number") {
          this.speakingIndicatorStyle = {display: 'none'};
          return;
        }

        const volume = Math.max(0, this.audioData.volumeDecibels + 96);
        const size = .02;
        const scale = size * volume;

        this.speakingIndicatorStyle = {
          background: `linear-gradient(${this.color}55 0%, ${this.color} 100%)`,
          transform: `translateX(-50%) scale(${scale})`
        };
      }
    }
  },
  created() {
    if (!this.isMine) {
      try {
        this.cursor =
          this.activeChart.users_cursors
            .find(cursor => cursor.user.username == this.username);
      } catch (e) {}
    }
  }
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.audio-person-avatar {
  width: 100% !important;
  height: 100% !important;
  border-width: 2px;
  border-style: solid;
}

.connect-indicator {
  position: absolute;
  background: pink;
  border: 1px solid pink;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  left: calc(50% - 5px);
  top: -5px;
}

.connect-indicator.disconnected {
  background: white;
}

.speaking-indicator {
  width: 15px;
  height: 15px;
  background: linear-gradient(rgba(209,39,56,1) 0%,rgba(122,23,38,1) 100%);
  clip-path: polygon(0 0, 100% 0%, 60% 100%, 40% 100%);
  position: absolute;
  top: -18px;
  left: 50%;
  transform-origin: 50% 100%;
}
</style>
