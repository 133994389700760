<template>
  <div class="color-picker">
    <Picker :value="localValue" @input="updateValue" :presetColors="localPresetColors" />
  </div>
</template>

<script>
import { Sketch } from "vue-color";
// import { Material } from 'vue-color'
// import { Swatches } from 'vue-color'

export default {
  name: "ColorPicker",
  components: {
    Picker: Sketch
  },
  props: {
    value: String,
    presetColors: Array
  },
  data() {
    return {
      localValue: {
        hex: this.value
      },
      localPresetColors: this.presetColors
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value.hex8);
    }
  },
  watch: {
    presetColors() {
      this.localPresetColors = this.presetColors
    },
    value() {
      this.localValue = {
        hex: this.value
      };
    }
  }
};
</script>

<style scoped>
.color-picker {
  margin-bottom: 20px;
}
</style>
