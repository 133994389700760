<template>
  <div>
    <component
      :storage="localSettings.storage"
      :baseObject="baseObject"
      v-bind="objectProps"
      :is="componentName || 'div'"
      :ENV="ENV"
      :OAUTH="OAUTH"
    />
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import ExtendsComponent from "../../_extends/ObjectComponent";
import {compileTemplate, getENV, getOAUTH} from "@/libData";

export default {
  name: "Core_CustomComponent",
  extends: ExtendsComponent,
  data() {
    return {
      component: null,
      componentName: null,
      error: null,
      env: [],
      objectProps: {},
    };
  },
  computed: {
    ENV() {
      return getENV(this.env);
    },
    OAUTH() {
      return getOAUTH();
    }
  },
  watch: {
    localSettings() {
      this.setProps();
    }
  },
  async created() {
    const id = this.settings.componentId;
    this.component = this.$store.getters['component/findById'](id);
    if (!this.component) {
      await this.$store.dispatch('component/loadComponentToCache', id);
      this.component = this.$store.getters['component/findById'](id);
    }
    if (!this.component)
      return;

    let properties = {};
    if (this.component.object_settings.properties) {
      this.component.object_settings.properties
        .forEach(property => {
          if (property.type != 'group') {
            properties[property.name] = property.defaultValue;
          } else {
            property.elements.forEach(elem => {
              properties[elem.name] = elem.defaultValue;
            });
          }
        });
    }

    this.localSettings = {
      storage: {},
      ...this.localSettings,
      properties: {
        ...properties,
        ...this.localSettings.properties
      }
    };

    this.setProps();
    this.compile();
  },
  methods: {
    setProps() {
      if (this.settings.manualProps) {
        try {
          this.objectProps = {
            ...this.localSettings.properties,
            ...eval(`${this.settings.manualProps}; props()`)
          };
        } catch (e) {
          console.log(e);
        }
      } else {
        this.objectProps = this.localSettings.properties;
      }
    },
    async compile() {
      try {
        const { component, env } = await compileTemplate({
          componentId: this.settings.componentId
        });

        this.env = env;

        const componentName = component.name + this.guid();
        Vue.component(componentName, component);

/*        Vue.config.errorHandler = (err, vm, info) => {
          this.error = err.toString();
        };
        Vue.config.warningHandler = (err, vm, info) => {
          this.error = err.toString();
        };*/

        await this.$nextTick();
        this.componentName = componentName;
        if (this.$parent.updateSize)
          this.$parent.updateSize();
      } catch (err) {
        this.error = err.toString();
      }
    },
  }
};
</script>

<style scoped>
</style>
