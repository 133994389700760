<template>
  <Modal
    :showSubmit="!!selectedPlan"
    @submit="submit"
    title="Plan"
    submitText="Select"
  >
    <v-layout>
      <v-flex class="px-5 py-2">
        <v-select
          v-model="selectedPlan"
          :items="plans"
          item-text="name"
          chips
          required
          return-object
          label="Plans *"
        ></v-select>
      </v-flex>
    </v-layout>
  </Modal>
</template>

<script>
export default {
  name: "PlanModal",
  props: {
    callback: Function,
    organisation: Object,
    currentPlan: Object
  },
  data() {
    return {
      plans: [],
      selectedPlan: this.currentPlan
    };
  },
  created() {
    this.fetchPlans();
  },
  methods: {
    fetchPlans() {
      this.api.Plan.get().then(res => (this.plans = res.body));
    },
    submit() {
      this.callback(this.selectedPlan);
    }
  }
};
</script>

<style>
.assignment-icon {
  padding: 20px 10px;
}
</style>
