const defaultSettings = {
  name: "Router",
  icon: 'swap_calls',
  showLabel: true,
  size: {
    width: 'auto',
    height: 40
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  // maxOutConnections: 1,
  inColor: "rgb(132, 132, 132)",
  outColor: "rgb(132, 132, 132)",
  layout: "horizontal",
  components: [/Chatbot_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
