import CheckboxList from './CheckboxList';
import RadioList from './RadioList';
import Image from './Image';
import Video from './Video';
import CustomComponent from './CustomComponent';
import * as _ from 'underscore';

const components = _.indexBy([
  CheckboxList,
  RadioList,
  Image,
  Video,
  CustomComponent
], 'name');

export default components;