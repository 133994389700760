<template>
  <v-menu max-height="300" min-width="100" v-model="menuShow" :activator="activator" offset-y>
    <v-list dense subheader>
      <template v-for="(section, index) in filteredItems">
        <v-subheader :key="index">{{section.name}}</v-subheader>

        <v-list-item
          v-for="(item, _index) in section.items"
          :key="index + ' ' + _index"
          :id="`notion-editor-command-line__${item.id}`"
          @click="select(item.id)"
        >
          <v-list-item-icon class="mr-5">
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <div v-html="item.name" />
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import VueScrollTo from "vue-scrollto";

export default {
  name: "NotionEditorTextCommandLine",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    content: {
      type: String,
      default: ""
    },
    position: {
      type: Number,
      default: 0
    },
    active: [String, Number],
    id: String
  },
  data() {
    return {
      menuShow: true,
      activator: `.notion-line__content-editable[id="${this.id}"]`
    };
  },
  mounted() {
    this.$emit("update:active", this.items[0].items[0].id);
  },
  beforeDestroy() {
    this.$emit("update:active", null);
  },
  methods: {
    select(itemId) {
      this.$emit("update:active", itemId);
      this.$emit("select");
    }
  },
  computed: {
    filteredItems() {
      const content = this.content.slice(1, this.position);
      return this.items.reduce((memo, i) => {
        const items = i.items.filter(
          _i =>
            (_i.shortcut || _i.name || "")
              .toLowerCase()
              .indexOf(content.toLowerCase()) != -1
        );
        if (items.length)
          memo.push({ ...i, items });
        return memo;
      }, []);
    }
  },
  watch: {
    menuShow(val) {
      if (!val)
        this.$emit("hide");
    }
    /*active() {
      if (this.$el) {
        const element = this.$el.querySelector(
            `#notion-editor-command-line__${this.active}`
          ),
          duration = 10,
          options = {
            container: this.$el,
            offset: -30
          };
        VueScrollTo.scrollTo(element, duration, options);
      }
    }*/
  }
};
</script>
