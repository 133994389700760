const defaultSettings = {
  name: "Wayfinder",
  icon: 'mdi-map',
  hotkey: `modkey + W`,
  size: {
    width: 200,
    height: 200
  },
  resizable: true,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  openPropertyEditorByClick: true,
  skipGroup: true,
  layout: "horizontal",
  showLabel: true,
  inColor: "#df4e9e",
  outColor: "red",
  components: [/Presentation_.*/],
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
