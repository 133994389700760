const defaultSettings = {
  name: "Template",
  icon: 'mdi-palette-advanced',
  hotkey: `modkey + Shift + T`,
  size: {
    width: 150,
    height: 100
  },
  resizable: false,
  draggable: false,
  has: {
    in: false,
    out: false,
    settings: false
  },
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
