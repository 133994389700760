import { deepClone } from "@/lib";
import addonDefiner from "./addonDefiner";
import "./quill.bubble.css";
import * as _ from "underscore";

export default {
  props: {
    settings: Object,
    baseObject: Object,
    readonly: Boolean
  },

  data() {
    return {
      localSettings: deepClone(this.settings),
      components: []
    };
  },

  created() {
    if (this.currentUser) {
      this.api.Component.get().then(response => {
        this.components = response.body;
      });
    }

    /*this.$store.watch(
      state => {
        return state.propertyEditor.settings;
      },
      () => {
        if (
          JSON.stringify(this.localSettings) !=
          JSON.stringify(this.$store.getters["propertyEditor/settings"])
        ) {
          this.localSettings = this.$store.getters["propertyEditor/settings"];
        }
      },
      { deep: true }
    );*/
  },

  mounted() {
    this.setCurrentState();
    this.setTabs();
  },

  methods: {
    onDataEditorClose() {
      this.openedData = undefined;
    },
    onAudioSettingClose() {
      this.openedAudio = undefined;
    },
    save: _.debounce(function() {
      this.$store.dispatch(
        "object/update",
        {
          id: this.baseObject.id,
          info: {
            ...this.baseObject.info,
            settings: deepClone(this.localSettings)
          }
        }
      );
    }, 300),
    notification({ tab, data }) {
      tab.notificationsCount = tab.notificationsCount || 0;
      switch (data.type) {
        case "INCREMENT":
          tab.notificationsCount += 1;
          break;
        case "SET":
          tab.notificationsCount = data.value;
          break;
        case "SHOW_MESSAGE":
          this.$root.$emit("showSnack", data);
          break;
      }
    },
    setTabs() {
      let tabs;
      if (!this.localSettings.addons || !this.localSettings.addons.length) {
        tabs = [];
      } else {
        tabs = this.localSettings.addons.reduce((memo, addon) => {
          switch (addonDefiner(addon)) {
            case "YoutubePlayer":
              memo.push({ title: addon.name, icon: "ondemand_video", addon });
              break;
            case "TwitterFeed":
              memo.push({ title: addon.name, icon: "t", plain: true, addon });
              break;
            case "RssFeed":
              memo.push({ title: addon.name, icon: "rss_feed", addon });
              break;
          }

          if (addon.type == "component" && addon.component) {
            const component = this.components.find(
              c => c.id == addon.component
            );
            const iconUrl =
              component && component.icon ? component.icon.body.url : null;
            const title = addon.name
              ? addon.name
              : component
              ? component.name
              : "";
            memo.push({
              title,
              iconUrl,
              addon,
              notificationsCount: 0
            });
          }
          return memo;
        }, []);
      }
      return tabs;
    },
    setCurrentState() {
      this.$store.commit("intellisense/setCurrentState", this.schema);
    }
  },

  watch: {
    settings: {
      handler() {
        this.setCurrentState();
        if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings)) {
          this.localSettings = deepClone(this.settings);
          this.$store.dispatch("propertyEditor/setSettings", this.localSettings);
          this.$root.$emit("DataEditor.update", {
            settings: this.localSettings
          });
        }
      },
      deep: true,
      immediate: true
    },
    localSettings: {
      handler() {
        if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings))
          this.save();
      },
      deep: true
    }
  },

  computed: {
    tabs() {
      return this.setTabs();
    },
    projectColors() {
      if (
        !this.activeProject ||
        !this.activeProject.settings ||
        !this.activeProject.settings.colors ||
        !this.activeProject.settings.colors.custom
      )
        return [];
      return this.activeProject.settings.colors.custom.map(a => a.value);
    }
  },
  beforeDestroy() {
    this.$emit("setTabs", []);
  }
};
