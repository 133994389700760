const defaultSettings = {
  name: "RESTClient",
  icon: 'mdi-code-greater-than-or-equal',
  hotkey: `modkey + K`,
  size: {
    width: 300,
    height: 600
  },
  resizable: true,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  openPropertyEditorByClick: true,
  skipGroup: true,
  layout: "horizontal",
  showLabel: true,
  inColor: "#blue",
  outColor: "green",
  components: ["Tech_RESTClient"],
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;