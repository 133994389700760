<template>
  <v-card class="shape-toolbar py-2" dense no-padding>
    <v-tooltip
        class="p-3"
        right
        v-for="(item, index) in shapesList"
        :key="index"
    >
      <template v-slot:activator="{ on }">
        <v-btn
            icon
            v-on="on"
            @click.stop="setPolygonType(item)"
            :class="activePolygonTypeClass(item)"
            class="p-3 shape-button"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ item.shape }}</span>
    </v-tooltip>
  </v-card>
</template>

<script>
export default {
  name: "ShapeToolbar",
  data() {
    const activeChart = this.$store.getters["chart/active"];
    const options =
        activeChart && activeChart.options ? activeChart.options : {};
    return {
      polygonType: options.activeTool.name
    };
  },
  computed: {
    shapesList() {
      return [
        {shape: "Rect", icon: "mdi-shape-rectangle-plus", cursor: "rectangle"},
        {shape: "Triangle", icon: "mdi-triangle-outline", cursor: "triangle"},
        {shape: "Diamond", icon: "mdi-rhombus-outline", cursor: "diamond"},
        {shape: "Circle", icon: "mdi-circle-outline", cursor: "circle"},
        {shape: "Label", icon: "mdi-label-outline", cursor: "label"},
        {shape: "Step", icon: "mdi-label-variant-outline", cursor: "step"},
      ];
    }
  },
  mounted() {
    const polygon = this.shapesList.find(polygon => polygon.shape == this.polygonType);
    this.setPolygonType(polygon ? polygon : this.shapesList[0]);
  },
  methods: {
    createObject(shape) {
      this.$emit("createPolygonObject", shape);
    },
    setPolygonType(polygon) {
      this.polygonType = polygon.shape;
      const activeChart = this.$store.getters["chart/active"];
      activeChart.options.activeTool.name = this.polygonType;
      activeChart.options.mouseCursor = `url(/cursors/${polygon.cursor}.svg), pointer`;
      this.$store.dispatch("chart/update", activeChart);
    },
    // class utility to indicate active polygon type
    activePolygonTypeClass(polygon) {
      return this.polygonType == polygon.shape ? 'active' : '';
    }
  },
};
</script>

<style scoped>
.shape-toolbar {
  position: absolute;
  left: 55px;
  top: 45px;
  z-index: 2000;
  width: 50px;
}

.shape-button.active {
  background-color: pink;
}
</style>
