<template>
  <div />
</template>

<script>
  export default {
    name: 'FacebookApi',
    created() {
    }
  }
</script>