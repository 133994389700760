<template>
  <div class="notion-type-divider" :style="contentStyle">
    <v-divider />
  </div>
</template>

<script>
export default {
  name: "NotionTypeDivider",
  props: {
    line: Object
  },
  data() {
    return {
      localLine: this.line
    };
  },
  computed: {
    contentStyle() {
      return {
        color: this.line.color || "#333",
        background: this.line.background || "transparent"
      };
    }
  },
  watch: {
    localLine: {
      handler() {
        this.$emit("update:line", this.localLine);
      }
    }
  }
};
</script>

<style scoped>
/* .notion-type-image >>> .uploader-modal {
  top: 0;
  left: 50%;
  margin-left: -200px;
}
.notion-type-image >>> .uploader {
  margin: 0 auto;
}
.notion-type-image {
  padding: 15px 0;
} */
</style>
