<template>
  <div :class="className">
    <div class="object-in__point" :style="style" />
  </div>
</template>

<script>
  import { defaultProperties } from './Types'

  export default {
    name: 'ObjectIn',
    props: {
      objectId: [Number, String]
    },
    computed: {
      object () {
        return this.$store.getters['object/findById'](this.objectId)
      },
      style () {
        return {
          background: this.object.info.settings.color || defaultProperties[this.object.type].inColor || '#605da5'
        }
      },
      layout() {
        return this.object.layout;
      },
      className() {
        return ["object-in", this.layout];
      },
    }
  }
</script>

<style scoped>
  .object-in {
    padding: 10px;
    position: absolute;
    z-index: 4;
    cursor: pointer;
  }

  .object-in.horizontal {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 1px;
  }
  .object-in.vertical {
    top: 1px;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .object-in__point {
    height: 10px;
    width: 10px;
    border-radius: 99px;
  }

  .object-in__point:after {
    content: "";
    display: block;
    border-radius: 4px;
    background: white;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
