<template>
</template>

<script>
  import ControlPropertyEditorExtend from '../_extends/ControlPropertyEditor';
  import Components from "./Components";

  export default {
    name: "ControlElement_CustomComponentControlPropertyEditor",

    extends: ControlPropertyEditorExtend,

    watch: {
      id() {
        this.openData();
      }
    },

    mounted() {
      this.openData();
    },

    methods: {
      openData() {
        this.$root.$emit("DataEditor.open", {
          component: Components.RenderTemplate,
          props: {
            baseObjectId: this.baseObject.id,
            settings: this.localSettings,
            readonly: this.readonly
          },
          title: "Template"
        });
        //TODO: костыль
        //this.$root.$emit('PropertyEditor.close');
      }
    }
  };
</script>