<template>
  <div>
    <div v-for="property in elements" class="prop">
      <v-text-field
        v-if="property.type == 'textfield'"
        hide-details
        :readonly="readonly"
        v-model="properties[property.name]"
        :label="property.visibleName"
      />

      <v-textarea
        v-if="property.type == 'textarea'"
        :label="property.visibleName"
        hide-details
        v-model="properties[property.name]"
        :readonly="readonly"
        rows="3"
        class="pb-1"
      />

      <v-text-field
        v-if="property.type == 'number'"
        hide-details
        type="number"
        :readonly="readonly"
        :value="properties[property.name]"
        @input="properties[property.name] = parseFloat($event)"
        :label="property.visibleName"
      />

      <v-select
        v-if="property.type == 'select'"
        :disabled="readonly"
        v-model="properties[property.name]"
        :items="property.selectOptions"
        :label="property.visibleName"
        item-text="name"
        item-value="data"
        hide-details
      />

      <v-menu
        v-if="property.type == 'date'"
        v-model="menus[property.id]"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-text-field
            v-model="properties[property.name]"
            :label="property.visibleName"
            class="pa-0 mx-2"
            hide-details
            :style="{width: '85px'}"
            v-on="menu"
          />
        </template>
        <v-date-picker
          v-model="localSettings.properties[property.name]"
          no-title
          scrollable
          :show-current="false"
          @change="menus[property.id] = false"
        />
      </v-menu>

      <v-menu
        v-if="property.type == 'time'"
        v-model="menus[property.id]"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-text-field
            v-model="properties[property.name]"
            class="pa-0 mx-2"
            :label="property.visibleName"
            hide-details
            :style="{width: '70px'}"
            v-on="menu"
          />
        </template>
        <v-time-picker
          v-model="properties[property.name]"
          full-width
          @change="menus[property.id] = false"
        />
      </v-menu>

      <div v-if="property.type == 'color_picker'">
        <span>{{property.visibleName}}: </span>
        <ColorPickerDialog
          v-model="properties[property.name]"
          :title="property.visibleName"
          :presetColors="projectColors"
          :disabled="readonly"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PropertyGroup",
    props: {
      elements: Array,
      properties: Object,
      readonly: Boolean
    },
    computed: {
      projectColors() {
        if (
          !this.activeProject ||
          !this.activeProject.settings ||
          !this.activeProject.settings.colors ||
          !this.activeProject.settings.colors.custom
        )
          return [];
        return this.activeProject.settings.colors.custom.map(a => a.value);
      }
    }
  }
</script>

<style scoped>

</style>