<template>
  <div class="endpoint" :style="style">
    <div class="top-arrow" v-if="localSettings.activateCallback">
      <span>Activate Callback</span>
      <div>
        <i class="material-icons">
          arrow_upward
        </i>
        <i class="material-icons">
          arrow_downward
        </i>
      </div>
    </div>
    <div class="text">
      <div>{{endpointName || 'Endpoint'}}</div>
    </div>
  </div>
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent';

  export default {
    name: 'Presentation_WayfinderEndpointObject',
    extends: ExtendsComponent,
    computed: {
      style () {
        return {
          background: this.localSettings.color || '#5a5a5a'
        };
      },
      endpointName () {
        return this.localSettings.endpoint ? this.localSettings.endpoint.name : null;
      }
    }
  }
</script>

<style scoped>
  .endpoint {
    border-radius: 30px;
    min-width: 250px;
  }

  .top-arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -50px;
  }

  .text {
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
