<template>
  <div>OPEN_PAGE</div>
</template>

<script>
  import componentExtends from './_extends/Component';

  export default {
    name: 'Chatbot_RouterObjectPropertyEditor_OPEN_PAGE',
    extends: componentExtends,
  }
</script>
