<template>
  <Modal
    :showSubmit="!!invitation.role_ids.length && !!invitation.email"
    @submit="submit"
    title="Invitation"
    submitText="Send"
  >
  <v-container>
    <v-layout>
      <v-flex xs6>
        <v-text-field solo label="Email *" required type="email" v-model="invitation.email" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6>
        <v-text-field label="First Name" v-model="invitation.first_name" />
      </v-flex>
      <v-flex xs6>
        <v-text-field label="Last Name" v-model="invitation.last_name" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <v-select
          v-model="invitation.role_ids"
          :items="roles"
          item-value="id"
          item-text="name"
          chips
          required
          label="Roles *"
          multiple
        />
      </v-flex>
    </v-layout>
  </v-container>
  </Modal>
</template>

<script>
export default {
  name: "InvitationsModal",
  props: {
    callback: Function,
    organisation: Object
  },
  data() {
    return {
      roles: [],
      invitation: {
        role_ids: []
      }
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      this.api.Role.get().then(res => (this.roles = res.body));
    },
    submit() {
      this.api.Invitation.save({
        invitation: this.invitation,
        group_type: "Organisation",
        group_id: this.organisation.id
      }).then(res => {
        this.callback();
      });
    }
  }
};
</script>

<style>
.assignment-icon {
  padding: 20px 10px;
}
</style>
