import Vue from "vue";

import ColorPicker from "./ColorPicker";
Vue.component("ColorPicker", ColorPicker);

import NotionEditor from "./NotionEditor";
Vue.component("NotionEditor", NotionEditor);

import OptionsList from "./OptionsList";
Vue.component("OptionsList", OptionsList);

import Uploader from "./Uploader";
Vue.component("Uploader", Uploader);

import Modal from "./Modal";
Vue.component("Modal", Modal);

import Variables from "./Variables";
Vue.component("Variables", Variables);

import OAuthList from "./OAuthList";
Vue.component("OAuthList", OAuthList);

import OAuthNew from "./OAuthNew";
Vue.component("OAuthNew", OAuthNew);

import Autocompleter from "./Autocompleter";
Vue.component("Autocompleter", Autocompleter);

import { VueEditor } from "vue2-editor";
Vue.component("VueEditor", VueEditor);

import { AtomSpinner } from "epic-spinners";
Vue.component("AtomSpinner", AtomSpinner);

import Snackbar from "./Snackbar";
Vue.component("Snackbar", Snackbar);

import { Timeline } from "vue-tweet-embed";
Vue.component("Timeline", Timeline);

import draggable from "vuedraggable";
Vue.component("draggable", draggable);

import ViewportPreview from "./ViewportPreview";
Vue.component("ViewportPreview", ViewportPreview);

import YoutubePlayer from "./YoutubePlayer";
Vue.component("YoutubePlayer", YoutubePlayer);
