<template>
  <div class="uploader-modal">
    <div class="uploader-modal__title">
      <span>Add a Thumbnail</span>
      <v-icon @click.stop="$emit('close')">close</v-icon>
    </div>
    <v-tabs grow v-model="activeTab" slider-color="#df4e9e">
      <v-tab>Uploads</v-tab>
      <v-tab>Search</v-tab>
      <!-- <v-tab>Emojis</v-tab> -->
    </v-tabs>
    <!-- <div class="uploader-modal__content" v-show="activeTab == 0">
      <div>Emojis WIP</div>
    </div> -->
    <div class="uploader-modal__content" v-show="activeTab == 0">
      <label class="uploader-modal__upload">
        <div
          v-if="localValue.body && !loading"
          class="uploader-modal__preview"
          :style="{backgroundImage: localValue.body ? `url(${localValue.body.thumb.url})` : ''}"
        />
        <div v-else class="uploader-placeholder">
          <atom-spinner v-if="loading" :animation-duration="1000" :size="60" :color="'#df4e9e'" />
          <v-icon v-else>{{icon}}</v-icon>
        </div>
        <input :disabled="disabled" class="uploader-input" type="file" @change="onFileChanged" />
      </label>
    </div>
    <div
      ref="searchResult"
      class="uploader-modal__content"
      v-show="activeTab == 1"
      v-infinite-scroll="fetchImages"
    >
      <div>
        <v-text-field v-model="query" placeholder="Type something..." solo>
          <template v-slot:append>
              <v-fade-transition group leave-absolute>
                <v-progress-circular
                  v-if="busy"
                  size="24"
                  color="info"
                  indeterminate
                  key="0"
                />
                <v-btn @click="fetchImages" icon key="1">
                  <v-icon>search</v-icon>
                </v-btn>
              </v-fade-transition>
            </template>
        </v-text-field>

        <div class="uploader-modal__search-result">
          <div
            @click="uploadSearchResult(image.thumb)"
            :style="{backgroundImage: `url(${image.thumb})`}"
            class="uploader-modal__search-item"
            v-for="(image, index) in images"
            :key="index"
          >
            <div class="open-original-icon pa-0" @click.stop="openImage(image.large)">
              <v-icon dense>open_in_new</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uploader-modal__actions">
      <v-btn text small color="error" @click="remove">Remove</v-btn>
      <v-btn text small color="info" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
import * as _ from "underscore";

export default {
  name: "UploaderModal",
  data() {
    return {
      activeTab: 0,
      query: "",
      images: [],
      offset: 0,
      localValue: { ...this.value },
      busy: false,
      loading: false
    };
  },
  props: {
    disabled: Boolean,
    icon: String,
    url: String,
    value: Object,
    countBase64: Boolean,
    base64: String
  },
  methods: {
    remove() {
      this.$emit("input", null);
      this.$emit("close");
    },
    onFileChanged(event) {
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append("attachment", file, file.name);

      this.loading = true;
      this.$http.post(this.url, formData).then(response => {
        this.loading = false;
        this.localValue = response.body;
      });
    },
    async save() {
      if (this.countBase64 && this.localValue.body) {
        const base64 = await this.toDataUrl(this.localValue.body.url);
        this.$emit("update:base64", base64);
      }
      this.$emit("input", { ...this.localValue });
      this.$emit("close");
    },
    fetchImages: _.debounce(function() {
      if (!this.query)
        return false;
      this.busy = true;
      const joinedQuery = this.query.split(" ").join("_").toLowerCase();
      this.$http
        .get("vulcan/v1/azure/image-search", {
          params: { query: joinedQuery, offset: this.offset }
        })
        .then(response => {
          const { offset, images } = response.body;
          this.offset = offset;
          this.images = [...this.images, ...images];
          this.busy = false;
        });
    }, 300),
    uploadSearchResult(url) {
      this.activeTab = 0;
      this.loading = true;

      var request = new XMLHttpRequest();
      request.responseType = "blob";
      request.onload = () => {
        const formData = new FormData();
        formData.append(
          "attachment",
          request.response,
          this.query + "." + request.response.type.split("/")[1]
        );
        this.$http.post(this.url, formData).then(response => {
          this.localValue = response.body;
          this.loading = false;
        });
      };

      request.open("GET", url);
      request.send();
    },
    toDataUrl(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
          const reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },
    openImage(url) {
      window.open(url,'_blank');
    }
  },
  watch: {
    query() {
      this.offset = 0;
      this.images = [];
    }
  }
};
</script>

<style scoped>
.uploader-input {
  display: none;
}
.uploader-modal {
  width: 400px;
  background: white;
  z-index: 4;
  position: absolute;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.uploader-modal__title {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
}

.uploader-modal__title >>> i {
  cursor: pointer;
}

.uploader-modal >>> .theme--light.v-tabs-bar {
  background: #f9f9f9;
}

.uploader-modal__content {
  padding: 15px;
  max-height: 280px;
  overflow: auto;
}
.uploader-modal__preview {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.uploader-modal__actions {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.uploader-modal__upload {
  display: block;
  width: 120px;
  height: 120px;
  border: 1px solid #e7e7e7;
  cursor: pointer;
  margin: auto;
  position: relative;
}

.uploader-modal__search-result {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.uploader-modal__search-item {
  margin: 10px;
  cursor: pointer;
  width: 95px;
  height: 65px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.uploader-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
}

.open-original-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border-radius: 0 0 0 2px;
  display: flex;
  visibility: hidden;
}

.uploader-modal__search-item:hover .open-original-icon {
  visibility: visible;
}

.open-original-icon:hover * {
  color: black !important;
}
</style>
