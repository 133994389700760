import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';
import checkAuth from './checkAuth';


Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes
});

checkAuth(router);

export default router;
