<template>
  <component
    :is="wrapper"
    @submit="submit"
    title="New Project"
    :showSubmit="!!params.name"
    submitText="Create"
  >
    <ProjectForm
      :showActions="showActions"
      mode="new"
      :params.sync="params"
      @submit="submit"
      @close="close"
    />
  </component>
</template>

<script>
import ProjectForm from "./_Form";

export default {
  name: "NewProject",
  components: {
    ProjectForm
  },
  props: {
    wrapper: {
      type: String,
      default: "Modal"
    },
    showActions: Boolean
  },
  data() {
    return {
      params: {
        type: "AnalysisTools",
        settings: {}
      },
      opened: true
    };
  },
  methods: {
    submit() {
      if (!this.params.name)
        return;

      this.api.Project.save({ project: this.params }).then(response => {
        const projectId = response.body.id,
          chartId = response.body.charts[0].id;
        this.$router.push({ name: "editor", params: { projectId, chartId } });
        this.close();
      });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>