import * as _ from "underscore";

import {Chart, Widget} from "@/resources";
import {objectModeTypes} from "../../utils/const";

const store = {
    namespaced: true,
    state: {
        active: null,
        scale: 1,
        translate: {x: 0, y: 0},
        allowedObjectTypes: {},
        delayedUpdates: [],
        openedComments: [],
        specialKey: null,
    },
    mutations: {
        set(state, chart) {
            state.active = chart;
        },
        setCursor(state, cursor) {
            if (state.active)
                state.active = {
                    ...state.active,
                    users_cursors: [
                        ...state.active.users_cursors.filter(
                            u => u.user.id != cursor.user.id
                        ),
                        cursor
                    ]
                };
        },
        setAllowedObjectTypes(state, objectTypes) {
            state.allowedObjectTypes = objectTypes;
        },
        setScale(state, scale) {
            state.scale = scale;
        },
        setTranslate(state, translate) {
            state.translate = translate;
        },
        openComment(state, comment) {
            state.openedComments.push(comment);
        },
        closeComment(state, comment) {
            state.openedComments.splice(state.openedComments.indexOf(comment), 1);
        },
        closeComments(state) {
            state.openedComments = [];
        },
        updateObject(state, object) {
            if (object.type == 'Base_CommentObject') {
                let ind = state.openedComments.findIndex(o => o.id == object.id);
                if (ind != -1)
                    state.openedComments.splice(ind, 1, object);
            }
        },
        setSpecialKey(state, specialKey) {
            state.specialKey = specialKey;
        },
        setObjectMode(state, objectMode) {
            if (state && state.active && state.active.options)
                state.active.options.objectMode = objectMode;
        }
    },
    actions: {
        set({commit, dispatch, rootGetters}, id) {
            commit('closeComments');

            if (id === null)
                return commit("set", null);

            return Chart.get({id})
                .then(res => {
                    const chart = res.body;
                    chart.users_cursors = chart.users_cursors.filter(
                        uc => uc.user.id != rootGetters["user/current"].id
                    );
                    commit("set", chart);

                    if (chart.options.scale)
                        commit("setScale", chart.options.scale);
                    else
                        dispatch("setScale", 1);

                    if (chart.options.translate)
                        commit("setTranslate", chart.options.translate);
                    else
                        dispatch("setTranslate", {x: 0, y: 0});

                    if (chart.options.allowed_object_types)
                        commit("setAllowedObjectTypes", chart.options.allowed_object_types);
                    else
                        dispatch("setAllowedObjectTypes", {});

                    commit("object/setList", chart.objects, {root: true});
                    commit("connection/setList", chart.connections, {root: true});

                    chart.objects = null;
                    chart.connections = null;
                    return chart;
                });
        },

        async fetchWidget({commit, dispatch, rootGetters}, id) {
            const {body} = await Widget.get({id});
            const chart = body;

            const objects = chart.objects;
            const connections = chart.connections;
            chart.objects = null;
            chart.connections = null;

            commit("set", chart);
            commit("object/setList", objects, {root: true});
            commit("connection/setList", connections, {root: true});

            if (chart.options.scale)
                commit("setScale", chart.options.scale);
            if (chart.options.translate)
                commit("setTranslate", chart.options.translate);
        },
        setScale({state, commit, dispatch}, scale) {
            commit("setScale", scale);
            if (state.active)
                dispatch("update", {
                    id: state.active.id,
                    options: {
                        ...state.active.options,
                        scale
                    }
                });
        },
        setTranslate({state, commit, dispatch}, translate) {
            commit("setTranslate", translate);
            if (state.active)
                dispatch("update", {
                    id: state.active.id,
                    options: {
                        ...state.active.options,
                        translate
                    }
                });
        },
        setAllowedObjectTypes({commit, dispatch, state}, objectTypes) {
            commit("setAllowedObjectTypes", objectTypes);
            if (state.active)
                dispatch("update", {
                    id: state.active.id,
                    options: {
                        ...state.active.options,
                        allowed_object_types: objectTypes
                    }
                });
        },
        setActiveTool({state, dispatch}, toolName, toolOptions) {
            if (state.active) {
                const options = state.active.options
                dispatch("update", {
                    id: state.active.id,
                    options: {...options, activeTool: {name: toolName, options: toolOptions || null}}
                })
            }
        },
        setObjectMode({state, dispatch}, objectMode) {
            if (state.active) {
                const options = state.active.options;
                let mouseCursor = "auto";
                const toolOptions = options.activeTool.options
                if (objectMode == objectModeTypes.Drawing && toolOptions && toolOptions.iconSvg) {
                    mouseCursor = `url(/cursors/${toolOptions.iconSvg}), pointer`;
                }
                dispatch("update", {
                    id: state.active.id,
                    options: {
                        ...state.active.options,
                        objectMode,
                        mouseCursor
                    }
                });
            }
        },
        setMouseCursor({state, dispatch}, mouseCursor) {
            if (state.active)
                dispatch("update", {
                    id: state.active.id,
                    options: {
                        ...state.active.options,
                        mouseCursor
                    }
                });
        },
        update({state, getters, rootGetters, dispatch}, params) {
            const project = rootGetters['project/active'];
            if (!project)
                return;

            const chart = project.charts.find(c => c.id == params.id);
            Object.assign(chart, params);

            if (state.active.id == params.id)
                Object.assign(state.active, chart);

            const ind = state.delayedUpdates.findIndex(c => c.id == chart.id);
            if (ind != -1)
                state.delayedUpdates[ind] = params;
            else
                state.delayedUpdates.push(params);
            dispatch("updateOnServer");
        },
        updateOnServer: _.debounce(function ({state}) {
            for (let chart of state.delayedUpdates) {
                Chart.update({id: chart.id}, {chart});
            }
            state.delayedUpdates = [];
        }, 300)
    },
    getters: {
        active(state) {
            return state.active;
        },
        cursor(state) {
            return state.cursor;
        },
        allowedObjectTypes(state) {
            return state.allowedObjectTypes;
        },
        scale(state) {
            return state.scale;
        },
        translate(state) {
            return state.translate;
        },
        openedComments(state) {
            return state.openedComments;
        },
        objectMode(state) {
            if (state.active && state.active.options)
                return state.active.options.objectMode;
            return false;
        },
        mouseCursor(state) {
            if (state.active && state.active.options)
                return state.active.options.mouseCursor;
            return "auto";
        },
        specialKey(state) {
            return state.specialKey;
        }
    }
};

export default store;
