import store from '@/store';

const checkAuth = (router) => {
  router.beforeEach(async (to, from, next) => {
    if (store.state.application.loading)
      return;

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (!store.state.user.current && !store.state.user.tryLoad) {
      try {
        await store.dispatch('user/load');
      } catch (error) {
        if (!requiresAuth)
          next();
        else
          next({
            name: 'signIn',
            query: { redirect: to.fullPath }
          });
        return;
      }
    }

    if (!requiresAuth) {
      if (store.state.user.current && to.meta.isAuth)
        next({name: 'dashboard'});
      else
        next();
    } else {
      if (store.state.user.current)
        next();
      else
        next({
          name: 'signIn',
          query: { redirect: to.fullPath }
        });
    }
  })
};

export default checkAuth;
