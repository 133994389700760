<template>
  <div class="pa-4" v-if="organisation">
    <v-layout justify-center>
      <v-flex xs5>
        <v-toolbar color="#f4f3f3">
          <v-toolbar-title>Info</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-container container--fluid>
            <form @submit.prevent="update" class="mini-form">
              <v-layout>
                <v-flex style="width: 80px">
                  <Uploader
                    label="Logo"
                    icon="image"
                    v-model="logo"
                    count-base64
                    :base64.sync="logoBase64"
                  />
                </v-flex>
                <v-flex xs10>
                  <v-text-field label="Name" v-model="organisation.name" />
                </v-flex>
              </v-layout>
              <v-layout justify-end>
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="!changed && !!organisation.name"
                >Update</v-btn>
              </v-layout>
            </form>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {
    return {
      organisation: null,
      initialOrganisationDump: null,
      changed: false,
      logo: null,
      logoBase64: null
    };
  },
  created() {
    this.fetchOrganisation();
  },
  methods: {
    goAway() {
      if (
        !this.organisation ||
        !this.organisation.current_user_roles.includes("admin")
      ) {
        this.$router.push({ name: "dashboard" });
        return true;
      }
    },
    fetchOrganisation() {
      this.api.Organisation.getCurrent().then(response => {
        this.organisation = response.body;
        if (this.goAway()) return;
        this.initialOrganisationDump = JSON.stringify(this.organisation);
        const url = this.organisation.logo_url;
        this.logo = {
          body: {
            url,
            quad: {
              url
            },
            thumb: {
              url
            }
          }
        };
      });
    },
    update() {
      this.api.Organisation.update(
        { id: this.organisation.id },
        { organisation: this.organisation }
      ).then(response => {
        this.organisation = response.body;
        this.initialOrganisationDump = JSON.stringify(this.organisation);
      });
    }
  },
  watch: {
    organisation: {
      handler() {
        this.changed =
          this.initialOrganisationDump != JSON.stringify(this.organisation);
      },
      deep: true
    },
    logoBase64() {
      this.organisation = {
        ...this.organisation,
        logo_image_attributes: {
          base64: this.logoBase64
        }
      };
    }
  }
};
</script>
