<template>
  <div :class="className">
    <div class="object-out__point" :style="style"/>
  </div>
</template>

<script>
import * as d3 from "d3";
import {defaultProperties, ObjectTypesByModule} from "./Types";
import {deepClone} from "@/lib";

export default {
  name: "ObjectOut",
  props: {
    objectId: [Number, String]
  },
  mounted() {
    this.d3el = d3.select(this.$el);
    this.d3el.call(this.dragHandler());
  },
  computed: {
    object() {
      return this.$store.getters["object/findById"](this.objectId);
    },
    outConnections() {
      return this.$store.getters["connection/find"]({
        from: this.objectId
      }).filter(c => c.id != -1);
    },
    hasMaxOutConnections() {
      return (
          typeof defaultProperties[this.object.type].maxOutConnections !=
          "undefined"
      );
    },
    maxOutConnections() {
      return defaultProperties[this.object.type].maxOutConnections;
    },
    layout() {
      return this.object.layout;
    },
    className() {
      return ["object-out", this.layout];
    },
    scale() {
      return this.$store.getters["chart/scale"];
    },
    style() {
      return {
        background:
            this.object.info.settings.color ||
            defaultProperties[this.object.type].outColor ||
            "#605da5"
      };
    },
    stop() {
      return (
          this.hasMaxOutConnections &&
          this.outConnections.length >= this.maxOutConnections
      );
    }
  },
  methods: {
    dragHandler() {
      let initialEvent = {};
      const dragHandler = d3
          .drag()
          .on("start", () => {
            if (this.stop) {
              return;
            }

            initialEvent = deepClone(d3.event);
            this.$store.dispatch("connection/create", {
              id: -1,
              from: this.objectId,
              to: {
                x: d3.event.x / this.scale + this.object.position.x,
                y: d3.event.y / this.scale + this.object.position.y
              }
            });
          })
          .on("drag", () => {
            if (this.stop) {
              return;
            }

            this.$store.dispatch("connection/update", {
              id: -1,
              from: this.objectId,
              to: {
                x: d3.event.x / this.scale + this.object.position.x - 10,
                y: d3.event.y / this.scale + this.object.position.y
              }
            });

            this.targetObject = null;

            let target, targetObjectNode;
            if (d3.event.sourceEvent.type == "touchmove") {
              const location = d3.event.sourceEvent.changedTouches[0];
              targetObjectNode = document
                  .elementFromPoint(location.clientX, location.clientY)
                  .closest("[data-object-id]");
            } else {
              target = d3.event.sourceEvent.target;
              targetObjectNode =
                  target && target.closest
                      ? target.closest("[data-object-id]")
                      : null;
            }

            if (targetObjectNode) {
              const targetObjectId = targetObjectNode.dataset.objectId;
              if (targetObjectId != this.objectId) {
                const targetObject = this.$store.getters["object/findById"](
                    targetObjectId
                );
                if (targetObject) {
                  const allowedComponents =
                      defaultProperties[this.object.type].components || [];

                  const allow = !!allowedComponents.find(el => {
                    if (el instanceof RegExp) {
                      return el.test(targetObject.type);
                    } else {
                      return el == targetObject.type;
                    }
                  });

                  const hasConnection = this.$store.getters["connection/find"]({
                    from: this.object.id,
                    to: targetObject.id
                  });

                  if (allow && !hasConnection) {
                    this.targetObject = targetObject;

                    this.$store.dispatch("connection/update", {
                      id: -1,
                      from: this.objectId,
                      to: this.targetObject.id
                    });
                  }
                }
              }
            }
          })
          .on("end", () => {
            if (this.stop) {
              return;
            }

            const promise = new Promise(resolve => {
              if (
                  Math.abs(initialEvent.x - d3.event.x) < 20 &&
                  Math.abs(initialEvent.y - d3.event.y) < 20
              )
                return resolve();
              if (this.targetObject) {
                resolve();
              } else {
                this.$store
                    .dispatch("object/create", {
                      type: "Core_ObjectPicker",
                      position: {
                        x: d3.event.x / this.scale + this.object.position.x,
                        y: d3.event.y / this.scale + this.object.position.y
                      },
                      info: {
                        settings: {
                          components: ObjectTypesByModule[
                              this.object.type === "Base_SymbolObject"
                                  ? this.object.type
                                  : this.object.type.split("_")[0]
                              ].filter(el => {
                            return defaultProperties[
                                this.object.type
                                ].components.find(cel => {
                              if (cel instanceof RegExp) {
                                return cel.test(el);
                              } else {
                                return el == cel;
                              }
                            });
                          })
                        }
                      }
                    })
                    .then(res => {
                      this.targetObject = this.$store.getters["object/findById"](
                          res.id
                      );
                      resolve();
                    });
              }
            }).then(async e => {
              if (this.targetObject) {
                this.$store.dispatch("connection/create", {
                  from: this.objectId,
                  to: this.targetObject.id
                });
                this.targetObject = null;
              }

              await this.$store.dispatch("connection/delete", -1);
            });
          });

      return dragHandler;
    }
  }
};
</script>

<style scoped>
.object-out {
  padding: 10px;
  position: absolute;
  z-index: 4;
  cursor: pointer;
  margin-right: 1px;
}

.object-out.horizontal {
  top: 50%;
  transform: translate(50%, -50%);
  right: -1px;
}

.object-out.vertical {
  bottom: 1px;
  transform: translate(-50%, 50%);
  left: 50%;
}

.object-out__point {
  height: 10px;
  width: 10px;
  border-radius: 99px;
  background: #605da5;
}

.object-out__point:after {
  content: "";
  display: block;
  border-radius: 4px;
  background: white;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
