<template>
  <div class="template-preview">
    <div class="template-preview__content" :style="cssScaleVar">
      <div
          v-for="object in objects"
          class="template-preview__object"
          :class="{[`template-preview__object-` + object.type]:true,
          drawingPreview: isDrawing(object)}"
          :style="getStyles(object)"
          :key="object.id"
      >
        <component
            :is="object.type"
            :style="{transform: needsScaling(object) &&`scale(${scale})`,
                    transformOrigin: needsScaling(object) && `0 0`}"
            :settings="object.info.settings"
            :baseObject="object"
            :position.sync="object.position"
        />
      </div>
      <!--      <div`
                class="viewport-preview__connection"
                v-for="connection in connections"
                :key="connection.id"
            >
                      <svg width="100%" height="100%">
                        <line
                            :x1="connectionPoints[connection.id].x1"
                            :y1="connectionPoints[connection.id].y1"
                            :x2="connectionPoints[connection.id].x2"
                            :y2="connectionPoints[connection.id].y2"
                            :stroke="'#605da5'"
                            :stroke-width="3"
                        />
                      </svg>
            </div>-->
    </div>
  </div>
</template>

<script>
import {ObjectComponents,} from "@/components/Layout/Authorized/TheEditor/Object/Types";

export default {
  name: "TemplatePreview",
  components: {...ObjectComponents},
  props: {
    group: {
      type: Object,
      default: {}
    },
    objects: {
      type: Array,
      default: () => []
    },
    connections: {
      type: Array,
      default: () => []
    },
    settings: Object,
  },
  data() {
    const {width, height} = this.group.size

    const scaleX = 400 / width;
    const scaleY = 400 / height;
    const scale = Math.min(scaleX, scaleY);

    const groupCenter = {x: (this.group.size.width / 2), y: (this.group.size.height / 2)}
    const canvasCenter = {x: 200, y: 200}
    const centerDiff = {
      x: Math.abs(canvasCenter.x + (canvasCenter.x >= groupCenter.x ? 1 : -1) * groupCenter.x),
      y: Math.abs(canvasCenter.y + (canvasCenter.y >= groupCenter.y ? 1 : -1) * (groupCenter.y))
    }

    const rotatedObjects = this.objects
        .filter(o => o.info.settings.transformOrigin && typeof o.info.settings.transformOrigin === 'string')
    const origins = rotatedObjects.reduce((acc = {}, o) => {
      acc[o.id] = this.getTransformNumbers(o.info.settings.transformOrigin, scale)
      return acc
    }, {})


    return {
      scale,
      origins,
      centerDiff
    }
  },
  methods: {
    getTransformNumbers(transformString, scale) {
      return transformString.split(' ').filter(str => str.length).map(px =>
          parseFloat(px) * scale)
    },
    getTOrigin(id) {
      return this.origins[id] ? `${this.origins[id][0]}px ${this.origins[id][1]}px` : 'center center'
    },

    getStyles(object) {
      const {position, size, id} = object

      const left = `${position && this.getObjectCenteredPosition(position, object).x || 100}px`
      const top = `${position && this.getObjectCenteredPosition(position).y || 100}px`
      const width = `${size && this.getScaledValue(size.width) || 100}px`
      const height = `${size && this.getScaledValue(size.height) || 100}px`
      const transformOrigin = `${this.getTOrigin(id)}`
      const transform = `${this.getTransformStyles(object)}`

      return {
        left,
        top,
        width,
        height,
        transformOrigin,
        transform
      }
    },

    getTransformStyles(object) {
      return object.info && object.info.settings.angle ? `rotate(${object.info.settings.angle}deg)` : 'none'
    },

    getScaledValue(value) {
      return Math.round(this.scale * value)
    },

    getObjectCenteredPosition(position, object = {}) {
      const {x, y} = position
      const dx = (400 - this.group.size.width * this.scale) / 2;
      const dy = (400 - this.group.size.height * this.scale) / 2;


      return ({
        x: x * this.scale + dx,
        y: y * this.scale + dy
      })
    },
    needsScaling(object) {
      const matches = [/Chatbot/, /Presentation/, /Analysis/];
      return Boolean(matches.map(m => m.test(object.type)).filter(res => res).length);
    },
    isDrawing(object) {
      const drawingPattern = /DrawingObject/
      return drawingPattern.test(object.type)
    }
  },
  computed: {
    cssScaleVar() {
      return {
        '--template-scale': this.scale
      }
    }
  }
}
</script>

<style scoped>


.rect-object::before {
  content: none;
}

.template-preview__content {
  position: relative;
  height: 400px;
  width: 400px;
  pointer-events: none;
  margin: auto;
}

.template-preview__object {
  position: absolute;
}

::v-deep .polygon-note, ::v-deep .rect-note {
  display: none;
}

::v-deep .polygon-object svg > polygon, ::v-deep .polygon-object svg > ellipse, ::v-deep .rect-object svg > rect {
  /*transform: scale(0.12);*/
  transform: scale(var(--template-scale));
  transform-origin: 0 0;
}

::v-deep .rect-object .text-object__content, ::v-deep .text-object .text-object__content {
  word-break: keep-all;
  white-space: nowrap;
  transform: scale(var(--template-scale));
  transform-origin: center;
}

::v-deep .drawingPreview svg, ::v-deep .drawingPreview svg {
  transform: scale(var(--template-scale));
  transform-origin: 0 0;
}

</style>


