<template>
  <div class="uploader" :style="{width, height}">
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <label class="uploader-section" @click="openModal" v-on="on">
          <div
            class="uploader-background"
            v-if="value && value.body"
            :style="{backgroundImage: value.body ? `url(${value.body.thumb.url})` : ''}"
          />
          <div class="uploader-placeholder" v-else>
            <v-icon>{{icon}}</v-icon>
          </div>
        </label>
      </template>
      <span>{{label}}</span>
    </v-tooltip>
    <UploaderModal
      v-if="opened"
      :value="value"
      :url="url"
      @input="$emit('input', $event)"
      @close="close"
      :icon="icon"
      :countBase64="countBase64"
      :base64.sync="localBase64"
    />
  </div>
</template>

<script>
import { deepClone } from "@/lib";
import UploaderModal from "./UploaderModal";

export default {
  name: "Uploader",
  components: {
    UploaderModal
  },
  props: {
    label: String,
    disabled: Boolean,
    base64: String,
    countBase64: Boolean,
    url: {
      type: String,
      default: "vulcan/v1/uploads/image"
    },
    width: {
      type: String,
      default: "60px"
    },
    height: {
      type: String,
      default: "60px"
    },
    value: {
      type: Object,
      default: function() {
        return {};
      }
    },
    icon: {
      type: String,
      default: "add_photo_alternate"
    }
  },
  data() {
    return {
      selectedFile: null,
      opened: false,
      localBase64: ""
    };
  },
  methods: {
    close() {
      this.opened = false;
    },
    openModal() {
      if (!this.disabled) {
        this.opened = !this.opened;
      }
    }
  },
  watch: {
    localBase64() {
      this.$emit("update:base64", this.localBase64);
    }
  }
};
</script>

<style>
.uploader {
  display: block;
  position: relative;
}

.uploader-section {
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.uploader-background {
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.uploader-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
}

.uploader-delete {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: #e04e9d;
  color: white;
  cursor: pointer;
  display: flex;
}

.uploader-delete i {
  color: white !important;
}
</style>
