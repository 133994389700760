import * as _ from "underscore";
import { Category, Component } from "@/resources";
import {guid} from "@/lib";

const store = {
  namespaced: true,
  state: {
    components: [],
    componentsCache: [],
    categories: [],
    delayedUpdates: []
  },
  mutations: {
    updateComponents(state, components) {
      state.components = components;
    },
    addComponentToCache(state, component) {
      state.componentsCache.push(component);
    },

    createComponent(state, component) {
      state.components.unshift(component);
    },
    updateComponent(state, params) {
      state.components = state.components.map(c =>
        c.id == params.id ? { ...c, ...params } : c
      );
    },
    deleteComponent(state, id) {
      state.components = state.components.filter(c => c.id != id);
    },

    updateCategories(state, categories) {
      state.categories = categories;
    },
    createCategory(state, category) {
      state.categories.push(category);
    }
  },
  actions: {
    async load({commit}) {
      await Component.get().then(response => {
        if (response.body.length > 0)
          commit('updateComponents', response.body);
        else
          commit('updateComponents', []);
      });
      await Category.get().then(response => {
        if (response.body.length > 0)
          commit('updateCategories', response.body.map(c => c.name));
        else
          commit('updateCategories', []);
      });
    },
    async loadComponentToCache({getters, commit}, id) {
      if (getters["findById"](id))
        return;

      try {
        const response = await Component.get({id});
        if (response.body && !getters["findById"](id))
          commit('addComponentToCache', response.body);
      } catch (e) {}
    },
    async createCategory({state, commit}, category) {
      if (!category)
        return;

      const baseCategories = (['Base', 'Analysis', 'Chatbot', 'Presentation', 'Tech']);
      if (baseCategories.indexOf(category) != -1 || state.categories.indexOf(category) != -1)
        return;

      commit('createCategory', category);
      await Category.save({category: {name: category}});
    },
    async createComponent({ commit }, params) {
      const component = { ...params, id: guid() };
      const response = await Component.save({ component });
      commit("createComponent", response.body);
    },
    updateComponent({ state, getters, commit, dispatch }, params) {
      commit("updateComponent", params);

      const component = getters["findById"](params.id);
      const ind = state.delayedUpdates.findIndex(c => c.id == params.id);
      if (ind != -1)
        state.delayedUpdates[ind] = component;
      else
        state.delayedUpdates.push(component);
      dispatch("updateComponentOnServer");
    },
    updateComponentOnServer: _.debounce(function({ state }) {
      for (let component of state.delayedUpdates) {
        Component.update({ id: component.id }, { component });
      }
      state.delayedUpdates = [];
    }, 300),
    deleteComponent({ commit }, id) {
      commit("deleteComponent", id);
      Component.delete({ id });
    },
    async forkComponent({commit}, id) {
      const response = await Component.fork({id}, {});
      commit("createComponent", response.body);
    },
    publishComponent({}, id) {
      Component.submit_general({id}, {});
    }
  },
  getters: {
    findById: state => id => {
      let component = state.components.find(c => c.id == id);
      if (!component)
        component = state.componentsCache.find(c => c.id == id);
      return component;
    },
    findByShortcut: state => shortcut => {
      return state.components.find(c => c.shortcut == shortcut);
    },
    componentsObjects(state) {
      return state.components.filter(c => c.is_object);
    },
    activeCategories(state) {
      return _.uniq(state.components
        .filter(c => c.is_object)
        .map(c => c.object_settings.category));
    }
  }
};

export default store;
