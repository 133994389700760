<template>
  <div>
    <v-icon size="40" color="#888">sync</v-icon>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_SymbolObject",
  extends: ExtendsComponent
};
</script>

<style>
.Base_SymbolObject .base-object__content,
.Base_SymbolObject .base-object__content > div {
  height: 100%;
}

.Base_SymbolObject .base-object__content > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>