<template>
  <div class="page">
    <app-header
      title="Home"
      :tabs="tabs"
      :activeTabInd="activeTab"
      @activeTabChanged="setActiveTab"
    />

    <v-main>
      <div>
        <div class="pa-4 text-left">
          <v-tabs-items v-model="activeTab">
            <v-tab-item class="dashboard__recent-projects">
              <div>
                <OpenProject wrapper="div" />
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="dashboard__new-project">
                <div class="dashboard__new-project__title">
                  <v-icon class="title__icon">create_new_folder</v-icon>
                  <h2>New Project</h2>
                </div>
                <NewProject wrapper="div" showActions />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
      <v-btn v-if="chatbotClosed && currentUser.id == 1" @click="openChatbot()" color="default" icon plain class="chatbot-btn" >
        <lottie-player src="https://assets1.lottiefiles.com/datafiles/p9xT9XBgoDvCqCV/data.json"  background="transparent"  speed="1"  style="width: 110px; height: 110px;"  loop hover></lottie-player>
      </v-btn>
    </v-main>
  </div>
</template>

<script>
import AppHeader from "./AppHeader";
import NewProject from "./Project/New";
import OpenProject from "./Project/Open";

export default {
  name: "TheDashboard",
  components: {
    AppHeader,
    NewProject,
    OpenProject
  },
  data() {
    return {
      activeTab: 0,
      chatbotClosed: true,
      projects: []
    };
  },
  created() {
    this.api.Project.query().then(result => {
      this.projects = result.body;
    });
  },
  computed: {
    tabs() {
      const tabs = [{ name: "Recent Projects" }, { name: "New Project" }];
      this.activeTab = (this.projects.length) ? 0 : 1;
      return tabs;
    }
  },
  methods: {
    setActiveTab(value) {
      this.activeTab = value;
    },
    openChatbot() {
      const chatbot = new Chatbot({
        id: "8402cfa6-0a0f-d52c-ccfc-5ecc582cb67d",
        signal: {},
        Model: {
          access_token: this.$store.getters["user/token"],
          current_user: this.currentUser,
          username: this.currentUser.username
        },
        endpointServer: "https://developers.beach.io",
        styles: {}
      });
      chatbot.run();
    }
  },
  mounted() {
    window.analytics.page('Dashboard');
  }
};
</script>

<style scoped>
.dashboard__new-project {
  width: 50%;
  max-width: 800px;
  margin: auto;
}

.dashboard__new-project__title {
  display: flex;
  margin-bottom: 20px;
}

.title__icon {
  margin-right: 12px;
}

.chatbot-btn {
  position: fixed;
  z-index: 99;
  right: 140px;
  bottom: 30px;
  /*border-radius: 6px 20px 20px 20px;*/
}

@media screen and (max-width: 680px) {
  .dashboard__new-project {
    width: 100%;
  }
}
</style>
