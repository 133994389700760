export default function(addon) {
  if (addon.type == "variable" || !addon.type) {
    if (isYoutube(addon.value)) return "YoutubePlayer";
    if (isTwitter(addon.value)) return "TwitterFeed";
    if (isRss(addon.value)) return "RssFeed";
    if (addon.key === "tags") return "Tags";
  } else if (addon.type == "component") {
    return "CustomComponent";
  }
}

function isYoutube(value) {
  var match = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  return match.test(value);
}

function isTwitter(value) {
  var match = /twitter/;
  return match.test(value);
}

function isRss(value) {
  var match = /\.rss/;
  return match.test(value);
}
