<template>
  <div class="base-group" :style="groupStyles">
    <div class="create-group-btn mt-3" v-if="!baseObject.chart_id">
      <v-btn color="primary" @click.stop="createGroup">Create Group</v-btn>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import {defaultProperties} from "../../index";
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_GroupObject",
  extends: ExtendsComponent,
  mounted() {
    this.d3BaseGroup = d3.select(this.$el);

    if (!this.readonly)
      this.d3BaseGroup.call(this.dragHandler());
  },
  data() {
    return {
      padding: 4
    };
  },
  watch: {
    readonly: {
      handler(val) {
        if (val)
          this.d3BaseGroup.on(".drag", null);
        else
          this.d3BaseGroup.call(this.dragHandler());
      }
    },
  },
  methods: {
    createGroup() {
      this.$store.dispatch("object/createGroup", this.localSettings);
    },
    dragHandler() {
      let initialPositions, initialGroupPosition;
      const dragHandler = d3
          .drag()
          .on("start", () => {
            initialGroupPosition = {
              x: d3.event.x / this.scale + this.baseObject.position.x,
              y: d3.event.y / this.scale + this.baseObject.position.y
            };
            initialPositions = {};
            this.objects.forEach(object => {
              initialPositions[object.id] = {
                x: object.position.x,
                y: object.position.y
              };
              const comments = this.$store.getters["object/comments"](object);
              comments.forEach(c => {
                initialPositions[c.id] = {
                  x: c.position.x,
                  y: c.position.y
                };
              });
            });
          })
          .on("drag", () => {
            Object.keys(initialPositions).forEach(objectId => {
              const targetPosition = {
                x:
                    initialPositions[objectId].x +
                    (d3.event.x / this.scale +
                        this.baseObject.position.x -
                        initialGroupPosition.x),
                y:
                    initialPositions[objectId].y +
                    (d3.event.y / this.scale +
                        this.baseObject.position.y -
                        initialGroupPosition.y)
              };
              this.$store.dispatch("object/update", {
                id: objectId,
                position: targetPosition
              });
            });
          });
      return dragHandler;
    },
    getObjectPosition(object) {
      if (object.type != "Base_ArrowObject")
        return object.position;

      let startX = object.info.settings.startX ? object.info.settings.startX : 0;
      let endX = object.info.settings.endX ? object.info.settings.endX : 100;

      startX += object.position.x;
      endX += object.position.x;
      const x = Math.min(startX, endX);

      let startY = object.info.settings.startY ? object.info.settings.startY : 0;
      let endY = object.info.settings.endY ? object.info.settings.endY : 0;

      startY += object.position.y;
      endY += object.position.y;
      const y = Math.min(startY, endY);

      return {x, y};
    },
    getObjectSize(object) {
      if (object.type != "Base_ArrowObject") {
        return {
          width: object.size && object.size.width ? object.size.width : defaultProperties[object.type].size.width,
          height: object.size && object.size.height ? object.size.height : defaultProperties[object.type].size.height,
        };
      }

      const startX = object.info.settings.startX ? object.info.settings.startX : 0;
      const endX = object.info.settings.endX ? object.info.settings.endX : 100;
      const width = Math.abs(startX - endX);

      const startY = object.info.settings.startY ? object.info.settings.startY : 0;
      const endY = object.info.settings.endY ? object.info.settings.endY : 0;
      const height = Math.abs(startY - endY);

      return {width, height};
    },
    getParams() {
      let minX = Number.MAX_VALUE,
          minY = Number.MAX_VALUE,
          maxX = -Number.MAX_VALUE,
          maxY = -Number.MAX_VALUE;

      for (let object of this.objects) {
        const position = this.getObjectPosition(object);
        const size = this.getObjectSize(object);

        if (position.x < minX)
          minX = position.x;
        if (position.y < minY)
          minY = position.y;
        if (position.x + size.width > maxX)
          maxX = position.x + size.width;
        if (position.y + size.height > maxY)
          maxY = position.y + size.height;
      }

      const result = {
        position: {
          x: minX - this.padding,
          y: minY - this.padding
        },
        size: {
          width: maxX - minX + 2 * this.padding,
          height: maxY - minY + 2 * this.padding,
        }
      };

      return result;
    }
  },
  computed: {
    scale() {
      return this.$store.getters["chart/scale"];
    },
    objects() {
      const objects = this.$store.getters["object/list"].filter(object =>
          this.settings.objectIds.includes(object.id)
      );
      return objects;
    },
    groupStyles() {
      if (!this.objects.length) return null;

      const result = this.getParams();

      if (
          !this.baseObject.size ||
          this.baseObject.size.width != result.size.width ||
          this.baseObject.size.height != result.size.height ||
          this.baseObject.position.x != result.position.x ||
          this.baseObject.position.y != result.position.y
      ) {
        this.$store.dispatch("object/update", {
          id: this.baseObject.id,
          size: result.size,
          position: result.position
        });
      }

      return {
        background: this.localSettings.background
      };
    }
  }
};
</script>

<style scoped>
.base-group {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 118, 209, 0);
  z-index: 1;
}

.create-group-btn {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}
</style>
