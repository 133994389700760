import {vulcanToScreen} from "./common";
import store from "../store";

export const getWidgetScreenCenter = (widget) => {
    const {position, size} = widget
    const scale = store.getters['chart/scale']
    const {x: screenX, y: screenY} = vulcanToScreen(position.x, position.y)

    return {
        x: Math.round(screenX + ((size.width / 2) * scale)),
        y: Math.round(screenY + ((size.height / 2) * scale))
    }
}
