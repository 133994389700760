<template>
  <div>
    <slot :openSettings="openSettings" />
    <div />
  </div>
</template>

<script>
  import ControlComponent from '../_extends/ControlComponent'

  export default {
    name: 'ControlElement_VideoControl',
    extends: ControlComponent
  }
</script>