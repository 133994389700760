<template>
  <div>
    <component
      @update="update"
      :is="componentName || 'div'"
      v-bind.sync="objectProps"
      :baseObject="baseObject"
      :ENV="ENV"
      :OAUTH="OAUTH"
    />
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import {compileTemplate, getENV, getOAUTH} from "@/libData";

export default {
  name: "NotionTypeCustomComponent",
  props: {
    line: Object,
    baseObject: Object
  },
  data() {
    return {
      componentName: null,
      error: null,
      env: [],
      props: this.line.props,
      objectProps: {},
      settings: this.$store.getters["propertyEditor/settings"]
    };
  },
  mounted() {
    this.$store.watch(
      state => state.propertyEditor.settings,
      () => {
        this.updateObjectProps();
      },
      { deep: true }
    );
    compileTemplate({
      shortcut: this.line.shortcut
    }).then(({ component, env }) => {
      try {
        this.env = env;
        const componentName = component.name + this.guid();
        Vue.component(componentName, component);
        Vue.config.errorHandler = (err, vm, info) => {
          this.error = err.toString();
        };
        Vue.config.warningHandler = (err, vm, info) => {
          this.error = err.toString();
        };
        this.$nextTick(function() {
          this.componentName = componentName;
        });
        this.updateObjectProps();
      } catch (err) {
        this.error = err.toString();
      }
    });
  },
  computed: {
    ENV() {
      return getENV(this.env);
    },
    OAUTH() {
      return getOAUTH();
    }
  },
  methods: {
    updateObjectProps() {
      const objectProps = { ...this.objectProps };
      Object.keys(this.props).forEach(key => {
        if (key[0] == ":") {
          objectProps[key.slice(1, key.length)] =
            this.getValue(this.props[key]);
        } else {
          objectProps[key] = this.props[key];
        }
      });
      this.objectProps = objectProps;
    },
    getValue(value) {
      if (value.indexOf("this") == 0) {
        this.settings = this.$store.getters["propertyEditor/settings"];
        const newValue = value.replace("this", "this.settings");
        const res = eval(newValue);
        return res;
      } else {
        return eval(value);
      }
    },
    update(object) {
      let settings = { ...this.settings };
      Object.keys(object).forEach(key => {
        settings = { ...settings, [key]: object[key] };
      });
      this.$store.dispatch("propertyEditor/setSettings", settings);
    }
  }
};
</script>
