<template>
  <div class="px-3">
    <v-text-field
      v-model="settings.title"
      hide-details
      label="Title"
      :readonly="readonly"
    />
    <v-autocomplete
      item-value="id"
      item-text="name"
      v-model="settings.componentId"
      :items="components"
      label="Component"
      :readonly="readonly"
      hide-details
      class="mt-3"
    />
    <v-text-field
      v-model="settings.model"
      label="Model (for use in expressions)"
      :readonly="readonly"
      hide-details
      class="mt-3"
    />
    <div class="mt-4 mb-1">Props function:</div>
    <editor
      v-model="settings.props"
      @init="editorInit"
      lang="javascript"
      theme="chrome"
      height="90"
    />
  </div>
</template>

<script>
import { compileTemplate } from "@/libData";

export default {
  name: "RenderTemplate",
  components: {
    editor: require("vue2-ace-editor")
  },
  props: {
    settings: Object,
    readonly: Boolean
  },
  created() {
    if (this.currentUser) {
      this.api.Component.get().then(
        response => (this.components = response.body)
      );

      if (!this.settings.props)
        this.$set(this.settings, 'props', `function props () {
  return {/*props*/}
}`);
    }
  },
  data() {
    return {
      components: [],
      availableProps: []
    };
  },
  methods: {
    editorInit(editor) {
      require("brace/ext/language_tools");
      require("brace/mode/html");
      require("brace/mode/javascript");
      require("brace/theme/chrome");
      require("brace/snippets/javascript");
      editor.setReadOnly(this.readonly);
      editor.setOptions({
        selectionStyle: "text",
        enableBasicAutocompletion: true,
        highlightSelectedWord: true,
        highlightActiveLine: false,
        tabSize: 2
      });
    }
  },
  watch: {
    "settings.componentId": function() {
      const component = this.components.find(
        e => e.id == this.settings.componentId
      );
      compileTemplate({ template: component.template }).then(compiled => {
        const { props } = compiled.component;
        if (typeof props == "object")
          this.availableProps = Object.keys(props);
        else
          this.availableProps = props;
      });
    },
    availableProps: {
      handler() {
        const template = this.availableProps
          .map(e => `${e}: null`)
          .join(",\n    ");
        this.settings.props = `function props () {
  return {
    ${template}
  }
}`;
      },
      deep: true
    }
  }
};
</script>