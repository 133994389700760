var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawing-toolbar"},[_c('v-toolbar',{staticClass:"rounded",attrs:{"dense":"","no-padding":""}},[_vm._l((_vm.brushes),function(brush,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0 px-0 brush-button",class:_vm.activeBrushTypeClass(brush),attrs:{"icon":""},on:{"click":function($event){return _vm.setBrushType(brush)}}},on),[_c('div',{class:brush.class})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(brush.value))])])}),(_vm.brushType != 'Eraser')?_c('ColorPickerDialog',{attrs:{"title":"Select Colour","presetColors":_vm.projectColors,"tooltip":"Line color"},model:{value:(_vm.localOptions.lineColor),callback:function ($$v) {_vm.$set(_vm.localOptions, "lineColor", $$v)},expression:"localOptions.lineColor"}}):_vm._e(),_c('v-divider',{staticClass:"mr-2",attrs:{"vertical":""}}),_c('v-select',{staticClass:"pa-0",attrs:{"hide-details":"","items":_vm.strokeWidths,"menu-props":{ maxHeight: '400' }},model:{value:(_vm.localOptions.strokeWidth),callback:function ($$v) {_vm.$set(_vm.localOptions, "strokeWidth", $$v)},expression:"localOptions.strokeWidth"}}),(_vm.brushType != 'Eraser')?_c('v-divider',{staticClass:"mr-2",attrs:{"vertical":""}}):_vm._e(),(_vm.brushType != 'Eraser')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-select',_vm._g({staticClass:"pa-0",attrs:{"hide-details":"","items":_vm.linestyles,"item-value":"value","item-label":"icon","single-line":"","menu-props":{ maxHeight: '400' }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._g({staticClass:"mx-0 px-0",attrs:{"icon":""}},on),[_c('div',{staticClass:"line-style-icon",class:item.class})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0 px-0",attrs:{"icon":""}},on),[_c('div',{staticClass:"line-style-icon",class:item.class})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.value))])])]}}],null,true),model:{value:(_vm.localOptions.lineStyle),callback:function ($$v) {_vm.$set(_vm.localOptions, "lineStyle", $$v)},expression:"localOptions.lineStyle"}},on))]}}],null,false,2514752349)},[_c('span',[_vm._v("Line style")])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }