<template>
  <div>
    <v-toolbar dense no-padding>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-text-field
            class="input-border-radius pa-0 ml-2"
            :style="'width: 70px'"
            hide-details
            type="number"
            :readonly="readonly"
            v-model="localSettings.borderRadius"
            v-on="on"
          >
            <template v-slot:append>
              <span class="input-subtext py-2">px</span>
            </template>
          </v-text-field>
        </template>
        <span>Border radius</span>
      </v-tooltip>

      <v-divider class="ml-3" vertical />

      <ColorPickerDialog
        v-model="localSettings.backgroundColor"
        :secondColor="localSettings.textColor"
        title="Select Background Colour"
        :presetColors="projectColors"
        :disabled="readonly"
        tooltip="Background color"
      />
      <ColorPickerDialog
        v-model="localSettings.borderColor"
        title="Select Border Colour"
        :presetColors="projectColors"
        :disabled="readonly"
        tooltip="Border color"
      />

      <v-divider class="mr-2" vertical />

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-select
            class="pa-0"
            :style="'width: 120px'"
            hide-details
            :items="borderstyles"
            :readonly="readonly"
            v-model="localSettings.borderStyle"
            :menu-props="{ maxHeight: '400' }"
            v-on="on"
          />
        </template>
        <span>Border style</span>
      </v-tooltip>

      <v-divider ml-1 vertical />

      <v-btn-toggle group borderless v-model="openedData" v-if="localSettings.notionEnabled">
        <v-btn text style="opacity: 1" class="ma-0">Notes</v-btn>

        <v-divider vertical />
      </v-btn-toggle>

      <slot name="menumore" />
    </v-toolbar>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";

export default {
  name: "Base_RectObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  data() {
    return {
      openedData: undefined,
      borderstyles: ["None", "Solid", "Dotted", "Dashed"]
    };
  },
  computed: mapState({textEditable: function (state) {
    // Ёбанный костыль
    this.$nextTick(function() {
      this.$emit("updateSize");
    });
    return state.object.contentEditable;
  }}),
  methods: {
    onOpenNotion() {
      this.localSettings = { notesLines: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    }
  },
  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenNotion();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      if (this.localSettings.notionEnabled)
        this.openedData = 0;
    }
  },
  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    if (this.localSettings.notionEnabled)
      this.openedData = 0;
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>

<style scoped>
.input-subtext {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
</style>
