<template>
  <div class="addons-list">
    <draggable @input="input" :value="value" handle=".addons-list__actions" :disabled="readonly">
      <div class="form-row addons-list__item" v-for="addon in localValue" :key="addon.id">
        <div class="addons-list__actions">
          <div v-if="!readonly">
            <v-icon class="drag">drag_handle</v-icon>
          </div>
          <div v-if="!readonly">
            <v-icon class="delete" @click="deleteAddon(addon.id)">delete</v-icon>
          </div>
        </div>

        <v-layout>
          <v-flex v-if="false">
            <v-select
              item-value="id"
              item-text="name"
              :items="types"
              v-model="addon.type"
              label="Type"
              :readonly="readonly"
            />
          </v-flex>
          <v-flex v-if="addon.type == 'component'">
            <v-select
              item-value="id"
              item-text="name"
              :items="components"
              v-model="addon.component"
              label="Component"
              :readonly="readonly"
            />
          </v-flex>
        </v-layout>

        <v-layout v-if="addon.type == 'variable'">
          <v-flex xs6>
            <v-combobox
              hide-selected
              v-model="addon.name"
              label="Name"
              :items="addOnNames"
              :readonly="readonly"
            />
          </v-flex>
          <v-flex xs1 />
          <v-flex xs6>
            <v-combobox
              v-model="addon.value"
              hide-selected
              label="Search and create tags"
              multiple
              persistent-hint
              small-chips
              v-if="addon.name && addon.name.toLowerCase() == 'tags'"
              :readonly="readonly"
            />
            <v-chip 
              v-else-if="addon.isLink" 
              :link="true" 
              :href="toLink(addon.value)" 
              target="_blank" 
              @click:close="removeLink(addon)"
              :close="true"
              class="my-3"
              >{{addon.value}}</v-chip>
            <v-text-field v-else v-model="addon.value" label="Value" :readonly="readonly" @blur="checkInputIsLink(addon)" />
          </v-flex>
        </v-layout>

        <v-layout v-if="addon.type == 'component' && selectedComponent(addon)">
          <v-flex xs6>
            <v-text-field v-model="addon.name" label="Name" :readonly="readonly" />
          </v-flex>
          <v-flex xs1 />
          <v-flex xs6>
            <v-btn block @click="openPropsFunction(addon)" :disabled="readonly">Props Function</v-btn>
          </v-flex>
        </v-layout>
      </div>
    </draggable>
    <div class="add" @click="addAddon" v-if="!readonly">Add Addon</div>
  </div>
</template>

<script>
import PropsFunctionModal from "./PropsFunctionModal";

import { compileTemplate } from "@/libData";

import * as linkify from "linkifyjs";

export default {
  name: "AddonsList",
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    readonly: Boolean
  },
  created() {
    if (!this.currentUser) return;
    this.api.Component.get().then(response => {
      this.components = response.body;
    });
  },
  data() {
    return {
      components: [],
      types: [
        { id: "variable", name: "Variable" },
        { id: "component", name: "Component" }
      ],
      addOnNames: ["Twitter", "News", "Tags", "Website", "Link", "RSS Feed"]
    };
  },
  computed: {
    localValue() {
      const localValue = this.value.map(item => ({...item, isLink: this.isLinkString(item.value)}));
      return localValue;
    }
  },
  methods: {
    deleteAddon(id) {
      const value = this.value.filter(item => item.id != id);
      this.$emit("input", value);
    },
    selectedComponent(addon) {
      return this.components.find(c => c.id == addon.component);
    },
    addAddon() {
      this.$emit("input", [
        ...this.localValue,
        { id: this.guid(), type: "variable", value: "", props: "" }
      ]);
    },
    input(value) {
      this.$emit("input", value);
    },
    openPropsFunction(addon) {
      const template = this.selectedComponent(addon).template;

      compileTemplate({
        template
      }).then(compiled => {
        let availableProps = [];

        if (typeof compiled.props == "object") {
          availableProps = Object.keys(compiled.props).filter(ap => {
            return compiled.props[ap].hidden !== true;
          });
        } else {
          availableProps = compiled.props;
        }

        this.$modal.show(
          PropsFunctionModal,
          {
            value: addon.props,
            availableProps,
            onSubmit(value) {
              addon.props = value;
            }
          },
          {
            scrollable: true,
            height: "auto"
          }
        );
      });
    },
    isLinkString(str) {
      return typeof str === "string" && linkify.test(str);
    },
    toLink(str) {
      const links = linkify.find(str);
      return (links && links.length > 0) ? links[0].href : null;
    },
    removeLink(addon) {
      const value = this.value.map(item => item.id != addon.id ? item : {...addon, value: '', isLink: false});
      this.$emit("input", value);
    },
    // on blur of input, check the input is link and if so, update the item to appear as link chip
    checkInputIsLink(addon) {
      if (this.isLinkString(addon.value)) {
        const value = this.value.map(item => item.id != addon.id ? item : {...addon, isLink: true});
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped>
.addons-list {
}

.option-icon >>> .v-icon {
  position: relative;
  top: 19px;
  padding-left: 5px;
}

.addons-list__item {
  border: 1px solid #f7f7f7;
  padding: 10px;
  margin: 10px 0;
}

.addons-list__actions {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  margin: -10px;
  margin-bottom: 20px;
  padding: 5px;
  cursor: move;
}
</style>
