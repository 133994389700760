import Core from "./Core";
import Base from "./Base";
import AnalysisTools from "./AnalysisTools";
import Chatbot from "./Chatbot";
import Presentation from "./Presentation";
import Tech from "./Tech";
import SpatialAudio from "./SpatialAudio";

let ObjectTypes = {
    Core,
    Base,
    AnalysisTools,
    Chatbot,
    Presentation,
    Tech,
    SpatialAudio
};

const ObjectComponents = {},
    ObjectPropertyEditors = {},
    defaultProperties = {},
    ObjectTypesByModule = {};

Object.keys(ObjectTypes).forEach(module => {
    const {objects, tab} = ObjectTypes[module];
    ObjectTypesByModule[module] = [];

    Object.keys(objects).forEach(name => {
        ObjectComponents[`${module}_${name}`] = {
            tab,
            ...objects[name].Component
        };
        ObjectPropertyEditors[`${module}_${name}`] = {
            tab,
            ...objects[name].PropertyEditor
        };
        defaultProperties[`${module}_${name}`] = {
            tab,
            ...objects[name].defaultProperties
        };
        ObjectTypesByModule[module].push(`${module}_${name}`);
    });
});

const availableBaseObjects = ObjectTypesByModule["Base"].filter(
    type => type != "Base_GroupObject"
);

/*
ObjectTypesByModule["Chatbot"] = [
  ...availableBaseObjects,
  ...ObjectTypesByModule["Chatbot"],
  ...ObjectTypesByModule["AnalysisTools"],
  ...ObjectTypesByModule["Presentation"]
];
*/

/*ObjectTypesByModule["AnalysisTools"] = [
  ...availableBaseObjects,
  ...ObjectTypesByModule["AnalysisTools"],
  ...ObjectTypesByModule["Presentation"],
  ...ObjectTypesByModule["Tech"],
  ...ObjectTypesByModule["SpatialAudio"]
];*/

ObjectTypesByModule["Base_SymbolObject"] = [
    ...ObjectTypesByModule["AnalysisTools"],
    ...ObjectTypesByModule["Presentation"],
    ...ObjectTypesByModule["Tech"]
];

ObjectTypesByModule["CustomTools"] = []

export {
    ObjectComponents,
    ObjectPropertyEditors,
    defaultProperties,
    ObjectTypesByModule
};

