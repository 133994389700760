<template>
  <div>
    <slot />
    <div
      @click.stop="openSettings"
      class="control-element__button"
      v-html="localSettings.label || 'Button'"
    />
  </div>
</template>

<script>
  import ControlComponent from '../_extends/ControlComponent'

  export default {
    name: 'ControlElement_ButtonControl',
    extends: ControlComponent
  }
</script>

<style scoped>
  .control-element__button {
    cursor: pointer;
    background-color: #df4e9e;
    height: 36px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 3px;
    color: white;
    transition: .3s;
  }
  .control-element__button:hover {
    filter: brightness(110%);
  }
</style>
