<template>
  <Modal
    title="Card"
    :showSubmit="(!!card_params.number || !!card_params.last4) && !!card_params.exp_date && (!!card_params.cvc || card_params.cvc_check === 'pass')"
    @submit="submit"
    submitText="Save Card"
  >
    <v-layout class="text-xs-left outlined px-2 py-3 mb-4 align-center" v-if="card_params && card_params.id">
      <v-flex grow="true">
        <v-layout>
          <v-flex>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="card_params.exp_date"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="card_params.exp_date"
                  label="MM/YYYY"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="card_params.exp_date"
                type="month"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(card_params.exp_date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout class="text-xs-left outlined px-2 py-3 mb-4 align-center" v-if="card_params && !card_params.id">
      <v-flex grow="true">
        <v-layout>
          <v-text-field prepend-icon="account_balance_wallet" label="Payment Information" placeholder="Card number" 
            hint="Please enter card details" v-model="card_params.number" />
          <v-flex>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="card_params.exp_date"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="card_params.exp_date"
                  label="MM/YYYY"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="card_params.exp_date"
                type="month"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn flat color="primary" @click="$refs.menu.save(card_params.exp_date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-text-field placeholder="CVC" v-model="card_params.cvc" shrink class="cvc" />
        </v-layout>
      </v-flex>
    </v-layout>
  </Modal>
</template>

<script>
export default {
  name: "CardModal",
  props: {
    organisation: Object,
    card: Object,
    customer: Object,
    callback: Function
  },
  data() {
    const exp_date = (this.card.exp_year && this.card.exp_month) ? new Date(this.card.exp_year, this.card.exp_month, 1) : new Date();
    return {
      card_params: {
        id: null,
        last4: null,
        exp_date: exp_date.toISOString().substr(0, 7),
        exp_month: null,
        exp_year: null,
        cvc: null,
        cvc_check: '',
        ...this.card
      },
      menu: false
    }
  },
  methods: {
    submit() {
      if (this.card_params.id === null)
        this.createCard();
      else 
        this.updateCard();
    },
    createCard() {
      // extract expired year and month
      let exp_arr = this.card_params.exp_date.split("-");
      this.card_params.exp_year = exp_arr[0];
      this.card_params.exp_month = exp_arr[1];
      if (!this.customer) {
        this.api.Organisation.createCustomer({organisation_id: this.organisation.id}, {card_params: this.card_params}).then(() => {
          this.callback();
        }) 
      }
      else 
        this.api.Organisation.createCard({organisation_id: this.organisation.id}, {card_params: this.card_params}).then(() => {
          this.callback();
        });
    },
    updateCard() {
      // extract expired year and month
      let exp_arr = this.card_params.exp_date.split("-");
      this.api.Organisation.updateCard({organisation_id: this.organisation.id, id: this.card.id}, {card_params: {id: this.card_params.id, exp_year: Number(exp_arr[0]), exp_month: Number(exp_arr[1])}}).then(() => {
        this.$modal.hide();
        this.callback();
      });
    }
  }
}
</script>