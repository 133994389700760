var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"flip"}},[(_vm.opened)?_c('div',{staticClass:"objects-tree-view"},[_c('v-card',{staticClass:"border px-4 d-flex flex-column",staticStyle:{"height":"100%"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Layer")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.toggleOpenAll($event)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.openAll ? 'folder_open' : 'folder'))])],1),_c('v-btn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.$emit('update:opened', false)}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('div',[_c('v-text-field',{attrs:{"label":"Quick Search Chart Objects","grey":"","filled":"","dense":"","rounded":"","flat":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"list"},[_c('v-treeview',{attrs:{"open":_vm.openedNodes,"items":_vm.items,"activatable":"","item-key":"id","color":"pink","hoverable":"","rounded":"","open-on-click":"","active":_vm.active,"search":_vm.search},on:{"update:open":function($event){_vm.openedNodes=$event},"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('draggable',{attrs:{"list":_vm.items,"group":"node","id":item.id,"data-parent":item.parentId,"fixed":item.locked,"sort":true},on:{"move":_vm.move,"start":_vm.checkStart,"end":_vm.checkEnd}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('span',{attrs:{"id":"item.id"}},[_vm._v(_vm._s(item.title ? item.title : item.name))]),(hover)?_c('v-icon',{staticClass:"append-right",on:{"click":function($event){$event.stopPropagation();return _vm.toggleLock(item)}}},[_vm._v(_vm._s(item.locked ? 'lock' : 'lock_open'))]):_vm._e(),(hover)?_c('v-icon',{staticClass:"append-right",on:{"click":function($event){$event.stopPropagation();return _vm.toggleVisible(item)}}},[_vm._v(_vm._s(item.visible ? 'visibility' : 'visibility_off'))]):_vm._e(),(hover)?_c('v-icon',{staticClass:"append-right",on:{"click":function($event){$event.stopPropagation();return _vm.navigateTo(item.id)}}},[_vm._v("gps_not_fixed")]):_vm._e()],1)]}}],null,true)})],1)]}}],null,false,3908313869)})],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }