<template>
  <div>
    <slot :openSettings="openSettings" />
    <div class="control-element__image">
      <div v-if="localSettings.image && localSettings.image.body">
        <v-hover
        v-slot="{ hover }"
        >
          <img 
            width="100%" 
            :src="localSettings.image.body.url" 
            alt="" 
            @click="imageClicked"
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            >
        </v-hover>
      </div>
      <div v-if="localSettings.imageSourceURL">
        <v-hover
        v-slot="{ hover }"
        >
          <img 
            width="100%" 
            :src="localSettings.imageSourceURL" 
            alt="" 
            @click="imageClicked"
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            >
        </v-hover>
      </div>
    </div>
  </div>
</template>

<script>
  import ControlComponent from '../_extends/ControlComponent'

  export default {
    name: 'ControlElement_ImageControl',
    extends: ControlComponent,
    methods: {
      imageClicked() {
      if(this.localSettings.imageLink) {
        window.open(this.localSettings.imageLink, '_blank', 'noreferrer');
      }
      }
    }
  }
</script>

<style scoped>
  .control-element__image {
    padding: 15px 0;
  }

  img.on-hover {
    background-color: rgba(#FFF, 0.8);
  }
  
</style>
