const defaultSettings = {
  name: 'Sticky',
  icon: 'mdi-sticker-emoji',
  hotkey: `modkey + S`,
  size: {
    width: 200,
    height: 200,
    proportion: 1,
    minHeight: 160,
    minWidth: 160
  },
  resizable: true,
  draggable: true,
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: true,
  notionOption: true,
  settings: {
    backgroundColor: "#FEF24E",
    borderRadius: 0,
    borderStyle: "None",
    borderColor: "none",

    textColor: "#333",
    textAlign: "center",
    verticalAlign: "center",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "open sans",

    locked: false,
    order: 0,
    notionEnabled: false
  }
};

export default defaultSettings;
