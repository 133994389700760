<template>
  <div>
    <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details />
    <v-data-table :headers="headers" :items="list" :search="search">
      <template v-slot:item="{item}">
        <tr>
          <td>{{ item.name }}</td>
          <td class="justify-center layout px-0">
            <v-icon small @click="deleteParticipant(item)">delete</v-icon>
          </td>
        </tr>
      </template>
      <v-alert
        v-slot:no-results
        color="error"
        icon="warning"
      >Your search for "{{ search }}" found no results.</v-alert>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "Participants",
  props: {
    team: Object
  },
  created() {
    this.setList();
  },
  data() {
    return {
      headers: [],
      search: "",
      list: []
    };
  },
  methods: {
    deleteParticipant({ id }) {
      this.api.Team.deleteMember({ id }).then(response => {
        this.list = this.list.filter(item => item.id != id);
      });
    },
    setList() {
      this.api.Team.members({ id: this.team.id }).then(
        response => (this.list = response.body)
      );
    }
  }
};
</script>