import SEND_BOT_MESSAGE from './SEND_BOT_MESSAGE'
import SUBMIT_ON_SERVER from './SUBMIT_ON_SERVER'
import OPEN_GALLERY from './OPEN_GALLERY'
import OPEN_CAMERA from './OPEN_CAMERA'
import OPEN_PAGE from './OPEN_PAGE'
import EMIT_SIGNAL from './EMIT_SIGNAL'
import GO_TO_ENTRY from './GO_TO_ENTRY'
import CONTINUE_PARENT_FLOW from './CONTINUE_PARENT_FLOW'

export default {
  SEND_BOT_MESSAGE,
  SUBMIT_ON_SERVER,
  OPEN_GALLERY,
  OPEN_CAMERA,
  OPEN_PAGE,
  EMIT_SIGNAL,
  GO_TO_ENTRY,
  CONTINUE_PARENT_FLOW
}