const defaultSettings = {
  name: "Comment",
  icon: 'mdi-comment',
  hotkey: `modkey + C`,
  resizable: false,
  draggable: true,
  showLabel: false,
  size: {
    width: 0,
    height: 0
  },
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: false,
  alwaysOnTop: true,
  settings: {
    comments: [],
    color: "#ffd900",
    x1: 0,
    y1: 2,
    x2: 130,
    y2: 2,

    locked: false,
    order: 0
  }
};

export default defaultSettings;
