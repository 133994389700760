<template>
  <v-layout justify-center>
    <v-flex xs8>
      <v-toolbar>
        <v-toolbar-title>Invoices</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-container fluid grid-list-md>
          <v-data-table :headers="headers" :items="invoices">
            <template v-slot:item.download="{ item }">
              <span @click="downloadInvoice(item)">Download</span>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert>
          </v-data-table>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from "moment";
export default {
  name: "Invoices",
  props: {
    invoices: []
  },
  data() {
    return {
      headers: [
        {
          text: "Issued Date",
          sortable: false,
          value: "created_at"
        },
        {
          sortable: false,
          value: "download"
        }
      ],
      search: ""
    };
  },
  methods: {
    toStatusString(item) {
      return item.paid ? "Paid" : "Pending"
    },
    downloadInvoice(item) {
      let pdfLink = item.invoice_pdf_link || "";
      window.open(pdfLink, "_blank");
    }
  }
};
</script>

<style>
</style>
