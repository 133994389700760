const defaultSettings = {
  name: "User Message",
  icon: "comment",
  showLabel: true,
  size: {
    width: 'auto',
    height: 40
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#e44e9d",
  outColor: "#e44e9d",
  layout: "horizontal",
  components: [/Chatbot_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
