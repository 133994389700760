<template>
  <div>
    <draggable v-model="value" handle=".drag" :disabled="readonly">
      <div class="mb-4" v-for="(option, index) in value" :key="index">
        <v-layout align-center>
          <v-flex xs1 class="option-icon">
            <v-icon class="drag" :class="readonly ? `disabled-icon` : null">drag_handle</v-icon>
          </v-flex>
          <v-flex xs6>
            <v-text-field v-model="option.name" label="Model Name" :readonly="readonly" />
          </v-flex>
          <v-flex xs1 />
          <v-flex xs6>
            <Autocompleter
              :style="{zIndex: 1000-index}"
              solo
              v-model="option.value"
              label="Model Value"
              :schema="schema"
              :readonly="readonly"
            />
          </v-flex>

          <v-btn text class="button-icon" @click="deleteOption(option.id)" :disabled="readonly">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-layout>
      </div>
    </draggable>
    <div class="add" @click="addOption" v-if="!readonly">Add Model</div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SaveToModel",
  components: {
    draggable
  },
  props: {
    schema: Object,
    value: Array,
    readonly: Boolean
  },
  methods: {
    addOption() {
      this.value.push({ id: this.guid() });
    },
    deleteOption(optionId) {
      let ind = -1;
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i].id == optionId)
          ind = i;
      }
      if (ind != -1)
        this.value.splice(ind, 1);
    }
  }
};
</script>

<style>
.save-to-model .drag {
  cursor: move;
  text-align: left;
  padding: 20px 5px;
}

.save-to-model .delete {
  text-align: right;
  flex: auto;
  padding: 12px 5px;
}

.disabled-icon {
  opacity: .5;
  cursor: auto;
}
</style>