<template>
  <div class="pa-4 text-left">

      <v-card class="pa-4">
        <v-container>
          <v-layout>
            <v-flex mb-6>
            <h2>Environment Variables</h2>
            <p>Environment variable can be used to store API credentials for use in custom components and objects, without exposing them publicly.</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <Variables :vars="vars" @save="save" />
            </v-flex>
          </v-layout>
          <v-layout mt-6>
            <p>See the <a href="https://docs.vulcanapp.com" target="_blank">help section</a> for information regarding usage of Environment Variables.</p>
          </v-layout>
        </v-container>
      </v-card>
    
  </div>
</template>


<script>
import * as _ from "underscore";

export default {
  name: "Configuration",
  data() {
    return {
      vars: []
    };
  },
  created() {
    this.api.User.getEnvironmentVariables().then(res => {
      this.vars = res.body;
    });
  },
  methods: {
    save: _.debounce(function($event) {
      this.vars = $event;
      this.api.User.setEnvironmentVariables({
        environment_variables: this.vars
      });
    }, 300)
  }
};
</script>

<style scoped>
.action {
  cursor: pointer;
  padding: 10px;
}
</style>
