import {HEADER_HEIGHT} from "../../../../../utils/const";
import {isNull, isUndefined} from "underscore";

//idMap = {group-id-01: ['id1', 'id2' ...], g-id-02: ['id3',...]}
export const flattenGroup = (groupObject, store, idMap) => {
    let widgets = []

    try {
        if (groupObject.info.settings.objectIds) {
            const {objectIds} = groupObject.info.settings
            objectIds.map(id => {
                const widget = store.getters['object/findById'](id)
                if (widget.type !== 'Base_GroupObject') {
                    if (idMap[groupObject]) {
                        idMap[groupObject].push(id)
                    } else idMap[groupObject] = [id]
                    widgets.push(widget)
                    return widgets
                } else {
                    flattenGroup(widget, store, idMap)
                }
            })
        }
        return widgets
    } catch (e) {
        console.info('flattenGroup() encountered an error')
    }
}

export const flattenPasteArray = (objects = [], store) => {
    let groupedObjectsMap = {}
    const flatObjectsList = objects.reduce((flatList = [], w) => {
        if (w.type === 'Base_GroupObject') {
            flatList.push(w)
            return flatList.concat(flattenGroup(w, store, groupedObjectsMap))
        } else {
            flatList.push(w)
            return flatList
        }
    }, [])
    return {
        objects: flatObjectsList, groupedObjectsMap
    }
}

export const getPastingCoordsDiff = async (object, vulcanCoords, corner) => {
    const {x: oldX, y: oldY} = await object.position

    let diffX = Math.abs(corner.x - vulcanCoords.x)
    let diffY = Math.abs(corner.y - vulcanCoords.y)

    if (oldX > vulcanCoords.x) {
        diffX = -diffX
    }
    if (oldY > vulcanCoords.y) {
        diffY = -diffY
    }
    return {diffX, diffY}
}

export const handlePaste = async (store, object, mouseVulcanCoords, idMap, corner) => {
    const scale = store.getters['chart/scale']
    const {diffX, diffY} = await getPastingCoordsDiff(object, mouseVulcanCoords, corner)

    if (object.info_id) {
        delete object.info_id
        delete object.info.id
    }

    let objectContents = {
        ...object,
        position: {
            ...object.position,
            x: Math.round(object.position.x + diffX),
            y: Math.round(object.position.y + diffY) - HEADER_HEIGHT / scale
        },
    }
    const newObject = await store.dispatch("object/create", objectContents)

    const fromConnections = store.getters["connection/find"]({
        from: object.id
    }).filter(c => !isNull(c.to) && !isUndefined(c.to))

    const toConnections = store.getters["connection/find"]({
        to: object.id
    })

    idMap[object.id] = newObject.id

    fromConnections.concat(toConnections).map(c => {
        const idFrom = idMap[c.from]
        const idTo = idMap[c.to]
        if (idFrom && idTo) {
            // this call handles duplicated connections (if one has been created before)
            store.dispatch('connection/create',
                {...c, from: idMap[c.from], to: idMap[c.to]})
        }
    })
}
