<template>
  <v-toolbar dense no-padding>
    <v-select
      :items="fontfamilies"
      item-text="name"
      item-value="value"
      style="width: 150px"
      hide-details
      class="pa-0 ml-2"
      v-model="localSettings.fontFamily"
      :readonly="readonly"
      placeholder="Font"
      :menu-props="{ maxHeight: '400' }"
    />

    <v-divider class="ml-1 mr-2" vertical />

    <v-select
      :items="fontsizes"
      style="width: 60px"
      hide-details
      class="pa-0"
      v-model="localSettings.fontSize"
      :readonly="readonly"
      :menu-props="{ maxHeight: '400' }"
    />

    <v-divider class="ml-1 mr-2" vertical />

    <v-select
      :items="fontweights"
      style="width: 70px"
      hide-details
      class="pa-0"
      v-model="localSettings.fontWeight"
      :readonly="readonly"
      :menu-props="{ maxHeight: '400' }"
    />

    <v-divider class="ml-1" vertical />

    <v-btn-toggle v-model="italic" group borderless>
      <v-btn text :disabled="readonly" class="ma-0 mr-1">
        <v-icon>format_italic</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-btn-toggle v-model="underline" group borderless>
      <v-btn text :disabled="readonly" class="ma-0 mr-1">
        <v-icon>format_underlined</v-icon>
      </v-btn>
    </v-btn-toggle>
    <ColorPickerDialog
      v-model="localSettings.textColor"
      title="Select Text Colour"
      :presetColors="projectColors"
      :disabled="readonly"
    />

    <v-divider vertical />

    <v-btn-toggle v-model="localSettings.textAlign" mandatory group borderless>
      <v-btn text value="left" :disabled="readonly" class="ma-0">
        <v-icon>format_align_left</v-icon>
      </v-btn>
      <v-btn text value="center" :disabled="readonly" class="ma-0">
        <v-icon>format_align_center</v-icon>
      </v-btn>
      <v-btn text value="right" :disabled="readonly" class="ma-0">
        <v-icon>format_align_right</v-icon>
      </v-btn>
      <v-btn text value="justify" :disabled="readonly" class="ma-0">
        <v-icon>format_align_justify</v-icon>
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <v-btn-toggle v-model="localSettings.verticalAlign" mandatory group borderless>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn text value="flex-start" v-on="on" :disabled="readonly" class="ma-0">
            <v-icon>mdi-format-align-top</v-icon>
          </v-btn>
        </template>
        <span>Top</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn text value="center" v-on="on" :disabled="readonly" class="ma-0">
            <v-icon>mdi-format-align-middle</v-icon>
          </v-btn>
        </template>
        <span>Center</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn text value="flex-end" v-on="on" :disabled="readonly" class="ma-0">
            <v-icon>mdi-format-align-bottom</v-icon>
          </v-btn>
        </template>
        <span>Bottom</span>
      </v-tooltip>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import * as _ from "underscore";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Base_TextObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    return {
      fontsizes: [9, 10, 11, 13, 16, 18, 24, 48, 64, 72, 144, 248],
      fontweights: [300, 500, 700, 900],
      fontfamilies: [
        {name: "Nunito Sans", value: "nunito sans"},
        {name: "Open Sans", value: "open sans"},
        {name: "Roboto", value: "roboto"},
        {name: "Raleway", value: "raleway"},
        {name: "Proxima Nova", value: "proxima-nova"},
        {name: "Verveine", value: "verveine"},
        {name: "Chantal", value: "chantal"},
        {name: "Helvetica", value: "helvetica"},
        {name: "Arial", value: "arial"},
        {name: "Sans Serif", value: "sans-serif"},
        {name: "Monospace", value: "monospace"},
        {name: "Serif", value: "serif"}
      ],
      italic: this.settings.fontStyle == 'italic' ? 0 : undefined,
      underline: this.settings.textDecoration == 'underline' ? 0 : undefined
    };
  },

  watch: {
    italic(val) {
      if (val === 0)
        this.$set(this.localSettings, 'fontStyle', 'italic');
      else
        this.$set(this.localSettings, 'fontStyle', 'normal');
    },
    underline(val) {
      if (val === 0)
        this.$set(this.localSettings, 'textDecoration', 'underline');
      else
        this.$set(this.localSettings, 'textDecoration', 'none');
    }
  }
};
</script>
