import { deepClone } from "@/lib";

export default {
  props: {
    readonly: Boolean,
    settings: Object,
    baseObject: Object,
    selected: Boolean
  },
  data() {
    return {
      localSettings: deepClone(this.settings)
    };
  },
  mounted() {
    this.setModel();
  },
  methods: {
    setModel() {
      this.$store.commit("intellisense/setModel", {});
    }
  },
  watch: {
    localSettings: {
      handler() {
        if (JSON.stringify(this.localSettings) != JSON.stringify(this.settings)) {
          this.setModel();
          this.$emit("update:settings", this.localSettings);
        }
      },
      deep: true
    },
    settings: {
      handler() {
        if (JSON.stringify(this.localSettings) != JSON.stringify(this.settings))
          this.localSettings = deepClone(this.settings);
      },
      deep: true
    }
  }
};
