const defaultChartOptions = {
  navigationControlsEnabled: true, 
  gridEnabled: false, 
  gridSize: "Medium", 
  gridColor: "Gray",
  lineStyle: "Solid",
  lineColor: "#333",
  strokeLineCap: 'Square',
  strokeWidth: 1
};

export default defaultChartOptions;