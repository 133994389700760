<template>
  <div class="list">
    <draggable @input="input" :value="value" handle=".actions" :disabled="readonly">
      <div class="form-row item" v-for="property in value" :key="property.id">
        <div class="actions">
          <div v-if="!readonly">
            <v-icon class="drag">drag_handle</v-icon>
          </div>
          <div v-if="!readonly">
            <v-icon class="delete" @click="deleteProperty(property.id)">delete</v-icon>
          </div>
        </div>

        <div v-if="property.type != 'group'">
          <Property :property="property" :readonly="readonly" />
        </div>
        <div v-else>
          <v-text-field
            hide-details
            v-model="property.name"
            label="Group name"
            :readonly="readonly"
          />

          <div style="color: rgba(0, 0, 0, 0.6)" class="ml-2 mt-4" >Child properties:</div>
          <draggable v-model="property.elements" handle=".select-options" :disabled="readonly">
            <div class="form-row item" v-for="prop in property.elements" :key="prop.id">
              <div class="select-options">
                <div v-if="!readonly">
                  <v-icon class="drag">drag_handle</v-icon>
                </div>
                <div v-if="!readonly">
                  <v-icon class="delete" @click="deleteChildProperty(property, prop.id)">delete</v-icon>
                </div>
              </div>

              <Property :property="prop" child :readonly="readonly" />
            </div>
          </draggable>
          <div class="add" @click="addChildProperty(property)" v-if="!readonly">Add child property</div>
        </div>
      </div>
    </draggable>
    <div v-if="!value.length" style="color: rgba(0, 0, 0, 0.25)"><i>(empty)</i></div>
    <v-row class="ma-0 mt-2">
      <v-col class="add mr-4" @click="addProperty" v-if="!readonly">Add Property</v-col>
      <v-col class="add" @click="addGroup" v-if="!readonly">Add a Group of Properties</v-col>
    </v-row>
  </div>
</template>

<script>
import Property from "./Property";

export default {
  name: "PropertiesList",
  components: {
    Property
  },
  props: {
    value: Array,
    readonly: Boolean
  },
  methods: {
    addProperty() {
      this.$emit("input", [
        ...this.value,
        {
          id: this.guid(),
          name: "",
          visibleName: "",
          defaultValue: "",
          type: "textfield",
          fieldWidth: "100",
          selectOptions: []
        }
      ]);
    },
    addGroup() {
      this.$emit("input", [
        ...this.value,
        {
          id: this.guid(),
          name: "",
          type: "group",
          elements: []
        }
      ]);
    },
    addChildProperty(property) {
      property.elements.push({
        id: this.guid(),
        name: "",
        visibleName: "",
        defaultValue: "",
        type: "textfield",
        fieldWidth: "100",
        selectOptions: []
      });
    },
    deleteProperty(id) {
      const value = this.value.filter(item => item.id != id);
      this.$emit("input", value);
    },
    deleteChildProperty(property, id) {
      property.elements = property.elements.filter(prop => prop.id != id);
    },
    input(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style scoped>
.list {
  width: 100%;
}

.item {
  border: 1px solid #f7f7f7;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.actions {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  margin: -10px;
  margin-bottom: 20px;
  padding: 5px;
  cursor: move;
}

.select-options {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  margin: -10px;
  margin-bottom: 20px;
  padding: 5px;
  cursor: move;
}
</style>
