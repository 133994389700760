import Vue from "vue";
import router from "@/router";

const store = {
  namespaced: true,
  state: {
    loading: false
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    async logOut({dispatch}) {
      try {
        /*await */Vue.http.delete("vulcan/v1/auth/logout");
      } catch (e) {}
      dispatch('user/logOut', null, {root: true});
      router.push({ name: "signIn" });
    }
  }
};

export default store;
