<script>
import Text from "./Text.vue";
export default {
  extends: Text,
  name: "NotionTypeHeader",
  props: {
    editorType: {
      type: String,
      default: "Header"
    }
  },
  computed: {
    contentStyle() {
      return {
        color: this.line.color || "#333",
        background: this.line.background || "transparent",
        fontSize: "24px",
        fontWeight: "700"
      };
    }
  }
};
</script>

<style lang="css">
</style>
