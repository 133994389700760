import ObjectPicker from './ObjectPicker';
import CustomComponent from './CustomComponent';

export default {
    objects: {
        ObjectPicker,
        CustomComponent
    },
    tab: 'Core'
}
