<template>
  <div class="mt-5">
    <div v-html="settings.title" />
    <v-checkbox
      class="mt-2"
      hide-details
      :disabled="disabled"
      v-for="option in settings.options"
      :key="option.id"
      v-model="model"
      :label="option.value"
      :value="option"
    />
  </div>
</template>

<script>
  export default {
    name: 'ControlElement_CheckboxList',
    props: {
      settings: Object,
      disabled: Boolean
    },
    data () {
      return {
        model: []
      };
    },
    watch: {
      model: {
        handler () {
          this.$store.commit(`wayfinder/updateCurrentScope`, {
            [this.settings.model]: this.model.map(e => ({
              id: e.value,
              display_name: e.name
            }))
          });
        },
        deep: true
      }
    },
    created () {
      this.$store.commit(`wayfinder/updateCurrentScope`, {
        [this.settings.model]: []
      });
    }
  }
</script>