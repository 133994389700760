<template>
  <div tabindex="0">
    <v-snackbar
      :timeout="8000"
      v-model="snackbar"
      color="deep-purple accent-4"
      style="padding-bottom: 70px"
    >
      Tip: Hold Space and click / drag to explore the canvas
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Viewport
      v-if="activeChart"
      :moving="moving"
      class="widget-viewport"
      readonly
      :scale="scale"
      :translate="translate"
      ref="viewport"
      :bgImage="activeChart.options.icon ? activeChart.options.icon.body.url : ''"
    >
      <template v-slot:html>
        <BaseObject
          readonly
          v-for="object in objects"
          :key="object.id"
          v-if="isObjectVisible(object)"
          v-bind="object"
        />
      </template>
      <template v-slot:svg>
        <BaseConnection
          readonly
          v-for="connection in connections"
          :key="connection.id"
          v-if="isConnectionVisible(connection)"
          v-bind="connection"
        />
      </template>
      
    </Viewport>

    <PropertyEditor
      readonly
      :viewport="$refs.viewport"
      :scale="scale"
      :translate="translate"
    />
    <DataEditor
      :viewport="$refs.viewport"
      :scale="scale"
      :translate="translate"
    />
    <PinnedContent />

    <WayfinderOverlay
      v-if="currentSlide"
      :slide="currentSlide"
      :slideContent="currentSlideContent"
    />
    <v-app-bar class="widget-footer" color="grey lighten-4">
      <img
        class="logo-image"
        @click="logoClick"
        height="40"
        src="./img/vulcan-logo.png"
      />
      <div class="footer-info">
        <div class="footer-info__name">{{ activeChart ? activeChart.name : ""}}</div>
        <div class="footer-info__link">
          <a href="http://vulcanapp.com">vulcanapp.com</a>
        </div>
      </div>
      <v-spacer />
      <div class="wayfinder-controls">
        <v-btn
          class="wayfinder-control"
          text
          :disabled="!previousSlide"
          @click="goPreviousSlide"
        >
          <v-icon>arrow_back_ios</v-icon>
        </v-btn>
        <span class="wayfinder-controls__name" v-if="currentSlide">
          <v-select
            :item-text="slide => slide.info.settings.wayfinder_title || slide.info.settings.title"
            item-value="id"
            hide-details
            :value="currentSlide.id"
            @change="gotoSlide($event)"
            :items="slides"
          />
        </span>
        <v-btn
          class="wayfinder-control"
          text
          :disabled="!nextSlide"
          @click="goNextSlide"
        >
          <v-icon>arrow_forward_ios</v-icon>
        </v-btn>
      </div>
      <v-spacer />
      <v-tooltip top v-if="commentsAllowed">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon @click="addComment">add</v-icon>
          </v-btn>
        </template>
        <span>Add Comment</span>
      </v-tooltip>
      <v-dialog v-model="helpModal" max-width="480px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon @click="showHelpModal">help</v-icon>
            </v-btn>
          </template>
        <v-card>
          <v-card-title class="headline">Get Started with Wayfinder</v-card-title>
          <v-card-text>A few simple steps and helpful hints to start your Wayfinder adventure.</v-card-text>
          <v-stepper v-model="e6" vertical>
            <v-stepper-step :complete="e6 > 1" step="1">
              Stay on Track
              <small>Follow the beaten path</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card class="mb-12" height="280px">
                <v-card-text>
                  <div>Use the footer controls to move through the Wayfinder</div>
                  <div>Pro Tip:
                    <v-icon>mdi-arrow-left-box</v-icon>
                    <v-icon>mdi-arrow-right-box</v-icon>
                    Left and Right Keys
                  </div>
                </v-card-text>
                <v-img src="https://vulcan-marketing.s3.eu-west-2.amazonaws.com/wayfinder-help-1.gif" />
              </v-card>
              <v-btn color="primary" @click="e6 = 2">Continue</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">
              Go Off-Piste
              <small>Jump off and explore the canvas</small>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card class="mb-12" height="260px">
                <v-card-text>
                  <v-btn small color="grey">SPACE</v-btn>
                  <v-icon>mdi-plus</v-icon>
                  <v-icon>mdi-cursor-move</v-icon>
                  <v-icon>mdi-mouse</v-icon>
                </v-card-text>
                <v-img src="https://vulcan-marketing.s3.eu-west-2.amazonaws.com/wayfinder-space-drag.2020-07-19+12_47_27.gif" />
              </v-card>
              <v-btn color="primary" @click="e6 = 3">Continue</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 3" step="3">
              Collaborate
              <small>Add comments to the canvas</small>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card class="mb-12" height="260px">
                <v-card-text>Start conversations anywhere, anytime</v-card-text>
                <v-img src="https://vulcan-marketing.s3.eu-west-2.amazonaws.com/wayfinder-comments.2020-07-19+22_17_25.gif" />
              </v-card>
              <v-btn color="primary" @click="e6 = 4">Continue</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 4" step="4">
              Share and Embed
              <small>Add Wayfinder to your Knowledgebase</small>
            </v-stepper-step>
            <v-stepper-content step="4">
              <v-card class="mb-12" height="260px">
                <v-card-text>Embed Wayfinder in Confluence, Notion and more...</v-card-text>
                <v-img src="https://vulcan-marketing.s3.eu-west-2.amazonaws.com/wayfinder-confluence.png" />
              </v-card>
            </v-stepper-content>
          </v-stepper>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="helpModal = false">Exit</v-btn>
            <v-btn :color="e6 == 4? 'primary darken-1':'grey'" @click="helpModal = false">Let's Go!</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon
              @click="toggleFavourite"
              v-bind:color="favourited ? 'red' : 'grey'"
            >
              favorite
            </v-icon>
          </v-btn>
        </template>
        <span>Show Appreciation</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon @click="refreshClick">refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh Content</span>
      </v-tooltip>

      <v-menu open-on-hover top offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            @click="menuItemClicked(index)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Viewport from "../Authorized/TheEditor/Viewport.vue";
import BaseObject from "../Authorized/TheEditor/BaseObject.vue";
import BaseConnection from "../Authorized/TheEditor/BaseConnection.vue";
import WayfinderOverlay from "./Wayfinder/WayfinderOverlay.vue";

export default {
  name: "TheWidget",
  components: { Viewport, BaseObject, BaseConnection, WayfinderOverlay },
  data() {
    return {
      moving: false,
      favourited: false,
      menuItems: [{ title: "Learn More" }, { title: "View Docs" }],
      wayfinderFlow: null,
      helpModal: false,
      e6: 1,
      snackbar: true
    };
  },
  metaInfo() {
    return {
      meta: [
        { name: 'robots', content: 'noindex' }
      ]
    };
  },
  computed: {
    ...mapState({
      objects:              state => state.object.list,
      connections:          state => state.connection.list,
      scale:                state => state.chart.scale,
      translate:            state => state.chart.translate,
      currentSlide:         state => state.wayfinder.currentSlide,
      currentSlideContent:  state => state.wayfinder.currentSlideContent,
      slides:               state => state.wayfinder.slides
    }),
    ...mapGetters({
      nextSlide: 'wayfinder/nextSlide',
      previousSlide: 'wayfinder/previousSlide',
    }),
    commentsAllowed() {
      return this.currentUser ||
        this.activeChart && this.activeChart.options.public_comments;
    }
  },
  watch: {
    currentSlide(newVal, oldVal) {
      if (newVal != oldVal)
        this.focusOnSlide(this.currentSlide);
    }
  },
  async created() {
    await this.$store.dispatch('chart/fetchWidget', this.$route.params.id);
    const slideId = this.$route.query.slide;

    if (slideId) {
      this.$store.dispatch('wayfinder/init', slideId);
      this.focusOnSlide(this.currentSlide);
    }
  },
  mounted() {
    this.$el.onkeydown = e => {
      // left arrow
      if (e.keyCode == 37) {
        this.goPreviousSlide();
      // right arrow
      } else if (e.keyCode == 39) {
        this.goNextSlide();
      }
    };

    window.addEventListener("keydown", this.onKeyDown);
    window.analytics.page('Widget');

    this.$intercom.update({vertical_padding: 90});
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onKeyDown(event) {
      if (event.target.tagName == "BODY") {
        switch (event.key) {
          case "ArrowLeft":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x + 10,
              y: this.translate.y
            });
            break;
          case "ArrowRight":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x - 10,
              y: this.translate.y
            });
            break;
          case "ArrowDown":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x,
              y: this.translate.y - 10
            });
            break;
          case "ArrowUp":
            this.$store.dispatch("chart/setTranslate", {
              x: this.translate.x,
              y: this.translate.y + 10
            });
            break;
        }
      }
    },
    isObjectVisible(object) {
      return object.type.indexOf("Presentation_") != 0;
    },
    isConnectionVisible(connection) {
      const object = this.$store.getters['object/findById'](connection.from);
      return object.type.indexOf("Presentation_") != 0;
    },
    gotoSlide(slideId) {
      this.$store.dispatch('wayfinder/setCurrentSlide', slideId);
    },
    goNextSlide() {
      if (this.nextSlide)
        this.$store.dispatch('wayfinder/nextSlide');
    },
    goPreviousSlide() {
      if (this.previousSlide)
        this.$store.dispatch('wayfinder/previousSlide');
    },
    focusOnSlide(slide) {
      if (!slide)
        return false;

      this.moving = true;
      setTimeout(() => this.moving = false, 500);

      const {
        offsetHeight: viewportHeight,
        offsetWidth:  viewportWidth
      } = document.querySelector(".viewport");

      const ratioWidth = viewportWidth / slide.size.width;
      const ratioHeight = (viewportHeight - 60) / slide.size.height;
      const ratio = Math.min(ratioWidth, ratioHeight);

      const translate = {
        x:
          -slide.position.x * ratio +
          (viewportWidth - slide.size.width * ratio) / 2,
        y:
          -slide.position.y * ratio +
          (viewportHeight - 64 - slide.size.height * ratio) / 2
      };

      this.$store.commit("chart/setScale", ratio);
      this.$store.commit("chart/setTranslate", translate);

      history.pushState(
        null,
        "",
        `/widget/${this.$route.params.id}?slide=${slide.id}`
      );
    },
    addComment() {
      const rect = this.$refs.viewport.$el.getBoundingClientRect();
      const position = {
        x: Math.round((-this.translate.x + rect.width / 2) / this.scale),
        y: Math.round((-this.translate.y + rect.height / 2) / this.scale)
      };

      this.$store.dispatch("object/create", {
        type: "Base_CommentObject",
        position
      });

      this.$root.$emit("PropertyEditor.close");
    },
    logoClick() {
      window.open("https://vulcanapp.com", "_blank");
    },
    refreshClick() {
      location.reload();
    },
    toggleFavourite() {
      this.favourited = !this.favourited;
    },
    showHelpModal() {

    },
    menuItemClicked(index) {
      switch (index) {
        case 0:
          window.open("https://vulcanapp.com", "_blank");
          break;
        case 1:
          window.open("https://docs.vulcanapp.com", "_blank");
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.widget-footer {
  bottom: 0;
  position: fixed;
  z-index: 10;
}
.logo-image {
  cursor: pointer;
}
.footer-info {
  text-align: left;
  margin-left: 10px;
}

.footer-info__name {
  font-weight: 900;
}

.footer-info__link a {
  text-decoration: none;
}

.wayfinder-controls {
  display: flex;
  align-items: center;
}

.wayfinder-control {
  min-width: 30px !important;
  padding: 0 !important;
}
.wayfinder-controls__name {
  margin: 0 15px;
  width: 150px;
}
</style>
