<template>
  <div>
    <v-menu offset-y offset-x>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" text class="toolbar-button" id="menuMore">
          <v-icon>more_horiz</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :disabled="readonly" @click="onViewInfo">
          <v-list-item-content>
            <v-list-item-title>View Info</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-if="defaultProperties && defaultProperties.notionOption"
            :disabled="readonly"
            @click="onNotionEnable"
        >
          <v-list-item-content>
            <v-list-item-title>{{ settings.notionEnabled ? 'Disable' : 'Enable' }} Editor</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-if="objectType == 'Base_ImageObject'"
            :disabled="readonly"
            @click="onAudioEnable"
        >
          <v-list-item-content>
            <v-list-item-title>{{ settings.audioEnabled ? 'Disable' : 'Enable' }} Audio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :disabled="readonly" @click="onChangeType">
          <v-list-item-content>
            <v-list-item-title>{{ `Change ${objectType == 'Base_SymbolObject' ? 'symbol' : 'type'}` }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :disabled="readonly"
            @click="onCreateSymbol"
            v-if="objectType != 'Base_SymbolObject'"
        >
          <v-list-item-content>
            <v-list-item-title>{{ baseObject && baseObject.symbol_name ? 'Change symbol name' : 'Create symbol' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="onCopyStyle" v-if="false">
          <v-list-item-content>
            <v-list-item-title>Copy style</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">{{ modKey1 + ' + Alt + C' }}</v-list-item-action>
        </v-list-item>

        <v-list-item :disabled="readonly" @click="onPasteStyle" v-if="false">
          <v-list-item-content>
            <v-list-item-title>Paste style</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">{{ modKey1 + ' + Alt + V' }}</v-list-item-action>
        </v-list-item>

        <v-list-item @click="onCopyLink">
          <v-list-item-content>
            <v-list-item-title>Copy link</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="onCopyID">
          <v-list-item-content>
            <v-list-item-title>Copy ID</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :disabled="!baseObject" @click="onLock">
          <v-list-item-content>
            <v-list-item-title>{{ locked ? 'Unlock' : 'Lock' }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">{{ modKey2 + ' + L' }}</v-list-item-action>
        </v-list-item>

        <v-list-item
            v-if="objectType != 'Base_GroupObject' && objectType != 'Base_CommentObject'"
            :disabled="readonly || orderForwardDisabled"
            @click="onBringForward"
        >
          <v-list-item-content>
            <v-list-item-title>Bring forward</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">Alt + ↑</v-list-item-action>
        </v-list-item>

        <v-list-item
            v-if="objectType != 'Base_GroupObject' && objectType != 'Base_CommentObject'"
            :disabled="readonly || orderBackDisabled"
            @click="onSendBackward"
        >
          <v-list-item-content>
            <v-list-item-title>Send backward</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">Alt + ↓</v-list-item-action>
        </v-list-item>

        <v-list-item
            v-if="objectType != 'Base_GroupObject' && objectType != 'Base_CommentObject'"
            :disabled="readonly || orderForwardDisabled"
            @click="onBringToFront"
        >
          <v-list-item-content>
            <v-list-item-title>Bring to front</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">Alt + Shift + ↑</v-list-item-action>
        </v-list-item>

        <v-list-item
            v-if="objectType != 'Base_GroupObject' && objectType != 'Base_CommentObject'"
            :disabled="readonly || orderBackDisabled"
            @click="onSendToBack"
        >
          <v-list-item-content>
            <v-list-item-title>Send to back</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">Alt + Shift + ↓</v-list-item-action>
        </v-list-item>

        <v-list-item @click="onCopy" v-if="false">
          <v-list-item-content>
            <v-list-item-title>Copy</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">{{ modKey1 + ' + C' }}</v-list-item-action>
        </v-list-item>

        <v-list-item @click="onDuplicate" id="duplicateObjectItem">
          <v-list-item-content>
            <v-list-item-title>Duplicate</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">{{ modKey2 + ' + D' }}</v-list-item-action>
        </v-list-item>

        <v-list-item @click="onExportCSV" v-if="false">
          <v-list-item-content>
            <v-list-item-title>Export to CSV (Excel)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-if="!inGroup && objectType != 'Base_GroupObject'"
            :disabled="readonly"
            @click="onCreateGroup"
        >
          <v-list-item-content>
            <v-list-item-title>Create group</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-if="inGroup && objectType != 'Base_GroupObject'"
            :disabled="readonly"
            @click="onExtractFromGroup"
        >
          <v-list-item-content>
            <v-list-item-title>Extract from group</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="objectType == 'Base_GroupObject'" @click="onSaveAsTemplate">
          <v-list-item-content>
            <v-list-item-title>Save as template</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="objectType == 'Base_GroupObject'" @click="onUngroup">
          <v-list-item-content>
            <v-list-item-title>Ungroup</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :disabled="readonly" @click="onDelete">
          <v-list-item-content>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="hotkey">Delete</v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>

    <div>
      <v-dialog v-model="dialogObjectInfo" width="500">
        <v-card>
          <v-card-title>Object Info</v-card-title>
          <v-card-text class="mt-4">
            <p>
              <b>Object ID:</b> {{ baseObject.id }}
            </p>
            <p>
              <b>Object type:</b> {{ baseObject.type }}
            </p>
            <p>
              <b>Created by</b> {{ creator }}, {{ createdTimeAgo }}
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogObjectType" width="500">
        <v-card>
          <v-card-title class="headline" primary-title>Set object type</v-card-title>
          <v-container>
            <v-select
                outlined
                label="Object type"
                :items="componentTypes"
                item-text="name"
                item-value="id"
                v-model="objectType"
                dense
            >
              <template v-slot:item="{item}">
                <template v-if="typeof item !== 'object'">
                  <v-list-item-content v-text="item"/>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>

            <v-btn @click="updateObjectType" color="secondary" small>ok</v-btn>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogChangeSymbol" width="500">
        <v-card>
          <v-card-title class="headline" primary-title>Set symbol</v-card-title>
          <v-container>
            <v-autocomplete
                outlined
                label="Symbol object"
                item-text="name"
                item-value="id"
                :items="symbols"
                v-model="symbol_id"
            />

            <v-btn @click="saveSymbol" color="secondary" :disabled="!symbol_id" small>ok</v-btn>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogCreateSymbol" width="500">
        <v-card>
          <v-card-title
              class="headline"
              primary-title
          >{{ baseObject && baseObject.symbol_name ? 'Change symbol name' : 'Create symbol' }}
          </v-card-title>
          <v-container>
            <v-text-field
                @click.stop
                ref="createSymbolField"
                label="Symbol name"
                v-model="symbolName"
            />

            <v-btn @click="createSymbol" color="secondary" small>Save</v-btn>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTemplate" width="500">
        <v-card :loading="templateCreating" :disabled="templateCreating">
          <v-card-title class="headline" primary-title>New template</v-card-title>
          <v-card-text class="pa-3">
            <div style="flex: auto">
              <v-text-field
                  ref="saveTemplate"
                  label="Template name"
                  type="text"
                  v-model="templateName"
              />
            </div>

            <v-flex xs12 class="mb-6">
              <TemplatePreview ref="preview"
                               :settings="{background: settings.background}"
                               :objects="templateObjects"
                               :connections="templateConnections"
                               :group="this.baseObject"
              />

            </v-flex>

            <v-btn @click="createTemplate" color="primary" :disabled="!templateName">create</v-btn>
            <v-btn class="ml-3" @click="dialogTemplate = false" color="secondary">close</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {copyToClipboard, isMac} from "@/lib";
import * as _ from "underscore";
import {ObjectTypesByModule, defaultProperties} from "../Object/Types";
import html2canvas from "html2canvas";
import moment from 'moment';
import {mapGetters} from "vuex";
import TemplatePreview from "./TemplatePreview";

export default {
  name: "MenuMore",
  components: {TemplatePreview},
  props: ["baseObject", "readonly", "settings"],

  data() {
    const componentTypes = [];
    _.keys(ObjectTypesByModule).forEach(group => {
      if (group == 'Core')
        return;

      componentTypes.push({header: group});

      ObjectTypesByModule[group].forEach(el => {
        if (el == "Base_GroupObject")
          return;

        componentTypes.push({
          id: el,
          name: _.last(el.split("_")).replace("Object", "")
        });
      });
    });

    return {
      menu: [],
      componentTypes,
      objectType: null,
      symbolName: "",
      symbols: [],
      symbol_id: null,
      templateName: "",
      defaultProperties: null,

      dialogObjectInfo: false,
      dialogObjectType: false,
      dialogChangeSymbol: false,
      dialogCreateSymbol: false,
      dialogTemplate: false,

      modKey1: isMac() ? "⌘" : "Ctrl",
      modKey2: isMac() ? "Ctrl" : "Win",

      templateCreating: false
    };
  },

  computed: {
    ...mapGetters({
      objects: "object/list"
    }),
    creator() {
      const _creator = this.baseObject.info.settings.created_by;
      return _creator ? _creator : 'Anonymous User';
    },
    createdTimeAgo() {
      return moment(this.baseObject.created_at).fromNow();
    },
    locked() {
      return this.settings.locked;
    },
    groups() {
      return this.objects.filter(
          object =>
              object.type == "Base_GroupObject" &&
              object.info.settings.objectIds?.includes(this.baseObject.id)
      );
    },
    objectUrl() {
      const projectId = this.activeProject.id;
      const chartId = this.activeChart.id;
      const objectId = this.baseObject ? this.baseObject.id : null;
      return `${window.location.origin}/project/${projectId}/chart/${chartId}?object=${objectId}`;
    },
    templateObjects() {
      return this.objects
          .filter(object => this.settings.objectIds?.includes(object.id))
          .map(o => {
            return {
              ...o,
              position: {
                x: o.position.x - this.baseObject.position.x,
                y: o.position.y - this.baseObject.position.y
              }
            };
          });
    },
    templateConnections() {
      const connections = this.$store.getters["connection/list"].filter(
          t =>
              this.settings.objectIds?.includes(t.from) ||
              this.settings.objectIds?.includes(t.to)
      );
      return connections;
    },
    inGroup() {
      return this.groups.length > 1 ||
          this.groups.length == 1 && !!this.groups[0].chart_id;
    },
    orderBackDisabled() {
      return (
          !this.baseObject ||
          this.$store.getters["object/isOrderMin"](this.baseObject.id)
      );
    },
    orderForwardDisabled() {
      return (
          !this.baseObject ||
          this.$store.getters["object/isOrderMax"](this.baseObject.id)
      );
    }
  },

  mounted: async function () {
    if (this.baseObject) {
      this.objectType = this.baseObject.type;
      this.symbolName = this.baseObject.symbol_name;
      if (!this.symbolName)
        this.symbolName = this.settings.title;

      this.symbol_id = this.baseObject.symbol_id;
      this.defaultProperties = defaultProperties[this.objectType];
    }
    if (this.currentUser) {
      const {body} = await this.api.ChartObject.symbols();
      this.symbols = body;
    }
  },

  methods: {
    onViewInfo() {
      this.dialogObjectInfo = true;
    },
    onChangeType() {
      if (this.objectType == "Base_SymbolObject")
        this.dialogChangeSymbol = true;
      else
        this.dialogObjectType = true;
    },
    onCreateSymbol() {
      this.dialogCreateSymbol = true;
      this.$nextTick(function () {
        this.$refs.createSymbolField.focus();
      });
    },
    onExtractFromGroup() {
      this.groups.forEach(group => {
        group.info.settings.objectIds = group.info.settings.objectIds.filter(
            id => id != this.baseObject.id
        );
        this.$store.dispatch("object/update", group);
      });
    },
    onCopyStyle() {
    },
    onPasteStyle() {
    },
    onCopyLink() {
      copyToClipboard(this.objectUrl);
    },
    onCopyID() {
      copyToClipboard(this.baseObject.id);
    },
    onLock() {
      this.settings.locked = !this.locked;
      this.$store.dispatch("object/update", this.baseObject);
      this.deselect();
    },
    onNotionEnable() {
      this.$set(this.settings, 'notionEnabled', !this.settings.notionEnabled);
      this.$store.dispatch("object/update", this.baseObject);
    },
    onAudioEnable() {
      this.$set(this.settings, 'audioEnabled', !this.settings.audioEnabled);
      this.$store.dispatch("object/update", this.baseObject);
    },
    onBringForward() {
      this.$store.dispatch("object/orderForward", this.baseObject.id);
      this.deselect();
    },
    onSendBackward() {
      this.$store.dispatch("object/orderBackward", this.baseObject.id);
      this.deselect();
    },
    onBringToFront() {
      this.$store.dispatch("object/orderFront", this.baseObject.id);
      this.deselect();
    },
    onSendToBack() {
      this.$store.dispatch("object/orderBack", this.baseObject.id);
      this.deselect();
    },
    onCopy() {
    },
    onDuplicate() {
      this.$store.dispatch("object/duplicate", this.baseObject.id);
    },
    onExportCSV() {
    },
    onCreateGroup() {
      this.$store.dispatch("object/createGroup");
    },
    onSaveAsTemplate() {
      this.dialogTemplate = true;
    },
    onUngroup() {
      this.baseObject.info.settings.objectIds.map((objId) => {
            const {id, info} = this.$store.getters['object/findById'](objId)

            this.$store.dispatch('object/update', {
              id, info:
                  {...info, settings: {...info.settings, transformOrigin: 'center center'}}
            })
          }
      )
      this.baseObject.info.settings.objectIds = []
      this.$store.dispatch("object/update", this.baseObject);
      this.$store.dispatch('object/delete', this.baseObject.id)


    },
    onDelete() {
      this.$store.dispatch("object/delete", this.baseObject.id);
    },
    deselect() {
      this.$root.$emit("PropertyEditor.close");
      this.$store.commit("object/setContentEditable", false);
      this.$store.commit("object/deselectAll");
      this.$store.commit("object/removeGroupPreview");
    },
    updateObjectType() {
      this.dialogObjectType = false;
      this.$store.dispatch("object/update", {
        id: this.baseObject.id,
        type: this.objectType
      });
      this.$root.$emit("PropertyEditor.close");
    },
    async createSymbol() {
      this.dialogCreateSymbol = false;
      this.$store.commit("object/update", {
        id: this.baseObject.id,
        symbol_name: this.symbolName
      });
      await this.api.ChartObject.createSymbol(
          {id: this.baseObject.id},
          {name: this.symbolName}
      );
    },
    async saveSymbol() {
      this.dialogChangeSymbol = false;
      if (!this.symbol_id) return;

      const {body} = await this.api.ChartObject.associateWithSymbol(
          {id: this.baseObject.id},
          {symbol_id: this.symbol_id}
      );
      this.$store.commit("object/update", body);
      this.$emit("updateSymbol", body);
    },
    async createTemplate() {
      this.templateCreating = true;

      const canvas = await html2canvas(this.$refs.preview.$el);

      const {body} = await this.$http.post("vulcan/v1/uploads/image", {
        attachment: canvas.toDataURL("image/png")
      });

      const template = {
        name: this.templateName,
        settings: {
          objects: this.templateObjects,
          connections: this.templateConnections,
          group: this.settings,
          image: body.body.url
        }
      };
      await this.api.ChartTemplate.save({chart_template: template});
      this.templateName = "";

      this.templateCreating = false;
      this.dialogTemplate = false;
    }
  }
};
</script>

<style scoped lang="scss">
.hotkey {
  padding-left: 20px;
  opacity: 0.6;
}
</style>
