<template>
  <div>SUBMIT ON SERVER</div>
</template>

<script>
  import componentExtends from './_extends/Component';

  export default {
    name: 'Chatbot_RouterObjectPropertyEditor_SUBMIT_ON_SERVER',
    extends: componentExtends,
  }
</script>
