<template>
  <v-toolbar dense no-padding>
    <v-text-field
        v-if="isBetweenChatbotNodes"
        class="pa-0 mx-2"
        hide-details
        placeholder="Priority"
        type="number"
        v-model="localSettings.priority"
    />

    <v-divider vertical v-if="isBetweenChatbotNodes"/>

    <v-text-field
        v-if="localSettings.isDelay"
        class="pa-0 ma-0"
        placeholder="Delay, ms"
        hide-details
        type="number"
        v-model="localSettings.delay"
    />

    <v-divider vertical v-if="localSettings.isDelay"/>
    <v-text-field
        class="px-2 ma-0"
        placeholder="Behaviour delay, ms"
        hide-details
        type="number"
        v-model="localSettings.behaviourDelay"
    />
    <v-divider vertical v-if="localSettings.isBehaviourDelay"/>

    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text class="ma-0 toggle-btn" value="notes">Notes</v-btn>

      <v-divider vertical/>

      <v-btn text class="ma-0 toggle-btn" value="addons">Addons</v-btn>
      <v-divider vertical v-if="isBetweenChatbotNodes"/>
      <v-btn text class="ma-0 toggle-btn" value="behaviours" v-if="isBetweenChatbotNodes">Behaviours</v-btn>
    </v-btn-toggle>

    <v-divider vertical/>

    <AddonsIcons
        :baseObject="baseConnection"
        :tabs="tabs"
        :localSettings="localSettings"
        @change="onChangeAddonTab"
        :parentTab="openedData"
    />

    <v-divider v-if="tabs && tabs.length" vertical/>

    <v-btn @click="destroy()" class="toolbar-button" text>
      <v-icon>delete</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../Object/Types/_extends/ObjectPropertyEditor";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";
import AddonsList from "../_Global/AddonsList";
import Vue from "vue";

export default {
  name: "ConnectionPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  props: {
    baseConnection: Object,
    settings: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    const behavioursComponent = Vue.extend({
      template: `
        <OptionsList
            :dimension="2"
            v-model="settings.behavioursList"
            itemName="Behaviours"
            nameLabel="Animation"
            valueLabel="Duration"
            :readonly="readonly"
        />`,
      props: ["settings"]
    })


    return {
      behavioursComponent,
      openedData: "notes"
    };
  },

  methods: {
    save() {
      this.$store.dispatch("connection/update", {
        id: this.baseConnection.id,
        settings: this.localSettings
      });
    },
    destroy() {
      this.$store.dispatch("connection/delete", this.baseConnection.id);
      this.$root.$emit("PropertyEditor.close");
    },
    onOpenNotion() {
      this.localSettings = {notesLines: [], ...this.localSettings};
      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseConnection: this.baseConnection,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    },
    onOpenAddons() {
      this.localSettings = {addons: [], ...this.localSettings};
      this.$root.$emit("DataEditor.open", {
        component: AddonsList,
        props: {
          baseConnection: this.baseConnection,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'addons',
        title: "Addons"
      });
    },
    onOpenBehaviours() {
      this.localSettings = {behavioursList: this.localSettings.behavioursList ?  [...this.localSettings.behavioursList] : [], ...this.localSettings};
      this.$root.$emit("DataEditor.open", {
        component: this.behavioursComponent,
        props: {
          baseConnection: this.baseConnection,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'behaviours',
        title: "Behaviours"
      });
    },
    onChangeAddonTab() {
      this.openedData = null;
    }
  },

  computed: {
    isBetweenChatbotNodes() {
      let fromObject = this.$store.getters["object/findById"](
          this.baseConnection.from
      );
      let match = /Chatbot/;
      return fromObject && match.test(fromObject.type);
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case null:
          break;
        case "notes":
          this.onOpenNotion();
          break;
        case "addons":
          this.onOpenAddons();
          break;
        case "behaviours":
          this.onOpenBehaviours();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenNotion();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>

<style scoped>
.toggle-btn {
  opacity: 1;
  align-self: center
}
</style>
