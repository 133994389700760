<template>
  <div class="standard-border">
    <img class="image" :src="url" />
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_ImageObject",
  extends: ExtendsComponent,
  computed: {
    imageStyle() {
      return {
        backgroundImage: this.url
      };
    },
    url() {
      const { image } = this.localSettings;
      const { height, width } = this.baseObject.size || {
        height: 100,
        width: 100
      };
      let url;
      if (image) {
        if (width > 150 || height > 150) {
          url = image.body.url;
        } else {
          url = image.body.thumb.url;
        }
      }

      return url ? url : require("./image_placeholder.png");
    }
  }
};
</script>

<style scoped>
.standard-border {
  position: absolute;
  background: none;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
}
</style>