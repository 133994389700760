<template>
  <SignFormsWrapper :loading="loading" subtitle="Create your account" :signIn="false">
    <MuralAuth :signIn="false" />
    <v-divider class="mb-4"></v-divider>
    <v-form v-if="!finished" @submit.prevent="signUp">
      <v-text-field
        v-model="email"
        @input="onInputEmail"
        :error-messages="errorVisibleEmail"
        :disabled="loading"
        label="Email"
        type="email"
        required
        autofocus
      />
      <v-text-field
        v-model="password"
        @input="onInputPassword"
        :error-messages="errorVisiblePassword"
        :disabled="loading"
        label="Password"
        type="password"
        counter="32"
        required
      />
      <v-text-field
        v-model="passwordConfirm"
        @input="onInputPasswordConfirm"
        :error-messages="errorVisiblePasswordConfirm"
        :disabled="loading"
        label="Confirm Password"
        type="password"
        counter="32"
        required
      />
      <v-btn
        type="submit"
        :disabled="!dirtyForm || loading"
        class="mt-6"
        color="primary"
        large
        rounded
      >Sign Up</v-btn>
    </v-form>
    <div v-else>
      <p>To finish creation process you need to confirm your email.</p>
      <p>Instructions sent to your email.</p>
    </div>

    <template v-slot:footer>
      <div class="mt-8 text-center">
        <span v-if="!finished">Already a member? </span>
        <span v-else>Return to </span>
        <router-link class="weight-500" to="/sign-in">Sign In</router-link>
      </div>
      <div class="mt-5 text-center">
        By creating an account you agree to our Terms of Service
      </div>
      <div class="mt-16 text-center font-weight-bold">
        <img height="80" src="../img/mural-logo.svg" />
        <div>In association with <a href="https://mural.co" target="_blank">MURAL Labs</a></div>
      </div>
    </template>
  </SignFormsWrapper>
</template>

<script>
import SignFormsWrapper from "./SignFormsWrapper";
import MuralAuth from './MuralAuth';
export default {
  name: "TheSignUp",
  components: {
    SignFormsWrapper,
    MuralAuth  
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirm: '',

      errorVisibleEmail: null,
      errorVisiblePassword: null,
      errorVisiblePasswordConfirm: null,

      dirtyForm: false,
      loading: false,
      finished: false
    };
  },
  computed: {
    errorEmail() {
      if (!this.email)
        return 'Email can not be empty';
      if (!this.email.match(/.+@.+/))
        return 'Email must be valid';
      return null;
    },
    errorPassword() {
      if (!this.password)
        return 'Password can not be empty';
      if (this.password.length < 8 || this.password.length > 32)
        return 'Password shoud contain 8-32 characters';
      return null;
    },
    errorPasswordConfirm() {
      if (this.password != this.passwordConfirm)
        return 'The confirmation must match the password';
      return null;
    }
  },
  mounted() {
    window.analytics.page('Register');
  },
  methods: {
    onInputEmail() {
      this.errorVisibleEmail = null;
      this.dirtyForm = true;
    },
    onInputPassword() {
      this.errorVisiblePassword = null;
      this.dirtyForm = true;
    },
    onInputPasswordConfirm() {
      this.errorVisiblePasswordConfirm = null;
      this.dirtyForm = true;
    },

    signUp() {
      this.dirtyForm = false;
      if (this.errorEmail || this.errorPassword || this.errorPasswordConfirm) {
        this.errorVisibleEmail = this.errorEmail;
        this.errorVisiblePassword = this.errorPassword;
        this.errorVisiblePasswordConfirm = this.errorPasswordConfirm;
        return;
      }

      this.loading = true;
      this.api.User.register({
        user: {
          email: this.email,
          password: this.password
        }
      })
        .then(res => {
          this.loading = false;

          const token = res.body.access_token;
          this.$store.commit("user/setToken", token);

          this.finished = true;
        })
        .catch(error => {
          this.loading = false;
          if (!error)
            return;

          let message = '';
          error.forEach(_message => {
            message = message + _message + '<br>';
          });
          message = message.substr(0, message.length - 4);
          this.$root.$emit("showSnack", {message});
        });
    }
  }
};
</script>

<style>
</style>