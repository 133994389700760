<template>
  <v-card class="sub-toolbar py-2" dense>
    <v-tooltip
        class="p-3"
        right
        v-for="(item, index) in tools"
        :key="index"
    >
      <template v-slot:activator="{on}">
        <v-btn class="p-3 create-object-button" :class="{active: activeTool == item.component}" icon v-on="on"
               @click.stop="createNewObject(item.component)">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ item.name }}</span>
    </v-tooltip>
  </v-card>
</template>

<script>
import {defaultProperties, ObjectTypesByModule} from "../Object/Types";
import {subToolbarProps} from "../Utils";

export default {
  name: "SubToolbar",
  props: {
    moduleName: String,
    positioning: {
      type: Object,
      required: false,
      default: () => ({bottom: 0})
    }
  },
  data: function () {
    return {tools: this.getTools(this.moduleName), activeTool: null}
  },
  methods: {
    createNewObject(component) {
      this.$emit("createObjectFromSubToolbar", component);
      this.activeTool = component
      this.$store.dispatch('chart/setActiveTool', component, null)
    },
    getTools(moduleName) {
      const toolsObjects = ObjectTypesByModule[moduleName]
      return toolsObjects.map(t => ({icon: defaultProperties[t].icon, name: defaultProperties[t].name, component: t}))
    }
  },
  created() {
    this.unwatch = this.$store.watch((_, getters) =>
            getters['chart/objectMode'],
        (newValue) => {
          this.tools = this.getTools(subToolbarProps[newValue].moduleName)
        })

  },
  beforeDestroy() {
    this.unwatch()
  }
}
</script>

<style scoped>

.sub-toolbar {
  position: absolute;
  left: 55px;
  bottom: 0;
  z-index: 2000;
  width: 50px;
}

.active {
  background-color: pink;
}
</style>
