<template>
  <v-container>
    <v-row>
      <v-btn icon @click.native="close()">
        <v-icon>close</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn color="primary" small @click.native="submit()">
        Submit
      </v-btn>
    </v-row>
    <v-row class="mx-0">
      <v-col class="pa-0">
        <v-switch
          v-model="localSettings.audioSetting.isSpatial"
          :label="localSettings.audioSetting.isSpatial ? 'Spatial Audio Effect': 'Flat Audio Effect'"
        />
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      v-if="localSettings.audioSetting.isSpatial"
    >
      <v-col class="text-left">
        <span class="subheading font-weight-light mr-2">Spatial Audio Distance </span>
      </v-col>
      <v-col class="text-right">
        <span class="subheading font-weight-light" :color="color">
          {{localSettings.audioSetting.spatialDistance}} m
        </span>
      </v-col>
    </v-row>
    <v-row class="my-0" v-if="localSettings.audioSetting.isSpatial">
      <v-col class="py-0">
        <v-slider
          v-model="localSettings.audioSetting.spatialDistance"
          :color="color"
          track-color="grey"
          always-dirty
          min="1"
          max="20"
        >
          <template v-slot:prepend>
            <v-icon
              :color="color"
              @click="decrement"
            >
              mdi-minus
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon
              :color="color"
              @click="increment"
            >
              mdi-plus
            </v-icon>
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      v-if="localSettings.audioSetting.isSpatial"
    >
      <v-col class="text-left">
        <span class="subheading font-weight-light mr-2">Audio Volume </span>
      </v-col>
      <v-col class="text-right">
        <span class="subheading font-weight-light">
          {{localSettings.audioSetting.audioVolume}}
        </span>
      </v-col>
    </v-row>
    <v-row class="my-0" v-if="localSettings.audioSetting.isSpatial">
      <v-col class="py-0">
        <v-slider
          v-model="localSettings.audioSetting.audioVolume"
          :color="color"
          track-color="grey"
          always-dirty
          min="0"
          max="1"
          step="0.05"
        >
          <template v-slot:prepend>
            <v-icon
              :color="color"
              @click="decrementVolume"
            >
              mdi-minus
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon
              :color="color"
              @click="incrementVolume"
            >
              mdi-plus
            </v-icon>
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="3">
        <div class="v-subtitle-1">
          Audio          
        </div>
      </v-col>

      <v-col cols="9">
        <v-select
          v-model="localSettings.audioSetting.audioURL"
          :items="audioList"
          item-text="label"
          item-value="value"
          label="Suggested Audio"
          persistent-hint
          return-object
          single-line
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col class="pa-0">
        <div v-if="localSettings.audioSetting.customAudio">
          <Uploader
            class=""
            label="Custom Audio File"
            icon="image"
            v-model="localSettings.audioSetting.customAudio"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="mx-0 my-0">
      <v-col class="pa-0">
        <v-switch
          v-model="localSettings.audioSetting.loop"
          :label="localSettings.audioSetting.loop ? 'Loop': 'Play Once'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {copyToClipboard, deepClone} from "@/lib";
import Parse from 'parse';
const defaultAudioSetting = {
  enabled: true, 
  isSpatial: true, 
  spatialDistance: 1,
  spatialConstant: 5,
  audioURL: null,
  customAudio: null,
  loop: true,
  audioVolume: 0.5
};
const defaultAudioList = [
  { label: "Campfire", value: "campfire" },
  { label: "Campfire 1", value: "campfire1" },
  { label: "Campfire 2", value: "campfire2" },
  { label: "Children", value: "children" },
  { label: "Office Ambience 1", value: "office-ambience-1" },
  { label: "Office Ambience", value: "office-ambience" },
  { label: "Stock Exchange", value: "stock-exchange" },
  { label: "Swimming Pool", value: "swimming-pool" },
  { label: "Swimming Pool 1", value: "swimming-pool1" },
  { label: "Swimming Pool 2", value: "swimming-pool2" },
];


export default {
  name: "ObjectAudioSettingsModal",
  props: {
    settings: Object,
    baseObjectId: String
  },
  data() {
    const localSettings = deepClone(this.settings || {});
    localSettings.audioSetting = {...defaultAudioSetting, ...localSettings.audioSetting};
    return {
      localSettings,
      audioList: defaultAudioList
    };
  },
  computed: {
    ...mapState({
      hifiStatus: state => state.audio.hifiStatus,
      hifiCommunicator: state => state.audio.hifiCommunicator
    }),
    ...mapGetters({
      spaceList: "object/spaceList",
    }),
    color () {
      if (this.localSettings.audioSetting.spatialDistance < 2)  return 'indigo';
      if (this.localSettings.audioSetting.spatialDistance < 5)  return 'teal';
      if (this.localSettings.audioSetting.spatialDistance < 10) return 'green';
      if (this.localSettings.audioSetting.spatialDistance < 15) return 'orange';
      return 'red';
    },
    baseObject() {
      return this.$store.getters["object/findById"](this.baseObjectId);
    }
  },
  methods: {
    async submit() {
      if (!this.localSettings.audioSetting.audioURL) {
        alert("Please select audio file first.");
        return;
      }

      this.localSettings.audioSetting.url = `https://vulcan-marketing.s3.eu-west-2.amazonaws.com/vulcan-audio/${this.localSettings.audioSetting.audioURL.value}.mp3`;
      const spaceId = this.detectSpaceId();
      if (spaceId === this.hifiStatus) {
        const spaceName = this.activeChart.name;
      }

      this.$store.dispatch("object/update", {
        id: this.baseObjectId,
        info: {
          ...this.baseObject.info,
          settings: {
            ...this.baseObject.info.settings,
            ...this.localSettings,
          }
        }
      });

      this.$store.dispatch("audio/playAudioBoxesWithinSpace", spaceId);

      this.close();
    },
    async close() {
      // await Parse.Cloud.run("stopAudioBox", { vulcanSpaceId: spaceId, spaceName, objectId: 'DJ Bot' });
      // await this.hifiCommunicator.disconnectFromHiFiAudioAPIServer();
      this.$root.$emit("DataEditor.close");
    },
    decrement() {
      this.localSettings.audioSetting.spatialDistance--;
    },
    increment() {
      this.localSettings.audioSetting.spatialDistance++;
    },
    decrementVolume() {
      this.localSettings.audioSetting.audioVolume -= 0.1;
    },
    incrementVolume() {
      this.localSettings.audioSetting.audioVolume += 0.1;
    },
    detectSpaceId() {
      if (!this.spaceList || this.spaceList.length < 1) return this.activeChart.id;
      for (const space of this.spaceList) {
        if (
          (this.baseObject.position.x > space.position.x) && 
          (this.baseObject.position.y > space.position.y) && 
          (this.baseObject.position.x < space.position.x + space.size.width) && 
          (this.baseObject.position.y < space.position.y + space.size.height)
         ) {
           return space.id;
         }
      }
      return this.activeChart.id;
    }
  }
};
</script>

<style scoped>
.sharing-options-wrap {
  background: #2c2c54;
  color: #FFF;
}

.sharing-options-header {
  padding: 20px;
  font-size: 24px;
}

.sharing-options-content {
  padding: 20px;
}

.embed-snippet {
  font-family: monospace !important;
}

.copy-btn {
  font-family: "Roboto", sans-serif !important;
}

.embed-switch {
  max-width: 60px;
}
</style>
