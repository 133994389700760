<template>
  <div class="arrow-object">
    <div class="arrow-object__start" ref="start" :style="startStyles" />
    <div class="arrow-object__end" ref="end" :style="endStyles" />
    <div class="arrow-object__container" :style="containerStyles">
      <div class="arrow-object__svg" :class="readonly ? 'arrow-object__svg_readonly' : ''">
        <svg width="100%" height="100%">
          <defs>
            <marker
              v-if="settings.arrow === 'Start' || settings.arrow === 'Both'"
              :id="'arrow_start' + _uid"
              viewBox="0 -6 10 12"
              refX="3"
              refY="0"
              markerWidth="6"
              markerHeight="6"
              orient="auto"
            >
              <path
                class="arrow-marker"
                :fill="settings.color || '#605da5'"
                d="M10,-5L0,0L10,5"
              />
            </marker>
            <marker
              v-if="settings.arrow === 'End' || settings.arrow === 'Both'"
              :id="'arrow_end' + _uid"
              viewBox="0 -6 10 12"
              refX="7"
              refY="0"
              markerWidth="6"
              markerHeight="6"
              orient="auto"
            >
              <path
                class="arrow-marker"
                :fill="settings.color || '#605da5'"
                d="M0,-5L10,0L0,5"
              />
            </marker>
          </defs>
          <line
            :x1="x1"
            :y1="y1"
            :x2="x2"
            :y2="y2"
            :stroke="settings.color || '#605da5'"
            :stroke-width="settings.strokeWidth || 3"
            :stroke-linecap="settings.strokeLineCap || 'square'"
            :stroke-dasharray="lineStyles(settings.lineStyle)"
            :marker-start="`url(#${'arrow_start' + _uid})`"
            :marker-end="`url(#${'arrow_end' + _uid})`"
            class="arrow-line"
          />
          <line
            :x1="x1"
            :y1="y1"
            :x2="x2"
            :y2="y2"
            stroke="transparent"
            stroke-width="7"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_ArrowObject",
  extends: ExtendsComponent,
  props: {
    position: Object,
    baseObject: Object
  },
  data() {
    return {
      startX: this.settings.startX || 0,
      startY: this.settings.startY || 0,
      endX: this.settings.endX || 100,
      endY: this.settings.endY || 0,
      targetObject: null
    };
  },
  mounted() {
    this.start = d3.select(this.$refs.start);
    this.end = d3.select(this.$refs.end);

    if (this.readonly)
      return;
    this.start.call(this.dragHandlerStart());
    this.end.call(this.dragHandlerEnd());
  },
  watch: {
    readonly: {
      handler(val) {
        if (val) {
          this.start.on(".drag", null);
          this.end.on(".drag", null);
        } else {
          this.start.call(this.dragHandlerStart());
          this.end.call(this.dragHandlerEnd());
        }
      }
    },
    settings: {
      handler(val) {
        this.startX = val.startX || 0;
        this.startY = val.startY || 0;
        this.endX = val.endX || 100;
        this.endY = val.endY || 0;
      }
    }
  },
  computed: {
    containerStyles() {
      const dwidth = this.endX - this.startX,
        dheight = this.endY - this.startY;

      let x = this.startX < this.endX ? this.startX : this.endX,
        y = this.startY < this.endY ? this.startY : this.endY;

      let width = Math.abs(dwidth),
        height = Math.abs(dheight);

      if (dwidth <= 0) {
        x -= 10;
        width += 20;
      }

      height += 10;

      this.x1 = dwidth > 0 ? 15 : width + 5;
      this.y1 = dheight > 0 ? 15 : height + 5;
      this.x2 = dwidth > 0 ? width + 5 : 15;
      this.y2 = dheight > 0 ? height + 5 : 15;

      return {
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${x}px, ${y}px)`
      };
    },
    parentObjectForArrow() {
      return this.$store.getters["object/list"].find(object => object.info.settings.arrowIds && object.info.settings.arrowIds.includes(this.id));
    },
    startStyles() {
      return {
        transform: `translate(${this.startX}px, ${this.startY}px)`,
        cursor: this.readonly ? 'auto' : 'pointer'
      };
    },
    endStyles() {
      return {
        transform: `translate(${this.endX}px, ${this.endY}px)`,
        cursor: this.readonly ? 'auto' : 'pointer'
      };
    },
    scale() {
      return this.$store.getters["chart/scale"];
    }
  },
  methods: {
    dragHandlerStart() {
      let initialEvent, initialPosition;

      const dragHandler = d3
        .drag()
        .on("start", () => {
          initialEvent = d3.event;
          initialPosition = { x: this.baseObject.position.x, y: this.baseObject.position.y, endX: this.endX, endY: this.endY };
          this.startX = 0;
          this.startY = 0;
        })
        .on("drag", () => {

          let target, targetObjectNode;
          if (d3.event.sourceEvent.type == "touchmove") {
            const location = d3.event.sourceEvent.changedTouches[0];
            targetObjectNode = document
              .elementFromPoint(location.clientX, location.clientY)
              .closest("[data-object-id]");
          } else {
            target = d3.event.sourceEvent.target;
            targetObjectNode =
              target && target.closest
                ? target.closest("[data-object-id]")
                : null;
          }

          // Attaching and detaching effort
          if (targetObjectNode) {
            const targetObjectId = targetObjectNode.dataset.objectId;
            // Only when an ANCHORABLE arrow start drops INSIDE the object
            if (targetObjectId != this.baseObject.id) {
              const targetObject = this.$store.getters["object/findById"](
                targetObjectId
              );

              if (!targetObject.info.settings.locked && targetObject.type.includes("Wayfinder") == false ) 
                this.$store.commit("object/setHighlight", targetObjectId);
            }
          } else {
            // When arrow start end doesn't fall into any object, remove highlighted
            const highlightedId = this.$store.getters["object/highlightedId"];
            if (highlightedId) {
              this.$store.commit("object/setHighlight", null);           
              this.$store.dispatch("object/removeArrowFromObject", {
                objectId: highlightedId,
                arrowId: this.baseObject.id
              });
            }
          }

          // When we move the starting point, we update the position of the arrow and end point. We want to keep starting point always zero!
          const offsetX = (d3.event.sourceEvent.screenX - initialEvent.sourceEvent.screenX) / this.scale;
          const offsetY = (d3.event.sourceEvent.screenY - initialEvent.sourceEvent.screenY) / this.scale;
          const x = Math.round(offsetX + initialPosition.x);
          const y = Math.round(offsetY + initialPosition.y);

          this.$store.dispatch("object/update", {
            id: this.baseObject.id,
            position: { x, y }
          });

          this.endX = Math.round(-offsetX + initialPosition.endX);
          this.endY = Math.round(-offsetY + initialPosition.endY);
        })
        .on("end", () => {
          const highlightedId = this.$store.getters["object/highlightedId"];
          const highlightedObject = this.$store.getters["object/findById"](
            highlightedId
          );
          
          if (highlightedObject && !highlightedObject.info.settings.locked) {
            this.$store.dispatch("object/addArrowToObject", {
              objectId: highlightedId,
              arrowId: this.baseObject.id,
              which: 'start'
            });
            
            const offsetX = (d3.event.sourceEvent.screenX - initialEvent.sourceEvent.screenX) / this.scale;
            const offsetY = (d3.event.sourceEvent.screenY - initialEvent.sourceEvent.screenY) / this.scale;
            const x = Math.round(offsetX + initialPosition.x);
            const y = Math.round(offsetY + initialPosition.y);
            
            this.endX = Math.round(initialPosition.endX - offsetX);
            this.endY = Math.round(initialPosition.endY - offsetY);
            const settings = {
              ...this.settings,
              startX: this.startX,
              startY: this.startY,
              endX: this.endX,
              endY: this.endY,
              startObject: highlightedId
            };
            this.$emit("update:settings", settings);

            this.$store.dispatch("object/update", {
              id: this.baseObject.id,
              position: {x, y}
            });
          }
          this.$store.commit("object/setHighlight", null);
        });
      return dragHandler;
    },
    dragHandlerEnd() {
      let initialEvent, initialPosition;

      const dragHandler = d3
        .drag()
        .on("start", () => {
          initialEvent = d3.event;
          initialPosition = { endX: this.endX, endY: this.endY };
        })
        .on("drag", () => {
          this.targetObject = null;
          this.endX =
            Math.round((d3.event.x - initialEvent.x) / this.scale + initialPosition.endX);
          this.endY =
            Math.round((d3.event.y - initialEvent.y) / this.scale + initialPosition.endY);

          const settings = {
            ...this.settings,
            endX: this.endX,
            endY: this.endY
          };

          let target, targetObjectNode;
          if (d3.event.sourceEvent.type == "touchmove") {
            const location = d3.event.sourceEvent.changedTouches[0];
            targetObjectNode = document
              .elementFromPoint(location.clientX, location.clientY)
              .closest("[data-object-id]");
          } else {
            target = d3.event.sourceEvent.target;
            targetObjectNode =
              target && target.closest
                ? target.closest("[data-object-id]")
                : null;
          }

          if (targetObjectNode) {
            const targetObjectId = targetObjectNode.dataset.objectId;
            // Only when an ANCHORABLE arrow start drops INSIDE the object
            if (targetObjectId != this.baseObject.id) {
              const targetObject = this.$store.getters["object/findById"](
                targetObjectId
              );
              if (!targetObject.info.settings.locked && targetObject.type.includes("Wayfinder") == false ) 
                this.$store.commit("object/setHighlight", targetObjectId);
            }
          } else {
            // When arrow start end doesn't fall into any object, remove highlighted
            const highlightedId = this.$store.getters["object/highlightedId"];
            if (highlightedId) {
              this.$store.commit("object/setHighlight", null);           
              this.$store.dispatch("object/removeArrowFromObject", {
                objectId: highlightedId,
                arrowId: this.baseObject.id
              });
            }
          }

          this.$emit("update:settings", settings);
        })
        .on("end", () => {
          const highlightedId = this.$store.getters["object/highlightedId"];
          const highlightedObject = this.$store.getters["object/findById"](
            highlightedId
          );
          
          if (!highlightedObject || !highlightedObject.info.settings.locked) {
            this.$store.dispatch("object/addArrowToObject", {
              objectId: highlightedId,
              arrowId: this.baseObject.id,
              which: 'end'
            });
            
            this.endX =
              Math.round((d3.event.x - initialEvent.x) / this.scale + initialPosition.endX);
            this.endY =
              Math.round((d3.event.y - initialEvent.y) / this.scale + initialPosition.endY);
            
            const settings = {
              ...this.settings,
              endX: this.endX,
              endY: this.endY,
              endObject: highlightedId
            };
            this.$emit("update:settings", settings);
          }

          this.$store.commit("object/setHighlight", null);
        });
      return dragHandler;
    },
    lineStyles(setting) {
      switch (setting) {
        case 'Dashed':  return "10,10";
        case 'Dotted':  return "5,5";
        case 'Solid':
        default:        return "0";
      }
    }
  }
};
</script>

<style scoped>
.arrow-object {
  position: relative;
  /* background: red; */
  width: 0;
  pointer-events: none;
}
.arrow-object__start,
.arrow-object__end {
  height: 10px;
  width: 10px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  pointer-events: auto;
}

.arrow-object__container {
  position: absolute;
  /* background: red; */
  left: 0;
  top: 0;
}

.arrow-object__start {
  /* background: yellow; */
  left: 0;
}

.arrow-object__end {
  /* background: green; */
  right: 0;
}

.arrow-object__svg {
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
}
.arrow-object__svg >>> line {
  cursor: move;
  pointer-events: auto;
}
.arrow-object__svg_readonly >>> line {
  cursor: auto;
  pointer-events: auto;
}
</style>

<style>
.Base_ArrowObject > .base-object__content {
  height: 100%;
}

.Base_ArrowObject .object-actions {
  display: none !important;
}

.base-object.Base_ArrowObject.selected .base-object__content > div {
  box-shadow: none;
}

.base-object.Base_ArrowObject.selected .arrow-line,
.base-object.Base_ArrowObject.selected .arrow-marker {
  fill: red !important;
  stroke: red !important;
}
</style>
