import moment from "moment";

const isInactiveCursor = (dateNow, dateCursor) => {
  const now = moment(dateNow);
  const end = moment(dateCursor);
  const duration = moment.duration(now.diff(end));

  return duration.asMinutes() >= 5;
};

const generateColor = str => {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return "#" + "00000".substring(0, 6 - c.length) + c;
};

const generateOpacityColor = (str, opacity) => {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();
  
   const hex = Math.round(opacity * 255);
   let hexOpacity = hex.toString(16);
  if (hexOpacity.length == 1)
      hexOpacity = "0" + hexOpacity;
  return "#" + hexOpacity + "00000".substring(0, 6 - c.length) + c;
};

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const indexBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    rv[x[key]] = x;
    return rv;
  }, {});
};

const walk = function(el, fn) {
  for (let i = 0, len = el.childNodes.length; i < len; i++) {
    const node = el.childNodes[i];
    if (node.nodeType === 3)
      fn(node);
    else if (node.nodeType === 1 && node.nodeName !== "SCRIPT")
      walk(node, fn);
  }
};

const guid = function() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

const fallbackCopyTextToClipboard = str => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

const copyToClipboard = async text => {
  try {
    return await navigator.clipboard.writeText(text);
  } catch (e) {
    fallbackCopyTextToClipboard(text);
  }
}

const deepClone = function(object) {
  return JSON.parse(JSON.stringify(object || {}));
};

const isMac = () => navigator.platform.toUpperCase().indexOf('MAC') >= 0;

const getDayEnd = day => {
  if (day == 1 || day == 21 || day == 31)
    return "st";
  else if  (day == 2 || day == 22)
    return "nd";
  else if (day == 3 || day == 23)
    return "rd";
  return "th";
};

const parseDate = dateStr => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const date = new Date(dateStr);
  const year = date.getUTCFullYear(),
    day = date.getDate(),
    month = months[date.getUTCMonth()];

  const dayEnd = getDayEnd(day);

  if (year && day && month)
    return `${day}${dayEnd} ${month} ${year}`;
  return "";
};

const parseTimestamp = timeStr => {
  try {
    let lastIndColon = timeStr.lastIndexOf(':');
    if (lastIndColon == -1)
      return parseInt(timeStr);

    const seconds = parseInt(timeStr.substr(lastIndColon + 1));
    let newStr = timeStr.substring(0, lastIndColon);
    lastIndColon = newStr.lastIndexOf(':');
    if (lastIndColon == -1) {
      const minutes = parseInt(newStr);
      return minutes * 60 + seconds;
    }

    const minutes = parseInt(newStr.substr(lastIndColon + 1));
    const hours = parseInt(newStr.substring(0, lastIndColon));
    return hours * 3600 + minutes * 60 + seconds;

  } catch (e) {
    return null;
  }
};

const secToTimestamp = seconds => {
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  if (seconds < 10)
    seconds = '0' + seconds;

  const hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (minutes < 10)
    minutes = '0' + minutes;

  if (hours > 0)
    return `${hours}:${minutes}:${seconds}`;
  return `${minutes}:${seconds}`;
}

const timeout = delay => {
  return new Promise(resolve => setTimeout(resolve, delay));
};



const HFSA_STATUS_CONNECTED = 1;
const HFSA_STATUS_CONNECTING = -1;
const HFSA_STATUS_DISCONNECTED = 0;
const HFSA_MAPPING_COEF = 100;
const HFSA_THRESHOLD_DEFAULT = -96;

const HFSA_MIN_VOLUME_DIFFERENCE = 0.008;
const HFSA_MULTIPLY_VOLUME_FACTOR = 12;
const HFSA_MAX_VOLUME_VALUE = 5;


export {
  groupBy,
  indexBy,
  guid,
  deepClone,
  walk,
  generateColor,
  generateOpacityColor,
  isInactiveCursor,
  copyToClipboard,
  isMac,
  getDayEnd,
  parseDate,
  parseTimestamp,
  secToTimestamp,
  timeout,

  HFSA_STATUS_CONNECTED,
  HFSA_STATUS_CONNECTING,
  HFSA_STATUS_DISCONNECTED,
  HFSA_MAPPING_COEF,
  HFSA_THRESHOLD_DEFAULT,
  HFSA_MIN_VOLUME_DIFFERENCE,
  HFSA_MULTIPLY_VOLUME_FACTOR,
  HFSA_MAX_VOLUME_VALUE
};
