<template>
  <v-layout justify-center>
    <v-flex xs8>
      <v-toolbar>
        <v-toolbar-title>Cards</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openNewCardModal">Add Card</v-btn>
      </v-toolbar>
      <v-card>
        <v-container fluid grid-list-md>
          <v-data-table :headers="headers" :items="cards" :search="search">
            <template v-slot:item.exp_date="{ item }">
              {{ item.exp_year }}-{{ item.exp_month }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon v-if="item.id==default_payment">star</v-icon>
              <v-icon v-if="item.id!=default_payment" @click="makeDefaultPaymentMethod(item)">star_border</v-icon>
              <v-icon @click="openEditCardModal(item)" class="member-role-edit">edit</v-icon>
              <v-icon small @click="deleteCard(item.id)">delete</v-icon>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert>
          </v-data-table>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import CardModal from "./CardModal";

export default {
  name: "Cards",
  data() {
    return {
      headers: [
        {
          text: "Card Number",
          align: "left",
          sortable: true,
          value: "last4"
        },
        {
          text: "Expire Date",
          align: "left",
          sortable: true,
          value: "exp_date"
        },
        {
          text: "CVC Check",
          align: "left",
          sortable: false,
          value: "cvc_check"
        },
        {
          sortable: false,
          value: "action"
        }
      ],
      cards: [],
      organisation: null,
      customer: null,
      search: "",
      default_payment: null
    };
  },
  created() {
    this.fetchOrganisation().then(() => {
      this.fetchCards();
      this.getCustomer();
    });
  },
  methods: {
    fetchOrganisation() {
      return this.api.Organisation.getCurrent().then(response => {
        this.organisation = response.body;
      });
    },
    getCustomer() {
      this.api.Organisation.getCustomer({ organisation_id: this.organisation.id }).then(res => {
        this.customer = res.body.customer;
        this.default_payment = this.customer ? this.customer.invoice_settings.default_payment_method : null;
      });
    },
    fetchCards() {
      this.api.Organisation.getAllCards({ organisation_id: this.organisation.id }).then(res => {
        this.cards = res.body.data;
      }, (error) => {
        console.log("Encountered error while fetching organisation cards", error);
      });
    },
    deleteCard(id) {
      if (confirm("Are you sure?")) {
        this.api.Organisation.deleteCard({ organisation_id: this.organisation.id, id }).then(() => this.fetchCards());
      }
    },
    openNewCardModal() {
      this.$modal.show(CardModal, {
        organisation: this.organisation,
        card: {},
        callback: () => {
          this.fetchCards();
        }
      });
    },
    openEditCardModal(card) {
      this.$modal.show(CardModal, {
        organisation: this.organisation,
        card,
        customer: this.customer,
        callback: () => {
          this.fetchCards();
        }
      });
    },
    makeDefaultPaymentMethod(card) {
      this.api.Organisation.setDefaultPaymentMethod({ organisation_id: this.organisation.id }, { payment_method_id: card.id })
        .then(response => {
          this.default_payment = response.body.invoice_settings.default_payment_method;
        });
    }
  }
};
</script>
