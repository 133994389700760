const store = {
  namespaced: true,
  state: {
    currentState: null,
    Model: {}
  },
  mutations: {
    setCurrentState (state, currentState) {
      state.currentState = currentState
    },
    setModel (state, Model) {
      state.Model = { ...state.Model, ...Model}
    },
    resetModel (state) {
      state.Model = {}
    }
  },
  getters: {
    currentState (state) {
      return state.currentState
    },
    Model (state) {
      return state.Model
    }
  }
}

export default store