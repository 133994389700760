<template>
  <div
      :data-object-id="id"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="[{ selected, resizable, highlighted , grouped: inGroups  }, type]"
      @click.stop="setSelected"
      @dblclick.stop="selectGrouped"
      class="base-object"
      :style="objectStyles"
  >
    <ObjectOut :objectId="id" v-if="defaultProperties.has.out && !readonlyOrLocked"/>
    <ObjectIn
        :objectId="id"
        v-if="defaultProperties.has.in && inConnections.length && !readonlyOrLocked"
    />
    <ObjectResizer
        @transform="resizeHandler"
        :width="localSize.width"
        :height="localSize.height"
        :position="localPosition"
        v-if="selected && resizable && !readonlyOrLocked && !isObjectPicker"
    />
    <ObjectCollaborators :collaborators="collaborators"/>
    <div class="animate__animated base-object__content" :class="[animation]" ref="componentOuter">
      <component
          :is="
          type == 'Base_SymbolObject' && baseObject.symbol
            ? baseObject.symbol.type
            : type
        "
          @destroy="destroy"
          v-bind.sync="localInfo"
          :baseObject="baseObject"
          :position="localPosition"
          :selected="selected"
          :readonly="readonlyOrLocked"
      />
      <div class="notes-count"
           v-if="localInfo.settings.notionEnabled || (localInfo.settings.notesLines && localInfo.settings.notesLines.length > 0)">
        <div class="notes-progress" :style="notesCountProgress"></div>
      </div>
    </div>
    <div
        class="rotate"
        ref="rotate-handle"
        v-show="selected && rotateAllowed"
        v-if="!readonlyOrLocked && !isObjectPicker"
        @dblclick="rotateReset"
    >
      <v-icon color="#619ecc" large>mdi-cached</v-icon>
    </div>
    <Base_TextObject
        v-if="defaultProperties.showLabel && chart_id"
        modelName="title"
        position="bottom"
        :placeholder="defaultProperties.name"
        :settings.sync="localInfo.settings"
        :baseObject="baseObject"
        :readonly="readonlyOrLocked"
        dark
    />
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import _ from 'lodash'
import * as d3 from "d3";
import ObjectResizer from "./Object/ObjectResizer";
import ObjectCollaborators from "./Object/ObjectCollaborators";
import ObjectIn from "./Object/ObjectIn";
import ObjectOut from "./Object/ObjectOut";
import {deepClone} from "@/lib";
import {
  getAbsoluteDistance, getHandleCoord, getQuarter,
  screenToVulcan, setQuarterCoordSigns,
  vulcanToScreen
} from '../../../../utils/common'
import {HEAER_HEIGHT, objectModeTypes} from "../../../../utils/const";

import {
  ObjectComponents,
  ObjectPropertyEditors,
  defaultProperties
} from "./Object/Types";
import {getWidgetScreenCenter} from "../../../../utils/object";


export default {
  name: "BaseObject",
  props: {
    id: [Number, String],
    readonly: Boolean,
    type: String,
    symbol: Object,
    skipSideEffect: Boolean,
    position: {
      type: Object,
      default() {
        return {x: 0, y: 0};
      }
    },
    size: {
      type: Object,
      default() {
        const {width, height} = defaultProperties[this.type].size;
        return {
          width,
          height
        };
      }
    },
    created_at: String,
    chart_id: [Number, String],
    info: Object,
    openPropertyEditorAfterCreate: Boolean,
    _animation: String
  },
  components: {
    ObjectResizer,
    ObjectIn,
    ObjectOut,
    ObjectCollaborators,
    ...ObjectComponents
  },
  created() {
    this.setLayout();
  },
  mounted() {
    this.d3BaseObject = d3.select(this.$el);

    if (this.defaultProperties.draggable && !this.readonlyOrLocked) {
      this.d3BaseObject.call(this.dragHandler());
    }

    if (this.openPropertyEditorAfterCreate) {
      this.openPropertyEditor();
    }

    this.updateSize();

    if (this.isGroup() && this.currentGroupedElms) {
      // put group behind
      this.$store.dispatch("object/orderBack", this.id);
    }
  },
  data() {
    const angle = (this.info.settings && this.info.settings.angle) ? this.info.settings.angle : 0;
    return {
      defaultProperties: defaultProperties[this.type],
      localPosition: {x: this.position.x, y: this.position.y},
      localSize: deepClone(this.size),
      localInfo: deepClone(this.info),
      hover: false,
      collaborators: [],
      angle,
      layout: null
    };
  },
  watch: {
    readonlyOrLocked: {
      handler(val) {
        if (!this.defaultProperties.draggable) {
          return;
        }

        if (val)
          this.d3BaseObject.on(".drag", null);
        else
          this.d3BaseObject.call(this.dragHandler());
      }
    },
    /*    'activeChart.users_cursors': {
          deep: true,
          handler() {
            this.collaborators = [];
            this.activeChart.users_cursors.forEach(uc => {
              if (uc.params.selected && uc.params.selected.includes(this.id)) {
                this.collaborators.push(uc);
              }
            });
          }
        },*/
    position: {
      handler() {
        this.localPosition = {x: this.position.x, y: this.position.y};
      },
      deep: true
    },
    size: {
      handler() {
        this.localSize = deepClone(this.size);
      },
      deep: true
    },
    info: {
      handler() {
        this.angle = (this.info.settings && this.info.settings.angle) ? this.info.settings.angle : 0;
        if (JSON.stringify(this.localInfo) != JSON.stringify(this.info)) {
          this.localInfo = deepClone(this.info);
          this.updateSize();
        }
      },
      deep: true
    },
    localInfo: {
      handler: _.debounce( function() {
        if (JSON.stringify(this.localInfo) != JSON.stringify(this.info)) {
          this.save();
          this.updateSize();
        }
      }, 700),
      deep: true
    },
    selected: {
      handler() {
        this.d3RotateHandle = d3.select(this.$refs["rotate-handle"]);
        if (this.selected) {
          if (!this.readonlyOrLocked) // Should consider to add rotatable
            this.d3RotateHandle.call(this.dragRotateHandler());
        } else {
          this.d3RotateHandle.on(".drag", null);
        }
      }
    },
    inGroups: {
      handler() {
        this.setLayout();
      }
    }
  },
  computed: {
    ...mapGetters({
      objectMode: 'chart/objectMode',
      specialKey: 'chart/specialKey',
    }),
    readonlyOrLocked() {
      let isForeignAudio = false;
      const belongsToViewOnlyMode = this.objectMode === objectModeTypes.Shape || this.objectMode === objectModeTypes.Drawing

      if (this.info.settings?.username)
        isForeignAudio = this.info.settings?.username != this.currentUser.username;
      else if (this.info.settings?.userId)
        isForeignAudio = this.info.settings?.userId != this.currentUser.username;

      return (
          belongsToViewOnlyMode  ||
          this.readonly ||
          this.info.settings?.locked ||
          !!this.collaborators.length ||
          isForeignAudio
      );
    },
    isObjectPicker() {
      return this.type === "Core_ObjectPicker";
    },
    groups() {
      return this.$store.getters["object/list"].filter(
          object =>
              this.isGroup(object) &&
              object.info.settings.objectIds.includes(this.id)
      );
    },
    inGroups() {
      return Boolean(this.groups.length > 0 && this.groups[0].info);
    },
    currentGroupedElms() {
      let currentElms = []
      if (this.isGroup()) {
        const children = this.localInfo.settings.objectIds
        children.map(id => {
          const object = this.$store.getters['object/findById'](id)
          currentElms = [...currentElms, object]
        })
      }
      return currentElms
    },
    comments() {
      return this.$store.getters["object/comments"](this);
    },
    transformOrigin() {
      return this.$store.getters['object/transformOrigin'](this);
    },
    arrows() {
      if (!this.localInfo.settings.arrows && !this.info.settings.arrows)
        return [];
      return this.$store.getters["object/arrows"](this);
    },
    ...mapState({
      scale: state => state.chart.scale,
    }),
    objectStyles() {
      const {
        width,
        height,
        maxWidth,
        maxHeight,
        minWidth,
        minHeight
      } = this.defaultProperties.size;

      let zIndex = this.info.settings.order;
      if (this.defaultProperties.alwaysOnBottom)
        zIndex = -1;
      if (this.defaultProperties.alwaysOnTop)
        zIndex = 999999;

      return {
        transform: `translate(${this.localPosition.x}px, ${this.localPosition.y}px) rotate(${this.angle}deg)`,
        transformOrigin: this.localInfo.settings.transformOrigin || 'center',
        width: `${this.localSize.width || width}px`,
        height: `${this.localSize.height || height}px`,
        minWidth: `${minWidth || 0}px`,
        minHeight: `${minHeight || 0}px`,
        maxWidth: `${maxWidth || 9999}px`,
        maxHeight: `${maxHeight || 9999}px`,
        zIndex
      };
    },
    selected() {
      return this.$store.getters["object/selectedIds"].includes(this.id);
    },
    highlighted() {
      return this.$store.getters["object/highlightedId"] == this.id;
    },
    baseObject() {
      return {
        id: this.id,
        type: this.type,
        chart_id: this.chart_id,
        created_at: this.created_at,
        selected: this.selected,
        position: this.localPosition,
        size: this.localSize,
        layout: this.layout,
        info: this.localInfo,
        symbol: this.symbol
      };
    },
    inConnections() {
      return this.$store.getters["connection/find"]({to: this.id});
    },
    animation() {
      if (this._animation)
        return `animate__` + this._animation;
      return null;
    },
    notesCountProgress() {
      const MAX_NOTES_COUNT = 20;
      const notesCountProgress = this.localInfo.settings.notesLines ? Math.min(100, this.localInfo.settings.notesLines.length / MAX_NOTES_COUNT * 100) : 0;
      return {height: notesCountProgress + '%'};
    },
    currentGridStep() {
      const gridSize = this.activeChart.options.gridSize;
      let scaleFactor = 60; // default: "Medium"
      if (gridSize === "Small") scaleFactor = 20;
      if (gridSize === "Large") scaleFactor = 120;
      return scaleFactor;
    },
    rotateAllowed() {
      return (this.type != 'Base_ArrowObject' && this.type != 'Base_CommentObject');
    },
    customComponent() {
      return this.$store.getters['component/findById'](this.localInfo.settings.componentId);
    },
    resizable() {
      if (this.customComponent)
        return !!this.customComponent.object_settings.resizing_enabled;
      return this.defaultProperties.resizable;
    }
  },
  methods: {
    save() {
      this.$store.dispatch(
          "object/update",
          {
            id: this.id,
            info: deepClone(this.localInfo)
          }
      );
    },
    isGroup(object) {
      if (!object) return this.type === "Base_GroupObject"
      return object.type === "Base_GroupObject"
    },
    async updateSize() {
      if (this.skipSideEffect)
        return;
      await this.$nextTick();
      if (!this.$refs.component && !this.$refs.componentOuter)
        return;

      const defaultWidth = this.defaultProperties.size.width,
          defaultHeight = this.defaultProperties.size.height;
      let {width, height} = this.size;
      if (defaultWidth == "auto")
        width = this.$refs.componentOuter.scrollWidth;
      if (defaultHeight == "auto")
        height = this.$refs.componentOuter.scrollHeight;

      if (width != this.size.width || height != this.size.height) {
        this.$store.dispatch("object/update", {
          id: this.id,
          size: {
            width,
            height
          },
          position: this.position
        });
      }

      if (this.layout) {
        this.$store.commit("object/update", {
          id: this.id,
          layout: this.layout
        });
      }
    },
    selectGrouped(event) {
      if (this.inGroups) {
        this.$store.commit("object/select", this.id);
      }
    },
    setSelected(event) {
      if (this.defaultProperties.openPropertyEditorByClick) {
        this.openPropertyEditor();
      } else if (!this.selected) {
        this.$root.$emit("PropertyEditor.close");
      }

      const isViewOnlyMode = this.objectMode === objectModeTypes.Shape || this.objectMode === objectModeTypes.Drawing

      if (this.inGroups) {
        return
      }

      if (this.readonly || isViewOnlyMode)
        return;

      const isAncComment = this.type == "Base_CommentObject" && this.localInfo.settings.parent_id;

      if (!event.metaKey && !event.ctrlKey && !event.shiftKey) {
        this.$store.commit("object/deselectAll");
        if (!isAncComment)
          this.$store.commit("object/select", this.id);
      } else if (this.selected) {
        this.$store.commit("object/deselect", this.id);
      } else {
        if (!isAncComment)
          this.$store.commit("object/select", this.id);
      }

      this.$store.dispatch("object/setGroupPreview");
    },
    destroy() {
      this.$store.dispatch("object/delete", this.id);
    },
    extract() {
      this.groups.forEach(group => {
        group.info.settings.objectIds = group.info.settings.objectIds.filter(
            id => id != this.id
        );
        this.$store.dispatch("object/update", group);
      });
    },
    openPropertyEditor() {
      if (this.type == "Core_ObjectPicker") return;
      this.$root.$emit("PropertyEditor.open", {
        component: ObjectPropertyEditors[this.type],
        props: {
          baseObjectId: this.baseObject.id,
          readonly: this.readonlyOrLocked
        }
      });
    },
    dragHandler() {
      let initialPositions;
      let initialArrowsPositions;
      let hide = false;
      let dragged = false;
      const dragHandler = d3
          .drag()
          .on("start", () => {
            if (this.specialKey == "space")
              return;

            initialPositions = {};
            initialArrowsPositions = {};

            const setupInitPos = object => {
              // for every selected object, move its comments and arrows as well as itself
              initialPositions[object.id] = {
                x: d3.event.x / this.scale - object.position.x,
                y: d3.event.y / this.scale - object.position.y
              };

              const comments = this.$store.getters["object/comments"](object);
              comments.forEach(comment => {
                initialPositions[comment.id] = {
                  x: d3.event.x / this.scale - comment.position.x,
                  y: d3.event.y / this.scale - comment.position.y
                };
              });

              const arrows = this.$store.getters["object/arrows"](object.id);
              arrows.forEach(arrowStore => {
                const {arrow, which} = arrowStore;
                if (!arrow)
                  return;

                initialArrowsPositions[`${arrow.id}_${which}`] = {
                  x: d3.event.x / this.scale - arrow.info.settings[`${which}X`],
                  y: d3.event.y / this.scale - arrow.info.settings[`${which}Y`]
                };
              });
            }

            if (this.selected) {
              // If selected, move the other selected ones altogether
              const selected = this.$store.getters["object/selected"];
              selected.forEach(setupInitPos);
            } else {
              // Normal case, Not in Multiple object Selection Mode: simple, move self and its arrows and comments
              setupInitPos(this);
            }
          })

          .on("drag", () => {
            if (this.specialKey == "space")
              return;

            if (!hide) {
              hide = true;
              this.$root.$emit("PropertyEditor.hide");
              this.$root.$emit("DataEditor.hide");
            }

            const x = d3.event.x / this.scale,
                y = d3.event.y / this.scale;

            Object.keys(initialPositions).forEach(id => {
              let targetPosition;
              if (this.activeChart.options.gridSnap)
                targetPosition = {
                  x: Math.round((x - initialPositions[id].x) / this.currentGridStep) * this.currentGridStep,
                  y: Math.round((y - initialPositions[id].y) / this.currentGridStep) * this.currentGridStep
                };
              else
                targetPosition = {
                  x: x - initialPositions[id].x,
                  y: y - initialPositions[id].y
                };
              this.$store.dispatch("object/update", {
                id,
                position: targetPosition
              });
            });

            // Special handling for arrows
            if (Object.keys(initialArrowsPositions)) {
              Object.keys(initialArrowsPositions).forEach(arrowKey => {
                const parsedKeys = arrowKey.split("_");
                const objectId = parsedKeys[0];
                const which = parsedKeys[1];

                const arrow = this.$store.getters["object/findById"](objectId);
                const arrowInfo = deepClone(arrow.info);
                arrowInfo.settings = {
                  ...arrowInfo.settings,
                  [`${which}X`]: d3.event.x / this.scale - initialArrowsPositions[arrowKey].x,
                  [`${which}Y`]: d3.event.y / this.scale - initialArrowsPositions[arrowKey].y
                };
                this.$store.dispatch("object/update", {
                  id: objectId,
                  info: arrowInfo
                });
              });
            }

            // To highlight the container when comment drags inside
            if (this.isGroup(this)) {
              this.$store.commit("object/setHighlight", null);

              for (let object of this.$store.getters["object/list"]) {
                if (this.isGroup(object))
                  continue;

                let inside =
                    x > object.position.x &&
                    x < object.position.x + object.size.width &&
                    y > object.position.y &&
                    y < object.position.y + object.size.height;

                if (inside && !object.info.settings.locked) {
                  this.$store.commit("object/setHighlight", object.id);
                  break;
                }
              }
            }

            dragged = true;
          })

          .on("end", () => {
            if (!dragged)
              return;

            if (hide) {
              hide = false;
              this.$root.$emit("PropertyEditor.unhide");
              this.$root.$emit("DataEditor.unhide");
            }

            if (this.type == "Base_CommentObject") {
              let parent_id = null;
              if (this.localInfo.settings.parent_id)
                this.$store.dispatch("object/removeCommentFromObject", {
                  objectId: this.localInfo.settings.parent_id,
                  commentId: this.id
                });

              const highlightedId = this.$store.getters["object/highlightedId"];
              if (highlightedId) {
                this.$store.dispatch("object/addCommentToObject", {
                  objectId: highlightedId,
                  commentId: this.id
                });
                parent_id = highlightedId;
              }

              this.$set(this.localInfo.settings, 'parent_id', parent_id);
              this.$store.commit("object/setHighlight", null);
            }

            const x = d3.event.x / this.scale,
                y = d3.event.y / this.scale;

            let startX, startY, endX, endY; // for Arrow
            if (this.type == "Base_ArrowObject") {
              startX = this.position.x + this.localInfo.settings.startX;
              startY = this.position.y + this.localInfo.settings.startY;
              endX = this.position.x + this.localInfo.settings.endX;
              endY = this.position.y + this.localInfo.settings.endY;
            }

            this.$store.getters["object/list"].forEach(object => {
              if (!this.isGroup(object) && this.type != "Base_ArrowObject")
                return;

              const objectEndX = object.position.x + object.size.width,
                  objectEndY = object.position.y + object.size.height;

              if (this.isGroup(object) && !this.defaultProperties.skipGroup) {
                const inside =
                    x > object.position.x &&
                    x < objectEndX &&
                    y > object.position.y &&
                    y < objectEndY;
                if (inside)
                  this.$store.commit("object/addToGroup", {
                    groupId: object.id,
                    objectId: this.id
                  });
                return;
              }

              if (this.type == "Base_ArrowObject") {
                const startInside =
                    startX > object.position.x &&
                    startX < objectEndX &&
                    startY > object.position.y &&
                    startY < objectEndY;
                const endInside =
                    endX > object.position.x &&
                    endX < objectEndX &&
                    endY > object.position.y &&
                    endY < objectEndY;

                if (startInside && this.localInfo.settings.startObject != object.id ||
                    !startInside && this.localInfo.settings.startObject == object.id) {
                  this.localInfo.settings.startObject = null;
                  this.$store.dispatch("object/removeArrowFromObject", {
                    objectId: this.localInfo.settings.startObject,
                    arrowId: this.id
                  });
                }

                if (endInside && this.localInfo.settings.endObject != object.id ||
                    !endInside && this.localInfo.settings.endObject == object.id) {
                  this.localInfo.settings.endObject = null;
                  this.$store.dispatch("object/removeArrowFromObject", {
                    objectId: this.localInfo.settings.endObject,
                    arrowId: this.id
                  });
                }

                if (startInside && this.localInfo.settings.startObject != object.id) {
                  this.localInfo.settings.startObject = object.id;
                  this.$store.dispatch("object/addArrowToObject", {
                    objectId: object.id,
                    arrowId: this.id,
                    which: 'start'
                  });
                }
                if (endInside && this.localInfo.settings.endObject != object.id) {
                  this.localInfo.settings.endObject = object.id;
                  this.$store.dispatch("object/addArrowToObject", {
                    objectId: object.id,
                    arrowId: this.id,
                    which: 'end'
                  });
                }
              }
            });

            dragged = false;
          });
      return dragHandler;
    },
    resizeHandler({transform: transformParams, handle}) {
      const {
        proportion,
        minHeight,
        maxHeight,
        minWidth,
        maxWidth
      } = this.defaultProperties.size

      const transform = deepClone(transformParams);
      const minWidthCondition = minWidth && transform.width < minWidth,
          minHeightCondition = minHeight && transform.height < minHeight,
          maxWidthCondition = maxWidth && transform.width > maxWidth,
          maxHeightCondition = maxHeight && transform.height > maxHeight;

      if (minWidthCondition) {
        transform.width = minWidth;
      }

      if (maxWidthCondition) {
        transform.width = maxWidth;
      }

      if (minHeightCondition) {
        transform.height = minHeight;
      }

      if (maxHeightCondition) {
        transform.height = maxHeight;
      }

      if (proportion) {
        transform.height = transform.width * proportion;
      }

      // Shift + Resize means preserve ratio
      if (this.specialKey == "shift") {
        transform.height = transform.width * this.localSize.height / this.localSize.width;
      }

      if (minWidthCondition || maxWidthCondition) {
        if (this.localPosition.x != transform.position.x) {
          transform.position.x =
              this.localPosition.x + (this.localSize.width - transform.width);
        } else {
          transform.position.x = this.localPosition.x;
        }
      }

      if (minHeightCondition || maxHeightCondition) {
        if (this.localPosition.y != transform.position.y) {
          transform.position.y =
              this.localPosition.y + (this.localSize.height - transform.height);
        } else {
          transform.position.y = this.localPosition.y;
        }
      }

      if (this.isGroup()) {
        // this.resizeGroup(transformParams, handle)
      }

      const updatedProperties = {
        id: this.id,
        size: {
          width: transform.width,
          height: transform.height
        },
        position: transform.position
      }


      if (this.type == 'Base_DrawingObject' && this.localInfo.settings.points) {
        this.localInfo.settings.points = this.localInfo.settings.points.map(pointsArray => {
          return pointsArray.map(point =>
              [point[0] / this.localSize.width * transform.width,
                point[1] / this.localSize.height * transform.height]
          );
        });
        updatedProperties.info = this.localInfo;
      }

      this.$store.dispatch("object/update", updatedProperties);
    },
    resizeGroup(transformParams, handle) {
      if (!this.initialGroupedElms) {
        this.initialGroupedElms = this.currentGroupedElms
      }

      // const prevPos = this.position
      //const {position: currentPos} = transformParams
      // const handleInitialCoord = getHandleCoord(handle, this.initialResize)
      //const handleCoord = getHandleCoord(handle, transformParams)
      // this.$store.dispatch('object/create', {position: handleInitialCoord, type: 'Base_StickyObject'})


      // update objects position
      const widthCoeff = transformParams.width - this.size.width //transformParams.width / this.size.width //then multiply size by koeff
      const heightCoeff = transformParams.height - this.size.height //transformParams.height / this.size.height
      if (this.currentGroupedElms) {
        this.currentGroupedElms.map((widget) => {
          const {id, position, size} = widget

          const {position: initialWidgetPos} = this.initialGroupedElms.find(w => w.id === id)

          const {proportion} = defaultProperties[widget.type].size

          const height = size.height + heightCoeff
          const width = proportion ? height * proportion : size.width + widthCoeff
          const posX = initialWidgetPos.x - (size.width - width)
          const posY = initialWidgetPos.y - (size.height - height)

          this.$store.dispatch('object/update', {
            id,
            size: {
              height,
              width
            },
            position: {x: posX, y: posY}
          })
        })
      }
    },
    rotateGroup() {
      this.currentGroupedElms.map((object, i) => {
        const {info, size, angle: widgetAngle} = object

        const objectCenter = getWidgetScreenCenter(object)
        const groupCenter = getWidgetScreenCenter(this)

        const absoluteDelta = getAbsoluteDistance(groupCenter, objectCenter)
        const signX = groupCenter.x > objectCenter.x ? 1 : -1
        const signY = groupCenter.y > objectCenter.y ? 1 : -1

        const angle = widgetAngle ? angle + widgetAngle : this.angle
        const transformOrigin = `${size.width / 2 + ((absoluteDelta.x) / this.scale) * signX}px
          ${size.height / 2 + (absoluteDelta.y / this.scale) * signY}px`

        const settings = {
          ...info.settings,
          angle,
          transformOrigin
        }

        this.$store.dispatch('object/update', {
          id: object.id, info: {
            ...info,
            settings
          }
        })
      })
    },
    dragRotateHandler() {
      let baseAngle;
      let startAngle;
      const dragHandler = d3
          .drag()
          .on("start", () => {
            baseAngle = this.angle;
            startAngle = this.getAngle(d3.event);
          })
          .on("drag", () => {
                const currentAngle = this.getAngle(d3.event);
                let angle = baseAngle - startAngle + currentAngle;
                if (angle < 0)
                  angle += 360;
                if (angle > 360)
                  angle -= 360;
                this.angle = angle;

                if (this.isGroup()) {
                  this.rotateGroup()
                }
              }
          )
          .on("end", () => {
            this.$set(this.localInfo.settings, 'angle', this.angle);
          });
      return dragHandler;
    },
    getAngle(eventPoint) {
      const dX = eventPoint.x - this.localSize.width * this.scale / 2;
      const dY = eventPoint.y - this.localSize.height * this.scale / 2;
      return 180 / Math.PI * Math.atan2(dY, dX);
    },
    rotateReset() {
      this.angle = 0;
      this.$set(this.localInfo.settings, 'angle', this.angle);
    },
    setLayout() {
      const isLayoutRelated =
          this.defaultProperties.has.in ||
          this.defaultProperties.has.out;
      this.layout = this.inGroups && isLayoutRelated && this.groups[0].info.settings.layout
          ? this.groups[0].info.settings.layout
          : this.defaultProperties.layout || "";
    }
  }
  ,
  beforeDestroy() {
    this.$root.$emit("PropertyEditor.close");
  }
}
;

</script>

<style>
.base-object {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  will-change: transform;
  box-sizing: content-box;
}

.base-object * {
  transform: translateZ(0);
  box-sizing: border-box;
}

.base-object__content {
  height: 100%;
  width: 100%;
  position: relative;
}

.base-object.selected {
  z-index: 99999 !important;
}

.base-object.selected .base-object__content > div {
  /* box-shadow: 0px 0px 17px 1px rgb(239, 176, 0); */
}

.base-object.selected.resizable {
  border: 1px solid #619ecc;
}

.base-object.highlighted {
  opacity: 0.6;
}

.notes-count {
  position: absolute;
  height: 100%;
  width: 3px;
  right: 0;
  top: 0;
  background: rgba(223, 78, 158, 0.1);
}

.gradient-border + .notes-count {
  right: 3px;
}

.notes-count > .notes-progress {
  position: relative;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to bottom,
      rgba(223, 78, 158, 0.3) 10%,
      rgba(223, 78, 158, 0.7) 80%,
      rgba(223, 78, 158, 1)
  );
}

.rotate {
  position: absolute;
  bottom: -30px;
  left: -30px;
}

.grouped {
  pointer-events: none
}
</style>
