<template>
  <v-toolbar dense no-padding>
    <!--    <v-btn-toggle group borderless v-model="openedData">
          <v-btn text style="opacity: 1" class="ma-0">Content</v-btn>
        </v-btn-toggle>-->

    <v-divider vertical/>
    <ColorPickerDialog
        :value="localSettings.backgroundColor"
        title="Select Main Colour"
        :presetColors="projectColors"
        :disabled="readonly"
        :groupColors="objectsColor()"
        :handleGroup="handleColor"
    />

    <v-divider class="mr-2" vertical/>

    <v-select
        class="pa-0"
        hide-details
        :readonly="readonly"
        placeholder="Layout"
        v-model="localSettings.layout"
        :style="'width: 120px'"
        :items="availableLayouts"
        @change="changeLayout"
    />

    <v-divider class="ml-1" vertical/>


    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="halign('left')">
          <v-icon>align_horizontal_left</v-icon>
        </v-btn>
      </template>
      <span>Align Left</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="halign('center')">
          <v-icon>align_horizontal_center</v-icon>
        </v-btn>
      </template>
      <span>Align Center</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="halign('right')">
          <v-icon>align_horizontal_right</v-icon>
        </v-btn>
      </template>
      <span>Align Right</span>
    </v-tooltip>

    <v-divider vertical/>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="valign('top')">
          <v-icon>align_vertical_top</v-icon>
        </v-btn>
      </template>
      <span>Align Top</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="valign('center')">
          <v-icon>align_vertical_center</v-icon>
        </v-btn>
      </template>
      <span>Align Center</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="valign('bottom')">
          <v-icon>align_vertical_bottom</v-icon>
        </v-btn>
      </template>
      <span>Align Bottom</span>
    </v-tooltip>

    <v-divider vertical/>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="distribute('x')">
          <v-icon>horizontal_distribute</v-icon>
        </v-btn>
      </template>
      <span>Horizontal Distribute</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="readonly" class="ma-0" @click="distribute('y')">
          <v-icon>vertical_distribute</v-icon>
        </v-btn>
      </template>
      <span>Vertical Distribute</span>
    </v-tooltip>

    <v-divider class="ml-1" vertical/>

    <slot name="menumore"/>
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import Vue from "vue";
import {deepClone} from "@/lib";

export default {
  name: "Base_GroupObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  data() {
    const cmpContent = Vue.extend({
      template: `
        <v-textarea class="pt-0 mb-1" :readonly="readonly" v-model="settings.content" autofocus auto-grow
                    hide-details/>`,
      props: ["settings", "readonly"],
      mounted() {
        setTimeout(() => this.$emit('updateSize'), 10);
      },
      watch: {
        'settings.content': {
          handler() {
            this.$emit('updateSize');
          }
        }
      }
    });

    return {
      cmpContent,
      openedData: 0
    };
  },
  methods: {
    /*onOpenContent() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpContent,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Content"
      });
    },*/
    objectsColor() {
      const uniqueColors = [...new Set(this.objects.map(({info}) => info.settings.backgroundColor ?? info.settings.lineColor)
          .filter(c => typeof c === 'string'))]
      if (uniqueColors.length > 3) return uniqueColors.slice(0, 3)
      return uniqueColors
    },
    handleColor(color) {
      this.$emit("input", color);
      this.objects.map(({id}) => {
        const object = this.$store.getters['object/findById'](id)
        const {info} = object

        if (info && info.settings.backgroundColor) {
          this.$store.dispatch('object/update',
              {id, info: {...info, settings: {...info.settings, backgroundColor: color}}})
        } else if (info && info.settings.lineColor) {
          this.$store.dispatch('object/update', {id, info: {...info, settings: {...info.settings, lineColor: color}}})
        }
      })
    },
    // change the distribution method event handler
    changeLayout($event) {
      this.objects.forEach(object => {
        object.layout = $event;
      });
    },
    distribute(axis) {
      const objects = deepClone(this.objects);
      objects.sort((a, b) => a.position[axis] > b.position[axis] ? 1 : -1);
      const minValue = objects[0].position[axis];
      const maxValue = objects[objects.length - 1].position[axis];
      const stepValue = (maxValue - minValue) / (objects.length - 1);

      objects.forEach((object, i) => {
        this.$store.dispatch("object/update", {
          id: object.id,
          position: {
            ...object.position,
            [axis]: minValue + stepValue * i
          }
        });
      })
    },
    // Vertical align button events handler
    valign(alignName) {
      const baseObjectTop = this.baseObject.position.y;
      const baseObjectBottom = this.baseObject.position.y + this.baseObject.size.height;
      this.objects.forEach((object) => {
        let y = this.baseObject.position.y + this.baseObject.size.height / 2;
        if (alignName === 'top') y = baseObjectTop + object.size.height / 2;
        if (alignName === 'bottom') y = baseObjectBottom - object.size.height / 2;
        this.$store.dispatch("object/update", {
          id: object.id,
          position: {
            ...object.position,
            y
          }
        });
      });
    },
    // Horizontal align button events handler
    halign(alignName) {
      const baseObjectLeft = this.baseObject.position.x;
      const baseObjectRight = this.baseObject.position.x + this.baseObject.size.width;
      this.objects.forEach((object) => {
        let x = this.baseObject.position.y + this.baseObject.size.width / 2;
        if (alignName === 'left') x = baseObjectLeft + object.size.width / 2;
        if (alignName === 'right') x = baseObjectRight - object.size.width / 2;
        this.$store.dispatch("object/update", {
          id: object.id,
          position: {
            ...object.position,
            x
          }
        });
      });
    }

  },
  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    },
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);

  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  },

  computed: {
    objects() {
      return this.$store.getters["object/list"].filter(object =>
          this.settings.objectIds.includes(object.id)
      );
    },
    availableLayouts() {
      return ["horizontal", "vertical"];
    },
  }
};
</script>
