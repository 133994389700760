<template>
  <div class="chatbot__user-message" :style="style">
    <div class="chatbot__user-message__text">
      <div>{{localSettings.message || 'Add Message'}}</div>
    </div>
  </div>
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent'

  export default {
    name: 'Chatbot_UserMessageObject',
    extends: ExtendsComponent,
    computed: {
      style () {
        return {
          background: this.localSettings.color || '#df4e9e'
        }
      }
    }
  }
</script>

<style scoped>

  .chatbot__user-message {
    border-radius: 30px;
    min-width: 250px;
  }

  .chatbot__user-message__text {
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
