<template>
  <div class="comp">
    ROUTER
  </div>
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent';

  export default {
    name: 'Presentation_WayfinderRouterObject',
    extends: ExtendsComponent,
  }
</script>

<style scoped>
  .comp {
    border: 3px solid rgb(132, 132, 132);
    padding: 5px;
    min-width: 200px;
    border-radius: 5px;
    cursor: default;
    background: white;
  }
</style>
