<template>
  <transition name="flip">
    <div :class="`pinned-content ` + width" v-if="opened">
      <v-card class="border pb-3 d-flex flex-column" style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title v-if="title">{{title}}</v-toolbar-title>
            <v-spacer />
            <v-btn-toggle v-model="width" mandatory group borderless dense>
              <v-btn text class="ma-0" value="small">
                <div class="third-icon" />
              </v-btn>
              <v-btn text class="ma-0" value="half">
                <div class="half-icon" />
              </v-btn>
              <v-btn text class="ma-0" value="full">
                <div class="full-icon" />
              </v-btn>
            </v-btn-toggle>
            <v-btn @click.native="close" text class="ma-0 pa-0" style="min-width: 40px">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <div class="flex-grow-1" style="overflow: auto;">
          <component
            class="px-4"
            ref="component"
            :is="component"
            v-bind="props"
            v-model="localSettings[dataField]"
            :baseObject="baseObject"
            :settings="localSettings"
            fromPinned
          />
        </div>
      </v-card>
    </div>
  </transition>
</template>

<script>

import {deepClone} from "@/lib";
import * as _ from "underscore";

export default {
  name: "PinnedContent",

  data() {
    return {
      baseObject: null,
      component: null,
      props: null,
      dataField: null,
      title: "",
      width: 'small',
      localSettings: {}
    };
  },

  computed: {
    opened() {
      return !!this.component;
    }
  },

  watch: {
    'baseObject.info.settings': {
      handler() {
        //this.setCurrentState();
        if (!this.baseObject)
          return;
        if (JSON.stringify(this.baseObject.info.settings) != JSON.stringify(this.localSettings))
          this.localSettings = deepClone(this.baseObject.info.settings);
      },
      deep: true,
      immediate: true
    },
    localSettings: {
      handler() {
        if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings))
          this.save();
      },
      deep: true
    }
  },

  mounted() {
    this.$root.$on("PinnedContent.open", this.onOpen);
    this.$root.$on("PinnedContent.close", this.onClose);
  },
  beforeDestroy() {
    this.$root.$off("PinnedContent.open", this.onOpen);
    this.$root.$off("PinnedContent.close", this.onClose);
  },

  methods: {
    async onOpen({component, baseObject, props, title, dataField}) {
      await this.$nextTick();
      this.component = component;
      this.baseObject = baseObject;
      this.props = props;
      this.title = typeof title == "undefined" ? "Notes" : title;
      this.dataField = dataField;
    },
    onClose() {
      this.close();
      this.baseObject = null;
    },

    close() {
      this.component = null;
    },
    save: _.debounce(function() {
      this.$store.dispatch(
        "object/update",
        {
          id: this.baseObject.id,
          info: {
            ...this.baseObject.info,
            settings: deepClone(this.localSettings)
          }
        }
      );
    }, 300)
  }
};
</script>

<style scoped lang="scss">
.pinned-content {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 15px;
  z-index: 10;
  transition-duration: 300ms;
  transition-property: transform, opacity;
}

.border {
  border-radius: 6px 20px 20px 20px !important;
}

.small {
  width: 33%;
}

.half {
  width: 50%;
}

.full {
  width: 100%;
}

.full-icon,
.half-icon,
.third-icon {
  width: 18px !important;
  height: 18px;
}

.full-icon {
  background-image: url("./full-screen.svg");
}

.half-icon {
  background-image: url("./half-screen.svg");
}

.third-icon {
  background-image: url("./third-screen.svg");
}

@media screen and (max-width: 680px) {
  .pinned-content {
    max-height: 100% !important;
    margin: 0;
    min-width: 100%;
  }
}

.flip-enter {
  opacity: .01;
  transform: translateX(100%);
}

.flip-enter-to {
  opacity: 1;
  transform: translateX(0%);
}

.flip-leave {
  opacity: 1;
}

.flip-leave-to {
  opacity: .01;
  transform: translateX(100%);
}
</style>