<template>
    <div class="gradient-border">
    <div class="at-general-object">
      <div class="at-general-object__image">
          <v-card tile>
            <v-card tile>
                <v-layout>
                    <div class="d-inline-flex">
                        <v-select
                        :items="requestTypes"
                        v-model="localSettings.requestType"
                        class="col-4"
                        ></v-select>
                        <v-text-field
                        placeholder="Enter request URL"
                        class="col-8"
                        v-model="localSettings.requestURL"
                        ></v-text-field>
                    </div>
                </v-layout>
            </v-card>
            <v-divider></v-divider>
            <v-card tile>
                <div class="sectionTitle pa-2">Request Header</div>
                <div v-for="item in localSettings.headerItems" :key="item.id" class="d-flex">
                    <v-autocomplete
                        :items="headerOptions"
                        color="white"
                        placeholder="Start typing to Search"
                        return-object
                        v-model="item.key"
                        class="col-5"
                    ></v-autocomplete>
                    <v-text-field 
                    placeholder="value"
                    v-model="item.value"
                    class="col-5"
                    >
                    </v-text-field>
                    <v-btn 
                    icon
                    class="col-1"
                    @click="addHeaderItem"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn 
                    icon
                    class="col-1"
                    @click="removeHeaderItem(item.id)"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-card>
            <v-divider></v-divider>
            <v-card tile>
                <div class="sectionTitle pa-2">Request Body</div>
                <div v-for="item in localSettings.bodyItems" :key="item.id" class="d-flex">
                    <v-text-field 
                    placeholder="key"
                    v-model="item.key" 
                    class="col-5"
                    >
                    </v-text-field>
                    <v-text-field 
                    placeholder="value"
                    v-model="item.value" 
                    class="col-5"
                    >
                    </v-text-field>
                    <v-btn 
                    icon
                    class="col-1"
                    @click="addBodyItem"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn 
                    icon
                    class="col-1"
                    @click="removeBodyItem(item.id)"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-card>
            <v-card-actions>
                <!--<v-btn 
                color="primary"
                @click="runRequest"
                >Run</v-btn> -->
                <div class="sectionTitle pa-2">Response Example</div>
                <v-btn
                    icon
                    @click="show = !show"
                >
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>
            <v-expand-transition>
                <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>
                        <v-textarea
                        v-model="localSettings.responseBodyContent"
                        >
                        </v-textarea>
                    </v-card-text>
                </div>
            </v-expand-transition>
          </v-card>
          
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";
import { deepClone } from "@/lib";

export default {
  name: "Tech_RESTClient",
  extends: ExtendsComponent,
  data(){
      const localSettings = {
        requestType: "GET",  
        requestURL: "",
        headerItems: [{id: this.guid(), key: "Content-Type", value: "application/json"}],
        bodyItems: [{id: this.guid(), key: "", value: ""}],
        responseBodyContent: "{}",  
        ...deepClone(this.settings)
        };
      return {
        show: false,
        requestTypes: ["GET", "POST", "PUT", "PATCH", "DELETE" ],
        defaultRequestType: "GET",
        headerOptions: ["Authorization", "Proxy-Authorization", "Accept", "Accept-Language", "Content-Type", "A-IM", "Accept-Charset", "Accept-DateTime", "Accept-Encoding", "Access-Control-Request-Method", "Cache Control", "Connection", "Content-Encoding", "Content-Length", "Content-MD5", "Cookie", "Date", "Expect", "Forwarded", "From", "Host", "HTTP2-Settings", "Origin", "User-Agent", "Via"],
        localSettings,
        headerItems: [],
        bodyItems: []
      };   
  },
  computed: {
      requestType() {
         return this.localSettings.requestType? this.localSettings.requestType : this.defaultRequestType;
      },
      requestURL() {
          return this.localSettings.requestURL? this.localSettings.requestURL : "";
      }
  },
  methods: {
      removeHeaderItem(id) {
        let newHeaderItems = this.remove(this.localSettings.headerItems, "id", id);
        this.localSettings.headerItems = newHeaderItems;
      },
      addHeaderItem() {
        this.localSettings.headerItems.push({id: this.guid(), key: "", value: ""});
      },
      removeBodyItem(id) {
        let newBodyItems = this.remove(this.localSettings.bodyItems, "id", id);
        this.localSettings.bodyItems = newBodyItems;
      },    
      addBodyItem() {
        this.localSettings.bodyItems.push({id: this.guid(), key: "", value: ""});
      },
      remove(array, key, value) {
        const index = array.findIndex(obj => obj[key] === value);
        return index >= 0 ? [
            ...array.slice(0, index),
            ...array.slice(index + 1)
        ] : array;
      },
      
      runRequest() {
        console.log('run request');
        // setup options
        
        const headerItems = this.localSettings.headerItems;
        const headers = Object.fromEntries(
           headerItems.map(item => [item.key, item.value ])
        )

        const bodyItems = this.localSettings.bodyItems;
        const bodies = Object.fromEntries(
           bodyItems.map(item => [item.key, item.value ])
        )

        console.log(headers);
        console.log(bodies);

        const options = {
            headers,
            params: bodies
        }


          switch(this.localSettings.requestType) {
            case 'GET': 
              this.$http.get(this.localSettings.requestURL, options)
                .then(function(result){
                    console.log(result.body);
                }, function(error){
                    console.log(error.description);
                });  
              break;
            case 'POST': 
                this.$http.post(this.localSettings.requestURL, options)
                    .then(function(result){
                        console.log(result.body);
                    }, function(error){
                        console.log(error.description);
                }); 
              break;
            case 'PUT': 
                this.$http.put(this.localSettings.requestURL, options)
                    .then(function(result){
                        console.log(result.body);
                    }, function(error){
                        console.log(error.description);
                }); 
              break;
            case 'PATCH': 
                this.$http.patch(this.localSettings.requestURL, options)
                    .then(function(result){
                        console.log(result.body);
                    }, function(error){
                        console.log(error.description);
                }); 
              break;
              break;
            case 'DELETE': 
                this.$http.delete(this.localSettings.requestURL, options)
                    .then(function(result){
                        console.log(result.body);
                    }, function(error){
                        console.log(error.description);
                }); 
              break;
            default:
                this.$http.get(this.localSettings.requestURL, options)
                .then(function(result){
                    console.log(result.body);
                }, function(error){
                    console.log(error.description);
                });  
              break;
          }

      }

  }
};
</script>

<style scoped>
.at-general-object {
  height: 100%;
  width: 100%;
}
.gradient-border {
  position: absolute;
}

.at-general-object__image {
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
}

.sectionTitle {
    font-size: 0.8em;
    text-transform: uppercase;
}
</style>