<script>
import Text from "./Text.vue";
export default {
  extends: Text,
  name: "NotionTypeQuote",
  props: {
    editorType: {
      type: String,
      default: "Quote"
    }
  },
  computed: {
    contentStyle() {
      return {
        color: this.line.color || "#333",
        background: this.line.background || "#f9f9f9",
        fontSize: "18px",
        borderLeft: "3px solid #CCC",
        padding: "15px",
        fontStyle: "italic"
      };
    }
  }
};
</script>

<style lang="css">
</style>
