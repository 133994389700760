const defaultSettings = {
  name: "Arrow",
  icon: 'mdi-arrow-top-right',
  hotkey: `modkey + A`,
  resizable: false,
  draggable: true,
  showLabel: false,
  size: {
    width: 0,
    height: 0
  },
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: true,
  notionOption: true,
  settings: {
    color: '#605da5',
    arrow: "None",
    strokeLineCap: 'Square',
    strokeWidth: 3,
    lineStyle: "Solid",

    startX: 0,
    startY: 0,
    endX: 100,
    endY: 0,

    locked: false,
    order: 0,
    notionEnabled: false
  }
};

export default defaultSettings;
