const defaultSettings = {
    name: "Group",
    icon: 'mdi-group',
    resizable: true,
    draggable: false,
    showLabel: false,
    size: {
        width: 10,
        height: 10
    },
    has: {
        in: false,
        out: false,
        settings: true
    },
    alwaysOnBottom: true,
    openPropertyEditorByClick: true,
    layout: "horizontal",
    settings: {
        background: 'rgba(212,237,255,0)',
        locked: false,
        order: 0
    }
};

export default defaultSettings;
