<template>
  <div class="page">
    <app-header
      title="Components Library"
      :tabs="tabs"
      :activeTabInd="activeTab"
      @activeTabChanged="setActiveTab"
    >
      <template v-slot:afterTabs>
        <v-btn text class="toolbar-button" :class="{adding}" @click="add">
          <v-icon color="primary">add</v-icon>
        </v-btn>
      </template>
    </app-header>

    <v-main>
      <div>
        <div class="pa-4 text-left">
          <div v-if="activeTab == 0">
            <MyComponents setTab="setActiveTab" @edit="onEdit" @add="add" />
          </div>
          <div v-if="activeTab == 1">
            <GeneralComponents @edit="onEdit($event, true)" />
          </div>
          <div v-if="adding">
            <ComponentForm type="new" @close="setActiveTab(0)" />
          </div>
          <div v-if="editingComponent">
            <ComponentForm
              :type="fork ? 'fork' : 'edit'"
              :settings="editingComponent"
              @close="setActiveTab(0)"
            />
          </div>
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import AppHeader from "./AppHeader";
import MyComponents from "./TheComponentsLib/MyComponents";
import GeneralComponents from "./TheComponentsLib/GeneralComponents";
import ComponentForm from "./TheComponentsLib/ComponentForm";

export default {
  name: "TheComponentsLib",
  components: {
    AppHeader,
    MyComponents,
    GeneralComponents,
    ComponentForm
  },
  data() {
    return {
      activeTab: 0,
      adding: false,

      editingComponent: null,
      fork: false,

      tabs: [
        { name: "My Components", icon: "" },
        { name: "Browse", icon: "" }
      ]
    };
  },
  watch: {
    activeTab() {
      if (this.editingComponent && this.activeTab != 2) {
        this.editingComponent = null;
        this.tabs.pop();
      }
    }
  },
  methods: {
    setActiveTab(value) {
      this.adding = false;
      this.activeTab = value;
    },
    add() {
      this.activeTab = undefined;
      this.adding = true;
    },
    onEdit(component, fork = false) {
      this.tabs.push({
        name: "Editing component",
        icon: ""
      });
      this.editingComponent = component;
      this.fork = fork;
      this.setActiveTab(2);
    }
  },
  mounted() {
    window.analytics.page('Components');
  }
};
</script>

<style scoped>

.v-tabs__div {
  padding: 7px;
}

.new-component-tab {
  color: #df4e9e !important;
  font-weight: bold;
  cursor: pointer;
  opacity: 1 !important;
}

.new-component-tab:hover {
  filter: brightness(110%);
}

.v-icon.chart-tab-action:hover {
  background: #e7e7e7;
}

.adding {
  border-bottom: 2px solid #df4e9f;
}
</style>
