<template>
  <v-card id="activeMembersToolbar">
    <v-card-actions class="pr-0 pl-3">
      <ActiveMember
        :viewport="viewport"
      />

      <v-divider
        v-if="cursors.length"
        vertical
        class="mr-3"
      />

      <ActiveMember
        v-for="cursor in cursors"
        :key="cursor.id"
        :cursor="cursor"
        :now="now"
        :viewport="viewport"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import ActiveMember from "./ActiveMember";

export default {
  name: "ActiveMembersToolbar",
  components: {ActiveMember},
  props: {
    cursors: Array,
    viewport: Object
  },
  data() {
    return {
      nowInterval: undefined,
      now: new Date()
    }
  },
  mounted() {
    this.nowInterval = setInterval(() => {
      this.now = new Date();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.nowInterval);
  }
}

</script>

<style scoped>
#activeMembersToolbar {
  position: absolute;
  bottom: 20px;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 2;
}

</style>
