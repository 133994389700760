<template>
  <div class="comp">
    <form
      v-if="controls.length"
      @submit.prevent="submitControls"
      class="controls"
    >
      <div
        class="control"
        v-for="(control, index) in controls"
        :key="index"
        v-if="checkCondition(control.settings.showCondition)"
        :style="{width: (control.settings.width || 100) + '%'}"
      >
        <component
          :is="control.type"
          :ref="control.type"
          :settings="control.settings"
        />
      </div>
    </form>
  </div>
</template>

<script>
  import Controls from './ActionsControls';

  export default {
    name: 'WayfinderActions',
    components: {
      ...Controls
    },
    props: {
      controls: Array
    },
    methods: {
      submitControls () {
        const buttons = this.controls.filter(c => c.type == 'ControlElement_Button');
        if (buttons.length == 1)
          this.$refs['ControlElement_Button'][0].doAction();
      },
      checkCondition (string) {
        return this.$store.getters[`wayfinder/checkCondition`](string);
      }
    }
  }
</script>

<style scoped>
  .comp {
    border-top: 1px solid #e7e7e7;
    position: relative;
  }

  .controls {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    margin: 0 -5px;
  }

  .control {
    margin: 0 5px;
  }
</style>
