<template functional>
  <div>
    <v-list-group v-if="!!props.group.items.length" :prepend-icon="props.group.icon">
      <template v-slot:activator>
        <v-list-item-title>{{props.group.name}}</v-list-item-title>
      </template>

      <NavigationListItem v-for="item in props.group.items" :key="item.id" :item="item" />
    </v-list-group>

    <v-list-item v-else style="opacity: .5" >
      <v-list-item-icon v-if="props.group.icon">
        <v-icon>{{props.group.icon}}</v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{props.group.name}}</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
  import NavigationListItem from './NavigationListItem';

  export default {
    name: "NavigationListGroup",
    props: {
      group: Object
    },
    components: {
      NavigationListItem
    }
  }
</script>