<template>
  <div>
    <div class="notion-line__content-line">
      <v-checkbox v-if="hasCheckbox" v-model="line.checked" class="notion-line__checkbox" />
      <div v-if="hasBullets" class="notion-line__bullet">○</div>
      <div
        ref="contentEditable"
        class="notion-line__content-editable"
        @input="setValue($event.target.innerHTML)"
        v-html="lineContent"
        @keydown.enter.stop.prevent="keyEnter"
        @keydown.up.stop="keyUp"
        @keydown.left.stop="keyLeft"
        @keydown.down.stop="keyDown"
        @keydown.right.stop="keyRight"
        @keydown.8.stop="keyBackspace"
        @keydown.191="keySlash"
        @keydown.27.stop="keyEsc"
        @keydown.219.stop="keyBracket"
        @focus="localActive = true"
        @blur="blur"
        :id="id"
        :style="contentOnlyStyle"
        :contenteditable="!readonly"
      />
    </div>
    <div
      :style="contentStyle"
      class="placeholder"
      v-if="!readonly && !line.content && localActive && editorType != 'BulletedList' && editorType != 'Checkbox'"
    >Type '/' for commands</div>
    <CommandLine
      v-if="showCommandLine"
      @select="keyEnter"
      @hide="showCommandLine = false"
      :content="contentText()"
      :id="id"
      :items="commandLineItems"
      :position="cursorPosition()"
      :active.sync="activeCommand"
    />
    <LinkObjectLine
      v-if="showLinkObjectLine"
      @select="setMapObjectLink"
      @hide="showLinkObjectLine = false"
      @resetHighlight="linkHighlightIndex = -1"
      :content="contentText()"
      :id="id"
      :linkId="linkId"
      :searchKey="linkSearchKey()"
      :startPosition="linkStartPosition"
      :active.sync="linkActiveObject"
      :highlightIndex="linkHighlightIndex"
      :highlightItem.sync="linkHighlightObject"
    />
  </div>
</template>

<script>
import {
  setCurrentCursorPosition,
  getCurrentCursorPosition,
  getCurrentCursorPositionInHTML,
  getLinkAttributeFromHTML,
  fixhtml,
  execute
} from "../lib";
import menuTypes from "../menuTypes";
import {
  defaultProperties,
  ObjectPropertyEditors
} from "@/components/Layout/Authorized/TheEditor/Object/Types";
import CommandLine from "./Text/CommandLine";
import LinkObjectLine from "./Text/LinkObjectLine";
import linkifyHtml from "linkifyjs/html";

export default {
  name: "NotionTypeText",
  components: { CommandLine, LinkObjectLine },
  props: {
    line: Object,
    active: Boolean,
    readonly: Boolean,
    startFocus: Boolean,
    editorType: {
      type: String,
      default: "Text"
    },
    customComponents: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      id: `notion-line-${this.line.id}`,
      localActive: this.active,
      contentEditable: null,
      lineContent: this.line.content || "",
      showCommandLine: false,
      activeCommand: null,
      commandLineItems: [{ name: "Add New Line", items: menuTypes }],
      showLinkObjectLine: false,
      linkId: null,
      linkStartPosition: -1,
      linkHighlightIndex: -1,
      linkActiveObject: null,
      linkHighlightObject: null
    };
  },
  mounted() {
    this.contentEditable = this.$refs.contentEditable;

    // it prevents focusing during appearing transition in PinnedContent
    if (this.startFocus) {
      setTimeout(() => this.contentEditable.focus(), 350);
      this.$parent.$emit("update:startFocus", false);
    } else {
      setTimeout(() => this.contentEditable.focus(), 10);
    }

    this.$root.$on("focusNotionLine", this.onFocusNotionLine);
    document.addEventListener("selectionchange", this.onSelectionChange);
  },
  beforeDestroy() {
    this.$root.$off("focusNotionLine", this.onFocusNotionLine);
    document.removeEventListener("selectionchange", this.onSelectionChange);
  },
  computed: {
    contentStyle() {
      return {
        color: this.line.color || "#333",
        background: this.line.background || "transparent"
      };
    },
    contentOnlyStyle() {
      const markedStyle = this.line.checked
        ? {
            opacity: 0.375,
            textDecoration: "line-through"
          }
        : {};

      return {
        ...this.contentStyle,
        ...markedStyle
      };
    },
    prefixStyle() {
      return {};
    },
    hasPrefix() {
      return this.editorType == "NumberedList";
    },
    hasCheckbox() {
      return this.editorType == "Checkbox";
    },
    hasBullets() {
      return this.editorType == "BulletedList";
    }
  },
  methods: {
    async onFocusNotionLine({ id, position, content }) {
      if (id != this.line.id)
        return;

      if (content)
        this.lineContent = content;

      this.contentEditable.focus();

      await this.$nextTick();

      let newPosition = position;
      const length = this.contentText().length;

      if (newPosition > length)
        newPosition = length;

      if (length) {
        if (newPosition === 0) {
          const selection = window.getSelection();
          selection.collapse(this.contentEditable.firstChild, 0);
        } else {
          this.setCurrentCursorPosition(newPosition);
        }
      }
    },
    setCurrentCursorPosition(position) {
      return setCurrentCursorPosition(this.contentEditable, position);
    },
    keyEsc() {
      this.showCommandLine = false;
      this.resetObjectLink();
    },
    keySlash() {
      if (this.contentText().length === 0) {
        this.showCommandLine = true;
      }
    },
    contentText() {
      return this.contentEditable.innerText;
    },
    contentHTML() {
      return this.contentEditable.innerHTML;
    },
    linkSearchKey() {
      const endBracketIndex = this.contentEditable.innerText.indexOf("]]", this.linkStartPosition);
      return this.contentEditable.innerText.slice(this.linkStartPosition, endBracketIndex);
    },
    setValue(content) {
      this.$parent.$emit("input", {
        content
      });
    },
    keyUp(e) {
      if (this.showCommandLine) {
        const commandLineItems = this.filterCommandLineItems();
        const { activeCommandIndex, chunk } = this.getActiveCommand(
          commandLineItems
        );

        if (activeCommandIndex !== null) {
          const chunkObject = [
            commandLineItems[chunk],
            commandLineItems[chunk - 1],
            commandLineItems[commandLineItems.length - 1]
          ];
          let activeCommandItem;
          if (chunkObject[0]) {
            activeCommandItem = chunkObject[0].items[activeCommandIndex - 1];
          }
          if (chunkObject[1] && !activeCommandItem) {
            activeCommandItem =
              chunkObject[1].items[chunkObject[1].items.length - 1];
          }
          if (chunkObject[2] && !activeCommandItem) {
            activeCommandItem =
              chunkObject[2].items[chunkObject[2].items.length - 1];
          }

          this.activeCommand = activeCommandItem.id;
        }
        e.preventDefault();
        return;
      }
      if (this.showLinkObjectLine) {
        this.linkHighlightIndex--;
      e.preventDefault();
        return;
      }
      const initialPosition = this.cursorPosition();
      this.$nextTick(function() {
        const position = this.cursorPosition();
        if (position === 0) {
          this.$parent.$emit("previousLine", { position: initialPosition });
        }
      });
    },
    getActiveCommand(commandLineItems) {
      let activeCommandIndex = null,
        chunk;
      for (let i = 0; i < commandLineItems.length; i++) {
        activeCommandIndex = commandLineItems[i].items.findIndex(
          c => c.id == this.activeCommand
        );

        if (activeCommandIndex !== -1) {
          chunk = i;
          break;
        }
      }
      return { activeCommandIndex, chunk };
    },
    filterCommandLineItems() {
      const content = this.contentText().slice(1, this.position);
      return this.commandLineItems.reduce((memo, i) => {
        const items = i.items.filter(
          _i =>
            (_i.shortcut || _i.name || "")
              .toLowerCase()
              .indexOf(content.toLowerCase()) != -1
        );
        if (items.length) {
          memo.push({ ...i, items });
        }
        return memo;
      }, []);
    },
    // event handler when user press "["
    // [[ will trigger the search box
    async keyBracket(e) {
      const position = this.cursorPosition();
      const oldText = this.contentText();
      const oldHTML = this.contentHTML();
      const previousLetter = oldText.substring(position - 1, position)
      const previousTwoLetters = oldText.substring(position - 2, position)
      const nextTwoLetters = oldText.substring(position, position + 2);
      if (previousLetter == "[" && previousTwoLetters != "[[" && nextTwoLetters != "]]") {
        const htmlPosition = getCurrentCursorPositionInHTML(this.id);
        const newId = this.guid();
        // Insert new link tag inside the existing html
        const newHTML = oldHTML.slice(0, htmlPosition - 1) + `[[<a id='${newId}'></a>]]` + oldHTML.slice(htmlPosition);
        this.setValue(newHTML);
        this.contentEditable.innerHTML = newHTML;
        this.setCurrentCursorPosition(position + 1);
        e.preventDefault();
        e.stopPropagation();

        // after showing link object line, save critical information.
        this.showLinkObjectLine = true;
        this.linkStartPosition = position + 1;
        this.linkId = newId;
      }
    },
    keyDown(e) {
      if (this.showCommandLine) {
        const commandLineItems = this.filterCommandLineItems();
        const { activeCommandIndex, chunk } = this.getActiveCommand(
          commandLineItems
        );

        if (activeCommandIndex !== null) {
          const chunkObject = [
            commandLineItems[chunk],
            commandLineItems[chunk + 1],
            commandLineItems[0]
          ];
          let activeCommandItem;
          if (chunkObject[0]) {
            activeCommandItem = chunkObject[0].items[activeCommandIndex + 1];
          }
          if (chunkObject[1] && !activeCommandItem) {
            activeCommandItem = chunkObject[1].items[0];
          }
          if (chunkObject[2] && !activeCommandItem) {
            activeCommandItem = chunkObject[2].items[0];
          }

          this.activeCommand = activeCommandItem.id;
        }
        e.preventDefault();
        return;
      }
      if (this.showLinkObjectLine) {
        this.linkHighlightIndex++;
      e.preventDefault();
        return;
      }
      this.$parent.$emit("nextLine", { position: this.cursorPosition() });
    },
    keyLeft() {
      if (this.showCommandLine) return;
      const initialPosition = this.cursorPosition();
      this.$nextTick(function() {
        const position = this.cursorPosition();

        if (position == initialPosition && position == 0) {
          this.$parent.$emit("previousLine", {
            position: -1
          });
        }
      });
    },
    keyRight() {
      const initialPosition = this.cursorPosition();

      this.$nextTick(function() {
        const position = this.cursorPosition();
        const text = this.contentText();

        if (position == initialPosition && position == text.length) {
          this.$parent.$emit("nextLine");
        }
      });
    },
    keyEnter(e) {
      if (this.showCommandLine) {
        const { activeCommandIndex, chunk } = this.getActiveCommand(
          this.commandLineItems
        );
        let lineContent = this.contentHTML().split("/");
        lineContent = lineContent.splice(0, lineContent.length - 1).join("/");
        const item = this.commandLineItems[chunk].items[activeCommandIndex];

        item.handler(this, () => {
          if (lineContent) {
            this.lineContent = lineContent || "";
            this.$parent.$emit("input", { content: this.lineContent });
            this.contentEditable.innerHTML = this.lineContent;
          } else {
            this.$parent.$emit("delete");
          }
        });

        this.showCommandLine = false;
        return;
      }

      if (this.showLinkObjectLine) {
        if (this.linkHighlightObject) {
          const searchKey = this.linkSearchKey();
          this.linkActiveObject = {...this.linkHighlightObject, searchKey};
          this.setMapObjectLink();
          return;
        }
      }

      if (this.editorType == "Code") {
        if (e.shiftKey) {
          if (this.lineContent[0] == "/") {
            execute(this, this.line.content);
            return;
          }
        } else {
          document.execCommand("insertHTML", false, "\n");
          return;
        }
      }

      const splitTag = '<br id="split">';

      document.execCommand("insertHTML", false, splitTag);

      this.$nextTick(function() {
        const [lineContent, newLineContent] = this.contentHTML().split(
          splitTag
        );

        this.lineContent = lineContent || "";
        this.$parent.$emit("input", { content: this.lineContent });

        this.contentEditable.innerHTML = this.contentEditable.innerHTML.replace(
          splitTag,
          ""
        );

        if (this.editorType === "Checkbox")
          this.$parent.$emit("addLine", {
            content: newLineContent,
            type: this.editorType,
            checked: false
          });
        else
          this.$parent.$emit("addLine", {
            content: newLineContent,
            type: this.editorType
          });
      });
    },
    keyBackspace() {
      const initialPosition = this.cursorPosition();
      if (
        this.showCommandLine &&
        this.line.content[initialPosition - 1] == "/"
      ) {
        this.showCommandLine = false;
      }

      this.$nextTick(function() {
        const position = this.cursorPosition();
        if (position == initialPosition && position == 0 && this.index !== 0) {
          const content = this.contentHTML();

          this.$parent.$emit("previousLine", {
            appendContent: content,
            position: -1
          });
          this.$parent.$emit("delete");
        }
      });
    },
    cursorPosition() {
      return getCurrentCursorPosition(this.id);
    },
    openPropertyEditorForObject(objectId) {
      if (!objectId) return false;

      const baseObject = this.$store.getters["object/findById"](objectId);
      if (baseObject) {
        this.$root.$emit("PropertyEditor.open", {
          component: ObjectPropertyEditors[baseObject.type],
          props: {
            baseObjectId: objectId,
            readonly: this.readonly
          }
        });
        return true;
      }
      return false;
    },
    // Link Object Line component select event handler
    setMapObjectLink() {
      this.api.Search.navigateTo(this.linkActiveObject).then(({ body }) => {
        let contentHTML = this.contentHTML();
        contentHTML = contentHTML.replace(`[[${this.linkActiveObject.searchKey}]]`, 
          `<a id="${this.linkId}" target="_blank" contenteditable="false" href="${body.url}">[[${this.linkActiveObject.name}]]</a>`);
        contentHTML = contentHTML.replace(`[[${this.linkActiveObject.searchKey}<a id="${this.linkId}"></a>]]`, 
          `<a id="${this.linkId}" target="_blank" contenteditable="false" href="${body.url}">[[${this.linkActiveObject.name}]]</a>`)
        this.setValue(contentHTML);
        this.contentEditable.innerHTML = contentHTML;
        this.setCurrentCursorPosition(this.linkStartPosition + this.linkActiveObject.name.length)
        // Reset afterwards
        this.resetObjectLink();
      });
    },
    resetObjectLink() {
      this.linkId = null;
      this.showLinkObjectLine = false;
      this.linkActiveObject = null;
      this.linkHighlightIndex = -1;
      this.linkHighlightObject = null;
    },
    // When blur from lineItem, make link(s) within the line clickable
    blur() {
      this.lineContent = linkifyHtml(this.lineContent, { attributes: {contenteditable: 'false'} });
      this.localActive = false
    },
    onSelectionChange() {
      if (this.localActive) {
        const innerHTML = this.contentEditable.innerHTML;
      
        // Get word within brackets ([[]]) and that word start position in contentText
        const result = this.currentWordsWithinBracket();
        if (!result) return;
        const { word: currentWord, textStartPosition, textEndPosition } = result;
        const textPosition = this.cursorPosition();
        const htmlPosition = getCurrentCursorPositionInHTML(this.id);
        const htmlStartBracketPosition = htmlPosition - textPosition + textStartPosition;
        const htmlEndBracketPosition = innerHTML.indexOf("]]", htmlPosition);
        // Just in case; no closing ]] found
        if (htmlEndBracketPosition == -1 || textEndPosition == -1) return;
        const bracketHTML = innerHTML.slice(htmlStartBracketPosition, htmlEndBracketPosition);
        if (bracketHTML != fixhtml(bracketHTML)) return; // incomplete html means no business

        const aTag = getLinkAttributeFromHTML(bracketHTML);
        const id = aTag ? aTag.getAttribute("id") : null;
        if (id) {          
          this.linkId = id;
          this.showLinkObjectLine = true;
          this.linkStartPosition = textStartPosition;
        } else {
          // NO ID
          const newId = this.guid();
          // Insert new link tag inside the existing html
          const newHTML = innerHTML.slice(0, htmlEndBracketPosition - 1) + `<a id='${newId}'></a>` + innerHTML.slice(htmlEndBracketPosition);
          this.setValue(newHTML);
          this.contentEditable.innerHTML = newHTML;

          // after showing link object line, save critical information.
          this.showLinkObjectLine = true;
          this.linkStartPosition = textStartPosition;
          this.linkId = newId;
        }
      }
    },
    // check if the current cursor is within [[ ]] and return the word within brackets
    currentWordsWithinBracket() {
      const position = this.cursorPosition();
      const innerText = this.contentEditable.innerText;
      const beforeText = innerText.slice(0, position);
      const afterText = innerText.slice(position);
      const hasStartingTag = beforeText.lastIndexOf("]]") < beforeText.lastIndexOf("[[");
      const hasEndingTag = (((afterText.indexOf("]]") < afterText.indexOf("[[")) || afterText.indexOf("[[") == -1) && afterText.indexOf("]]") != -1);
      if (hasStartingTag && hasEndingTag) 
        return { 
          word: beforeText.slice(beforeText.lastIndexOf("[[") + 2) + afterText.slice(0, afterText.indexOf("]]")), 
          textStartPosition: beforeText.lastIndexOf("[[") + 2,
          textEndPosition: position + afterText.indexOf("]]")
        };

      return null
    }
  },
  watch: {
    localActive() {
      this.$emit("update:active", this.localActive);
    },
    customComponents: {
      handler() {
        if (this.customComponents.length) {
          this.commandLineItems.push({
            name: "Custom Components",
            items: this.customComponents
          });
        }
      },
      deep: true,
      immediate: true
    },
    lineContent: {
      immediate: true,
      handler() {
        this.$nextTick(function() {
          const taNodes = this.$el.querySelectorAll(".text-analyze");
          taNodes.forEach(taNode => {
            taNode.onclick = e => {
              const { name, type, fromObjectId, objectId } = taNode.dataset;

              if (this.openPropertyEditorForObject(objectId)) {
                return true;
              }

              const types = {
                Organization: "AnalysisTools_OrganisationObject",
                Person: "AnalysisTools_PersonObject"
              };

              new Promise(resolve => {
                const url = `https://en.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&titles=${taNode.innerText}`;
                this.$http.get(url, { link: name }).then(response => {
                  const query = response.body.query;
                  const pages = query ? query.pages : null;
                  const article = Object.values(pages || {})
                    .map(t => t.extract)
                    .join("\n");
                  if (article) {
                    resolve(article);
                  } else {
                    resolve("");
                  }
                });
              }).then(content => {
                const fromObject = this.$store.getters["object/findById"](
                  fromObjectId
                );
                const position = {
                  x: fromObject.position.x + 100,
                  y: fromObject.position.y + 100
                };
                const type = types[type] || "AnalysisTools_GeneralObject";
                const objectParams = {
                  type,
                  position: position,
                  info: {
                    settings: {
                      title: taNode.innerText,
                      notesLines: [{
                        type: "Text",
                        content,
                        id: this.guid()
                      }]
                    }
                  }
                };

                this.$store
                  .dispatch("object/create", objectParams)
                  .then(object => {
                    this.$store.dispatch("connection/create", {
                      from: fromObjectId,
                      to: object.id
                    });
                    taNode.dataset.objectId = object.id;
                    this.$parent.$emit("input", {
                      content: this.contentHTML()
                    });
                    this.$root.$emit("PropertyEditor.close");
                    setTimeout(() => {
                      this.openPropertyEditorForObject(object.id);
                    }, 300);
                  });
              });
            };
          });
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  color: #8c8b8b !important;
  font-style: italic !important;
  font-weight: normal !important;
  pointer-events: none;
}

.notion-line__checkbox {
  flex-grow: 0;
  flex-shrink: 1;
  margin-top: 0;
  padding-top: 2px;
}
.notion-line__bullet {
  flex-grow: 0;
  flex-shrink: 1;
  margin-top: 0;
  margin-right: 5px;
  padding-top: 5px;
}
.notion-line__content-editable {
  flex-grow: 1;
}
</style>
