<template>
  <v-list>
    <v-list-item class="pa-0">
      <v-list-item-avatar style="background-color: #EBEBEB">
        <img v-if="avatar" :src="avatar" />
        <h2 v-else style="margin-bottom: 1px">{{capital}}</h2>
      </v-list-item-avatar>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
// import OrganisationModal from "./OrganisationModal";

export default {
  name: "AppHeaderAccount",
  data() {
    return {
      activeOrganisation: null,
      individual: {
        id: 0,
        name: "Individual Account"
      },
      addOrganisation: {
        id: -1,
        name: "+ Add Organisation"
      }
    };
  },
  mounted() {
    // this.api.Organisation.getCurrent()
    //   .then(res => this.activeOrganisation = res.body || this.individual);
  },
  methods: {
    // toggleOrganisationList() {
    //   this.organisationListIsActive = !this.organisationListIsActive;
    // },
    // hideOrganisationList() {
    //   this.organisationListIsActive = false;
    // },
    // getActiveOrganisation() {
    //   this.api.Organisation.getCurrent().then(res => {
    //     this.activeOrganisation = res.body || this.individual;
    //   });
    // },
    // showAddOrganisationModal() {
    //   this.$modal.show(OrganisationModal, {
    //     callback: (newOrganisation) => {
    //       this.activeOrganisation = newOrganisation || this.individual;
    //       this.$router.go({ name: "dashboard" });
    //     }
    //   });
    // },
    // setActiveOrganisation(id) {
    //   if (id === -1)
    //     this.showAddOrganisationModal();
    //   else
    //     this.api.Organisation.setCurrent({ id }, { id })
    //       .then(res => this.$router.go({ name: "dashboard" }));
    // }
  },
  computed: {
    ...mapGetters({
      visibleName: "user/visibleName"
    }),
    // organisations() {
    //   return [this.individual, ...this.currentUser.organisations, this.addOrganisation];
    // },
    avatar() {
      if (this.currentUser.profile)
        return this.currentUser.profile.avatar_url;
      return null;
    },
    capital() {
      return this.visibleName.charAt(0).toUpperCase();
    }
  }
};
</script>

<style scoped>
 .v-list {
   padding: 0px 0px;
 }

 .v-list-item__content {
   padding-bottom: 0px;
 }

</style>
