<template>
  <div class="mt-5" v-if="settings.videoUrl">
    <h3>{{settings.title}}</h3>
    <YoutubePlayer :value="settings.videoUrl" />
  </div>
</template>

<script>
export default {
  name: 'ControlElement_Video',
  props: {
    settings: Object
  }
}
</script>