import {defaultProperties} from "@/components/Layout/Authorized/TheEditor/Object/Types";
import store from '@/store';

export function getObjPosition(obj, connection) {
  if (obj && obj.position && obj.size) {
    return obj.position;
  } else if (connection && connection.from && connection.to) {
    const objFrom = store.getters["object/findById"](connection.from);
    const objTo = store.getters["object/findById"](connection.to);

    if (objFrom && objTo && objFrom.position && objTo.position) {
      return {
        x: Math.min(objFrom.position.x, objTo.position.x),
        y: Math.min(objFrom.position.y, objTo.position.y)
      };
    }
  }
  return null;
}

export function getObjSize(obj, connection) {
  let objSize = null;

  if (obj && obj.position && obj.size) {
    objSize = obj.size;
    if (!objSize)
      objSize = defaultProperties[obj.type].size;
    if (!objSize.height)
      objSize.height = defaultProperties[obj.type].size.height;
    if (objSize.height == 'auto')
      objSize.height = 0;
    if (!objSize.width)
      objSize.width = defaultProperties[obj.type].size.width;
    if (objSize.width == 'auto')
      objSize.width = 0;

  } else if (connection && connection.from && connection.to) {
    const objFrom = store.getters["object/findById"](connection.from);
    const objTo = store.getters["object/findById"](connection.to);

    if (objFrom && objTo && objFrom.position && objTo.position) {
      let objFromSize = objFrom.size;
      if (!objFromSize || !objFromSize.height || !objFromSize.width)
        objFromSize = defaultProperties[objFrom.type].size;
      let objToSize = objTo.size;
      if (!objToSize || !objToSize.height || !objToSize.width)
        objToSize = defaultProperties[objTo.type].size;

      objSize = {
        width:
          Math.max(
            objFrom.position.x + objFromSize.width,
            objTo.position.x + objToSize.width
          ) - Math.min(objFrom.position.x, objTo.position.x),
        height:
          Math.max(
            objFrom.position.y + objFromSize.height,
            objTo.position.y + objToSize.height
          ) - Math.min(objFrom.position.y, objTo.position.y)
      };
    }
  }
  return objSize;
}