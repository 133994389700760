const defaultSettings = {
  name: "Audio Person",
  icon: 'mdi-account-circle',
  showLabel: false,
  size: {
    width: 50,
    height: 50,
    proportion: 1,
  },
  resizable: false,
  draggable: true,
  has: {
    in: false,
    out: false,
    settings: false
  },
  layout: "horizontal",
  components: [/SpartialAudio_.*/, 'Base_SymbolObject'],
  openPropertyEditorByClick: false,
  alwaysOnTop: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
