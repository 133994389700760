<template>
  <Modal
    @submit="submit"
    title="Adding New Authorization"
    :showSubmit="false"
    content-class="oauth-new-modal"
  >
    <v-card v-if="step == STEP_INIT" loading>
      <v-card-text class="ma-4">
        Initialization...
      </v-card-text>
    </v-card>
    <v-card v-if="step == STEP_SELECT_SERVICE" class="pa-4">
      <v-card-title>Step 1: Select the service</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item-group v-model="service" color="primary">
            <v-list-item v-for="(serviceData, index) in data" :key="index" :value="serviceData">
              <v-list-item-avatar>
                <img :src="serviceData.icon">
              </v-list-item-avatar>
              <v-list-item-title>{{serviceData.name}}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="toPermissions" color="primary" :disabled="!service" class="mx-auto">
          Next
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="step == STEP_SELECT_PERMISSIONS" class="pa-4">
      <v-card-title>Step 2: Select permissions</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item-group v-if="!!service" v-model="permissions" multiple color="primary" style="overflow-y: auto; max-height: 500px">
            <v-list-item v-for="(permissionData, index) in service.permissions" :key="index" :value="permissionData.value">
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-checkbox
                    v-model="permissionData.alwaysEnabled ? true : active"
                    :disabled="permissionData.alwaysEnabled"
                    color="primary"
                    @click="toggle"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{permissionData.name}}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="authorize" color="primary" class="mx-auto">
          Authorize
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="step == STEP_WAITING" loading>
      <v-card-text class="ma-4">
        The authorization is in process. Please, wait...
      </v-card-text>
    </v-card>
    <v-card v-if="step == STEP_FINISH" class="pa-4">
      <v-card-title>
        You're successfully authoirized on {{service.name}}.
      </v-card-title>
      <v-card-subtitle class="mt-5">
        <p>Your username is:  <b>{{auth.username}}</b>.</p>
        <p>Your access token is: <b>{{auth.token}}</b>.</p>
      </v-card-subtitle>
      <v-card-actions>
        <v-btn @click="close" color="primary" class="mx-auto">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="step == STEP_ERROR" class="pa-4">
      <v-card-title>
        Error!
      </v-card-title>
      <v-card-subtitle>
        {{errorMsg}}
      </v-card-subtitle>
      <v-card-actions>
        <v-btn @click="close" color="primary" class="mx-auto">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </Modal>
</template>

<script>

  import { GITHUB_CLIENT_ID, GITHUB_CLIENT_SECRET, NETLIFY_CLIENT_ID } from "@/env";
  const ERROR_WRONG_STATE = 0;

  export default {
    name: "OAuthNew",
    props: {
      settings: Object,
      type: String,
      callback: Function,
      destroyCallback: Function
    },

    data() {
      const fixtures = [
        {
          name: 'Netlify',
          icon: require("./OAuthIcons/netlify.png"),
          urlAuth: 'https://app.netlify.com/authorize',
          urlUserInfo: 'https://api.netlify.com/api/v1/user',
          usernameKey: 'email',
          authMethod: 'parameter',
          clientId: NETLIFY_CLIENT_ID,
          windowParams: 'location=yes,status=no,width=640,height=640,resizable=yes',
          permissions: null
        },
        {
          name: 'GitHub',
          icon: require("./OAuthIcons/github.png"),
          urlAuth: 'https://github.com/login/oauth/authorize',
          urlToken: 'https://github.com/login/oauth/access_token',
          urlUserInfo: 'https://api.github.com/user',
          usernameKey: 'login',
          authMethod: 'header',
          clientId: GITHUB_CLIENT_ID,
          clientSecret: GITHUB_CLIENT_SECRET,
          windowParams: 'location=yes,status=no,width=800,height=700,resizable=yes',
          permissions: [
            {
              name: 'Public information',
              alwaysEnabled: true,
              value: ''
            },
            {
              name: 'Repos: full access',
              value: 'repo'
            },
            {
              name: 'Repos: read/write status to repos\' commit statuses',
              value: 'repo:status'
            },
            {
              name: 'Repos: read repos\' deployment statuses',
              value: 'repo_deployment'
            },
            {
              name: 'Repos: public repos read and write',
              value: 'public_repo'
            },
            {
              name: 'Invites: accept/decline',
              value: 'repo:invite'
            },
            {
              name: 'Repo hooks: full access',
              value: 'admin:repo_hook'
            },
            {
              name: 'Repo hooks: read, write and ping access',
              value: 'write:repo_hook'
            },
            {
              name: 'Repo status: read and ping access',
              value: 'read:repo_hook'
            },
            {
              name: 'Organizations: full access',
              value: 'admin:org'
            },
            {
              name: 'Organizations: read & write',
              value: 'write:org'
            },
            {
              name: 'Organizations: read only',
              value: 'read:org'
            },
            {
              name: 'Public keys: full access',
              value: 'admin:public_key'
            },
            {
              name: 'Public keys: create & read',
              value: 'write:public_key'
            },
            {
              name: 'Public keys: read',
              value: 'read:public_key'
            },
            {
              name: 'Organization hooks',
              value: 'admin:org_hook'
            },
            {
              name: 'Gists',
              value: 'gist'
            },
            {
              name: 'Notifications',
              value: 'notifications'
            },
            {
              name: 'User: full access',
              value: 'user'
            },
            {
              name: 'User: read',
              value: 'read:user'
            },
            {
              name: 'User: read email',
              value: 'user:email'
            },
            {
              name: 'User: follow others users',
              value: 'user:follow'
            },
            {
              name: 'Repo delete',
              value: 'delete_repo'
            },
            {
              name: 'Discussion: read & write',
              value: 'write:discussion'
            },
            {
              name: 'Discussion: read',
              value: 'read:discussion'
            },
            {
              name: 'Packages: upload & publish',
              value: 'write:packages'
            },
            {
              name: 'Packages: download & install',
              value: 'read:packages'
            },
            {
              name: 'Packages: delete',
              value: 'delete:packages'
            },
            {
              name: 'GPG key: full access',
              value: 'admin:gpg_key'
            },
            {
              name: 'GPG key: create & read',
              value: 'write:gpg_key'
            },
            {
              name: 'GPG key: read',
              value: 'read:gpg_key'
            }
          ]
        },
      ];

      return {
        STEP_INIT: 0,
        STEP_SELECT_SERVICE: 1,
        STEP_SELECT_PERMISSIONS: 2,
        STEP_WAITING: 3,
        STEP_FINISH: 4,
        STEP_ERROR: 5,
        step: 0,
        data: [],
        service: null,
        permissions: [],
        auth: {id: this.settings.id},
        popup: null,
        errorMsg: 'There is something wrong. Please, try again later.'
      }
    },

    async created() {
      try {
        const res = await this.api.OAuthFixtures.get();
        this.data = res.body.service_references;
        if (this.data && this.data.length)
          this.step = this.STEP_SELECT_SERVICE;
        else
          this.step = this.STEP_ERROR;
      } catch (e) {
        this.step = this.STEP_ERROR;
      }
    },

    methods: {
      toPermissions() {
        if (this.service.permissions && this.service.permissions.length)
          this.step = this.STEP_SELECT_PERMISSIONS;
        else
          this.authorize();
      },
      authorize() {
        try {
          if (this.popup && !this.popup.closed)
            return;
        } catch (e) {
          return;
        }

        this.step = this.STEP_WAITING;

        const state = Math.random().toFixed(20);
        const scope = this.permissions.join(' ');
        const params = new URLSearchParams({
          client_id: this.service.clientId,
          state,
          scope,
          response_type: 'token',
          redirect_uri: window.location.origin
        }).toString();

        this.popup = window.open(
          `${this.service.urlAuth}?${params}`,
          `${this.service.name} Vulcan authorization ${state}`,
          this.service.windowParams
        );

        new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            try {
              if (this.popup.closed) {
                this.close();
                return;
              }
              let params = new URLSearchParams(this.popup.location.search.substring(1));
              params = Object.fromEntries(params);
              if (params.code) {
                clearInterval(interval);
                this.popup.close();
                if (params.state == state)
                  resolve({code: params.code});
                else
                  reject(ERROR_WRONG_STATE);
              }

              params = new URLSearchParams(this.popup.location.hash.replace(/^#/, ''));
              params = Object.fromEntries(params);
              if (params.access_token) {
                clearInterval(interval);
                this.popup.close();
                if (params.state == state)
                  resolve({token: params.access_token});
                else
                  reject(ERROR_WRONG_STATE);
              }

            } catch(e) {}
          }, 300);
        })
        .then(({code, token}) => {
          if (token)
            return token;

          if (code) {
            const params = new URLSearchParams({
              client_id: this.service.clientId,
              client_secret: this.service.clientSecret,
              code: code,
              state
            }).toString();

            return this.$http.post(`${this.service.urlToken}?${params}`)
              .then(response => {
                if (!response.ok)
                  return Promise.reject();

                const params = new URLSearchParams(response.body);
                return params.get('access_token');
              });
          }
        })
        .then(token => {
          this.auth.serviceName = this.service.name;
          this.auth.token = token;

          switch (this.service.authMethod) {
            case 'parameter':
              return this.$http.get(`${this.service.urlUserInfo}?access_token=${token}`);

            case 'header':
              return this.$http.get(this.service.urlUserInfo, {
                headers: {Authorization: `token ${token}`}
              });

            default: return null;
          }
        })
        .then(response => {
          if (!response.ok)
            return Promise.reject();

          this.auth.username = response.body[this.service.usernameKey];
          if (this.callback)
            this.callback(this.auth);
          this.step = this.STEP_FINISH;
        })
        .catch(error => {
          this.step = this.STEP_ERROR;
          switch (error) {
            case ERROR_WRONG_STATE:
              this.errorMsg = 'Your connection is not safe. Please, try on another computer.';
              break;
          }
        });
      },
      close() {
        this.$emit("close");
      },
      submit() {
        switch (this.step) {
          case this.STEP_SELECT_SERVICE:
            this.toPermissions();
            break;

          case this.STEP_SELECT_PERMISSIONS:
            this.authorize();
            break;

          case this.STEP_FINISH:
          case this.STEP_ERROR:
            this.close();
            break;
        }
      },
    }
  }
</script>

<style>
  .oauth-new-modal {
    width: 500px !important;
  }
</style>