<template>
  <v-toolbar dense no-padding>
    <Uploader
      width="40px"
      height="40px"
      :disabled="readonly"
      label="Image"
      icon="image"
      class="mx-2"
      v-model="localSettings.image"
    />

    <v-divider class="mr-2" vertical />

    <v-text-field
      class="pa-0"
      hide-details
      placeholder="Link"
      v-model="localSettings.link"
      :readonly="readonly"
    />

    <v-divider class="ml-2" vertical />

    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="notes">Notes</v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="addons">Addons</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <AddonsIcons
      :baseObject="baseObject"
      :tabs="tabs"
      :localSettings="localSettings"
      @change="onChangeAddonTab"
      :parentTab="openedData"
    />

    <v-divider v-if="tabs && tabs.length" vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";
import AddonsList from "../../../../_Global/AddonsList";

export default {
  name: "AnalysisTools_PersonPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data: function() {
    return {
      openedData: "notes"
    };
  },

  methods: {
    onOpenNotion() {
      this.localSettings = { notesLines: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    },
    onOpenAddons() {
      this.localSettings = { addons: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: AddonsList,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'addons',
        title: "Addons"
      });
    },
    onChangeAddonTab() {
      this.openedData = null;
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case null:
          break;
        case "notes":
          this.onOpenNotion();
          break;
        case "addons":
          this.onOpenAddons();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenNotion();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>
