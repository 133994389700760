import store from "@/store";
import localVueLoader from "@/lib/local-vue-loader";

export function compileTemplate ({ componentId, template, shortcut, env = [] }) {
  return new Promise(resolve => {
    if (template) {
      const componentObject = localVueLoader(template)();

      componentObject.then(component => {
        resolve({ component, env });
      });
    }

    if (componentId || shortcut) {
      let componentData;
      if (componentId)
        componentData = store.getters["component/findById"](componentId);
      else if (shortcut)
        componentData = store.getters["component/findByShortcut"](shortcut);

      const { template, vars } = componentData;
      const componentObject = localVueLoader(template)();

      componentObject.then(component => {
        resolve({ component, env: vars });
      });
    }
  });
}

export function getENV(localEnv) {
  const currentUser = store.getters["user/current"];
  const userVars = currentUser ? currentUser.environment_variables : [];

  const activeProject = store.getters["project/active"];
  const projectVars = activeProject
    ? activeProject.settings.credentials.custom
    : [];

  const env = {};
  (userVars || []).forEach(v => (env[v.key] = v.value));
  (projectVars || []).forEach(v => (env[v.key] = v.value));
  (localEnv || []).forEach(v => (env[v.key] = v.value));
  return env;
}

export function getOAUTH() {
  const currentUser = store.getters["user/current"];
  const userOauths = currentUser ? currentUser.oauth_tokens : [];

  const oauth = {};
  (userOauths || []).forEach(v => {
    oauth[v.serviceName.toLowerCase()] = v.token;
    oauth[`${v.serviceName.toLowerCase()}__${v.username.toLowerCase()}`] = v.token;
  });
  return oauth;
}

function getRectObjectCoordinates(rectObject) {
  const {width, height} = rectObject.size;
  const {x, y} = rectObject.position;
  const angle = rectObject.info.settings.angle ? rectObject.info.settings.angle : 0;

  let angleRad = angle / 360 * 2 * Math.PI;
  let cos = Math.cos(angleRad);
  let sin = Math.sin(angleRad);

  let x4 = - width / 2 * cos - height / 2 * sin;
  let y4 = - width / 2 * sin + height / 2 * cos;

  let x3 =   width / 2 * cos - height / 2 * sin;
  let y3 =   width / 2 * sin + height / 2 * cos;

  let x2 =   width / 2 * cos + height / 2 * sin;
  let y2 =   width / 2 * sin - height / 2 * cos;

  let x1 = - width / 2 * cos + height / 2 * sin;
  let y1 = - width / 2 * sin - height / 2 * cos;

  x1 += x + width/2;
  x2 += x + width/2;
  x3 += x + width/2;
  x4 += x + width/2;
  y1 += y + height/2;
  y2 += y + height/2;
  y3 += y + height/2;
  y4 += y + height/2;

  return {x1, y1, x2, y2, x3, y3, x4, y4};
}

export function detectSpaceId({x, y}, {width, height}) {
  const chartId = store.state.chart.active.id;
  const spaceList = store.getters['object/spaceList'];
  if (!spaceList || spaceList.length < 1)
    return chartId;

  const X = x + width/2;
  const Y = y + height/2;

  for (const space of spaceList) {
    const {x1, y1, x2, y2, x3, y3, x4, y4} = getRectObjectCoordinates(space);

    const product = (Px, Py, Ax, Ay, Bx, By) =>
      (Bx - Ax) * (Py - Ay) - (By - Ay) * (Px - Ax);

    const p1 = product(X, Y, x1, y1, x2, y2),
          p2 = product(X, Y, x2, y2, x3, y3),
          p3 = product(X, Y, x3, y3, x4, y4),
          p4 = product(X, Y, x4, y4, x1, y1);

    if ((p1 < 0 && p2 < 0 && p3 < 0 && p4 < 0) ||
        (p1 > 0 && p2 > 0 && p3 > 0 && p4 > 0)) {
      return space.id;
    }
  }
  return chartId;
}
