import RectObject from "./RectObject";
import PolygonObject from "./PolygonObject";
import StickyObject from "./StickyObject";
import TextObject from "./TextObject";
import GroupObject from "./GroupObject";
import ArrowObject from "./ArrowObject";
import CommentObject from "./CommentObject";
import ImageObject from "./ImageObject";
import TemplateObject from "./TemplateObject";
import SymbolObject from "./SymbolObject";
import EmbedObject from "./EmbedObject";
import DrawingObject from "./DrawingObject";

export default {
  objects: {
    TemplateObject,
    RectObject,
    PolygonObject,
    TextObject,
    GroupObject,
    ArrowObject,
    CommentObject,
    ImageObject,
    SymbolObject,
    EmbedObject,
    StickyObject,
    DrawingObject
  },
  tab: "Base"
};
