<template>
  <div class="polygon-object" @click="onClick" :style="containerStyles">
    <component
      :is="'Polygon_' + settings.shape"
      :settings.sync="localSettings"
      :baseObject="baseObject"
      :selected="selected"
      :readonly="readonly"
    />
    <div class="polygon-note">
      <Base_TextObject
        :settings.sync="localSettings"
        :baseObject="baseObject"
        :readonly="readonly"
        ref="textObj" />
    </div>
  </div>
</template>

<script>
import Base_TextObject from "../TextObject/Component";
import ExtendsComponent from "../../_extends/ObjectComponent";
import Polygon_Rect from "./Shapes/Rect";
import Polygon_Triangle from "./Shapes/Triangle";
import Polygon_Diamond from "./Shapes/Diamond";
import Polygon_Circle from "./Shapes/Circle";
import Polygon_Label from "./Shapes/Label";
import Polygon_Step from "./Shapes/Step";

export default {
  name: "Base_PolygonObject",
  props: ['selected'],
  extends: ExtendsComponent,
  components: {
    Base_TextObject,
    Polygon_Rect,
    Polygon_Triangle,
    Polygon_Diamond,
    Polygon_Circle,
    Polygon_Label,
    Polygon_Step
  },
  computed: {
    containerStyles() {
      return {
        fontSize: (this.localSettings.fontSize || 0) + "px",
        textAlign: this.localSettings.textAlign || "center"
      };
    },
    contentEditable() {
      return this.$refs.textObj.contentEditable;
    }
  },
  methods: {
    onClick() {
      if (!this.$refs.textObj.contentEditable)
        this.$store.commit('object/setContentEditable', false);
    }
  }
};
</script>

<style scoped>
.polygon-object {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.polygon-note {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>

<style>
.Base_PolygonObject > .base-object__content {
  height: 100%;
}
</style>
