const defaultSettings = {
  name: 'Polygon',
  icon: 'mdi-shape-polygon-plus',
  size: {
    width: 150,
    height: 100
  },
  resizable: true,
  draggable: true,
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: true,
  subToolbar: true,
  settings: {
    backgroundColor: "#e7e7e7",
    strokeWidth: 1,
    borderColor: "#555",

    textColor: "#333",
    textAlign: "center",
    verticalAlign: "center",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "open sans",

    locked: false,
    order: 0,
    notionEnabled: false,
    audioEnabled: false
  }
};

export default defaultSettings;