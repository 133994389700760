<template>
  <v-toolbar dense no-padding>
    <v-checkbox
      class="mx-2"
      style="padding-top: 23px"
      :disabled="readonly"
      label="Allow Custom Input"
      v-model="localSettings.allowCustomInput"
    />

    <v-divider vertical />

    <v-btn-toggle group borderless v-model="openedData">
      <v-btn
        text
        :disabled="!localSettings.allowCustomInput"
        style="opacity: 1"
        class="ma-0"
      >Intellisense</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_WayfinderContentObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpIntellisense = Vue.extend({
      template: `
        <OptionsList
          :dimension="2"
          v-model="settings.intellisenseList"
          itemName="Intellisense"
          nameLabel="Display Value"
          valueLabel="Signal"
          :readonly="readonly"
        />
      `,
      props: ["settings", "readonly"]
    });

    return {
      cmpIntellisense,
      openedData: this.settings.allowCustomInput ? 0 : undefined
    };
  },

  methods: {
    onOpenIntellisense() {
      this.localSettings = { intellisenseList: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: this.cmpIntellisense,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Intellisense"
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenIntellisense();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    "localSettings.allowCustomInput": function(newV, oldV) {
      if (oldV && !newV)
        this.$root.$emit("DataEditor.close");
    },
    'baseObject.id'() {
      if (this.localSettings.allowCustomInput)
        this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    if (this.localSettings.allowCustomInput)
      this.onOpenIntellisense();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>
