import Parse from 'parse';
import { Howl, Howler } from 'howler';
import {
  HiFiAudioAPIData, HiFiCommunicator, AvailableUserDataSubscriptionComponents,
  UserDataSubscription, HiFiConnectionStates} from 'hifi-spatial-audio';

import {
  HFSA_THRESHOLD_DEFAULT, HFSA_STATUS_CONNECTING, HFSA_STATUS_DISCONNECTED, HFSA_STATUS_CONNECTED,
  HFSA_MAPPING_COEF, HFSA_MIN_VOLUME_DIFFERENCE, HFSA_MULTIPLY_VOLUME_FACTOR, HFSA_MAX_VOLUME_VALUE
} from "@/lib";



function volumeAudioProcess(event) {
  const buf = event.inputBuffer.getChannelData(0);
  const bufLength = buf.length;
  let sum = 0;
  let x;

  // Do a root-mean-square on the samples: sum up the squares...
  for (let i = 0; i < bufLength; i++) {
    x = buf[i];
    if (Math.abs(x) >= this.clipLevel) {
      this.clipping = true;
      this.lastClip = window.performance.now();
    }
    sum += x * x;
  }

  // ... then take the square root of the sum.
  const rms = Math.sqrt(sum / bufLength);

  // Now smooth this out with the averaging factor applied
  // to the previous sample - take the max here because we
  // want "fast attack, slow release."
  //console.log(rms);
  let p = 0;

  if (Math.abs(p - rms) > HFSA_MIN_VOLUME_DIFFERENCE) {
    p = Math.min(rms * HFSA_MULTIPLY_VOLUME_FACTOR, HFSA_MAX_VOLUME_VALUE);
  }
}

function createAudioMeter(audioContext) {
  const processor = audioContext.createScriptProcessor(512);
  processor.onaudioprocess = volumeAudioProcess;
  processor.clipping = false;
  processor.lastClip = 0;
  processor.volume = 0;
  processor.clipLevel = 0.98;
  processor.averaging = 0.95;
  processor.clipLag = 750;

  // this will have no effect, since we don't copy the input to the output,
  // but works around a current Chrome bug.
  processor.connect(audioContext.destination);

  processor.checkClipping = function () {
    if (!this.clipping) {
      return false;
    }
    if ((this.lastClip + this.clipLag) < window.performance.now()) {
      this.clipping = false;
    }
    return this.clipping;
  }

  processor.shutdown = function () {
    this.disconnect();
    this.onaudioprocess = null;
  }

  return processor;
}


const store = {
  namespaced: true,
  state: {
    outputAudioElm: null,
    hifiCommunicator: null,
    hifiStatus: HFSA_STATUS_DISCONNECTED,
    users: [],
    mediaStreamSource: null,
    currentSpaceId: null,
    muted: false
  },
  mutations: {
    setOutputAudioElm(state, elm) {
      state.outputAudioElm = elm;
    },
    setHifiCommunicator(state, hifiCommunicator) {
      state.hifiCommunicator = hifiCommunicator;
    },
    setHifiStatus(state, hifiStatus) {
      state.hifiStatus = hifiStatus;
    },
    addUsers(state, _users) {
      for (let _user of _users) {
        if (!_user.providedUserID && !_user.hashedVisitID)
          continue;

        const user = state.users.find(u =>
          _user.providedUserID === u.providedUserID || _user.hashedVisitID === u.hashedVisitID);

        if (user) {
          if (typeof (_user.volumeDecibels) === "number")
            user.volumeDecibels = _user.volumeDecibels;
        } else {
          state.users.push(_user);
        }
      }
    },
    removeUsers(state, usersDis) {
      state.users = state.users.filter(user => {
        return !usersDis.find(u =>
          user.providedUserID === u.providedUserID || user.hashedVisitID === u.hashedVisitID);
      });
    },
    clearUsers(state) {
      state.users = [];
    },
    setMuted(state, muted) {
      state.muted = muted;
    },
    setCurrentSpaceId(state, spaceId) {
      state.currentSpaceId = spaceId;
    }
  },
  actions: {
    async connectToHiFiAudio({state, commit, dispatch, rootGetters}, params) {
      if (state.hifiStatus != HFSA_STATUS_DISCONNECTED)
        return;

      const { spaceId, spaceName, userData } = params;
      commit('setHifiStatus', HFSA_STATUS_CONNECTING);
      commit('setCurrentSpaceId', spaceId);


      // === get JWT-token

      let hifiAudioJWT;
      try {
        hifiAudioJWT = await Parse.Cloud.run("generateAudioJWT", {
          userID: rootGetters['user/current'].username,
          vulcanSpaceId: spaceId,
          spaceName
        });
      } catch(e) {
        console.error(`Error while generating audio JWT\n${e}`);
        return;
      }


      // === initialize HiFiCommunicatior with user data

      const onConnectionStateChanged = connectionState => {
        if (connectionState === HiFiConnectionStates.Disconnected || connectionState === HiFiConnectionStates.Failed)
          dispatch("disconnectFromHiFiAudio", true);
      };
      const onUsersDisconnected = usersDis => commit("removeUsers", usersDis);

      const initialHiFiAudioAPIData = new HiFiAudioAPIData(userData);
      state.hifiCommunicator = new HiFiCommunicator({
        initialHiFiAudioAPIData,
        onConnectionStateChanged,
        onUsersDisconnected
      });


      // === Set input

      try {
        // === Just for get mic permissions
        await navigator.mediaDevices.getUserMedia({audio: true, video: false});

        // === Get input devices
        let inputDevices = [];
        try {
          let devices = await navigator.mediaDevices.enumerateDevices();
          inputDevices = devices.filter(device => device.kind == 'audioinput');
        } catch (e) {
          console.error(`Error in \`enumerateDevices()\`:\n${e}`);
        }

        let audioConstraints = rootGetters['user/audioSettings'];
        if (audioConstraints.deviceId) {
          const device = inputDevices.find(d => d.id == audioConstraints.deviceId);
          if (device)
            audioConstraints.deviceId = {exact: audioConstraints.deviceId};
          else
            audioConstraints.deviceId = undefined;
        }

        let audioMediaStream = await navigator.mediaDevices.getUserMedia({
          audio: audioConstraints,
          video: false
        });
        await state.hifiCommunicator.setInputAudioMediaStream(audioMediaStream);

        // TODO: meter
        /*const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        state.mediaStreamSource = audioContext.createMediaStreamSource(audioMediaStream);
        const meter = createAudioMeter(audioContext);
        state.mediaStreamSource.connect(meter);*/

      } catch(err) {
        console.error("Can't access audio input:");
        console.error(err);
      }


      // === Connect to server

      try {
        await state.hifiCommunicator.connectToHiFiAudioAPIServer(hifiAudioJWT);
      } catch (e) {
        console.error(`Error connecting to High Fidelity:\n${e}`);
        dispatch('disconnectFromHiFiAudio', true);
        return;
      }


      // === Subscribe to users' data (only on volume level)

      const onNewUserDataReceived = _users => commit("addUsers", _users);

      let newUserDataSubscription = new UserDataSubscription({
        components: [
          AvailableUserDataSubscriptionComponents.VolumeDecibels
        ],
        callback: onNewUserDataReceived
      });
      state.hifiCommunicator.addUserDataSubscription(newUserDataSubscription);


      // === Associating with HTML audio element
      state.outputAudioElm.srcObject = state.hifiCommunicator.getOutputAudioMediaStream();
      state.outputAudioElm.play();

      dispatch('playAudioBoxesWithinSpace', spaceId);
      commit('setHifiStatus', HFSA_STATUS_CONNECTED);
    },

    async disconnectFromHiFiAudio({state, commit, rootState, dispatch}, alreadyDis) {
      try {
        if (!state.hifiCommunicator || state.hifiStatus === HFSA_STATUS_DISCONNECTED || state.hifiStatus === HFSA_STATUS_CONNECTING)
          return;

        if (!alreadyDis) {
          commit('setHifiStatus', HFSA_STATUS_CONNECTING);
          await state.hifiCommunicator.disconnectFromHiFiAudioAPIServer();
        }
        commit('clearUsers');
        commit('setHifiStatus', HFSA_STATUS_DISCONNECTED);
        //TODO: meter
        //state.mediaStreamSource.disconnect();

/*        const audioObjects = rootState.object.list
          .filter(object => object.type === "SpatialAudio_AudioPersonObject" && object.userId === rootState.user.current.id);
        for (const audioObject of audioObjects) {
          await dispatch('object/delete', audioObject.id, {root: true});
        }*/
      } catch (error) {
        console.log("error in disconnecting", error);
      }
    },

    async updateInputAudioMediaStream({state, commit, rootGetters}, settings) {
      let audioSettings = {...rootGetters['user/audioSettings'], ...settings};
      commit('user/updateAudioSettings', audioSettings, {root: true});

      if (!state.hifiCommunicator)
        return;

      let audioConstraints = {...audioSettings};
      if (settings.deviceId)
        audioConstraints.deviceId = {exact: audioSettings.deviceId};
      try {
        const audioMediaStream = await navigator.mediaDevices.getUserMedia({
          audio: audioConstraints,
          video: false
        });
        await state.hifiCommunicator.setInputAudioMediaStream(audioMediaStream);
      } catch (e) {
        console.error(`Error while set audio input stream\n${e}`);
      }

      if (settings.hiFiGain !== undefined || settings.volumeThreshold !== undefined) {
        try {
          await state.hifiCommunicator.updateUserDataAndTransmit({
            hiFiGain: audioSettings.hiFiGain,
            volumeThreshold: audioSettings.volumeThreshold
          });
        } catch (e) {
          console.error(`Error while updateUserDataAndTransmit\n${e}`);
        }
      }
    },

    async setMuted({state, commit}, muted) {
      commit('setMuted', muted);
      if (state.hifiCommunicator) {
        try {
          await state.hifiCommunicator.updateUserDataAndTransmit({
            volumeThreshold: muted ? 0 : HFSA_THRESHOLD_DEFAULT
          });
        } catch (e) {
          console.error(`Error while updateUserDataAndTransmit\n${e}`);
        }
      }
    },

    playAudioBoxesWithinSpace({state, rootGetters, rootState}, spaceId) {
      const space = rootGetters["object/findById"](spaceId);
      if (!space && spaceId !== rootState.chart.active.id)
        return; // Stop processing for invalid hifiStatus
      Howler.stop();
      const audioBoxes = rootGetters["object/spaceAudioBoxesList"](space);
      for (const audioBox of audioBoxes) {
        const audioSetting = audioBox.info.settings.audioSetting;
        const newSound = new Howl({
          src: [audioSetting.url],
          html5: true,
          volume: audioSetting.audioVolume,
          loop: audioSetting.loop
        });
        const soundId = newSound.play();
        newSound.once('play', function() {
          // Set the position of the speaker in 3D space.
          newSound.pos(audioBox.position.x / HFSA_MAPPING_COEF, 0, audioBox.position.y / HFSA_MAPPING_COEF, soundId);
          newSound.volume(audioBox.info.settings.audioSetting.audioVolume, soundId);

          // Tweak the attributes to get the desired effect.
          newSound.pannerAttr({
            panningModel: 'HRTF',
            refDistance: 1,
            rolloffFactor: audioBox.info.settings.audioSetting.spatialDistance,
            distanceModel: 'exponential'
          }, soundId);
        }.bind(audioBox), soundId);
      }
    }
  },
  getters: {
    hifiStatus(state) {
      return state.hifiStatus;
    },
    hifiCommunicator(state) {
      return state.hifiCommunicator;
    },
    getUserData: state => username => {
      return state.users.find(user => user.providedUserID == username);
    },
  }
};

export default store;
