const defaultSettings = {
  name: "Embed",
  icon: 'mdi-library-plus',
  hotkey: `modkey + E`,
  showLabel: false,
  size: {
    width: 300,
    height: 314,
    minHeight: 314,
    minWidth: 300,
    maxHeight: 1200,
    maxWidth: 1200
  },
  resizable: true,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  layout: "horizontal",
  inColor: "#e44e9d",
  outColor: "#505fa6",
  components: [/AnalysisTools_.*/, "Base_EmbedObject"],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;