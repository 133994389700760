<template>
  <div class="variables pa-1">
    <v-row wrap v-for="variable in localVars" :key="variable.id" no-gutters>
      <v-col cols="5">
        <v-text-field :disabled="disabled" v-model="variable.key" solo placeholder="Key" />
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-text-field
          :disabled="disabled"
          v-model="variable.value"
          solo
          placeholder="Value"
          :append-icon="getEyeIcon(variable.id)"
          :type="(show[variable.id] || showVars) ? 'text' : 'password'"
          @click:append="onClickEye(variable.id)"
        />
      </v-col>
      <v-col v-if="!disabled" cols="auto">
        <v-btn text class="toolbar-button" @click="deleteVar(variable.id)">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <slot :variable="variable" />
      </v-col>
    </v-row>
    <v-btn color="secondary no-uppercase" @click="addVar">Add Item</v-btn>
    <input type="file" ref="csvFile" style="display: none" @change="csvUploaded">
    <v-btn color="secondary" outlined class="ml-4 no-uppercase font-weight-black" @click="$refs.csvFile.click()">Import CSV</v-btn>
  </div>
</template>

<script>
export default {
  name: "Configuration",
  props: {
    vars: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: Boolean,
    showVars: Boolean
  },
  data() {
    return {
      localVars: [],
      show: {}
    };
  },
  methods: {
    getEyeIcon(id) {
      if (this.showVars)
        return null;
      return this.show[id] ? 'mdi-eye' : 'mdi-eye-off';
    },
    onClickEye(id) {
      this.$set(this.show, id, !this.show[id]);
    },
    addVar() {
      this.localVars.push({
        id: this.guid()
      });
    },
    deleteVar(id) {
      this.localVars = this.localVars.filter(v => v.id != id);
    },
    csvUploaded(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', (e) => {
        let csvdata = e.target.result; 
        this.parseCSV(csvdata); 
      });
    
      fileReader.readAsBinaryString(files[0]);
    },
    parseCSV(data) {
      let linesData = data.split("\n");
      for(let i = 0; i < linesData.length; i++) {
        let cellsData = linesData[i].split(",");
        if (cellsData && cellsData.length >= 2) {
          this.localVars.splice(this.localVars.length, 1, {
            id: this.guid(),
            key: cellsData[0],
            value: cellsData[1]
          });
        }
      }
    }
  },
  watch: {
    vars: {
      handler() {
        if (JSON.stringify(this.localVars) != JSON.stringify(this.vars)) {
          if (this.vars)
            this.localVars = [...this.vars];
          else
            this.localVars = [];
        }
      },
      deep: true,
      immediate: true
    },
    localVars: {
      handler() {
        this.$emit("save", [...this.localVars]);
      },
      deep: true
    }
  }
};
</script>

<style scoped>

.variables >>> .v-label,
.variables >>> input {
  color: #333 !important;
}
</style>
