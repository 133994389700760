<template>
</template>

<script>
import Vue from "vue";
import ControlPropertyEditorExtend from '../_extends/ControlPropertyEditor'

export default {
  name: 'ControlElement_InputControlPropertyEditor',

  extends: ControlPropertyEditorExtend,

  data() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-layout>
            <v-flex xs8>
              <Autocompleter solo v-model="settings.showCondition" label="Show Condition, default true" :readonly="readonly" />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs3>
              <v-text-field type="number" v-model="settings.width" label="Width, %" :readonly="readonly" />
            </v-flex>
          </v-layout>
          <v-text-field type="text" v-model="settings.model" label="Model (for use in expressions)" :readonly="readonly" />

          <v-layout>
            <v-flex xs3>
              <v-select v-model="settings.inputType" :items="items" label="Type" :readonly="readonly" />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs8>
              <v-text-field type="text" v-model="settings.placeholder" label="Placeholder" :readonly="readonly" />
            </v-flex>
          </v-layout>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {items: ['text', 'email', 'number', 'password']};
      }
    });

    return {
      cmpData
    };
  },

  watch: {
    id() {
      this.openData();
    }
  },

  mounted() {
    this.openData();
  },

  methods: {
    openData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Data'
      });
      //TODO: костыль
      //this.$root.$emit('PropertyEditor.close');
    }
  }
}
</script>