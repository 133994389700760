<template>
  <component :is="wrapper" title="Open Project" :showSubmit="false">
    <v-layout>
      <v-flex sm12>
        <v-card flat class="projects-list-item" v-for="project in projects" :key="project.id">
          <v-card-title class="project-title">
            <img height="66" :src="projectImage(project)">
            <span class="headline font-weight-light ml-2">{{project.name}}</span>
            <v-spacer />
            <v-menu bottom float-left>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(menuItem, i) in menuItems"
                  :key="i"
                  @click="menuItem.callback ? menuItem.callback(project) : null"
                >
                  <v-list-item-title :class="menuItem.color">{{ menuItem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                md3
                v-for="chart in project.charts"
                :key="chart.id"
              >
                <v-hover v-slot="{ hover }">

                <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  light
                  @click="open({chartId: chart.id, projectId: project.id})"
                  class="projects-list-item__chart"
                >
                  <v-img :src="chartPlaceholderImage(project)" aspect-ratio="2.75" />
                  <v-card-title>
                    <v-layout>
                      <v-flex>
                        <div class="title">{{chart.name}}</div>
                        <v-divider />
                        <div class="chart-time">
                          <v-layout>
                            <v-flex xs12 class="chart-time__time">
                              <div><v-icon small>update</v-icon> Last updated {{friendlyTime(chart.last_update)}}</div>
                            </v-flex>
                            <v-flex>
                              <v-icon @click="favorite">favorite_border</v-icon>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-card-actions>
                    <!-- <v-list-item>
                      <v-list-item-avatar
                        class="collaborator-item"
                        v-for="i in 4"
                        :key="`1${i}`"
                      >
                        <img
                          src="https://cdn.vuetifyjs.com/images/john.jpg"
                          alt="John"
                          height="6"
                          class="avatar-image"
                        >
                      </v-list-item-avatar>
                    </v-list-item> -->
                  </v-card-actions>
                </v-card>
              </v-hover>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </component>
</template>

<script>
import moment from "moment";

import EditProject from "./Edit";

export default {
  name: "OpenProject",
  props: {
    wrapper: {
      type: String,
      default: "Modal"
    }
  },
  data() {
    return {
      projects: [],
      selectedProject: null,
      menuItems: [
        {
          title: 'Edit',
          color: 'text-black',
          callback: (project) => {
            this.$modal.show(
              EditProject,
              {
                params: project,
                callback: res => {
                  project = { ...project, ...res };
                  this.api.Project.update(
                    { id: project.id },
                    { project: project }
                  ).then(response => {
                    let projectIndex = this.projects.findIndex((item) => item.id === project.id);
                    this.projects.splice(projectIndex, 1, project);
                  });
                }
              },
              { scrollable: true, height: "auto" }
            );
          }
        },
        {
          title: 'Delete',
          color: 'text-red',
          callback: (project) => {
            if (confirm("Are you sure? This cannot be undone.")) {
              this.api.Project.delete({ id: project.id })
                .then(response => {
                  let projectIndex = this.projects.findIndex((item) => item.id === project.id);
                  if (projectIndex >= 0)
                    this.projects.splice(projectIndex, 1);
                });
            }
          }
        },
      ]
    };
  },
  async created() {
    const res = await this.api.Project.query();
    const projects = res.body;
    projects.forEach(project => {
      project.charts.sort(
        (a, b) => a.options.order - b.options.order
      );
    });
    this.projects = projects;
  },
  methods: {
    open({ chartId, projectId }) {
      this.$router.push({ name: "editor", params: { chartId, projectId } });
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    projectImage(project) {
      var imageUrl = ""
      if (project.type == 'Chatbot') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/chatbot-icon.svg"
      }
      return imageUrl
    },
    chartPlaceholderImage(project) {
      var imageUrl = ""
      if (project.type == 'AnalysisTools') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/knowledge-project-placeholder.jpg"
      } else if (project.type == 'Chatbot') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/chatbot-project-placeholder.jpg"
      } else {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/knowledge-project-placeholder.jpg"
      }
      return imageUrl
    },
    friendlyTime(date) {
      return moment(date).fromNow()
    },
    favorite(chart) {
      console.log('make chart favorited');
    }
  },
  computed: {}
};
</script>

<style scoped>
.projects-list {
  display: flex;
  flex-wrap: wrap;
}

.projects-list-item {
  padding: 12px;
  margin-bottom: 12px;
}
.project-title__icon {
  margin-right: 12px;
}

.title {
  margin-bottom: 20px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chart-time {
  font-size: 10px;
  margin-top: 10px;
  color: grey;
}

.projects-list-item__chart {
  margin: 20px 4px;
  border-radius: 12px !important;
  /* width: 300px; */
}
.projects-list-item__chart > span {
  cursor: pointer !important;
}

.projects-list-item__chart:hover > span {
  background: #fe00620d;
}

.collaborator-item:first-child {
  margin-left: -16px;
}

.collaborator-item:not(:first-child) {
  margin-left: -30px;
}

.avatar-image {
  border: 2px solid white;
}

.text-red {
  color: red;
}

.text-black {
  color: black;
}

.chart-time__time {
  width: calc(100% - 32px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
  .projects-list-item__name {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .projects-list-item__chart {
    display: inline-block;
    margin: 5px;
    padding: 5px 10px;
    background: #535fa6;
    color: white;
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
    transition: .2s;
    border-radius: 3px;
  }

  .projects-list-item__chart:hover {
    filter: brightness(110%);
  } */
</style>
