<template>
  <div class="chatbot__bot-message">
    <div
      class="chatbot__bot-message__message d-flex py-1"
      :class="{first: index == 0}"
      :key="message.id"
      v-for="(message, index) in localSettings.messages"
    >
      <v-icon
        class="chatbot__bot-message__delete-message ml-2"
        @click.stop="deleteMessage(message.id)"
      >close</v-icon>
      <v-textarea
        class="pa-0 my-0 ml-2"
        auto-grow
        rows="1"
        v-model="message.content"
        :readonly="readonly"
        hide-details
        dark
        placeholder="Type message"
      />
    </div>
    <div v-if="!readonly">
      <div class="add" @click.stop="addMessage()">Add Message Variant</div>
    </div>

    <br />
    <div>
      <Controls
        :baseObject="baseObject"
        type="body"
        label="Body"
        class="chatbot__bot-message__body"
        :controls.sync="localSettings.bodyControls"
        group="body"
        :readonly="readonly"
      />
      <br />
      <Controls
        :baseObject="baseObject"
        type="actions"
        label="Actions"
        class="chatbot__bot-message__actions"
        :controls.sync="localSettings.actionsControls"
        group="actions"
        :readonly="readonly"
      />
    </div>
  </div>
</template>

<script>
import Base_TextObject from "../../Base/TextObject/Component";
import Controls from "@/components/_Common/ControlElements/Controls";
import ExtendsComponent from "../../_extends/ObjectComponent";

import { deepClone } from "@/lib";

export default {
  name: "Chatbot_BotMessageObject",
  extends: ExtendsComponent,
  components: {
    Base_TextObject,
    Controls,
  },
  data() {
    const localSettings = {
      messages: [],
      bodyControls: [],
      actionsControls: [],
      ...deepClone(this.settings)
    };
    return {
      localSettings,
      controlPickerOpened: false,
      controlPickerSectionName: null
    };
  },
  methods: {
    setModel() {
      const controls = []
        .concat(this.baseObject.info.settings.actionsControls || [])
        .concat(this.baseObject.info.settings.bodyControls || []);

      const models = controls
        .map(a => {
          if (a.settings.model)
            return a.settings.model;
        })
        .filter(a => a);

      const schema = {};
      models.forEach(e => schema[e] = true);
      this.$store.commit("intellisense/setModel", schema);
    },
    addMessage() {
      this.localSettings.messages.push({ id: this.guid(), content: '' });
    },
    deleteMessage(id) {
      const index = this.localSettings.messages.findIndex(m => m.id == id);
      this.localSettings.messages.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.chatbot__bot-message {
  border: 4px solid #605da5;
  border-radius: 20px;
  padding: 20px;
  background: white;
  position: relative;
  width: 380px;
}
.chatbot__bot-message__message {
  background: #555fa6;
  border-radius: 5px;
  margin-bottom: 10px;
}
.chatbot__bot-message__delete-message {
  color: white !important;
  cursor: pointer;
}
.chatbot__bot-message__body {
  min-height: 90px;
}
.chatbot__bot-message__actions {
  min-height: 58px;
}
.chatbot__bot-message__body,
.chatbot__bot-message__actions {
  margin-top: 15px;
}
</style>
