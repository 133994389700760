import * as _ from "underscore";
import store from "@/store";
import { Chatbot, Callback } from "@/resources";

export const setSlides = (startSlide) => {
  const slides = [];
  setSlidesRound(slides, startSlide);
  return slides;
};
const setSlidesRound = (slides, currentSlide) => {
  if (!currentSlide) return;

  if (currentSlide.type == "Presentation_Wayfinder") slides.push(currentSlide);

  const nextIds = store.state.connection.list
    .filter((c) => c.from == currentSlide.id)
    .map((c) => c.to);

  store.state.object.list
    .filter((o) => nextIds.includes(o.id))
    .forEach((o) => setSlidesRound(slides, o));
};

export const checkNextSlide = (currentObject) => {
  if (!currentObject) return false;

  const nextIds = store.state.connection.list
    .filter((c) => c.from == currentObject.id)
    .map((c) => c.to);

  const objects = store.state.object.list.filter((o) => nextIds.includes(o.id));

  for (let object of objects) {
    switch (object.type) {
      case "Presentation_Wayfinder":
        return true;

      case "Presentation_WayfinderFilterObject":
        if (
          store.getters["wayfinder/checkCondition"](
            object.info.settings.condition,
            true
          )
        )
          return checkNextSlide(object);
        break;

      case "Presentation_WayfinderEndpointObject":
        return checkNextSlide(object);

      case "Presentation_WayfinderRouterObject":
        for (let action of object.info.settings.actions) {
          if (!store.getters["wayfinder/checkCondition"](action.condition))
            continue;

          switch (action.type) {
            case "GO_TO_ENTRY":
              const nextObject = store.getters["object/findById"](
                action.settings.entry
              );
              if (nextObject) return true;
              continue;

            case "CONTINUE_PARENT_FLOW":
              const { parentFlow } = store.state.wayfinder;
              if (!parentFlow.length) continue;
              return checkNextSlide(parentFlow[parentFlow.length - 1]);

            case "SEND_BOT_MESSAGE":
            case "EMIT_SIGNAL":
            case "SUBMIT_ON_SERVER":
            case "OPEN_GALLERY":
            case "OPEN_CAMERA":
            case "OPEN_PAGE":
              return checkNextSlide(object);
          }
        }
        return checkNextSlide(object);
    }
  }

  return false;
};

export const getNextSlide = async (currentObject, isFurther) => {
  const nextIds = store.state.connection.list
    .filter((c) => c.from == currentObject.id)
    .map((c) => c.to);

  const objects = store.state.object.list.filter((o) => nextIds.includes(o.id));

  for (let object of objects) {
    switch (object.type) {
      case "Presentation_Wayfinder":
        return object;

      case "Presentation_WayfinderFilterObject":
        if (
          store.getters["wayfinder/checkCondition"](
            object.info.settings.condition,
            isFurther
          )
        )
          return await getNextSlide(object, isFurther);
        break;

      case "Presentation_WayfinderEndpointObject":
        if (!isFurther) await runEndpoint(object);
        return await getNextSlide(object, isFurther);

      case "Presentation_WayfinderRouterObject":
        //const action = store.getters['wayfinder/currentAction'](object.info.settings.actions, { conditionField: 'condition' });
        for (let action of object.info.settings.actions) {
          if (!store.getters["wayfinder/checkCondition"](action.condition))
            continue;

          if (action.settings.signal)
            store.commit(
              `wayfinder/setSignal`,
              this.template(action.settings.signal)
            );

          let nextObject;
          switch (action.type) {
            case "GO_TO_ENTRY":
              nextObject = store.getters["object/findById"](
                action.settings.entry
              );
              if (nextObject) {
                store.commit(`wayfinder/pushParentFlow`, object);
                store.commit(`wayfinder/update`, nextObject);
                return nextObject;
              }
              continue;

            case "CONTINUE_PARENT_FLOW":
              const { parentFlow } = store.state.wayfinder;
              if (!parentFlow.length) continue;
              nextObject = parentFlow[parentFlow.length - 1];
              store.commit(`wayfinder/popParentFlow`);
              return getNextSlide(nextObject);

            case "EMIT_SIGNAL":
            case "SUBMIT_ON_SERVER":
            case "OPEN_GALLERY":
            case "OPEN_CAMERA":
            case "OPEN_PAGE":
              return getNextSlide(object);
          }
        }
        return getNextSlide(object);
    }
  }

  return null;
};

const runEndpoint = async (object) => {
  let {
    endpoint,
    params,
    headers,
    models,
    signal,
    activateCallback,
    callbackModels,
  } = object.info.settings;
  params = params || "{}";
  headers = headers || "{}";
  params = store.getters["wayfinder/template"](params);
  headers = store.getters["wayfinder/template"](headers);

  return Chatbot.endpoint(
    { id: object.id },
    { endpoint, params, headers, activate_callback: activateCallback }
  ).then(
    (response) => {
      let currentScope = { Response: response.body };
      if (_.isObject(response.body))
        currentScope = { ...currentScope, ...response.body };

      store.commit(`wayfinder/updateCurrentScope`, currentScope);

      return new Promise((resolve) => {
        if (!response.body.error) {
          (models || []).forEach((m) => {
            store.commit(`wayfinder/updateModel`, {
              [m.name]: store.getters["wayfinder/template"](
                m.value,
                response.body
              ),
            });
          });
        }

        if (signal)
          store.commit(
            `wayfinder/setSignal`,
            store.getters["wayfinder/template"](signal)
          );

        if (activateCallback) {
          const interval = setInterval(() => {
            Callback.completed({ id: response.body.callback_uuid }).then(
              (res) => {
                if (!res.body) return false;

                clearInterval(interval);

                let currentScope = { Request: res.body };
                if (_.isObject(response.body))
                  currentScope = { ...currentScope, ...res.body };

                store.commit(`wayfinder/updateCurrentScope`, currentScope);

                (callbackModels || []).forEach((m) => {
                  store.commit(`wayfinder/setModel`, {
                    [m.name]: store.getters["wayfinder/template"](
                      m.value,
                      res.body
                    ),
                  });
                });

                if (signal)
                  store.commit(
                    `wayfinder/setSignal`,
                    store.getters["wayfinder/template"](signal)
                  );

                resolve(true);
              }
            );
          }, 5000);
        } else {
          resolve(true);
        }
      });
    },
    (err) => console.error(err.body)
  );
};

