<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0">Set Template</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import Components from "./Components";

export default {
  name: "Chatbot_RenderTemplateObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    return {
      openedData: 0
    };
  },

  methods: {
    onOpenTemplate() {
      this.$root.$emit("DataEditor.open", {
        component: Components.RenderTemplate,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Template"
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenTemplate();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenTemplate();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>

<style scoped>
</style>
