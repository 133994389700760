<template>
  <div class="chatbot-dialogflow" />
</template>

<script>
  import ExtendsComponent from '../../_extends/ObjectComponent'
  import * as _ from "underscore";

  export default {
    name: 'Chatbot_DialogflowObject',
    extends: ExtendsComponent,
    methods: {
      setModel() {
        const models = _.pluck(this.baseObject.info.settings.models || [], "name");
        const schema = {};
        models.forEach(e => schema[e] = true);
        this.$store.commit("intellisense/setModel", schema);
      }
    }
  }
</script>

<style scoped>
  .chatbot-dialogflow {
    border: 3px solid #ef6c00;
    cursor: pointer;
    background-color: white;
    background-image: url('./dialogflow-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: #333;
    font-weight: bold;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50px;
  }
</style>
