<template>
  <div class="youtube-player">
    <iframe
      width="100%"
      :src="src"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
    />
  </div>
</template>

<script>
export default {
  name: "YoutubePlayer",
  props: {
    value: String,
    readonly: Boolean
  },
  data() {
    return {};
  },
  computed: {
    src() {
      return `https://www.youtube.com/embed/${this.youtubeId}`;
    },
    youtubeId() {
      if (!this.value) return null;
      var match = this.value.match(
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
      );

      return match ? match[5] : null;
    }
  }
};
</script>

<style scoped>
.youtube-player {
  position: relative;
  padding-bottom: 56.4%; /* 16:9 */
  padding-top: 28px;
  height: 0;
  margin-bottom: 0;
}
.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
