<template>
  <v-toolbar dense no-padding>
    <Uploader
      width="40px"
      height="40px"
      :disabled="readonly"
      label="Image"
      icon="image"
      class="mx-2"
      v-model="localSettings.audioAvatar"
    />

    <v-divider class="ml-2" vertical />

  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "SpartialAudio_AudioPersonObject",

  extends: ObjectPropertyEditorExtend,

  data: function() {
    return {
      openedData: "notes"
    };
  },

  methods: {
  },

  watch: {
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>
