<template>
  <v-menu max-height="600" v-model="mainMenu">
    <template v-slot:activator="{on}">
      <slot :on="on" />
    </template>

    <v-list dense>
      <v-subheader @click.stop>
        <v-text-field
          class="mx-2 pa-0"
          hide-details
          dense
          placeholder="Filter actions..."
          :style="'width: 120px'"
          v-model="query"
        />
      </v-subheader>

      <v-list-item
        v-for="(item, index) in filteredItems"
        :key="index"
        class="px-3"
        :class="`notion-line-menu-item__` + index"
        @click="item.handler ? callHandler(item.handler) : null"
      >
        <v-list-item-icon class="mr-5">
          <v-icon>{{item.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <div v-html="item.name" />
        </v-list-item-title>
        <v-list-item-icon v-if="item.menu && item.menu.length">
          <v-icon>arrow_right</v-icon>
        </v-list-item-icon>
        <v-list-item-icon v-if="!!item.addon" class="hotkey ml-0"  :style="'width: 90px'">
          {{item.addon}}
        </v-list-item-icon>

        <v-menu
          v-if="item.menu && item.menu.length"
          :activator="`.notion-line-menu-item__` + index"
          offset-x
          max-height="600"
          open-on-hover
        >
          <v-list dense>
            <v-list-item
              v-for="(subItem, index) in item.menu"
              :key="index"
              class="px-3"
              @click="subItem.handler ? callHandler(subItem.handler) : null"
            >
              <v-list-item-icon class="mr-5">
                <v-icon :color="subItem.color">{{subItem.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <div v-html="subItem.name" />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "NotionLineMenu",
  props: {
    line: Object,
    menu: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      query: "",
      mainMenu: false
    };
  },
  methods: {
    callHandler(handler) {
      this.mainMenu = false;
      handler(this);
    }
  },
  computed: {
    filteredItems() {
      return this.menu.filter(
        item => item.name.toLowerCase().indexOf(this.query.toLowerCase()) != -1
      );
    }
  }
};
</script>

<style scoped>
.hotkey {
  opacity: .6;
}
</style>