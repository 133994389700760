<template>
  <v-toolbar dense no-padding>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{on}">
        <v-btn-toggle group borderless :value="dialog">
          <v-btn v-on="on" :disabled="readonly" text style="opacity: 1" class="ma-0">Set symbol</v-btn>
        </v-btn-toggle>
      </template>

      <v-card>
        <v-card-title class="headline" primary-title>Set symbol</v-card-title>
        <v-container>
          <v-autocomplete
            outlined
            label="Symbol object"
            item-text="name"
            item-value="id"
            :items="symbols"
            v-model="symbol_id"
          />

          <v-btn @click="save" color="secondary" :disabled="!symbol_id" small>ok</v-btn>
        </v-container>
      </v-card>
    </v-dialog>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Base_SymbolPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data: function() {
    return {
      dialog: false,
      symbols: [],
      symbol_id: null
    };
  },

  methods: {
    async save() {
      this.dialog = false;
      if (!this.symbol_id)
        return;

      const { body } = await this.api.ChartObject.associateWithSymbol(
        { id: this.baseObject.id },
        { symbol_id: this.symbol_id }
      );
      this.$store.commit("object/update", body);
      this.$emit("updateSymbol", body);
    }
  },

  mounted: async function() {
    //this.symbol_id = this.baseObject.symbol_id;
    if (this.currentUser) {
      const { body } = await this.api.ChartObject.symbols();
      this.symbols = body;
    }
  }
};
</script>