<template>
  <div>
    <v-tabs v-if="mode == 'edit'" background-color="white" v-model="activeTab">
      <v-tabs-slider color="primary" />
      <v-tab>General</v-tab>
      <v-tab>Participants</v-tab>
      <v-tab>Invites</v-tab>
      <v-tab>Access</v-tab>
    </v-tabs>
    <br />
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-text-field
          autofocus
          @keyup.enter.native="$emit('submit')"
          type="text"
          v-model="localParams.name"
          label="Team Name"
        />
      </v-tab-item>
      <v-tab-item v-if="mode == 'edit'">
        <Participants :team="localParams" />
      </v-tab-item>
      <v-tab-item v-if="mode == 'edit'">
        <Invites :team="localParams" />
      </v-tab-item>
      <v-tab-item v-if="mode == 'edit'">
        <AccessLevel :team.sync="localParams" />
      </v-tab-item>
    </v-tabs-items>

    <div class="text-right" v-if="showActions">
      <v-btn color="primary" @click="$emit('submit')">
        {{mode == 'new' ? 'Create' : 'Save'}}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { deepClone } from "@/lib";
import Participants from "./Form/Participants";
import Invites from "./Form/Invites";
import AccessLevel from "./Form/AccessLevel";

export default {
  name: "TeamForm",
  components: {
    Participants,
    Invites,
    AccessLevel
  },
  props: {
    params: Object,
    mode: String,
    showActions: Boolean
  },
  mounted() {
    this.$nextTick(function() {
      this.$el.querySelector("input[autofocus]").focus();
    });
  },
  data() {
    const localParams = deepClone(this.params);
    return {
      localParams,
      submitBtn: {
        edit: "Update",
        new: "Create"
      },
      activeTab: 0
    };
  },
  methods: {
    setInvites() {
      this.api.Team.teamInvites({ id: this.localParams.id }).then(
        response => (this.invites = response.body)
      );
    }
  },
  watch: {
    localParams: {
      handler() {
        this.$emit("update:params", this.localParams);
      },
      deep: true
    }
  }
};
</script>

<style>
</style>
