import { User } from "@/resources";
import {deepClone, HFSA_THRESHOLD_DEFAULT} from "@/lib";

const store = {
  namespaced: true,
  state: {
    tryLoad: false,
    current: null,
    token: localStorage["token"],
    audioSettings: {}
  },
  mutations: {
    set(state, user) {
      state.current = user;
    },
    setTryLoad (state, tryLoad) {
      state.tryLoad = tryLoad;
    },
    setToken(state, token) {
      state.token = token;
      localStorage["token"] = token;
    },
    deleteToken(state) {
      state.token = null;
      delete localStorage["token"];
    },
    updateAudioSettings(state, settings) {
      state.audioSettings = {...state.audioSettings, ...settings};
    }
  },
  actions: {
    async load({dispatch,commit }) {
      commit("setTryLoad", true);
      commit('application/setLoading', true, {root: true});

      try {
        const response = await User.current();
        dispatch('set', response.body);
      } finally {
        commit('application/setLoading', false, {root: true});
      }
    },

    set({ commit }, user) {
      commit("set", user);
      if (user) {
        this._vm.$intercom.boot({
          user_id: user.id,
          name: user.username,
          first_name: user.profile.first_name,
          last_name: user.profile.last_name ? user.profile.last_name : "",
          email: user.email,
          organisations: user.organisations.length,
          teams: user.teams.length,
          admin: !!user.roles.admin
        })
      }
    },

    update({ commit }, params) {
      return User.update({ id: params.id }, { user: params })
        .then(({body}) => {
          commit("set", body);
          return body;
        });
    },

    logOut({commit}) {
      commit('deleteToken');
      commit('set', null);
    }
  },
  getters: {
    current(state) {
      return state.current;
    },
    token(state) {
      return state.token;
    },
    visibleName(state) {
      const {profile} = state.current;
      if (profile.first_name && profile.last_name)
        return profile.first_name + ' ' + profile.last_name;
      else if (profile.first_name)
        return profile.first_name;
      else if (profile.last_name)
        return profile.last_name;

      if (state.current.username)
        return state.current.username;
      return state.current.email;
    },
    audioSettings(state) {
      return {
        echoCancellation: false,
        noiseSuppression: false,
        autoGainControl: false,
        deviceId: undefined,
        hiFiGain: 1,
        volumeThreshold: HFSA_THRESHOLD_DEFAULT,
        ...state.audioSettings
      };
    }
  }
};

export default store;
