const defaultSettings = {
  name: 'Text',
  icon: 'mdi-format-text',
  hotkey: `modkey + T`,
  size: {
    width: 250,
    height: 100
  },
  resizable: true,
  draggable: true,
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: true,
  settings: {
    textColor: "#333",
    textAlign: "center",
    verticalAlign: "center",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "open sans",
    fontStyle: "normal",
    textDecoration: "none",
    locked: false,
    order: 0
  }
};

export default defaultSettings;
