<template>
  <v-btn-toggle group borderless v-model="tab">
    <div v-for="(tab, index) in tabs" :key="index" v-if="tab.addon">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" :value="tab" style="width: 48px; border-radius: 0">
            <component
              v-if="tab.icon"
              class="v-icon"
              :is="tab.plain ? 'div' : 'v-icon'"
            >{{tab.icon}}</component>
            <div class="v-icon" v-else>
              <img :src="tab.iconUrl" height="24" style="filter: invert(1)" />
            </div>
            <div
              class="bottom-tab-notification"
              v-if="tab.notificationsCount"
            >{{tab.notificationsCount}}</div>
          </v-btn>
        </template>
        <span>{{tab.title}}</span>
      </v-tooltip>
    </div>
  </v-btn-toggle>
</template>

<script>
import addonDefiner from "../../_extends/addonDefiner";

export default {
  name: "AddonsIcons",

  props: ["baseObject", "tabs", "localSettings", "parentTab"],

  data() {
    return {
      tab: null
    };
  },

  watch: {
    tab() {
      if (!this.tab) return;
      this.$emit("change");
      this.$root.$emit("DataEditor.open", {
        component: addonDefiner(this.tab.addon),
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly,
          ...this.tab.addon
        },
        title: this.tab.title
      });
    },
    parentTab(val) {
      if (val) this.tab = null;
    }
  }
};
</script>