const defaultSettings = {
  name: "Chatbot Entry",
  icon: 'forward',
  showLabel: true,
  size: {
    width: 'auto',
    height: 40
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#8BC34A",
  outColor: "#8BC34A",
  layout: "horizontal",
  components: [/Chatbot_.+/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
