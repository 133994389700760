<template>
  <div class="comp">
    <input
      ref="input"
      v-model="currentScope"
      :type="settings.inputType"
      :placeholder="settings.placeholder"
    >
  </div>
</template>

<script>
  export default {
    name: 'ControlElement_Input',
    props: {
      settings: Object
    },
    computed: {
      currentScope: {
        get () {
          return this.$store.getters[`wayfinder/scope`][this.settings.model];
        },
        set (value) {
          this.updateCurrentScope(value);
        }
      }
    },
    created () {
      this.updateCurrentScope(null);
    },
    mounted () {
      this.$refs.input.focus();
    },
    methods: {
      updateCurrentScope(value) {
        if (!this.settings.model)
          return;
        this.$store.commit(`wayfinder/updateCurrentScope`, {
          [this.settings.model]: value
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .comp {
    width: 100%;

    input {
      outline: none;
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
      border-radius: 5px;
      height: 36px;
      padding: 8px 10px;
      width: 100%;
    }
  }
</style>
