<template>
  <v-card class="sub-toolbar py-2" id="text-toolbar" dense>
    <v-tooltip
        class="p-3"
        right
        v-for="(item, index) in tools"
        :key="index"
    >
      <template v-slot:activator="{on}">
        <v-btn class="p-3 create-object-button" :class="{active: false}" icon v-on="on"
               @click.stop="createNewObject(item)">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ item.name }}</span>
    </v-tooltip>
  </v-card>
</template>

<script>
import {defaultProperties,} from "../Object/Types";
import {HEADER_HEIGHT} from "../../../../../utils/const";

const COMPONENT = "Base_TextObject"

export default {
  name: "TextToolbar",
  props: {
    isOpen: Boolean,
    togglePanel: Function,
  },


  computed: {
    tools() {
      return this.getTools()
    }
  },
  methods: {
    createNewObject(cmp) {
      const {scale, translate} = this.$store.state.chart;
      const rect = this.$parent.$parent.$parent.$el.getBoundingClientRect();

      const position = {
        x: Math.round((rect.width / 2 - translate.x - rect.x) / scale),
        y: Math.round((rect.height / 2 - HEADER_HEIGHT - translate.y - rect.y) / scale)
      };

      this.$store.dispatch('object/create', {type: COMPONENT, position, size: cmp.size, info: cmp.settings})
      this.togglePanel()
    },
    getSettings(params) {
      return (
          {
            settings: {
              ...defaultProperties[COMPONENT].settings,
              ...params
            },
          }
      )
    },
    getTool(name, icon, size = {width: 250, height: 100}, settings,) {
      return ({
        name,
        icon,
        settings,
        size,
        component: COMPONENT
      })
    },
    getTools() {
      return [
        this.getTool("H1", "mdi-format-header-1", {width: 620}, this.getSettings({
          fontWeight: 700,
          fontSize: 48,
          textAlign: 'left'
        })),
        this.getTool("H2", "mdi-format-header-2", {width: 580}, this.getSettings({
          fontWeight: 500,
          fontSize: 32,
          textAlign: 'left'
        })),
        this.getTool("H3", "mdi-format-header-3", {width: 400}, this.getSettings({
          fontWeight: 600,
          fontSize: 28,
          textAlign: 'left'
        })),
        this.getTool("H4", "mdi-format-header-1", {width: 400}, this.getSettings({
          fontWeight: 600,
          fontSize: 22,
          textAlign: 'left'
        })),
        this.getTool("Paragraph", "mdi-format-paragraph",{width: 300},
            this.getSettings({fontSize: 16, textAlign: 'left'})),
      ]

    }
  },

}
</script>

<style scoped>
.sub-toolbar {
  position: absolute;
  left: 55px;
  bottom: 0;
  z-index: 2000;
  width: 50px;
}

.active {
  background-color: pink;
}
</style>
