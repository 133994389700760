<template>
  <v-list class="pa-1">
    <v-list-item>
      <v-list-item-avatar class="pa-4">
        <div class="welcome-logo">🖖</div>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="brand font-weight-black">Vulcan</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: "TheNavigationHeader"
  }
</script>

<style scoped>
  .brand {
    font-size: 32px;
    color: #313133;
  }
  .welcome-logo {
    height: 75px;
    width: 75px;
    margin: 20px auto;
    background: transparent;
    border: 2px solid #ccc;
    font-weight: 800;
    border-radius: 8px 20px 20px 20px;
    color: #313133;
    font-size: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>