<template>
  <div>
    
  </div>
</template>

<script>
  import componentExtends from './_extends/Component'
  export default {
    name: 'Chatbot_RouterObjectPropertyEditor_OPEN_CAMERA',
    extends: componentExtends,
  }
</script>
