<template>
  <div class="pa-4 text-left">
    <v-card>
      <v-container>
        <v-layout>
          <v-flex mb-6>
            <h2>Profile</h2>
          </v-flex>
        </v-layout>
        <form @submit.prevent="update" class="mini-form">
          <v-layout mb-6>
            <v-flex sm1>
              <Uploader
                label="Avatar"
                icon="image"
                v-model="logo"
                count-base64
                :base64.sync="logoBase64"
              />
            </v-flex>
            <v-flex xs12 sm3 mx-2>
              <v-text-field filled label="First Name" v-model="profile.first_name" placeholder="Enter your firstname" />
            </v-flex>
            <v-flex xs12 sm3>
              <v-text-field filled label="Last Name" v-model="profile.last_name" placeholder="Enter your lastname" />
            </v-flex>
          </v-layout>
          <v-divider />
          <v-layout mt-6>
            <v-flex mb-6>
              <h4>Personal Details</h4>
            </v-flex>
          </v-layout>
          <v-layout mt-6 mb-6>
            <v-flex xs12 sm3 mx-2>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Birth Date"
                    hint="MM/DD/YYYY format"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    filled
                  />
                </template>
                <v-date-picker v-model="profile.birth_date" no-title @input="menu = false" />
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 sm3>
              <v-select
                :items="sexes"
                v-model="profile.sex"
                label="Sex"
                filled
                prepend-icon="perm_identity"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 sm3>
              <v-switch v-model="profile.notifications_enabled" inset label="Notifications" prepend-icon="notifications_none" />
            </v-flex>
          </v-layout>
          <v-layout justify-end>
            <v-btn
              type="submit"
              color="primary"
              :disabled="!changed"
            >Update</v-btn>
          </v-layout>
        </form>
      </v-container>
    </v-card>

  </div>
</template>


<script>
import { deepClone } from '@/lib';
export default {
  name: "Profile",
  data() {
    return {
      username: '',
      profile: null,
      changed: false,
      logo: null,
      logoBase64: null,
      menu: false
    };
  },
  created() {
    this.fetchUser();
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.profile.birth_date)
    },
    sexes() {
      return [{text: "Male", value: "male"}, {text: "Female", value: "female"}];
    }
  },
  methods: {
    fetchUser() {
      this.profile = deepClone(this.currentUser.profile);
      const url = this.profile.avatar_url;
      this.logo = {
        body: {
          url,
          quad: {
            url
          },
          thumb: {
            url
          }
        }
      };
    },
    update() {
      this.$store.dispatch('user/update', {...this.currentUser, profile_attributes: this.profile})
        .then(res => {
          this.profile = deepClone(res.profile);
        });
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  },
  watch: {
    profile: {
      handler() {
        this.changed =
          JSON.stringify(this.currentUser.profile) != JSON.stringify(this.profile);
      },
      deep: true
    },
    logoBase64() {
      this.profile = {
        ...this.profile,
        avatar_attributes: {
          base64: this.logoBase64
        }
      };
    }
  }
}
</script>
