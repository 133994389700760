<template>
  <div>
    <v-text-field
      hide-details
      v-model="property.name"
      label="Property name"
      :readonly="readonly"
    />
    <v-text-field
      hide-details
      v-model="property.visibleName"
      label="Visible name"
      :readonly="readonly"
    />
    <v-select
      hide-details
      v-model="property.type"
      @change="property.defaultValue = null"
      :items="controlTypes"
      label="Control type"
      item-value="name"
      item-text="visibleName"
      :readonly="readonly"
    />

    <v-text-field
      v-if="property.type == 'textfield'"
      hide-details
      v-model="property.defaultValue"
      label="Default value"
      :readonly="readonly"
    />

    <v-text-field
      v-if="property.type == 'number'"
      hide-details
      :value="property.defaultValue"
      @input="property.defaultValue = parseFloat($event)"
      label="Default value"
      type="number"
      :readonly="readonly"
    />

    <v-menu
      v-if="property.type == 'date'"
      v-model="menus[property.id]"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="property.defaultValue"
          :readonly="readonly"
          label="Default value"
          prepend-icon="mdi-calendar"
          hide-details
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="property.defaultValue"
        no-title
        scrollable
        :show-current="false"
        @change="menus[property.id] = false"
      />
    </v-menu>

    <v-menu
      v-if="property.type == 'time'"
      v-model="menus[property.id]"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="property.defaultValue"
          :readonly="readonly"
          label="Default value"
          prepend-icon="access_time"
          hide-details
          v-on="on"
        />
      </template>
      <v-time-picker
        v-model="property.defaultValue"
        full-width
        @change="menus[property.id] = false"
      />
    </v-menu>

    <div v-if="property.type == 'color_picker'">
      <span>Default value: </span>
      <ColorPickerDialog
        v-model="property.defaultValue"
        :title="'Default value for ' + property.visibleName"
        :disabled="readonly"
        :tooltip="property.visibleName"
      />
    </div>

    <v-select
      v-if="property.type == 'select'"
      :disabled="readonly"
      v-model="property.defaultValue"
      :items="property.selectOptions"
      item-text="name"
      item-value="data"
      label="Default value"
      hide-details
    />

    <v-textarea
      v-if="property.type == 'textarea'"
      hide-details
      v-model="property.defaultValue"
      label="Default value"
      :readonly="readonly"
    />

    <v-text-field
      hide-details
      v-if="hasWidth"
      v-model="property.fieldWidth"
      label="Field width"
    />

    <div v-if="property.type == 'select'">
      <div style="color: rgba(0, 0, 0, 0.6)" class="ml-2 mt-4" >Select options:</div>
      <draggable v-model="property.selectOptions" handle=".select-options" :disabled="readonly">
        <div class="form-row item" v-for="option in property.selectOptions" :key="option.id">
          <div class="select-options">
            <div v-if="!readonly">
              <v-icon class="drag">drag_handle</v-icon>
            </div>
            <div v-if="!readonly">
              <v-icon class="delete" @click="deleteSelectOption(property, option.id)">delete</v-icon>
            </div>
          </div>

          <v-text-field
            hide-details
            v-model="option.name"
            label="Option visible name"
            :readonly="readonly"
          />
          <v-text-field
            hide-details
            v-model="option.data"
            label="Option data"
            :readonly="readonly"
          />
        </div>
      </draggable>
      <div class="add" @click="addSelectOption(property)" v-if="!readonly">Add option</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Propertiy",
  props: {
    property: Object,
    readonly: Boolean,
    child: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const controlTypes = [
      {
        name: "textfield",
        visibleName: "Text field"
      },
      {
        name: "number",
        visibleName: "Number"
      },
      {
        name: "date",
        visibleName: "Date picker"
      },
      {
        name: "time",
        visibleName: "Time picker"
      },
      {
        name: "color_picker",
        visibleName: "Color picker"
      },
      {
        name: "select",
        visibleName: "Select"
      }
    ];
    if (this.child)
      controlTypes.push({
        name: "textarea",
        visibleName: "Text area"
      });
    return {
      menus: [],
      controlTypes
    };
  },
  computed: {
    hasWidth() {
      return !this.child && (
        this.property.type == 'textfield' ||
        this.property.type == 'number' ||
        this.property.type == 'select');
    }
  },
  methods: {
    addSelectOption(property) {
      property.selectOptions.push({
        id: this.guid(),
        name: "",
        data: ""
      });
    },
    deleteSelectOption(property, id) {
      property.selectOptions = property.selectOptions.filter(item => item.id != id);
    },
  }
}
</script>

<style scoped>
.item {
  border: 1px solid #f7f7f7;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.select-options {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  margin: -10px;
  margin-bottom: 20px;
  padding: 5px;
  cursor: move;
}
</style>