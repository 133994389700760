<template>
  <div>
    <slot />
    <div
      @click.stop="openSettings"
      class="control-element__select"
      v-html="label"
    />
  </div>
</template>

<script>
  import ControlComponent from '../_extends/ControlComponent'

  export default {
    name: "ControlElement_SelectControl",
    extends: ControlComponent,
    computed: {
      label () {
        return this.localSettings.options && this.localSettings.options.length ? this.localSettings.options[0].value : 'Select'
      }
    },
  }
</script>

<style scoped>
  .control-element__select {
    cursor: pointer;
    background-color: #f5f5f5;
    height: 36px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 3px;
  }
</style>