<template>
  <div>
    <div class="pa-4 text-left">
      <div class="clearfix table-header">
        <div class="pull-left">
          <h2>Invites</h2>
        </div>
      </div>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details/>
      <v-data-table :headers="headers" :items="list" :search="search" hide-default-footer>
        <template v-slot:item="{item}">
          <tr>
            <td>{{ item.name }}</td>
            <td class="text-xs-right">
              <v-icon class="accept-invite" @click="acceptInvite(item)">check</v-icon>
              <v-icon @click="rejectInvite(item)">close</v-icon>
            </td>
          </tr>
        </template>
        <v-alert
          v-slot:no-results
          color="error"
          icon="warning"
        >Your search for "{{ search }}" found no results.</v-alert>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Invites",
  created() {
    this.setList();
  },
  data() {
    return {
      headers: [
        {
          text: "Team",
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: "",
          sortable: false,
          align: "right",
          value: "actions"
        }
      ],
      list: [],
      search: ""
    };
  },
  methods: {
    setList() {
      this.api.User.invites().then(response => (this.list = response.body));
    },
    acceptInvite({ id }) {
      this.api.User.acceptInvite({ team_id: id }).then(response => {
        this.list = this.list.filter(item => item.id != id);
      });
    },
    rejectInvite({ id }) {
      this.api.User.rejectInvite({ team_id: id }).then(response => {
        this.list = this.list.filter(item => item.id != id);
      });
    }
  }
};
</script>

<style>
.accept-invite {
  margin-right: 20px;
}
</style>
