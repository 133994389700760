import AudioPersonObject from "./AudioPersonObject";
import AudioSpaceObject from "./AudioSpaceObject";

export default {
  objects: {
    AudioPersonObject,
    AudioSpaceObject
  },
  tab: "SpatialAudio"
};
