import { deepClone } from '@/lib';

export default {
  props: {
    id: String,
    settings: Object,
    baseObject: Object,
    callback: Function,
    readonly: Boolean
  },

  data() {
    return {
      localSettings: deepClone(this.settings)
    };
  },

  mounted () {
    this.setCurrentState();
  },

  methods: {
    onDataEditorClose() {
      this.openedData = undefined;
    },
    save() {
      this.callback(this.localSettings);
    },
    setCurrentState () {
      const schema = {};
      const controls = []
        .concat(this.baseObject.info.settings.actionsControls || [])
        .concat(this.baseObject.info.settings.bodyControls || []);

      controls.forEach(a => {
        if (a.settings.model)
          schema[a.settings.model] = true;
      });

      if (this.localSettings.allowCustomInput)
        schema.custom_input = true;

      this.$store.commit('intellisense/setCurrentState', schema);
    }
  },

  beforeDestroy () {
    this.$store.commit('intellisense/setCurrentState', {});
  },

  watch: {
    settings: {
      handler() {
        if (JSON.stringify(this.localSettings) != JSON.stringify(this.settings))
          this.localSettings = deepClone(this.settings);
      },
      deep: true
    },
    localSettings: {
      handler() {
        if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings)) {
          this.save();
          this.setCurrentState();
        }
      },
      deep: true
    }
  }
}