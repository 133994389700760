<template>
  <div class="drawing-object">
    <div class="drawing-object__container" :style="containerStyles">
      <div class="drawing-object__svg" :class="readonly ? 'drawing-object__svg_readonly' : ''">
        <svg 
          :width="svgWidth"
          :height="svgHeight"
          v-if="settings.points" class="mine">
          <path 
            :d="interpolatedLine"
            :stroke="settings.lineColor || '#333'"
            :stroke-dasharray="lineStyles(settings.lineStyle)"
            :stroke-width="settings.strokeWidth || 1"
            stroke-linecap="round"
            fill="none"
            class="drawing-line"
            />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_DrawingObject",
  extends: ExtendsComponent,
  props: {
    position: Object,
    baseObject: Object
  },
  data() {
    const strokeWidth = this.settings.strokeWidth || 1;
    const scale = this.scale || 1;
    const strokeOffset = strokeWidth * scale;
    let points = this.settings.points;
    // To deal with legacy lines
    // To change from original 2-dimensions array to 3
    if (this.settings.points && this.settings.points[0] && !Array.isArray(this.settings.points[0][0])) {
      points = [this.settings.points];
    }
    const lines = points.map(ps => d3.line().curve(d3.curveBasis)(ps));

    return {
      interpolatedLine: lines.join("")
    };
  },
  computed: {
    containerStyles() {
      return {
        width: `${(this.baseObject.size.width) || 300 }px`,
        height: `${(this.baseObject.size.height) || 300 }px`,
        transform: `translate(0px, 0px)`
      };
    },
    scale() {
      return this.$store.getters["chart/scale"];
    },
    svgWidth() {
      const strokeWidth = this.settings.strokeWidth || 1;
      const scale = this.scale || 1;
      return Math.abs(Math.round(this.baseObject.size.width + strokeWidth * scale * 2));
    },
    svgHeight() {
      const strokeWidth = this.settings.strokeWidth || 1;
      const scale = this.scale || 1;
      return Math.abs(Math.round(this.baseObject.size.height + strokeWidth * scale * 2));
    }
  },
  methods: {
    lineStyles(setting) {
      const strokeWidth = this.settings.strokeWidth || 1;
      const scale = this.scale || 1;
      switch (setting) {
        case 'Dashed':  return `${10 * strokeWidth * scale},${10 * strokeWidth * scale}`;
        case 'Dotted':  return `${5 * strokeWidth * scale},${5 * strokeWidth * scale}`;
        case 'Solid':
        default:        return "0";
      }
    }
  },
  watch: {
    settings: {
      handler(val, oldVal) {
        if (oldVal.strokeWidth != val.strokeWidth) {
          const strokeWidth = this.settings.strokeWidth || 1;
          const scale = this.scale || 1;
        }

        let points = this.settings.points;
        if (this.settings.points && this.settings.points[0] && !Array.isArray(this.settings.points[0][0])) {
          points = [this.settings.points];
        }
        const lines = points.map(ps => d3.line().curve(d3.curveBasis)(ps));

        this.interpolatedLine = lines.join("");
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.drawing-object {
  position: relative;
  width: 0;
  pointer-events: none;
}

.drawing-object__container {
  position: absolute;
  left: 0;
  top: 0;
}

.drawing-object__svg {
  line-height: 0;
}

</style>

<style>
.Base_DrawingObject > .base-object__content {
  height: 100%;
}

.Base_DrawingObject .object-actions {
  display: none !important;
}

.base-object.Base_DrawingObject.selected .base-object__content > div {
  box-shadow: none;
}

.base-object.Base_DrawingObject.selected .drawing-line {
  stroke: red !important;
}
</style>
