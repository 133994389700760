<template>
</template>

<script>
import Vue from "vue";
import ControlPropertyEditorExtend from '../_extends/ControlPropertyEditor';

export default {
  name: 'ControlElement_VideoControlPropertyEditor',

  extends: ControlPropertyEditorExtend,

  data() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-layout>
            <v-flex xs8>
              <Autocompleter solo v-model="settings.showCondition" label="Show Condition, default true" :readonly="readonly" />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs3>
              <v-text-field type="number" v-model="settings.width" label="Width, %" :readonly="readonly" />
            </v-flex>
          </v-layout>
          <Autocompleter v-model="settings.title" label="Title" :readonly="readonly" class="mb-3" />
          <Autocompleter v-model="settings.videoUrl" label="Youtube Or Vimeo Url" :readonly="readonly" class="pb-1" />
        </div>
      `,
      props: ['settings', 'readonly']
    });

    return {
      cmpData
    };
  },

  watch: {
    id() {
      this.openData();
    }
  },

  mounted() {
    this.openData();
  },

  methods: {
    openData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Data'
      });
      //TODO: костыль
      //this.$root.$emit('PropertyEditor.close');
    }
  }
}
</script>