import { guid } from "@/lib";

const types = [
  { type: "Text",         name: "Text",           icon: "text_format",          content: "" },
  { type: "Image",        name: "Image",          icon: "image"                             },
  { type: "Header",       name: "Header",         icon: "mdi-format-header-1",  content: "" },
  { type: "Header2",      name: "Header 2",       icon: "mdi-format-header-2",  content: "" },
  { type: "Header3",      name: "Header 3",       icon: "mdi-format-header-3",  content: "" },
  { type: "Checkbox",     name: "Checkbox",       icon: "check_box",            content: "" },
  { type: "Quote",        name: "Quote",          icon: "format_quote",         content: "" },
  { type: "Code",         name: "Code",           icon: "code",                 content: "" },
  { type: "Divider",      name: "Divider",        icon: "remove"                            },
  { type: "BulletedList", name: "Bulleted List",  icon: "format_list_bulleted", content: "" },
//{ type: "NumberedList", name: "Numbered List",  icon: "" }
];

const menuTypes = types.map(type => ({
  id: guid(),
  name: type.name,
  icon: type.icon,
  handler(ctx, fn) {
    ctx.$emit("emit", {
      type: "ADD_LINE",
      payload: {
        type: type.type,
        content: type.content
      }
    });
    if (fn) fn();
  }
}));

export default menuTypes;

export { types };
