<template>
  <div class="pa-4 text-left">

    <v-card class="pa-4">
      <v-container>
        <v-layout>
          <v-flex mb-6>
            <h2>Your Connected Apps</h2>
            <p>Connect any of these services in a few easy steps</p>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <OAuthList :auths="auths" @save="save" />
          </v-flex>
        </v-layout>
        <v-layout mt-6>
          <p>See the <a href="https://docs.vulcanapp.com" target="_blank">help section</a> for information regarding usage of Apps Authorisation.</p>
        </v-layout>
      </v-container>
    </v-card>

  </div>
</template>


<script>
  import * as _ from "underscore";

  export default {
    name: "OAuthConfig",
    data() {
      return {
        auths: []
      };
    },
    created() {
      this.api.User.getOauthTokens().then(res =>
        this.auths = res.body
      );
    },
    methods: {
      save: _.debounce(function($event) {
        this.auths = $event;
        this.api.User.setOauthTokens({
          oauth_tokens: this.auths
        });
      }, 300)
    }
  };
</script>

<style scoped>
  .action {
    cursor: pointer;
    padding: 10px;
  }
</style>
