<template>
  <v-card id="chartObjectsToolbar" class="px-2">
    <div>
      <v-tooltip
        class="p-3"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            class="p-3"
            @click="$emit('openFilter')"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Filter</span>
      </v-tooltip>
    </div>
    <div>
      <v-tooltip
        class="p-3"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            class="p-3"
            @click="$emit('openTree')"
          >
            <v-icon>account_tree</v-icon>
          </v-btn>
        </template>
        <span>Layer</span>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { defaultProperties, ObjectTypesByModule } from "../Object/Types";
import { isMac } from "@/lib";
import {objectModeTypes} from "../../../../../utils/const";
export default {
  name: "ChartObjectsToolbar",
  props: {
    openFilter: Function,
    openTree: Function
  },
  computed: {
    ...mapGetters({ objectMode: "chart/objectMode" }),
    drawingIconColor() {
      return this.objectMode == objectModeTypes.Drawing ? "#df4e9e" : "rgba(0,0,0,0.54)";
    },
  },
  data() {
    const modKey = isMac() ? "Ctrl" : "Win";
    const toolsList = ObjectTypesByModule["Base"]
      .filter((c) => defaultProperties[c].name !== "Group")
      .map((c) => {
        const hotkey = defaultProperties[c].hotkey
          ? defaultProperties[c].hotkey.replace("modkey", modKey)
          : null;
        let domClass = "";
        const tooltipLength =
          (hotkey ? hotkey.length : 0) + defaultProperties[c].name.length;
        if (tooltipLength < 10) domClass = "short";
        if (tooltipLength > 20) domClass = "long";
        return {
          component: c,
          name: defaultProperties[c].name,
          icon: defaultProperties[c].icon,
          hotkey,
          domClass,
          hasSubToolbar: defaultProperties[c].subToolbar
        };
      });
    return {
      toolsList: [
        {
          icon: "mdi-filter",
        }
      ],
      defaultProperties,
      drawingClass: "",
    };
  },
  methods: {
    createPolygonObject(shape) {
      this.$emit("createObject", null, 'Base_PolygonObject', { shape })
    },
    togglePolygonMode(item) {
      this.$store.dispatch("chart/setObjectMode", this.objectMode == "shape" ? null : "shape");
    },
    toggleDrawing() {
      this.$store.dispatch("chart/setObjectMode", this.objectMode == "drawing" ? null : "drawing");
    },
  },
};
</script>

<style scoped>
#chartObjectsToolbar {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 45px;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  background: white;
  text-align: center;
  display: flex;
  align-items: center;
}

.tooltip-wrapper {
  width: 150px;
}
.tooltip-wrapper.long {
  width: 200px;
}
.tooltip-wrapper.short {
  width: 80px;
}

.tooltip-name {
  font-weight: bold;
}
.tooltip-hotkey {
  color: rgba(255, 255, 255, 0.6);
}

.v-btn.active i {
  color: #df4e9e;
}

</style>
