<template>
  <div>
    <v-layout align-center justify-space-between>
      <div class="video-settings__actions" v-if="!readonly">
        <v-btn
          flat
          :class="{ active: activeTab == 'record' }"
          @click="activeTab = 'record'"
          class="mr-2"
          :color="activeTabColorRecord"
        >
          Record
          <v-icon class="ml-2">
            mdi-video-account
          </v-icon>
      </v-btn>
        <v-btn
          :class="{ active: activeTab == 'upload' }"
          @click="activeTab = 'upload'"
          :color="activeTabColorUpload"
        >
          Upload
          <v-icon class="ml-2">
            upload
          </v-icon>
    </v-btn>
      </div>
    </v-layout>
    <div>
      <RecordWebcam
        @close="save"
        v-model="video"
        v-if="activeTab == 'record'"
      />
      <UploadVideo
        @close="save"
        v-model="video"
        v-else-if="activeTab == 'upload'"
      />
      <v-btn v-if="video && video.body" 
        @click="addKeyframe" 
        class="mt-3"
      >
        <v-icon>
          video-marker
        </v-icon>
      </v-btn>
      <div v-if="video && video.body">
        <div class="video-settings__capture mt-2">
          <video
            :src="video.body.url"
            height="100%"
            width="100%"
            controls
            @timeupdate="onProgress"
          />
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
import {secToTimestamp} from "@/lib";
import { gray } from "d3-color";
import RecordWebcam from "./VideoSettings/RecordWebcam";
import UploadVideo from "./VideoSettings/UploadVideo";
import VideoUrl from "./VideoSettings/VideoUrl";

export default {
  name: "WayfinderSettings",
  components: { RecordWebcam, UploadVideo, VideoUrl },
  props: {
    value: Object,
    readonly: Boolean
  },
  data() {
    return {
      video: this.value,
      activeTab: null,
      time: 0
    };
  },
  computed: {
    activeTabColorUpload() {
      if (this.activeTab == 'upload') {
        return 'rgba(232,0,90,0.2)';
      }
      return 'gray';
    },
    activeTabColorRecord() {
      if (this.activeTab == 'record') {
        return 'rgba(232,0,90,0.2)';
      }
      return 'gray';
    }
  },
  methods: {
    save() {
      this.activeTab = null;
      this.$emit("input", this.video);
    },
    onProgress(e) {
      this.time = Math.floor(e.target.currentTime);
    },
    addKeyframe() {
      if (!this.video.timestamps)
        this.video.timestamps = [];
      this.video.timestamps.push({
        id: this.guid(),
        objectId: "",
        time: secToTimestamp(this.time)
      });
    }
  }
};
</script>

<style scoped>
.video-settings__capture {
  width: 100%;
  height: 240px;
  background: black;
  border-radius: 6px;
}

.video-settings__actions {
  float: right;
}

.video-settings__actions >>> a {
  text-decoration: underline;
  margin-left: 10px;
}

.video-settings__actions >>> a:hover {
  text-decoration: none;
}

.video-settings__actions >>> a.active {
  cursor: default;
  color: black;
  text-decoration: none;
}
</style>
