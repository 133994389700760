<template>
  <div>
    <component
        @update="update"
        :is="componentName || 'div'"
        v-bind.sync="propsObject"
        @notification="notification"
        :baseObject="baseObject"
        :ENV="ENV"
        :OAUTH="OAUTH"
    />
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import {compileTemplate, getENV, getOAUTH} from "@/libData";

export default {
  name: "CustomComponent",
  props: {
    component: [Number, String],
    props: String,
    tab: Object,
    settings: Object,
    baseObject: Object
  },
  data() {
    return {
      componentName: null,
      error: null,
      propsObject: {},
      env: []
    };
  },
  mounted() {
    this.setPropsObject();

    compileTemplate({
      componentId: this.component
    }).then(({component, env}) => {
      try {
        this.env = env;
        Vue.component(component.name, component);

        Vue.config.errorHandler = (err, vm, info) => {
          this.error = err.toString();
        };
        Vue.config.warningHandler = (err, vm, info) => {
          this.error = err.toString();
        };

        this.$nextTick(function () {
          this.componentName = component.name;
        });
      } catch (err) {
        this.error = err.toString();
      }
    });
  },
  computed: {
    ENV() {
      return getENV(this.env);
    },
    OAUTH() {
      return getOAUTH();
    }
  },
  methods: {
    notification(data) {
      this.$emit("notification", {tab: this.tab, data});
    },
    update(object) {
      let settings = {...this.settings};

      Object.keys(object).forEach(key => {
        settings = {...settings, [key]: object[key]};
      });

      this.$emit("update:settings", settings);
    },
    setPropsObject() {
      const self = this;

      (function () {
        const that = self.settings;

        self.propsObject = self.props
            ? eval(`(${self.props.replace("this", "that")})()`)
            : {};
      })();
    }
  },
  watch: {
    props: {
      handler() {
        this.setPropsObject();
      },
      immediate: true
    },
    settings: {
      handler() {
        this.setPropsObject();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
