<template>
  <div>
    <v-layout justify-center>
      <v-flex xs8>
        <v-toolbar>
          <v-toolbar-title>Subscription Overview</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-container fluid grid-list-md>
            <v-layout class="mb-4" v-if="plan.id">
              <h4 class="mr-4 display-1">{{ plan.amount | toCurrency }}/{{ plan.interval }}</h4>
              <v-chip color="pink" text-color="white" v-if="plan.coupon">
                <v-icon left>check</v-icon>Coupon Applied
              </v-chip>
              <v-spacer></v-spacer>
              <div class="plan-view">
                <v-layout>
                  <v-icon large>grade</v-icon>
                  <v-flex>
                    <p class="mb-0 text-xs-left">{{ plan.name }}</p>
                    <p class="body-1 mb-0 text-xs-left">Active</p>
                  </v-flex>
                </v-layout>
              </div>
            </v-layout>
            <v-layout class="text-xs-left grey lighten-3 pa-2 mb-4" v-if="plan.id && (plan.end_date || plan.start_date || plan.coupon || plan.extra_users > 0)">
              <v-flex xs12 sm6 md3 v-if="plan.end_date">
                <p class="caption mb-1">Next Payment</p>
                <p class="subheading mb-0">-{{ plan.amount | toCurrency }} on {{ plan.end_date | formatDate }}</p>
              </v-flex>
              <v-flex xs12 sm6 md3 v-if="plan.start_date">
                <p class="caption mb-1">Started</p>
                <p class="subheading mb-0">{{ plan.start_date | formatDate }}</p>
              </v-flex>
              <v-flex xs12 sm6 md3  v-if="plan.coupon">
                <div>
                  <p class="caption mb-1">Coupon</p>
                  <p class="subheading mb-0">25% off for 6 months</p>
                </div>
              </v-flex>
              <v-flex xs12 sm6 md3 v-if="plan.extra_users > 0">
                <p class="caption mb-1">Extra Users</p>
                <p class="subheading mb-0">+{{ plan.extra_users }} ${{ plan.extra_users * plan.unit_amount | toCurrency }} per {{ plan.interval }}</p>
              </v-flex>
            </v-layout>

            <v-layout class="text-xs-left outlined pa-2 mb-4 align-center" v-if="organisation && organisation.id">
              <v-flex>
                <div v-if="plan.id">
                  <p class="caption mb-1">{{ planSubscriptionLabel }}</p>
                  <p class="body-1 mb-0">{{ plan.amount | toCurrency }} per {{ plan.interval }}</p>
                </div>
              </v-flex>
              <v-flex shrink class="action-container text-xs-center">
                <a class="title text-uppercase" @click="showPlanModal">Change Plan</a>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <Invoices v-if="invoices" class="mt-3" />
  </div>
</template>

<script>
import PlanModal from "./PlanModal";
import Invoices from "./Invoices";
import * as _ from "underscore";
export default {
  name: "Subscriptions",
  components: {
    Invoices
  },
  data() {
    return {
      changed: false,
      organisation: null,
      subscriptionId: null,
      plans: {},
      editingCard: false,
      invoices: [],
      plan: {
        name: '',
        interval: null,
        amount: 0,
        id: '',
        start_date: '',
        end_date: '',
        coupon: null,
        unit_amount: 0,
        extra_users: 0
      },
      menu: false
    };
  },
  created() {
    this.fetchPlans().then(res => {
      this.plans = res.body;
      this.fetchOrganisation();
    });
  },
  methods: {
    goAway() {
      if (
        !this.organisation ||
        !this.organisation.current_user_roles.includes("admin")
      ) {
        this.$router.push({ name: "dashboard" });
        return true;
      }
    },
    fetchOrganisation() {
      return this.api.Organisation.getCurrent().then(res => {
        this.organisation = res.body;
        // Subscription informaiton; plan and invoices as well
        this.fetchSubscription();
      });
    },

    fetchSubscription() {
      this.api.Organisation.getSubscription({ id: this.organisation.id }).then(res => {
        this.subscriptionId = res.body.id;
        let plan = res.body.plan;
        // Parse the api result into plan object for display purpose.
        this.plan.id = plan.id;
        this.plan.name = plan.name;
        this.plan.interval = plan.interval;
        if (res.body.current_period_start) this.plan.start_date = new Date(res.body.current_period_start * 1000);
        if (res.body.current_period_end) this.plan.end_date = new Date(res.body.current_period_end * 1000);
        // need more calculation
        this.calculateAmount(res.body.plan, res.body.quantity);

        this.fetchInvoices();
      });
    },

    fetchInvoices() {
      // Get invoicies after getting suscription
      this.api.Organisation.getInvoices({ organisation_id: this.organisation.id, id: this.subscriptionId }).then(res => {
        if (res.body.messages === "Invoices not found") this.invoices = []; else this.invoices = res.body;
      });
    },

    
    fetchPlans() {
      return this.api.Plan.get();
    },

    // Utility methods
    // 
    calculateAmount(planBaseData, memberCount) {
      if (planBaseData.amount) {
        this.plan.amount = planBaseData.amount / 100;
      } else {
        let amount = 0;
        if (planBaseData.billing_scheme === "tiered") {
          let baseTier = planBaseData.tiers[0];
          let extraTier = planBaseData.tiers[1];
          amount = baseTier.flat_amount;
          this.plan.unit_amount = extraTier.unit_amount;
          if (memberCount > baseTier.up_to) {
            amount += extraTier.unit_amount * (memberCount - baseTier.up_to);
          }
        }
        this.plan.amount = amount / 100;
      }
    },

    update() {
      this.api.Organisation.update(
        { id: this.organisation.id },
        { organisation: this.organisation }
      ).then(res => {
        this.organisation = res.body;
        this.initialOrganisationDump = JSON.stringify(this.organisation);
      });
    },
    showPlanModal() {
      this.$modal.show(PlanModal, {
        organisation: this.organisation,
        currentPlan: this.plan,
        callback: (plan) => {
          this.plan = plan;
          this.plan.amount = plan.amount / 100;
          if (this.subscriptionId) {
            this.api.Subscription.update({ id: this.subscriptionId },  { subscription: {plan_id: plan.id} })
              .then(res => {
                console.log("SUBSCRIPTION udpate response", res);
              })
          } else {
            let subscription = {
              plan_id: plan.id,
              subscription_for: 'organisation',
              owner_id: this.organisation.id
            }
            this.api.Subscription.save({ subscription })
              .then(res => {
                console.log("SUBSCRIPTION create response", res);
              })
          }
        }
      });
    }
  },
  watch: {
    organisation: {
      handler() {
        this.changed =
          this.initialOrganisationDump != JSON.stringify(this.organisation);
      },
      deep: true
    }
  },
  computed: {
    planSubscriptionLabel() {
      if (this.plan && this.plan.interval === "month") return "Monthly Subscription";
      if (this.plan && this.plan.interval === "year") return "Yearly Subscription";
      return "";
    }
  }
};
</script>
<style>
.plan-view {
  width: 200px;
}

.outlined {
  border: 1px solid #EEEEEE;
}

.action-container {
  width: 200px;
}

.cvc {
  width: 50px;
}

a:disabled {
  color: gray;
}
</style>