<template>
  <div class="rect-object" :style="rectObjectStyles">
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "SpatialAudio_AudioSpaceObject",
  props: ['selected'],
  extends: ExtendsComponent,
  computed: {
    rectObjectStyles() {
      return {
        background: this.localSettings.backgroundColor || "#e7e7e7",
        borderRadius: (this.localSettings.borderRadius || 0) + "px",
        fontSize: (this.localSettings.fontSize || 0) + "px",
        borderStyle: this.localSettings.borderStyle || "none",
        borderColor: this.localSettings.borderColor || "none",
        textAlign: this.localSettings.textAlign || "center"
      };
    }
  }
};
</script>

<style scoped>
.rect-object {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>

<style>
.Base_RectObject > .base-object__content {
  height: 100%;
}
</style>
