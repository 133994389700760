const actions = [
  'activity.fetchLatest',
  'activity.fetchPast',
  'activity.recover',
  'async.unread.markAsRead',
  'async.unread.highlightAllWidgets',
  'chat.initialize',
  'chat.receiveMessage',
  'chat.sendMessage',
  'chatWithCustomerSupport',
  'clipboard.copy',
  'clipboard.cut',
  'clipboard.paste',
  'dom.changeState',
  'dom.register.viewport',
  'dom.setDraggingWidget',
  'dom.setHoverOverHandler',
  'dom.setHoverOverWidget',
  'dragging.move',
  'dragging.start',
  'dragging.stop',
  'educational.templateStarterGuide.init',
  'educational.templateStarterGuide.next',
  'educational.templateStarterGuide.hide',
  'educational.gettingStartedGuide.show',
  'educational.gettingStartedGuide.hide',
  'educational.howToNavigateWithOutline.show',
  'educational.howToNavigateWithOutline.hide',
  'educational.ifYouAreReadyToStart.show',
  'educational.ifYouAreReadyToStart.hide',
  'educational.navigation.show',
  'educational.removeCurrent',
  'educational.suggest',
  'educational.summonEveryone.show',
  'educational.summonEveryone.hide',
  'facilitation.asFacilitator.addControlledParticipant',
  'facilitation.asFacilitator.removeControlledParticipant',
  'facilitation.asFacilitator.stopFollowingController',
  'facilitation.asFacilitator.summonParticipants',
  'facilitation.asFacilitator.takeControl',
  'facilitation.asFollowee.addFollower',
  'facilitation.asFollowee.removeFollower',
  'facilitation.asFollower.deactivateSummon',
  'facilitation.asFollower.releaseFromControl',
  'facilitation.asFollower.releaseFromFollow',
  'facilitation.asFollower.stopFollowingParticipant',
  'facilitation.asParticipant.acceptFollowRequest',
  'facilitation.asParticipant.askToBeFollowed',
  'facilitation.asParticipant.dismissFollowRequest',
  'facilitation.asParticipant.followController',
  'facilitation.asParticipant.followParticipant',
  'facilitation.asParticipant.followSummoner',
  'facilitation.asParticipant.handleFollowRequest',
  'facilitation.capabilities.allow.only',
  'facilitation.capabilities.custom.on',
  'facilitation.capabilities.custom.off',
  'facilitation.capabilities.update',
  'facilitation.capabilities.preview.on',
  'facilitation.capabilities.preview.off',
  'facilitation.capabilities.sync.fromMural',
  'facilitation.celebrate.send',
  'facilitation.celebrate.receive',
  'facilitation.laser.on.for.everyone',
  'facilitation.laser.on.for.me',
  'facilitation.laser.off.for.everyone',
  'facilitation.laser.off.for.me',
  'facilitation.pointer.broadcast',
  'facilitation.pointer.pause.for.everyone',
  'facilitation.pointer.pause.for.user',
  'facilitation.pointer.receive',
  'facilitation.pointer.resume.for.everyone',
  'facilitation.pointer.resume.for.user',
  'facilitation.pointer.showCollaboratorPointers',
  'facilitation.pointer.toggleRemotePointers',
  'facilitation.pointer.toggleShare',
  'facilitation.privateTime.begin',
  'facilitation.privateTime.closeOverlay',
  'facilitation.privateTime.end',
  'facilitation.privateTime.hideFeedback',
  'facilitation.privateTime.refresh',
  'facilitation.privateTime.showFeedback',
  'facilitation.privateTime.toggle',
  'facilitation.reactions.enable',
  'facilitation.reactions.render',
  'facilitation.reactions.react',
  'facilitation.reactions.rejectAll',
  'facilitation.reactions.remove',
  'facilitation.sendState',
  'facilitation.timer.applyDelta',
  'facilitation.timer.pause',
  'facilitation.timer.resume',
  'facilitation.timer.setSoundEnabled',
  'facilitation.timer.start',
  'facilitation.timer.stop',
  'facilitation.timer.update',
  'facilitation.timer.updateSound',
  'facilitation.viewport.broadcast',
  'facilitation.viewport.resize',
  'facilitation.viewport.update',
  'facilitation.voting.add.vote',
  'facilitation.voting.end.for.everyone',
  'facilitation.voting.end.from.remote',
  'facilitation.voting.hide.votes',
  'facilitation.voting.highlight.start',
  'facilitation.voting.highlight.stop',
  'facilitation.voting.remove.sessions.for.everyone',
  'facilitation.voting.remove.sessions.from.remote',
  'facilitation.voting.remove.vote',
  'facilitation.voting.select',
  'facilitation.voting.show.results',
  'facilitation.voting.show.votes',
  'facilitation.voting.start.for.everyone',
  'facilitation.voting.start.from.remote',
  'facilitation.voting.sync.fromMural',
  'facilitation.voting.update.from.remote',
  'facilitation.voting.update.onParticipantOffline',
  'facilitation.voting.update.onParticipantOnline',
  'facilitation.dispose',
  'find.browse',
  'find.clear',
  'find.search',
  'find.refresh',
  'find.track',
  'hiddenWidgetTooltip.hide',
  'hiddenWidgetTooltip.show',
  'input.mouse.handleContextMenu',
  'input.mouse.handleDoubleClick',
  'input.mouse.handleDown',
  'input.mouse.handleDragMove',
  'input.mouse.handleLeave',
  'input.mouse.handleMove',
  'input.mouse.handleTouch',
  'input.mouse.handleUp',
  'integrations.jira.connect',
  'members.changePermissions',
  'menus.bottomBar.hide',
  'menus.bottomBar.show',
  'menus.connect.hide',
  'menus.connect.show',
  'menus.context.hide',
  'menus.context.show',
  'menus.hideAll',
  'modals.browserZoom.close',
  'modals.browserZoom.open',
  'modals.clipboardShortcuts.close',
  'modals.clipboardShortcuts.open',
  'modals.closeCurrent',
  'modals.companyDisclaimer.close',
  'modals.companyDisclaimer.open',
  'modals.connectJira.close',
  'modals.connectJira.open',
  'modals.confirmDeleteVotingSession.close',
  'modals.confirmDeleteVotingSession.open',
  'modals.createFromTemplate.close',
  'modals.createFromTemplate.open',
  'modals.deleteMural.close',
  'modals.deleteMural.open',
  'modals.deleteVotingSession.close',
  'modals.deleteVotingSession.open',
  'modals.duplicateMural.close',
  'modals.duplicateMural.open',
  'modals.exportArea.close',
  'modals.exportArea.open',
  'modals.exportGithub.close',
  'modals.exportGithub.open',
  'modals.exportJira.close',
  'modals.exportJira.open',
  'modals.exportJiraIssues.close',
  'modals.exportJiraIssues.open',
  'modals.exportJiraLegacyIssues.close',
  'modals.exportJiraLegacyIssues.open',
  'modals.exportMural.close',
  'modals.exportMural.open',
  'modals.exportText.close',
  'modals.exportText.open',
  'modals.facilitatorEducation.close',
  'modals.facilitatorEducation.open',
  'modals.importJira.close',
  'modals.importJira.open',
  'modals.integrations.open',
  'modals.jiraClipboardCallout.close',
  'modals.jiraClipboardCallout.open',
  'modals.muralSettings.close',
  'modals.muralSettings.open',
  'modals.outlineInstructions.close',
  'modals.outlineInstructions.open',
  'modals.pushToHub.close',
  'modals.pushToHub.open',
  'modals.search.close',
  'modals.search.open',
  'modals.share.close',
  'modals.share.open',
  'modals.shareTemplate.close',
  'modals.shareTemplate.open',
  'modals.switchTypeWarning.close',
  'modals.switchTypeWarning.open',
  'modals.thirdPartyWarning.close',
  'modals.thirdPartyWarning.open',
  'modals.tutorialVideo.close',
  'modals.tutorialVideo.open',
  'modals.upgradePlan.close',
  'modals.upgradePlan.open',
  'modals.uploadFiles.close',
  'modals.uploadFiles.open',
  'modals.whoIsFollowing.close',
  'modals.whoIsFollowing.open',
  'mural.addConnectorAt',
  'mural.addStickyAt',
  'mural.createMuralFromTemplate',
  'mural.delete',
  'mural.deleteSelectedWidgets',
  'mural.deleteWidgets',
  'mural.editTemplateDetails',
  'mural.endConnectorMode',
  'mural.endPanning',
  'mural.export',
  'mural.openLink',
  'mural.publishAsTemplate',
  'mural.pushToHub',
  'mural.redo',
  'mural.toggleFavorite',
  'mural.update.for.everyone',
  'mural.update.for.me',
  'mural.undo',
  'mural.resync',
  'mural.setMode',
  'mural.sharing.editLink.create',
  'mural.sharing.editLink.revoke',
  'mural.sharing.editLink.set',
  'mural.sharing.settings.reset',
  'mural.sharing.settings.update',
  'mural.sharing.templateLink.create',
  'mural.sharing.templateLink.revoke',
  'mural.sharing.updateMuralLinkWithSenderID',
  'mural.startConnectorMode',
  'mural.startDrawMode',
  'mural.template.update',
  'mural.updateSettings',
  'mural.uploadAssets.byFiles',
  'mural.uploadAssets.byUrls',
  'navigation.controls.save',
  'navigation.settings.close',
  'navigation.settings.open',
  'navigation.tooltip.hide',
  'network.reconnecting',
  'network.setStatus',
  'notifications.close',
  'notifications.hide',
  'notifications.show',
  'onboarding.guided.callouts.holdSpace.hide',
  'onboarding.guided.callouts.holdSpace.show',
  'onboarding.guided.callouts.suggestSpaceBar.hide',
  'onboarding.guided.callouts.suggestSpaceBar.show',
  'onboarding.guided.finish',
  'onboarding.guided.setSearchedImage',
  'onboarding.guided.setUploadedFile',
  'onboarding.guided.toCopyLink',
  'onboarding.guided.toDoubleClickSticky',
  'onboarding.guided.toDragFramework',
  'onboarding.guided.toFifthStepComplete',
  'onboarding.guided.toFirstStepComplete',
  'onboarding.guided.toFourthStepComplete',
  'onboarding.guided.toSearchImage',
  'onboarding.guided.toSecondStepComplete',
  'onboarding.guided.toSixthStepComplete',
  'onboarding.guided.toThirdStepComplete',
  'onboarding.guided.toTypeCity',
  'onboarding.invitee.doubleClickDone',
  'onboarding.invitee.finish',
  'onboarding.invitee.panningDone',
  'onboarding.invitee.skip',
  'onboarding.invitee.welcomeDone',
  'onboarding.invitee.writeNameDone',
  'onboarding.invitee.zoomInDone',
  'onboarding.invitee.zoomOutDone',
  'onboarding.invitee.zoomSettingsDone',
  'onboarding.modals.openVisitorModal',
  'outline.index.clear',
  'outline.index.set',
  'outline.presentation.end',
  'outline.presentation.next',
  'outline.presentation.previous',
  'outline.presentation.start',
  'outline.setTitle',
  'outline.startEditingTitle',
  'outline.stopEditingTitle',
  'outline.widgets.add',
  'outline.widgets.drag.move',
  'outline.widgets.drag.start',
  'outline.widgets.drag.stop',
  'outline.widgets.remove',
  'outline.widgets.setCurrent',
  'participants.add',
  'participants.update.member',
  'participants.update.visitor',
  'participants.set.everyone.offline',
  'participants.set.online',
  'participants.set.offline',
  'participants.sync.fromMural',
  'participants.load.visitors',
  'rightbar.allowClosing',
  'rightbar.close',
  'rightbar.comments.clearDraft',
  'rightbar.comments.setCommentListTab',
  'rightbar.comments.setDraft',
  'rightbar.open',
  'rightbar.preventClosing',
  'rightbar.toggle',
  'selection.align',
  'selection.arrange',
  'selection.clear',
  'selection.clearByClick',
  'selection.distribute',
  'selection.filter',
  'selection.group',
  'selection.marquee.move',
  'selection.marquee.start',
  'selection.marquee.stop',
  'selection.resize',
  'selection.select.rootWidgets',
  'selection.ungroup',
  'selection.unselectAllAndHideLock',
  'sidebar.changeTab',
  'sidebar.closeTab',
  'sidebar.createDraggingWidget',
  'sidebar.createFromProto',
  'sidebar.dropDraggingWidget',
  'sidebar.close',
  'sidebar.icons.fetchNext',
  'sidebar.icons.list',
  'sidebar.icons.search',
  'sidebar.moveDraggingWidget',
  'sidebar.scaleDraggingWidget',
  'sidebar.setActiveConnector',
  'snap.setClusters',
  'snap.stopSnapping',
  'snippets.add',
  'snippets.delete',
  'snippets.get',
  'snippets.getNext',
  'snippets.rename',
  'toolbar.refresh',
  'view.init',
  'viewport.fit',
  'viewport.fitToContent',
  'viewport.fitToLastVisit',
  'viewport.fitToWidget',
  'viewport.scroll',
  'viewport.setRawViewport',
  'viewport.update',
  'viewport.zoomDeltaTo',
  'viewport.zoomTo',
  'widgets.add.arrow',
  'widgets.add.shape',
  'widgets.add.sticky',
  'widgets.add.textbox',
  'widgets.add.title',
  'widgets.addConnected',
  'widgets.addAtPosition',
  'widgets.area.absorbAllWidgets',
  'widgets.area.absorbOverlappingWidgets',
  'widgets.area.showTitle',
  'widgets.area.startEditing',
  'widgets.area.stopEditing',
  'widgets.area.switchLayout',
  'widgets.cluster.removeWidgetsFromCluster',
  'widgets.cluster.removeAllWidgetsFromCluster',
  'widgets.comment.addReply',
  'widgets.comment.deleteIfEmpty',
  'widgets.comment.hide',
  'widgets.comment.hideResolved',
  'widgets.comment.openInRightbar',
  'widgets.comment.removeReply',
  'widgets.comment.reopen',
  'widgets.comment.resolve',
  'widgets.comment.show',
  'widgets.comment.showResolved',
  'widgets.comment.updateVisibility',
  'widgets.comment.upload',
  'widgets.connector.draw',
  'widgets.connector.edit',
  'widgets.connector.endDraw',
  'widgets.connector.handler.drag',
  'widgets.connector.handler.drop',
  'widgets.connector.handler.segment.drag',
  'widgets.connector.handler.segment.drop',
  'widgets.connector.invert',
  'widgets.connector.label.add',
  'widgets.connector.label.addFromToolbar',
  'widgets.connector.label.drag',
  'widgets.connector.label.drop',
  'widgets.connector.label.edit',
  'widgets.connector.label.realign',
  'widgets.connector.label.setFormat',
  'widgets.connector.label.setTextColor',
  'widgets.connector.label.stopEditing',
  'widgets.connector.label.typing',
  'widgets.connector.openConnectMenu',
  'widgets.connector.setTip',
  'widgets.connector.setType',
  'widgets.connector.startDraw',
  'widgets.connector.startDrawFromWidget',
  'widgets.createConnectedWidget',
  'widgets.decreaseFontSize',
  'widgets.deleteSelected',
  'widgets.drawing.edit',
  'widgets.drawing.setHasPendingContent',
  'widgets.drawing.setPencil',
  'widgets.drawing.startEditing',
  'widgets.drawing.stopEditing',
  'widgets.drawing.switchToSticky',
  'widgets.drawing.updatePath',
  'widgets.edit.start',
  'widgets.edit.stop',
  'widgets.file.startEditing',
  'widgets.file.stopEditing',
  'widgets.framework.absorbOverlappingWidgets',
  'widgets.framework.showTitle',
  'widgets.hide',
  'widgets.increaseFontSize',
  'widgets.inking.addStrokes',
  'widgets.inking.focus',
  'widgets.inking.removeStrokes',
  'widgets.inking.setInputType',
  'widgets.inking.setPencil',
  'widgets.inking.stopEditing',
  'widgets.locked.hide',
  'widgets.locked.hideAllUnselected',
  'widgets.outline.set',
  'widgets.photo.crop.start',
  'widgets.photo.setThumb',
  'widgets.photo.showCaption',
  'widgets.photo.startEditing',
  'widgets.photo.stopEditing',
  'widgets.photo.typing',
  'widgets.pointer.move',
  'widgets.pointer.down',
  'widgets.pointer.up',
  'widgets.preview.disable',
  'widgets.preview.enable',
  'widgets.quickAdd.clearSuggestion',
  'widgets.quickAdd.create',
  'widgets.quickAdd.createFromDirection',
  'widgets.quickAdd.refreshHandlers',
  'widgets.quickAdd.suggest',
  'widgets.quickAdd.startDrawingConnector',
  'widgets.quickAdd.suggestFromDirection',
  'widgets.remote.dragging.start',
  'widgets.remote.dragging.stop',
  'widgets.remote.editing.start',
  'widgets.remote.editing.stop',
  'widgets.remote.set.offline',
  'widgets.resizeLegacy.updateBboxes',
  'widgets.resizeLegacy.updateGroupBoundingBox',
  'widgets.resizeLegacy.updateSize',
  'widgets.rotate.drag',
  'widgets.rotate.end',
  'widgets.rotate.start',
  'widgets.set.background.color',
  'widgets.set.border.color',
  'widgets.set.border.style',
  'widgets.set.border.visible',
  'widgets.set.link',
  'widgets.set.stroke.color',
  'widgets.set.stroke.style',
  'widgets.set.stroke.width',
  'widgets.set.text.align',
  'widgets.set.text.fontFamily',
  'widgets.set.text.format',
  'widgets.shape.resetFont',
  'widgets.shape.setTextColor',
  'widgets.shape.startEditing',
  'widgets.shape.stopEditing',
  'widgets.shape.toggleFormat',
  'widgets.shape.typing',
  'widgets.sticky.cloneAndEdit',
  'widgets.sticky.startEditing',
  'widgets.sticky.stopEditing',
  'widgets.sticky.switchToDrawing',
  'widgets.sticky.toggleFormat',
  'widgets.sticky.typing',
  'widgets.switch',
  'widgets.title.scrollIntoView',
  'widgets.title.selectionChange',
  'widgets.title.setListType',
  'widgets.title.setTextColor',
  'widgets.title.startEditing',
  'widgets.title.stopEditing',
  'widgets.title.typing'
];

export default actions;
