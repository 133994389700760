<template>
  <div id="app">
    <div v-if="!loading">
      <TheAuthorizedLayout v-if="currentUser" />
      <TheNotAuthorizedLayout v-if="!currentUser" />
    </div>
    <div v-else class="spinner">
      <atom-spinner :animation-duration="1000" :size="100" :color="'#df4e9e'" />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import TheAuthorizedLayout from "./components/Layout/TheAuthorizedLayout";
import TheNotAuthorizedLayout from "./components/Layout/TheNotAuthorizedLayout";
import "./components/_Global";

export default {
  name: "app",
  components: {
    TheAuthorizedLayout,
    TheNotAuthorizedLayout
  },
  created() {
    document.execCommand("defaultParagraphSeparator", false, "p");
  },
  computed: {
    ...mapState({
      loading: state => state.application.loading
    })
  }
};
</script>

<style>
#app {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
}
</style>
