import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
import Vue from "vue";
import Vuetify from "vuetify";
import VModal from "vue-js-modal";
import { VueMasonryPlugin } from "vue-masonry";
import infiniteScroll from "vue-infinite-scroll";

import { WEBSOCKETS_URL } from "@/env";

import App from "./App.vue";

import "vuetify/dist/vuetify.min.css";
import "./assets/styles.css";
import "./assets/responsive.css";
import "animate.css";

import store from "./store";
import router from "./router";
import { mapState } from "vuex";
import moment from "moment";

import ActionCableVue from "actioncable-vue";
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: WEBSOCKETS_URL
});

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import shortKey from "vue-shortkey";
Vue.use(shortKey);

import { Plugin } from "vue-fragment";
Vue.use(Plugin);

import "./resources";

import _ from 'underscore';
_.templateSettings = {
  interpolate: /{{(.+?)}}/g
};

import VueIntercom from "vue-intercom";
Vue.use(VueIntercom, { appId: 'lo9rzy2a'});

import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

Vue.config.productionTip = false;

Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#df4e9e",
        secondary: "#424242",
        accent: "#525fa6",
        error: "#FF5252",
        info: "#2196F3",
        success: "#6eccb9",
        warning: "#FFC107"
      }
    }
  }
});

Vue.use(VModal, { dynamic: true });
Vue.use(VueMasonryPlugin);
Vue.use(infiniteScroll);

import { guid } from "@/lib";
Vue.mixin({
  methods: {
    guid
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current,
      activeChart: state => state.chart.active
    }),
    activeProject: {
      get() {
        return this.$store.getters["project/active"];
      },
      set(res) {
        this.api.Project.update({ id: this.projectId }, { project: res }).then(
          response => {
            this.$store.dispatch("project/set", response.body.id);
          }
        );
      }
    }
  }
});

import titleMixin from './mixins/titleMixin';
Vue.mixin(titleMixin);

Vue.filter('toCurrency', (value) => {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format("MMM DD, YYYY")
  }
});

const app = new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default app;
