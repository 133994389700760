<template>
  <div>
    <label class="select-video">
      <v-icon color="white" class="mr-2">upload</v-icon>
      {{label}}
      <input type="file" @change="onFileChanged" />
    </label>

    <v-layout justify-space-between>
      <v-btn v-if="!loading" @click="close">Cancel</v-btn>

      <v-btn
        v-if="selectedFile"
        color="primary"
        @click="upload"
        :disabled="loading"
      >
        {{ loading ? "Uploading..." : "Upload" }}
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      selectedFile: null
    };
  },
  computed: {
    label() {
      if (!this.selectedFile)
        return "Select video";
      return this.selectedFile.name + (this.loading ? " uploading..." : "");
    }
  },
  methods: {
    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },
    upload() {
      this.loading = true;

      const formData = new FormData();
      formData.append("attachment", this.selectedFile, this.selectedFile.name);

      this.$http.post("vulcan/v1/uploads/video", formData).then(response => {
        const result = response.body;
        this.loading = false;
        this.$emit("input", result);
        this.$emit("close");
      });
    },
    close() {
      this.selectedFile = null;
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.select-video {
  width: 100%;
  cursor: pointer;
  height: 210px;
  border: 1px solid rgba(232,0,90);
  border-radius: 4px;
  background: rgba(232,0,90,0.7);
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.select-video:hover {
  filter: brightness(110%);
}

.select-video >>> input {
  position: absolute;
  visibility: hidden;
}
</style>
