<template>
  <div class="wayfinder">
    <div
        :key="position"
        v-for="position in ['top', 'bottom', 'left', 'right']"
        :class="['wayfinder__handle', `wayfinder__handle-${position}`]"
    ></div>
    <div class="wayfinder-cam" v-if="localSettings.video"></div>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Presentation_Wayfinder",
  extends: ExtendsComponent
};
</script>

<style scoped>
.wayfinder {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 3px dashed rgb(232, 0, 90);
  position: relative;
  pointer-events: none !important;
}

.wayfinder__handle {
  pointer-events: all;
  position: absolute;
  background: rgba(232, 0, 90, 0.1);
}

.wayfinder__handle-top {
  height: 40px;
  top: 0;
  width: 100%;
}

.wayfinder__handle-bottom {
  height: 40px;
  bottom: 0;
  width: 100%;
}

.wayfinder__handle-left {
  left: 0;
  width: 40px;
  height: 100%;
}

.wayfinder__handle-right {
  right: 0;
  width: 40px;
  height: 100%;
}

.wayfinder-cam {
  position: absolute;
  right: 28px;
  bottom: 28px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  border-radius: 50%;
  background: rgba(232, 0, 90);
  border: 2px solid rgba(232, 0, 90);
}

.wayfinder-cam:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 50%;
  cursor: pointer;
  right: 0;
  border: 2px solid white;
}
</style>

<style>
.Presentation_Wayfinder,
.Presentation_Wayfinder .base-object__content {
  z-index: -1 !important;
  pointer-events: none;
}

.Presentation_Wayfinder > * {
  pointer-events: all;
}

.Presentation_Wayfinder > .base-object__content {
  height: 100%;
}
</style>
