<template>
  <v-card :class="{'pa-3': true, 'isOpen': isOpen }" id="custom-components-toolbar" dense>
    <div class="custom-components-toolbar-header">
      <h4>Custom Components</h4>
      <v-btn icon rounded id="close-btn" @click.stop="closePanel">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <p class="entry-text my-2">A library of your custom components by categories.
      <span v-if="isEmpty">Currently empty.</span></p>
    <section class="py-2" v-for="(components, propertyName, index) in objectsByCategory" :key="index">
      <h4 class="category-name">{{ propertyName }}</h4>
      <template v-for="cmp in components">
        <v-btn
            @click.stop="createInstance(cmp, $event)"
            color="#df4e9e"
            outlined
            rounded
            small
            class="ma-1"
        >
          {{ cmp.name }}
        </v-btn>
      </template>
    </section>
  </v-card>

</template>

<script>
import {HEADER_HEIGHT} from "../../../../../utils/const";

export default {
  name: "CustomComponentsToolbar",
  props: {
    isOpen: Boolean,
    togglePanel: Function
  },
  computed: {
    objectsByCategory() {
      let componentMap = {}
      const categories = this.$store.getters['component/activeCategories']
      if (categories.length) {
        categories.map(c =>
            componentMap[c] = []
        )
      }

      this.$store.getters['component/componentsObjects']
          .map(cmp => {
            const {category} = cmp.object_settings

            componentMap[category] = [...componentMap[category], {
              component: cmp,
              name: cmp.name,
            }]
          })

      return componentMap
    },
    isEmpty() {
      return Object.keys(this.objectsByCategory).length === 0
    }

  },
  methods: {
    async createInstance({component}, event) {
      let position;

      if (event) {
        const {scale, translate} = this.$store.state.chart;
        const rect = this.$parent.$parent.$parent.$el.getBoundingClientRect();

        position = {
          x: Math.round((rect.width / 2 - translate.x - rect.x) / scale),
          y: Math.round((rect.height / 2 - HEADER_HEIGHT - translate.y - rect.y) / scale)
        };
      }

      const objectParams = {
        ...component,
        type: "Core_CustomComponent",
        position,
        info: {
          settings: {
            title: "",
            image: null,
            componentId: component.id
          }
        },
      };

      const res = await this.$store.dispatch("object/create", objectParams);
      await this.$nextTick();
      await this.$nextTick();

      const object = this.$store.getters["object/findById"](res.id);
      const {width, height} = object.size
      await this.$store.dispatch("object/update", {
        id: object.id,

        position: {
          x: object.position.x - width / 2,
          y: object.position.y - height / 2
        }
      });


      await this.$nextTick();
      const connection = this.$store.getters["connection/find"]({
        to: component.id
      })[0];

      if (connection) {
        await this.$store.dispatch("connection/create", {
          from: connection.from,
          to: object.id
        });
      }
      this.$root.$emit("PropertyEditor.close");
    },
    closePanel() {
      this.togglePanel()
    }
  },

}
</script>

<style>
#custom-components-toolbar {
  transition: 0.1s ease-out left;
  padding-top: 0 !important;
  display: none;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 55px;
  height: 100%;
  min-width: 340px;
  overflow-x: hidden;
  overflow-y: auto;
}

.custom-components-toolbar-header {
  position: sticky;
  top: 0;
  padding: 8px 0;
  display: flex;
  flex-wrap: nowrap;
  background: #FFFFFF;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
}

.entry-text {
  font-weight: 400;
  font-size: .9rem;
}

.category-name {
  font-weight: 600;
  font-size: 1rem;
}

.isOpen {
  display: block !important;
}
</style>
