import Vue from "vue";

import "../Object/Types/AnalysisTools/_Global";

import PinnedContent from "./PinnedContent";
Vue.component("PinnedContent", PinnedContent);

import PropertyEditor from "./PropertyEditor";
Vue.component("PropertyEditor", PropertyEditor);

import DataEditor from "./DataEditor";
Vue.component("DataEditor", DataEditor);

import AudioSettingsModal from "./AudioSettingsModal";
Vue.component("AudioSettingsModal", AudioSettingsModal);

import ObjectAudioSettingsModal from "./ObjectAudioSettingsModal";
Vue.component("ObjectAudioSettingsModal", ObjectAudioSettingsModal);

import ColorPickerDialog from "./ColorPickerDialog";
Vue.component("ColorPickerDialog", ColorPickerDialog);

import AddonsList from "./AddonsList";
Vue.component("AddonsList", AddonsList);

import ScreenPositionSelector from "./ScreenPositionSelector";
Vue.component("ScreenPositionSelector", ScreenPositionSelector);
