<template>
  <div class="mt-5" v-if="settings.componentId">
    <h3>{{settings.title}}</h3>
    <component
      :is="componentName || 'div'"
      v-bind="objectProps"
      :scope="scope"
      @update="onUpdate"
    />
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
  import Vue from "vue";
  import { compileTemplate } from "@/libData";
  import {mapGetters} from "vuex";

  export default {
    name: 'ControlElement_CustomComponent',
    props: {
      settings: Object
    },
    data() {
      let objectProps = {};
      if (this.settings.props)
        objectProps = eval(`${this.settings.props}; props()`);
      return {
        componentName: null,
        error: null,
        env: [],
        objectProps
      };
    },
    computed: {
      ...mapGetters({
        scope: 'wayfinder/scope'
      })
    },
    watch: {
      settings() {
        this.componentName = null;
        this.compile();
      }
    },
    created() {
      this.compile();
      this.onUpdate(null);
    },
    methods: {
      async compile() {
        try {
          const { component, env } = await compileTemplate({
            componentId: this.settings.componentId
          });

          this.env = env;

          const componentName = component.name + this.guid();
          Vue.component(componentName, component);

          Vue.config.errorHandler = (err, vm, info) => {
            this.error = err.toString();
          };
          Vue.config.warningHandler = (err, vm, info) => {
            this.error = err.toString();
          };

          await this.$nextTick();
          this.componentName = componentName;
        } catch (err) {
          this.error = err.toString();
        }
      },
      onUpdate(value) {
        if (!this.settings.model)
          return;
        this.$store.commit(`wayfinder/updateCurrentScope`, {
          [this.settings.model]: value
        });
      }
    }
  }
</script>