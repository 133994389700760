<template>
  <div class="notion-editor d-flex flex-column" :style="{maxHeight, height: '100%'}">
    <v-row no-gutters class="flex-shrink-1">
      <v-col>
        <v-tabs background-color="white" v-model="activeTab">
          <v-tabs-slider color="primary" />
          <v-tab>
            <v-icon>view_day</v-icon>
          </v-tab>
          <v-tab v-if="!readonly">
            <v-icon>code</v-icon>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="auto">
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn
              class="mr-2"
              v-if="!readonly"
              v-on="on"
              outlined
              color="primary"
              small
              @click="generateStickies"
            >
              <v-icon>mdi-sticker-emoji</v-icon>
            </v-btn>
          </template>
          <span>Generate Stickies from Notes</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn
              v-if="!readonly"
              outlined
              v-on="on"
              color="primary"
              small
              @click="textAnalyze"
            >
              <v-icon>mdi-flash-auto</v-icon>
            </v-btn>
          </template>
          <span>Entity Analysis</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn
              text
              v-if="!!onPin && !readonly"
              @click="onPin"
              v-on="on"
              class="pa-0 ml-2"
              style="min-width: 40px"
            >
              <v-icon>mdi-pin</v-icon>
            </v-btn>
          </template>
          <span>Pin Editor</span>
        </v-tooltip>
        <v-btn text v-if="!!onClose" @click="onClose" class="pa-0 ml-1" style="min-width: 40px">
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="notion-editor-content flex-grow-1" v-show="activeTab == 0" style="overflow-y: auto">
      <draggable v-model="lines" handle=".notion-line__action-more">
        <NotionLine
          v-for="line in lines"
          :key="line.id"
          :line="line"
          :active="lines.length == 1"
          @emit="callback(line.id, $event)"
          @addLine="addLine(line.id, $event || {})"
          @previousLine="previousLine(line.id, $event || {})"
          @nextLine="nextLine(line.id, $event || {})"
          @delete="deleteLine(line.id)"
          @duplicate="duplicateLine(line.id)"
          @createSticky="createStickyFromLine(line.id)"
          @input="updateLine({id: line.id, $event})"
          :customComponents="customComponents"
          :baseObject="baseObject"
          :readonly="readonly"
          :startFocus.sync="startFocus"
        />
      </draggable>
    </div>

    <div v-show="activeTab == 1" class="flex-grow-1 notion-editor-content">
      <VueEditor disabled :value="rawContent" :editorToolbar="[]" />
    </div>
  </div>
</template>

<script>
import NotionLine from "./NotionLine";

import { walk, guid } from "@/lib";
import {compileTemplate} from '@/libData';
import { htmlToText } from "./lib";

export default {
  name: "NotionEditor",
  props: {
    value: {
      type: Array,
      default() {
        return [
          {
            id: guid(),
            type: "Text",
            content: ""
          }
        ];
      }
    },
    baseObject: {
      type: Object,
      default() {
        return {};
      }
    },
    readonly: Boolean,
    onClose: Function,
    onPin: Function,
    maxHeight: String,
    fromPinned: Boolean
  },
  components: {
    NotionLine
  },
  data() {
    return {
      lines: this.value,
      activeTab: 0,
      customComponents: [],
      rawContent: "",
      startFocus: this.fromPinned
    };
  },
  created() {
    if (!this.currentUser) return;
    this.api.Component.get({ shortcut: true }).then(response => {
      this.customComponents = response.body.map(item => ({
        id: item.id,
        name: item.shortcut,
        type: "CustomComponent",
        template: item.template,
        handler(ctx, fn) {
          compileTemplate({
            componentId: item.id
          }).then(({ component: compiled }) => {
            let availableProps = [];
            if (typeof compiled.props == "object") {
              availableProps = Object.keys(compiled.props).filter(ap => {
                return compiled.props[ap].hidden !== true;
              });
            } else {
              availableProps = compiled.props;
            }

            availableProps = availableProps.map(ap => `${ap}=""`).join(" ");

            const content = `/${item.shortcut} ${availableProps}`;

            ctx.$emit("emit", {
              type: "ADD_LINE",
              payload: {
                position: content.indexOf('"') + 1,
                type: "Code",
                content
              }
            });

            if (fn) fn();
          });
        }
      }));
    });
  },
  methods: {
    callback(id, { type, payload }) {
      switch (type) {
        case "DUPLICATE":
          this.duplicateLine(id);
          break;
        case "CREATE_STICKY":
          this.createStickyFromLine(id);
          break;
        case "DELETE":
          this.deleteLine(id);
          break;
        case "SET_COLOR":
          this.setColor(id, payload);
          break;
        case "SET_BACKGROUND":
          this.setBackground(id, payload);
          break;
        case "ADD_LINE":
          this.addLine(id, payload);
          break;
        case "TURN_INTO":
          this.turnInto(id, payload);
          break;
      }
    },
    turnInto(id, { type }) {
      const index = this.findLineIndex(id);
      this.lines.splice(index, 1, { ...this.lines[index], type });
    },
    setColor(id, color) {
      const index = this.findLineIndex(id);
      this.lines.splice(index, 1, { ...this.lines[index], color });
    },
    setBackground(id, background) {
      const index = this.findLineIndex(id);
      this.lines.splice(index, 1, { ...this.lines[index], background });
    },
    findLineIndex(id) {
      const index = this.lines.findIndex(l => l.id == id);
      return index;
    },
    addLine(afterLineId, { content, position, id, type, params = {} }) {
      const index = this.findLineIndex(afterLineId);

      let splicer;
      if (id == this.lines[index].id) {
        splicer = 1;
      } else {
        splicer = 0;
      }

      const uuid = this.guid();
      this.lines.splice(index + 1, splicer, {
        id: uuid,
        type: type,
        content,
        ...params
      });

      if (position) {
        this.$nextTick(function() {
          this.focusLine(uuid, { position });
        });
      }
    },
    focusLine(id, { position, content }) {
      this.$root.$emit("focusNotionLine", { id, position, content });
    },
    previousLine(id, { appendContent = "", position }) {
      const index = this.findLineIndex(id);
      const targetLine = this.lines[index - 1];

      if (targetLine) {
        const targetContentLength = htmlToText(targetLine.content).length;
        let newPosition = position,
          content = targetLine.content;

        if (appendContent) {
          content += appendContent;
          this.lines.splice(index - 1, 1, {
            ...targetLine,
            content
          });

          newPosition = targetContentLength;
        }

        newPosition =
          newPosition > targetContentLength ? targetContentLength : newPosition;

        if (newPosition == -1) {
          newPosition = targetContentLength;
        }
        this.focusLine(targetLine.id, { position: newPosition, content });
      }
    },
    nextLine(id, { position = 0, initialContent = "" }) {
      const index = this.findLineIndex(id);
      const targetLine = this.lines[index + 1];
      if (targetLine) {
        if (initialContent) {
          this.lines.splice(index + 1, 0, {
            ...targetLine,
            content: initialContent
          });
        }
        this.focusLine(targetLine.id, { position });
      }
    },
    updateLine({ id, $event }) {
      const index = this.findLineIndex(id);
      this.lines.splice(index, 1, { ...this.lines[index], ...$event });
    },
    deleteLine(id) {
      const index = this.findLineIndex(id);
      this.lines.splice(index, 1);
    },
    duplicateLine(id) {
      const index = this.findLineIndex(id);

      this.lines.splice(index + 1, 0, {
        ...this.lines[index],
        id: this.guid()
      });
    },
    createStickyFromLine(id) {
      const index = this.findLineIndex(id);

      const line = this.lines[index];

      const baseObject = this.baseObject;
      const positionX = this.baseObject.position.x;
      const positionY = this.baseObject.position.y;

      const position = {x: positionX + baseObject.size.width + 20, y: positionY};

      this.createSticky(line, position);
      
    },
    generateStickies() {
      const lines = this.lines;

      const baseObject = this.baseObject;
      const positionX = this.baseObject.position.x;
      const positionY = this.baseObject.position.y;

      for (const [index, line] of lines.entries()) {
        const margin = 30;
        const height = 180;
        const y = positionY + (index * height);
        const position = {x: positionX + baseObject.size.width + 20, y: y + margin};
        this.createSticky(line, position);
      }
    },
    createSticky(line, position) {
      this.$store.dispatch('object/create', {
        type: "Base_StickyObject",
        position,
        size: {
          height: 150,
          width: 150
        },
        info: {
          settings: {
            content: line.content,
            backgroundColor: 'rgb(254, 242, 78)'
          }
        }
      })
    },
    textAnalyze() {
      this.lines.forEach(line => {
        line.content = line.content.replace(
          /<span[^>]+class="text-analyze[^>]+>([^>]*)<\/span>/g,
          "$1"
        );
      });

      const documents = this.lines
        .filter(l => l.type == "Text" && l.content && l.content.length)
        .map(l => ({ id: l.id, text: l.content }));
      this.$http
        .post("vulcan/v1/azure/text-analyticts", { documents })
        .then(response => {
          const documents = response.body.documents;
          documents.forEach(document => {
            const id = document.id;
            const lineIndex = this.lines.findIndex(l => l.id == id);
            if (lineIndex == -1) {
              return;
            }

            const contentNode = window.document.createElement("div");
            contentNode.innerHTML = this.lines[lineIndex].content;

            let offset = 0;
            document.entities.forEach(entity => {
              // if (entity.type != "Person" && entity.type != "Organization")
              //   return;
              const match = entity.matches.sort(
                (a, b) => b.text.length - a.text.length
              )[0];

              walk(contentNode, node => {
                if (node.data.includes(match.text)) {
                  var text = node.data.split(match.text),
                    parent = node.parentNode,
                    i = 1,
                    newNode;

                  if (parent.className.includes("text-analyze")) {
                    return;
                  }

                  parent.insertBefore(
                    window.document.createTextNode(text[0]),
                    node
                  );

                  for (; i < text.length; i += 2) {
                    (newNode = window.document.createElement(
                      "span"
                    )).appendChild(window.document.createTextNode(match.text));
                    newNode.dataset.name = entity.name;
                    newNode.dataset.type = entity.type || "General";
                    newNode.dataset.fromObjectId = this.baseObject.id;
                    newNode.setAttribute(
                      "class",
                      `text-analyze text-analyze-${(
                        entity.type || "general"
                      ).toLowerCase()}`
                    );

                    parent.insertBefore(newNode, node);
                    parent.insertBefore(
                      window.document.createTextNode(text[i]),
                      node
                    );
                  }
                  parent.removeChild(node);
                }
              });
            });

            this.lines.splice(lineIndex, 1, {
              ...this.lines[lineIndex],
              content: contentNode.innerHTML
            });
            this.focusLine(this.lines[lineIndex].id, {
              content: this.lines[lineIndex].content
            });
          });
        });
    }
  },
  watch: {
    lines: {
      handler() {
        if (!this.lines.length) {
          this.lines.push({
            id: guid(),
            type: "Text",
            content: ""
          });
          return;
        }
        this.rawContent =
          (this.lines || []).map(l => l.content || "").join("<p>") || "";

        this.$emit("input", this.lines);
      },
      deep: true,
      immediate: true
    },
    value: {
      handler() {
        if (JSON.stringify(this.lines) != JSON.stringify(this.value)) {
          this.lines = this.value;
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.notion-editor {
  position: relative;
}

.notion-editor-content {
  padding: 15px 0;
  height: 100%;
  overflow-y: auto;
}
</style>

<style>
.text-analyze {
  padding: 2px 5px;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin: 5px 0;
  display: inline-block;
  background: rgba(0, 0, 0, 0.2);
}

.text-analyze-person[data-object-id],
.text-analyze-person:hover {
  background: rgba(255, 0, 0, 0.4);
}

.text-analyze-organization[data-object-id],
.text-analyze-organization:hover {
  background: rgba(0, 255, 0, 0.4);
}

.text-analyze:before {
  position: absolute;
  top: -10px;
  width: 100%;
  white-space: nowrap;
  font-size: 8px;
  left: 0;
}
.text-analyze-person {
  background: rgba(255, 0, 0, 0.2);
}
.text-analyze-person:before {
  content: "Person";
  color: red;
}
.text-analyze-organization {
  background: rgba(0, 255, 0, 0.2);
}
.text-analyze-organization:before {
  color: green;
}
.text-analyze:before {
  content: attr(data-type);
}
.notion-line__content-line .notion-line__checkbox.v-input--selection-controls .v-input__slot {
  margin-bottom: 0;
  padding-top: 3px;
}

.notion-line__content-line .notion-line__checkbox.v-input--selection-controls .v-messages {
  display: none;
}
</style>
