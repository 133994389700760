<template>
  <div class="rss-feed" v-if="rss">
    <div class="rss-feed__title">{{rss.feed.title}}</div>
    <ul class="rss-feed__list">
      <li class="rss-feed__item" v-for="(item, index) in rss.items" :key="index">
        <a :href="item.link">{{item.title}}</a>
        <p>{{item.content || item.description}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import * as _ from "underscore";
export default {
  name: "RssFeed",
  props: {
    value: String,
    readonly: Boolean
  },
  data() {
    return {
      rss: null
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch: _.debounce(function() {
      if (!this.value) return (this.rss = null);
      const url = `${
        document.location.protocol
      }//api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(
        this.value
      )}`;
      this.$http.jsonp(url).then(res => {
        this.rss = res.body;
      });
    }, 500)
  },
  watch: {
    url() {
      this.fetch();
    }
  }
};
</script>

<style scoped>
.rss-feed {
}
.rss-feed__title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.rss-feed__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.rss-feed__item {
}
</style>
