<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0">Actions</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import SettingsComponents from "./PropertyEditorComponents";

export default {
  name: "Chatbot_RouterObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpActions = Vue.extend({
      template: `
        <div>
          <div class="section pa-4 mb-4" v-for="action in settings.actions" :key="action.id">
            <v-layout align-center>
              <v-flex>
                <v-select
                  :items="actionsList"
                  item-value="id"
                  item-text="title"
                  label="Action"
                  v-model="action.type"
                  solo
                  hide-details
                  :readonly="readonly"
                />
              </v-flex>
              <v-flex xs1 />
              <v-flex>
                <Autocompleter v-model="action.condition" label="Condition" solo :readonly="readonly" />
              </v-flex>
              <v-btn text class="button-icon" @click="deleteAction(action.id)" :disabled="readonly">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-layout>
            <v-layout class="mt-4" v-if="action.type">
              <v-flex>
                <component :settings.sync="action.settings" :is="action.type" :readonly="readonly" />
              </v-flex>
            </v-layout>
          </div>
          <div class="add" @click="pushAction" v-if="!readonly">Add Action</div>
        </div>
      `,
      components: { ...SettingsComponents },
      props: ["settings", "readonly"],
      data() {
        return {
          actionsList: [
            {
              id: "GO_TO_ENTRY",
              title: "Go to entry"
            }, {
              id: "CONTINUE_PARENT_FLOW",
              title: "Continue parent flow"
            }, {
              id: "SEND_BOT_MESSAGE",
              title: "Send bot message"
            }, {
              id: "EMIT_SIGNAL",
              title: "Emit signal"
            }, /*{
              id: "SUBMIT_ON_SERVER",
              title: "Submit on server"
            }, {
              id: "OPEN_GALLERY",
              title: "Open gallery"
            }, {
              id: "OPEN_CAMERA",
              title: "Open camera"
            }, {
              id: "OPEN_PAGE",
              title: "Open page"
            }*/
          ]
        };
      },
      methods: {
        pushAction() {
          this.settings.actions.push({ id: this.guid(), settings: {} });
        },
        deleteAction(id) {
          let ind = -1;
          for (let i = 0; i < this.settings.actions.length; i++) {
            if (this.settings.actions[i].id == id) ind = i;
          }
          if (ind != -1) this.settings.actions.splice(ind, 1);
        }
      }
    });

    return {
      cmpActions,
      openedData: 0
    };
  },

  methods: {
    onOpenActions() {
      this.localSettings = { actions: [], ...this.localSettings };
      this.$root.$emit("DataEditor.open", {
        component: this.cmpActions,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Actions"
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenActions();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenActions();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>
