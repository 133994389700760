<template>
  <div class="comp">
    <v-btn
      class="btn"
      color="primary"
      v-html="settings.label"
      @click="doAction"
    />
  </div>
</template>

<script>
  export default {
    name: 'ControlElement_Button',
    props: {
      settings: Object
    },
    methods: {
      doAction () {
        const action = this.$store.getters[`wayfinder/currentAction`](this.settings.actions, {conditionField: 'if'});
        if (action)
          this.$store.commit(`wayfinder/setSignal`, this.$store.getters[`wayfinder/template`](action.signal));
        this.$store.dispatch(`wayfinder/nextSlide`);
      }
    }
  }
</script>

<style scoped>
  .comp {
    width: 100%;
  }

  .btn {
    margin: 0 !important;
    width: 100%;
    min-width: 0;
    white-space: nowrap;
  }
</style>
