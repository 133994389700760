<template>
  <v-toolbar dense no-padding>
    <v-checkbox
        class="mx-2"
        style="padding-top: 23px"
        :disabled="readonly"
        label="Allow Custom Input"
        v-model="localSettings.allowCustomInput"
    />

    <v-divider vertical/>

    <v-btn-toggle group borderless v-model="openedData" class="btn-toggle-container">
      <v-btn
          text
          style="opacity: 1"
          class="ma-0"
          value="intellisense"
      >Intellisense
      </v-btn>

      <v-divider vertical/>
      <v-btn
          text
          class="ma-0"
          style="opacity: 1"
          value="behaviours"
      >Behaviours
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical/>

    <slot name="menumore"/>
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_BotMessageObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpIntellisense = Vue.extend({
      props: ["settings", "readonly"],
      template: `
        <OptionsList
            :dimension="2"
            v-model="settings.intellisenseList"
            itemName="Intellisense"
            nameLabel="Display Value"
            valueLabel="Signal"
            :readonly="readonly"
        />
      `
    });

    const behaviours = Vue.extend({
      template: `
        <OptionsList
            :dimension="2"
            v-model="settings.behavioursList"
            itemName="Behaviours"
            nameLabel="Animation"
            valueLabel="Duration"
            :readonly="readonly"
        />`,
      props: ["settings", "readonly"]
    })

    return {
      cmpIntellisense,
      behaviours,
      openedData: undefined,
    };
  },

  methods: {
    onOpenIntellisense() {
      this.localSettings = {intellisenseList: [], ...this.localSettings};

      this.$root.$emit("DataEditor.open", {
        component: this.cmpIntellisense,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Intellisense"
      });
    },

    onOpenBehaviours() {
      this.localSettings = {
        behavioursList: [],
        ...this.localSettings
      };
      this.$root.$emit("DataEditor.open", {
        component: this.behaviours,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Behaviours"
      });
    },
  },
  watch: {
    openedData() {
      switch (this.openedData) {
        case "intellisense":
          this.onOpenIntellisense();
          break;
        case "behaviours":
          this.onOpenBehaviours();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    "localSettings.allowCustomInput": function (newV, oldV) {
      if (oldV && !newV)
        this.$root.$emit("DataEditor.close");
    },
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    if (this.localSettings.allowCustomInput)
      this.onOpenIntellisense();
    // TODO rewrite this boolean when there's real data on backend

    if (this.localSettings.behaviourSettings) {
      this.onOpenBehaviours();
    }
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>

<style>
.btn-toggle-container {
  align-items: center
}
</style>
