<script>
import Text from "./Text.vue";
export default {
  extends: Text,
  name: "NotionTypeCheckbox",
  props: {
    editorType: {
      type: String,
      default: "Checkbox"
    }
  }
};
</script>