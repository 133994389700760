<template>
  <div class="gradient-border">
    <div class="at-general-object__image" :style="imageStyle" />
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "AnalysisTools_GeneralObject",
  extends: ExtendsComponent,
  computed: {
    imageStyle() {
      return {
        backgroundImage: this.url
      };
    },
    url() {
      if (!this.baseObject.size) {
        return null;
      }
      const { image } = this.localSettings;
      const { height, width } = this.baseObject.size;
      let url;
      if (image) {
        if (width > 150 || height > 150) {
          url = image.body.url;
        } else {
          url = image.body.thumb.url;
        }
      }

      return url ? `url(${url})` : null;
    }
  }
};
</script>

<style scoped>
.gradient-border {
  position: absolute;
}

.at-general-object__image {
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
}

</style>
