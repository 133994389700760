<template>
  <div>
    <v-alert
      color="blue"
      outlined
      icon="mdi-source-fork"
      border="right"
      dismissible
    >
      Click on the Fork button of any component to add to My Components
    </v-alert>
    <div v-masonry item-selector=".component-item">
      <ComponentItem
        fork
        v-masonry-tile
        v-for="(component, index) in components"
        :key="index"
        :component="component"
        openBtnText="Open"
        @edit="$emit('edit', $event)"
      />
    </div>
  </div>
</template>


<script>
import ComponentItem from "./ComponentItem";

export default {
  name: "GeneralComponents",
  components: { ComponentItem },
  created() {
    if (!this.currentUser)
      return;
    this.api.Component.get({ type: "General" }).then(response => {
      this.components = response.body;
    });
  },
  data() {
    return {
      components: []
    };
  }
};
</script>

<style>
.component-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.component-item {
}
</style>
