<template>
  <div class="notion-line" :class="{active: localActive || active}">
    <div v-if="!readonly" class="notion-line__actions">
      <NotionLineMenu
        :line="line"
        :menu="menuTypes"
        @emit="$emit('emit', $event)"
      >
        <template v-slot="{on}">
          <v-btn v-on="on" icon small>
            <v-icon>add</v-icon>
          </v-btn>
        </template>
      </NotionLineMenu>

      <NotionLineMenu
        :line="line"
        :menu="menuSettings"
        @emit="$emit('emit', $event)"
      >
        <template v-slot="{on}">
          <v-btn v-on="on" icon small>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
      </NotionLineMenu>

    </div>
    <div class="notion-line__editor">
      <component
        @emit="$emit('emit', $event)"
        :line.sync="localLine"
        :active.sync="localActive"
        :is="componentName"
        :customComponents="customComponents"
        :baseObject="baseObject"
        :readonly="readonly"
        :startFocus="startFocus"
      />
    </div>
  </div>
</template>

<script>
import menuTypes from "./menuTypes";
import menuSettings from "./menuSettings";
import NotionLineMenu from "./NotionLineMenu";
import NotionTypeText from "./types/Text";
import NotionTypeHeader from "./types/Header";
import NotionTypeHeader2 from "./types/Header2";
import NotionTypeHeader3 from "./types/Header3";
import NotionTypeImage from "./types/Image";
import NotionTypeCheckbox from "./types/Checkbox";
import NotionTypeCode from "./types/Code";
import NotionTypeQuote from "./types/Quote";
import NotionTypeDivider from "./types/Divider";
import NotionTypeBulletedList from "./types/BulletedList";
import NotionTypeCustomComponent from "./types/CustomComponent";
import * as _ from "underscore";
import linkify from "linkifyjs";
import linkifyHtml from "linkifyjs/html";

const fromTypes = ["Text", "Header", "Header2", "Header3", "Checkbox", "Quote"];

const dictionaries = [
  {prefix: "- ", type: "BulletedList"},
  {prefix: "[ ] ", type: "Checkbox"},
  {prefix: "[] ", type: "Checkbox"},
  {prefix: "[x] ", type: "Checkbox", checked: true},
  {prefix: "[X] ", type: "Checkbox", checked: true},
  {prefix: "# ", type: "Header"},
  {prefix: "## ", type: "Header2"},
  {prefix: "### ", type: "Header3"}
];

export default {
  name: "NotionLine",
  props: {
    line: Object,
    active: Boolean,
    baseObject: Object,
    readonly: Boolean,
    startFocus: Boolean,
    customComponents: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    NotionLineMenu,
    NotionTypeText,
    NotionTypeHeader,
    NotionTypeHeader2,
    NotionTypeHeader3,
    NotionTypeImage,
    NotionTypeCheckbox,
    NotionTypeCode,
    NotionTypeDivider,
    NotionTypeQuote,
    NotionTypeBulletedList,
    NotionTypeCustomComponent
  },
  data() {
    return {
      menu: [],
      menuTypes,
      menuSettings,
      localActive: this.active,
      localLine: {...this.line, content: linkifyHtml(this.line.content, { attributes: {contenteditable: 'false'} })}
    };
  },
  computed: {
    componentName() {
      if (this.line.type) {
        return `NotionType${this.line.type}`;
      } else {
        return `div`;
      }
    }
  },
  watch: {
    localLine: {
      handler() {
        this.$emit("update:line", {...this.localLine, content: linkifyHtml(this.localLine.content, { attributes: {contenteditable: 'false'} })});
      },
      deep: true
    },
    line: {
      handler() {
        if (JSON.stringify(this.localLine) != JSON.stringify(this.line)) {
          if (fromTypes.includes(this.line.type)) {
            // Markdown parse
            dictionaries.forEach(term => {
              if (this.line.content.indexOf(term.prefix) === 0) {
                this.line.type = term.type;
                this.line.content = this.line.content.substring(term.prefix.length);
                if (term.hasOwnProperty("checked")) this.line.checked = term.checked;
              }
            });
          }
          this.localLine = this.line;
        }
      },
      deep: true
    },
    active() {
      this.localActive = this.active;
    },
    localActive() {
      this.$emit("update:active", this.localActive);
    }
  }
};
</script>

<style scoped>
.notion-line {
  display: flex;
  position: relative;
}

.notion-line.sortable-chosen {
  cursor: move;
}
.notion-line__actions {
  display: flex;
  opacity: 0;
  align-items: center;
}
.notion-line.active >>> .notion-line__actions,
.notion-line:hover >>> .notion-line__actions {
  opacity: 1;
}

.notion-line__editor {
  flex: 1;
  position: relative;
}
</style>


<style>
.notion-line__content-editable {
  outline: none;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  padding: 5px;
}

.notion-line:hover >>> .notion-line__content-editable[placeholder]:empty:before,
.notion-line.active >>> .notion-line__content-editable[placeholder]:empty:before,
.notion-line__content-editable[placeholder]:focus:empty:before {
  content: attr(placeholder);
  color: #555;
}

.notion-line__content-line {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
</style>
