var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"navigation-control"},[(_vm.activeChart.options && _vm.activeChart.options.minimapEnabled)?_c('div',{staticClass:"minimap"},[_c('ViewportPreview',{attrs:{"settings":{background: 'rgba(241,183,235,0.69)'},"width":297,"objects":_vm.objects,"connections":_vm.connections,"allowedObjectTypes":_vm.allowedObjectTypes,"viewport":_vm.viewport}})],1):_vm._e(),_c('v-card-actions',{staticClass:"d-flex flex-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.fullscreen ? 'primary' : null,"icon":""},on:{"click":_vm.toggleFullscreen}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fullscreen")])],1)]}}])},[_c('span',[_vm._v("Fullscreen")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.activeChart.options && _vm.activeChart.options.minimapEnabled ? 'primary' : null,"icon":""},on:{"click":_vm.toggleMinimap}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map-outline")])],1)]}}])},[_c('span',[_vm._v("Show Minimap")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.activeChart.options && _vm.activeChart.options.cursorsEnabled ? 'primary' : null,"icon":""},on:{"click":_vm.toggleCursors}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mouse")])],1)]}}])},[_c('span',[_vm._v("Show Cursors")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.specialKey === 'space' ? 'primary' : null,"icon":""},on:{"click":_vm.toggleMoveAround}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("pan_tool")])],1)]}}])},[_c('span',[_vm._v("Pan")])]),_c('v-spacer'),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.zoomOut}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}])},[_c('span',[_vm._v("Zoom Out")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"zoom-text-btn px-0",attrs:{"color":"primary","text":""},on:{"click":_vm.resetScale}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(Math.round(_vm.activeChart.options.scale * 100))+" % ")])]}}])},[_c('span',[_vm._v("Zoom to 100%")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.zoomIn}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Zoom In")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }