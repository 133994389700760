const defaultSettings = {
  name: "Wayfinder Content",
  icon: 'collections',
  showLabel: true,
  size: {
    width: 380,
    height: "auto"
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: false,
    settings: true
  },
  inColor: "#505fa6",
  outColor: "#505fa6",
  layout: "horizontal",
  components: [/Presentation_.*/],
  openPropertyEditorByClick: true,
  settings: {
    messages: [],
    locked: false,
    order: 0
  }
};

export default defaultSettings;
