<template>
  <v-layout justify-center>
    <v-container container--fluid>
      <v-data-table :headers="headers" :items="localMembers" :search="search">
        <template v-slot:item="{item}">
          <tr>
            <td style="width: 1px">{{ item.id }}</td>
            <td style="text-align: left">{{ item.email }}</td>
            <td style="text-align: left">{{ item.username }}</td>
            <td style="text-align: left">
              <span
                class="member-role"
                v-for="(a, index) in item.assignments"
                :key="index"
              >{{a.role.name}}</span>
            </td>
            <td class="justify-center layout px-0" style="text-align: right">
              <v-icon small @click="deleteMember(item.id)">delete</v-icon>
            </td>
          </tr>
        </template>
        <v-alert
          v-slot:no-results
          color="error"
          icon="warning"
        >Your search for "{{ search }}" found no results.</v-alert>
      </v-data-table>
      <v-flex>
        <v-autocomplete
          item-value="id"
          item-text="email"
          v-model="newMember"
          :items="lookupMembers"
          label="Search and add new members"
          class="mt-3"
          solo
        >
          <template v-slot:selection="data">
            <template v-if="data.item.profile && (!!data.item.profile.first_name || !!data.item.profile.last_name)">
              {{ data.item.profile.first_name }} {{ data.item.profile.last_name }}
            </template>
            <template v-else>
              {{ data.item.email }}
            </template>
          </template>
          <template v-slot:item="data">
            <template v-if="data.item.profile && (!!data.item.profile.first_name || !!data.item.profile.last_name)">
              {{ data.item.profile.first_name }} {{ data.item.profile.last_name }}
            </template>
            <template v-else>
              {{ data.item.email }}
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
    </v-container>
  </v-layout>
</template>

<script>
import * as _ from "underscore";

export default {
  name: "Members",
  props: {
    members: Array,
    projectId: String
  },
  data() {
    return {
      localMembers: this.members ? [...this.members] : [],
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email"
        },
        {
          text: "Full Name",
          align: "left",
          sortable: true,
          value: "email"
        },
        {
          text: "Roles",
          sortable: false
        },
        {
          sortable: false
        }
      ],
      organisationMembers: [],
      lookupMembers: [],
      newMember: "",
      search: ""
    };
  },
  created() {
    this.fetchOrganisationMembers();
  },
  methods: {
    setMembersWithRoles(members) {
      if (!members) return;
      this.localMembers = members.map(member => {
        const organisationRecord = this.organisationMembers.find(organisationMember => organisationMember.id === member.id);
        if (organisationRecord)
          return {...member, assignments: organisationRecord.assignments};
        return member;
      });
    },
    fetchOrganisationMembers() {
      this.api.Organisation.members().then(res => {
        this.organisationMembers = res.body;
        this.setMembersWithRoles(this.localMembers);
      });
    },
    deleteMember(id) {
      if (confirm("Are you sure?")) {
        this.api.Project.removeMember(
          { id: this.projectId },
          { user_id: id }
        ).then( (res) => {
          this.setMembersWithRoles(res.body.members);
        } )
      }
    },
    addMember(val) {
      this.api.Project.addMember(
        { id: this.projectId }, 
        { user_id: val }
      ).then( (res) => {
        this.setMembersWithRoles(res.body.members)
        this.newMember = "";
      } )
    }
  },
  watch: {
    newMember (val) {
      val && this.addMember(val);
    },
    localMembers: {
      handler() {
        const memberIDs = _.pluck(this.localMembers, 'id');
        this.lookupMembers = this.organisationMembers.filter(member => memberIDs.indexOf(member.id) === -1);
        this.$emit("update:members", this.localMembers);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
.member-role {
  padding: 5px;
  background: #e7e7e7;
  margin-right: 5px;
  display: inline-block;
}

.member-role-edit {
  cursor: pointer;
}
</style>
