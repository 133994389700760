<template>
  <div class="twitter-feed" v-if="id">
    <Timeline :id="id" :sourceType="'profile'" :options="{ tweetLimit: '10' }"/>
  </div>
</template>

<script>
import { Timeline } from "vue-tweet-embed";

export default {
  name: "TwitterFeed",
  components: {
    Timeline
  },
  props: {
    value: String,
    readonly: Boolean
  },
  computed: {
    id() {
      if (!this.value) return null;
      const regexp = new RegExp(
        "^(?:https?://)?(?:www\\.)?twitter\\.com/(\\w+)$",
        "i"
      );
      return this.value.match(regexp)[1];
    }
  }
};
</script>

<style scoped>
.rss-feed {
}
.rss-feed__title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.rss-feed__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.rss-feed__item {
}
</style>
