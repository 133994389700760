<template>
  <div
    class="comment-object"
    :class="{opened: 'opened'}"
    :style="objectStyles"
    @dblclick.stop
    @mousemove.stop
    @mousedown.stop
    @mouseup.stop
  >
    <div ref="container" :style="containerStyles">
      <div class="comment-object__container" ref="commentsContainer">
        <div class="comment-object__head" v-if="!readonly">
          <div class="comment-object__actions">
            <v-switch label="Resolve" v-model="localSettings.resolve" />
          </div>
          <div class="comment-object__colors mx-3">
            <div
              class="comment-object__color"
              v-for="(color, index) in colors"
              :key="index"
              @click="setColor(color)"
              :style="{background: color}"
            />
          </div>
          <v-btn @click="onDelete" text class="toolbar-button">
            <v-icon>delete</v-icon>
          </v-btn>
        </div>
        <div @mousemove.stop @mousedown.stop @mouseup.stop>
          <div v-if="localSettings.comments && localSettings.comments.length > 0">
            <v-card v-for="(comment, index) in localSettings.comments" :key="'tile-' + index" tile>
              <v-card-title style="font-size: 1rem" v-if="comment.user">{{ comment.user.username }}</v-card-title>
              <v-card-title style="font-size: 1rem" v-else><i>anonymous</i></v-card-title>
              <v-card-subtitle>{{ parseDate(comment.datetime) }}</v-card-subtitle>
              <v-card-text style="color: rgba(0,0,0,.87)">{{ comment.text }}</v-card-text>
            </v-card>
          </div>
          <div v-else>
            <v-card tile>
              <v-card-text><i>No comments for now</i></v-card-text>
            </v-card>
          </div>
          <div class="comment-object__input" v-if="!localSettings.resolve && !localReadonly">
            <v-text-field
              class="pt-2 px-2"
              placeholder="Type a comment"
              v-model="text"
              @keyup.enter="sendComment"
              autofocus
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as d3 from "d3";
  import {deepClone, parseDate} from "@/lib";

  export default {
    name: "CommentContent",
    props: {
      readonly: Boolean,
      comment: Object
    },
    data() {
      return {
        localSettings: deepClone(this.comment.info.settings),
        text: "",
        colors: ["#ffd900", "#00d800", "#ff3e00", "#0076ff", "#333333"]
      };
    },
    computed: {
      objectStyles() {
        return {
          transform: `translate(${this.comment.position.x}px, ${this.comment.position.y}px)`,
        };
      },
      localReadonly() {
        return this.readonly && !(this.activeChart && this.activeChart.options.public_comments);
      },
      containerStyles() {
        const { x2, y2 } = this.localSettings;

        return {
          transform: `translate(${x2}px, ${y2}px)`,
          position: "relative",
          zIndex: 2
        };
      },
      scale() {
        return this.$store.getters["chart/scale"];
      }
    },
    watch: {
      settings: {
        handler() {
          if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings))
            this.localSettings = deepClone(this.settings);
        }
      },
      localSettings: {
        handler() {
          if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings))
            this.save();
        },
        deep: true
      },
      localReadonly: {
        handler(val) {
          if (val)
            this.d3container.on(".drag", null);
          else
            this.d3container.call(this.dragHandlerEnd());
        }
      }
    },
    created() {
      this.localSettings.comments = this.localSettings.comments || [];
      this.localSettings.color = this.localSettings.color || "#ffd900";
      this.localSettings.x2 = this.localSettings.x2 || 130;
      this.localSettings.y2 = this.localSettings.y2 || 12;
    },
    mounted() {
      this.d3container = d3.select(this.$refs.container);

      if (this.localReadonly)
        return;

      this.d3container.call(this.dragHandlerEnd());
    },
    methods: {
      save() {
        // save the object/update
        this.$store.dispatch(
          "object/update",
          {
            id: this.comment.id,
            info: {
              ...this.comment.info,
              settings: deepClone(this.localSettings)
            }
          }
        );
      },
      dragHandlerEnd() {
        let initialTransform, initialEvent;

        const dragHandler = d3
          .drag()
          .on("start", () => {
            initialTransform = { x: this.localSettings.x2, y: this.localSettings.y2 };
            initialEvent = d3.event;
          })
          .on("drag", () => {
            this.localSettings.x2 = initialTransform.x + (d3.event.x - initialEvent.x) / this.scale;
            this.localSettings.y2 = initialTransform.y + (d3.event.y - initialEvent.y) / this.scale;
          });

        return dragHandler;
      },
      setColor(color) {
        this.localSettings.color = color;
      },
      sendComment() {
        if (!this.text)
          return;

        const comment = {
          user: this.currentUser,
          text: this.text,
          datetime: new Date()
        };
        this.localSettings.comments.push(comment);
        this.text = "";
      },
      onDelete() {
        this.$store.commit("chart/closeComment", this.comment);
        this.$store.dispatch("object/delete", this.comment.id);
      },
      parseDate(dateStr) {
        return parseDate(dateStr);
      }
    }
  };
</script>

<style scoped>
  .comment-object {
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: none !important;
  }

  .comment-object__container * {
    transform: translateZ(0);
  }

  .comment-object__container {
    width: 330px;
    background: white;
    position: absolute;
    left: 20px;
    top: -57px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  .comment-object__head {
    height: 45px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 15px;
    cursor: grab;
  }

  .comment-object__colors {
    display: flex;
  }

  .comment-object__color {
    height: 12px;
    width: 12px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  .comment-object__input {
    height: 50px;
    border-top: 1px solid #e6e6e6;
    position: relative;
    z-index: 2;
  }

  .comment-object__input >>> input {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
  }
</style>
