<template>
  <div class="rect-object" :style="containerStyles" @click="onClick">
    <svg width="100%" height="100%">
      <rect :fill="fill" 
        :stroke="stroke" :stroke-width="strokeWidth" :stroke-dasharray="strokeDasharray"
        :width="width" :height="height" 
        :rx="borderRadius" :ry="borderRadius"
        :x="strokeWidth / 2" :y="strokeWidth / 2"
        />
    </svg>
    <div class="rect-note">
      <Base_TextObject
        :settings.sync="localSettings"
        :baseObject="baseObject"
        :readonly="readonly"
        ref="textObj" />
    </div>
  </div>
  
</template>

<script>
import { mapGetters } from "vuex";
import Base_TextObject from "../TextObject/Component";
import ExtendsComponent from "../../_extends/ObjectComponent";
import defaultProperties from "./default";

export default {
  name: "Base_RectObject",
  props: ['selected'],
  extends: ExtendsComponent,
  components: {
    Base_TextObject
  },
  data () {
    const strokeWidth = this.settings.strokeWidth || defaultProperties.settings.strokeWidth;
    const strokeColor = (!this.settings.borderColor || this.settings.borderColor == "none") ? defaultProperties.settings.borderColor : this.settings.borderColor;
    return {
      fill: this.settings.backgroundColor,
      stroke: strokeColor,
      strokeWidth,
      width: this.baseObject.size.width - strokeWidth,
      height: this.baseObject.size.height - strokeWidth,
      borderRadius: (this.settings.borderRadius || 0),
      strokeDasharray: this.lineStyles(this.settings.borderStyle)
    };
  },
  computed: {
    ...mapGetters({
      scale: "chart/scale"
    }),
    containerStyles() {
      return {
        fontSize: (this.settings.fontSize || 0) + "px",
        textAlign: this.settings.textAlign || "center"
      };
    },
    contentEditable() {
      return this.$refs.textObj.contentEditable;
    }
  },
  methods: {
    onClick() {
      if (!this.$refs.textObj.contentEditable)
        this.$store.commit('object/setContentEditable', false);
    },
    lineStyles(setting) {
      switch (setting) {
        case 'Dashed':  return "10,10";
        case 'Dotted':  return "5,5";
        case 'Solid':
        default:        return "0";
      }
    },
  },
  watch: {
    settings: {
      handler() {
        this.fill = this.settings.backgroundColor;
        this.stroke = (!this.settings.borderColor || this.settings.borderColor == "none") ? defaultProperties.settings.borderColor : this.settings.borderColor;
        this.strokeWidth = this.settings.strokeWidth || defaultProperties.settings.strokeWidth;
        this.borderRadius = this.settings.borderRadius;
        this.strokeDasharray = this.lineStyles(this.settings.borderStyle);
        this.width = this.baseObject.size.width - this.strokeWidth;
        this.height = this.baseObject.size.height - this.strokeWidth;
      },
      deep: true,
      immediate: true
    },
    'baseObject.size': {
      handler() {
        this.width = this.baseObject.size.width - this.strokeWidth;
        this.height = this.baseObject.size.height - this.strokeWidth;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.rect-object {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.rect-note {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

</style>

<style>
.Base_RectObject > .base-object__content {
  height: 100%;
}
</style>
