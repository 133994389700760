import store from '../store/index'
import {HANDLES, HEADER_HEIGHT} from "./const";

// mouse event coordinates into vulcan coordinates
export const screenToVulcan = (x, y, subtractHeaderHeight) => {
    const translate = store.getters['chart/translate']
    const scale = store.getters['chart/scale']
    const headerHeight = subtractHeaderHeight ? HEADER_HEIGHT / scale : 0

    return ({
        x: Math.round((x - translate.x) / scale),
        y: Math.round((y - translate.y) / scale) - headerHeight
    })
}

export const vulcanToScreen = (x, y) => {
    const translate = store.getters['chart/translate']
    const scale = store.getters['chart/scale']
    return {x: Math.round(x * scale + translate.x), y: Math.round(y * scale + translate.y)}
}

export const getAbsoluteDistance = (pointA, pointB) =>
    ({x: Math.abs(pointA.x - pointB.x), y: Math.abs(pointA.y - pointB.y)})

export const getQuarter = (point) => {
    // 1 | 2
    // 4 | 3
    const {x, y} = point
    if (x > 0 && y > 0) return 3
    if (x > 0 && y < 0) return 2
    if (x < 0 && y < 0) return 1
    else return 4
}

// handle : string
export const setQuarterCoordSigns = (handle, point) => {
    const {x, y} = point
    switch (handle) {
        case (HANDLES.TopLeft): {
            return {x: -x, y: -y}

        }
        case HANDLES.TopRight : {
            return {x, y: -y}

        }
        case HANDLES.BottomRight: {
            return {x, y}
        }

        case HANDLES.BottomLeft: {
            return {x: -x, y: y}
        }
        default: {
            return point
        }
    }
}

export const getHandleCoord = (handle, transformParams) => {
    const {position, width, height} = transformParams
    switch (handle) {
        case HANDLES.TopLeft:
            return position
        case HANDLES.BottomLeft:
            return {x: position.x, y: position.y + height}
        case HANDLES.BottomRight:
            return {x: position.x + width, y: position.y + height}
        case HANDLES.TopRight:
            return {x: position.x + width, y: position.y}
    }
}

// distance is the scale of a circle
export const getPointOnACircle = (center, pointAngle, radius, distance = 1) => ({
    x: center.x + radius * Math.cos(-pointAngle * Math.PI / 180) * distance,
    y: center.y + radius * Math.sin(-pointAngle * Math.PI / 180) * distance
})
