<template>
  <v-toolbar dense no-padding>
    <div v-for="(property, index) in component.object_settings.properties" class="prop">
      <v-tooltip v-if="isTooltip(property)" top>
        <template v-slot:activator="{ on: tooltip }">
          <v-text-field
            v-if="property.type == 'textfield'"
            class="pa-0 mx-2"
            :style="{width: property.fieldWidth + `px`}"
            hide-details
            :readonly="readonly"
            v-model="localSettings.properties[property.name]"
            :placeholder="property.visibleName"
            v-on="tooltip"
          />

          <v-text-field
            v-if="property.type == 'number'"
            class="pa-0 mx-2"
            :style="{width: property.fieldWidth + `px`}"
            hide-details
            type="number"
            :readonly="readonly"
            :value="localSettings.properties[property.name]"
            @input="localSettings.properties[property.name] = parseFloat($event)"
            :placeholder="property.visibleName"
            v-on="tooltip"
          />

          <div v-if="property.type == 'select'" v-on="tooltip">
            <v-select
              :disabled="readonly"
              v-model="localSettings.properties[property.name]"
              :items="property.selectOptions"
              item-text="name"
              item-value="data"
              :style="{width: property.fieldWidth + `px`}"
              class="pa-0 mx-2"
              hide-details
            />
          </div>

          <v-menu
            v-if="property.type == 'date'"
            v-model="menus[property.id]"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-text-field
                v-model="localSettings.properties[property.name]"
                class="pa-0 mx-2"
                hide-details
                :style="{width: '85px'}"
                v-on="{ ...tooltip, ...menu }"
              />
            </template>
            <v-date-picker
              v-model="localSettings.properties[property.name]"
              no-title
              scrollable
              :show-current="false"
              @change="menus[property.id] = false"
            />
          </v-menu>

          <v-menu
            v-if="property.type == 'time'"
            v-model="menus[property.id]"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-text-field
                v-model="localSettings.properties[property.name]"
                class="pa-0 mx-2"
                hide-details
                :style="{width: '70px'}"
                v-on="{ ...tooltip, ...menu }"
              />
            </template>
            <v-time-picker
              v-model="localSettings.properties[property.name]"
              full-width
              @change="menus[property.id] = false"
            />
          </v-menu>

        </template>
        <span>{{ property.visibleName }}</span>
      </v-tooltip>

      <ColorPickerDialog
        v-if="property.type == 'color_picker'"
        v-model="localSettings.properties[property.name]"
        :title="property.visibleName"
        :presetColors="projectColors"
        :disabled="readonly"
        :tooltip="property.visibleName"
      />

      <v-btn
        v-if="property.type == 'group'"
        @click="openGroup(index)"
        :input-value="openedGroups[index]"
        text
        class="ma-0 prop-btn"
      >
        {{ property.name }}
      </v-btn>
    </div>

    <v-btn
      v-if="component.object_settings.manual_props"
      @click="openManualProps"
      :input-value="openedManual"
      text
      class="ma-0 prop-btn"
    >
      Properties
    </v-btn>

    <v-divider v-if="component.object_settings.manual_props" vertical />

    <v-btn
      v-if="localSettings.notionEnabled"
      @click="openNotion"
      :input-value="openedNotion"
      text
      class="ma-0 prop-btn"
    >
      Notes
    </v-btn>

    <v-divider v-if="localSettings.notionEnabled" vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import PropertyGroup from "./PropertyGroup";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";

export default {
  name: "Core_CustomComponentPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    return {
      cmpProperties: null,
      component: null,
      openedGroups: [],
      openedManual: false,
      openedNotion: false,
      menus: [],
      firstGroupInd: -1
    };
  },

  methods: {
    init() {
      this.component = this.$store.getters['component/findById'](this.settings.componentId);
      this.firstGroupInd = this.component.object_settings.properties.findIndex(p => p.type == 'group');

      if (!this.cmpProperties && this.component.object_settings.manual_props)
        this.cmpProperties = Vue.extend({
          template: `
            <div>
              <div class="mt-4 mb-1">Props function:</div>
              <editor
                v-model="settings.manualProps"
                @init="editorInit"
                lang="javascript"
                theme="chrome"
                height="200"
              />
            </div>
          `,
          components: {
            editor: require("vue2-ace-editor")
          },
          props: ["settings", "readonly"],
          methods: {
            editorInit(editor) {
              require("brace/ext/language_tools");
              require("brace/mode/html");
              require("brace/mode/javascript");
              require("brace/theme/chrome");
              require("brace/snippets/javascript");
              editor.setReadOnly(this.readonly);
              editor.setOptions({
                selectionStyle: "text",
                enableBasicAutocompletion: true,
                highlightSelectedWord: true,
                highlightActiveLine: false,
                tabSize: 2
              });
            }
          },
        });
    },
    openManualProps() {
      if (this.openedManual) {
        this.openedManual = false;
        this.$root.$emit("DataEditor.close");

      } else {
        this.openedManual = true;
        this.openedNotion = false;
        for (let i = 0; i < this.openedGroups.length; i++) {
          this.$set(this.openedGroups, i, false);
        }

        this.localSettings = {
          manualProps: `function props () {
  return {/*props*/}
}`,
          ...this.localSettings
        };

        this.$root.$emit("DataEditor.open", {
          component: this.cmpProperties,
          props: {
            baseObjectId: this.baseObject.id,
            settings: this.localSettings,
            readonly: this.readonly
          },
          title: "Properties"
        });
      }
    },
    openNotion() {
      if (this.openedNotion) {
        this.openedNotion = false;
        this.$root.$emit("DataEditor.close");

      } else {
        this.openedNotion = true;
        this.openedManual = false;
        for (let i = 0; i < this.openedGroups.length; i++) {
          this.$set(this.openedGroups, i, false);
        }

        this.localSettings = { notesLines: [], ...this.localSettings };
        this.$root.$emit("DataEditor.open", {
          component: NotionEditor,
          props: {
            baseObjectId: this.baseObject.id,
            settings: this.localSettings,
            readonly: this.readonly
          },
          dataField: 'notesLines'
        });
      }
    },
    openGroup(ind) {
      if (this.openedGroups[ind]) {
        this.$set(this.openedGroups, ind, false);
        this.$root.$emit("DataEditor.close");
        
      } else {
        for (let i = 0; i < this.openedGroups.length; i++) {
          this.$set(this.openedGroups, i, false);
        }
        this.$set(this.openedGroups, ind, true);
        this.openedManual = false;
        this.openedNotion = false;

        const property = this.component.object_settings.properties[ind];

        this.$root.$emit("DataEditor.open", {
          component: PropertyGroup,
          props: {
            baseObjectId: this.baseObject.id,
            properties: this.localSettings.properties,
            readonly: this.readonly,
            elements: property.elements
          },
          title: property.name
        });
      }
    },
    isTooltip(property) {
      return property.type == 'textfield' ||
        property.type == 'number' ||
        property.type == 'select' ||
        property.type == 'date' ||
        property.type == 'time';
    },
    openFirstDataEditor() {
      if (this.firstGroupInd != -1) {
        this.openGroup(this.firstGroupInd);
      } else if (this.component.object_settings.manual_props) {
        this.openManualProps();
      } else if (this.localSettings.notionEnabled) {
        this.openNotion();
      }
    },
    onDataEditorClose() {
      for (let i = 0; i < this.openedGroups.length; i++) {
        this.$set(this.openedGroups, i, false);
      }
      this.openedManual = false;
      this.openedNotion = false;
    }
  },

  watch: {
    'baseObject.id'() {
      this.init();
      this.openFirstDataEditor();
    }
  },

  created() {
    this.init();
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.openFirstDataEditor();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>

<style scoped>
.prop {
  height: 100%;
  border-right: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}

.prop-btn {
  height: 100% !important;
  border-radius: 0;
  opacity: 1;
}
</style>
