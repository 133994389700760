const defaultSettings = {
  name: "Symbol",
  icon: 'mdi-autorenew',
  hotkey: `modkey + Shift + S`,
  showLabel: true,
  size: {
    width: 150,
    height: 100
  },
  resizable: true,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  layout: "horizontal",
  inColor: "#e44e9d",
  outColor: "#505fa6",
  components: [/AnalysisTools_.*/, "Base_SymbolObject"],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
