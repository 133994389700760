<template>
  <v-layout justify-center>
    <v-flex xs8>
      <v-toolbar color="#f4f3f3">
        <v-toolbar-title>Members</v-toolbar-title>
        <v-spacer />
        <v-text-field
          solo
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-toolbar>
      <v-card>
        <v-container container--fluid>
          <v-data-table :headers="headers" :items="members" :search="search">
            <template v-slot:item="{item}">
              <tr>
                <td style="width: 1px">{{ item.id }}</td>
                <td style="text-align: left">{{ item.email }}</td>
                <td style="text-align: left">
                  <span
                    class="member-role"
                    v-for="(a, index) in item.assignments"
                    :key="index"
                  >{{a.role.name}}</span>
                  <v-icon @click="openAccessModal(item)" class="member-role-edit">edit</v-icon>
                </td>
                <td class="justify-center layout px-0" style="text-align: right">
                  <v-icon small @click="deleteMember(item.membership.id)">delete</v-icon>
                </td>
              </tr>
            </template>
            <v-alert
              v-slot:no-results
              color="error"
              icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert>
          </v-data-table>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import AssignmentsModal from "./AssignmentsModal";

export default {
  name: "Members",
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email"
        },
        {
          text: "Roles",
          sortable: false
        },
        {
          sortable: false
        }
      ],
      members: [],
      search: ""
    };
  },
  created() {
    this.fetchMembers();
  },
  methods: {
    fetchMembers() {
      this.api.Organisation.members().then(res => {
        this.members = res.body;
      });
    },
    deleteMember(id) {
      if (confirm("Are you sure?")) {
        this.api.Organisation.deleteMember({ id }).then(response => {
          this.fetchMembers();
        });
      }
    },
    openAccessModal(member) {
      this.$modal.show(AssignmentsModal, {
        assignments: member.assignments,
        callback: roleIds => {
          const deletePromises = [],
            createPromises = [];

          member.assignments.forEach(a => {
            deletePromises.push(
              this.api.Organisation.deleteAssignment({
                id: a.id
              })
            );
          });

          Promise.all(deletePromises).then(() => {
            roleIds.forEach(roleId => {
              createPromises.push(
                this.api.Organisation.createAssignment({
                  role_id: roleId,
                  user_id: member.id
                })
              );
            });
            Promise.all(createPromises).then(() => this.fetchMembers());
          });
        }
      });
    }
  }
};
</script>

<style>
.member-role {
  padding: 5px;
  background: #e7e7e7;
  margin-right: 5px;
  display: inline-block;
}

.member-role-edit {
  cursor: pointer;
}
</style>
