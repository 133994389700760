import {guid} from "@/lib";
import _ from "underscore";
import {Connection} from "@/resources";

const store = {
    namespaced: true,
    state: {
        list: [],
        delayedUpdates: []
    },
    mutations: {
        setList(state, connections) {
            state.list = connections;
        },
        create(state, connection) {
            state.list.push(connection);
        },
        update(state, params) {
            state.list = state.list.map(connection =>
                connection.id == params.id ? {...connection, ...params} : connection
            );
        },
        delete(state, id) {
            state.list = state.list.filter(c => c.id != id);
        }
    },
    actions: {
        create({commit, dispatch}, params) {
            let connection;
            if (params.id == -1) {
                connection = params;
                commit("create", params);

            } else {
                connection = {...params, id: guid()};
                Connection.save({connection});
                commit("create", connection);
            }
            Promise.resolve(connection);
        },
        update({state, getters, commit, dispatch}, params) {
            commit("update", params);

            const connection = getters["findById"](params.id);
            const ind = state.delayedUpdates.findIndex(c => c.id == params.id);
            if (ind != -1)
                state.delayedUpdates[ind] = connection;
            else
                state.delayedUpdates.push(connection);
            dispatch("updateOnServer");
        },
        updateOnServer: _.debounce(function ({state}) {
            for (let connection of state.delayedUpdates) {
                Connection.update({id: connection.id}, {connection});
            }
            state.delayedUpdates = [];
        }, 300),
        delete({commit, dispatch}, id) {
            commit("delete", id);
            if (id != -1) {
                Connection.delete({id});
            }
        },
        deleteConnectionsOfDeletedObj({state, dispatch}, id) {
            state.list.forEach(connection => {
                if (connection.to == id || connection.from == id)
                    dispatch('delete', connection.id);
            });
        }
    },
    getters: {
        list(state) {
            return state.list;
        },
        find: state => ({from, to}) => {
            if (from && to) {
                return state.list.find(c => c.from == from && c.to == to);
            } else if (from || to) {
                return state.list.filter(c => c.from == from || c.to == to);
            } else {
                return [];
            }
        },
        findById: state => id => {
            return state.list.find(connection => connection.id == id);
        }
    }
};

export default store;
