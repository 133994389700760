<template>
  <div>
    <v-list class="header_nav" dense>
      <v-row
        align="center"
        justify="space-around"
        class="mb-4"
      >
        <v-btn
          tile
          dark
          depressed
          color="rgba(232,0,90)"
          class="font-weight-black border-radius no-uppercase"
          @click="showNewProjectModal"
        >
          <v-icon left>
            add
          </v-icon>
          New Project
        </v-btn>
      </v-row>
      <div :key="item.id" v-for="item in items">
        <NavigationListGroup v-if="item.items" :group="item" />
        <NavigationListItem v-else :item="item" />
      </div>
    </v-list>
    <v-divider></v-divider>
    <v-list class="pt-0 header_nav" dense>
      <v-row
        align="left"
        class="ml-4 my-4 overline">
        <div>Workspace</div>
      </v-row>
      <v-row
        align="center"
        justify="space-around"
        class="mx-3 my-2">
        <v-select
          outlined
          dense
          hide-details
          :items="organisations"
          item-text="name"
          item-value="id"
          v-model="activeOrganisation"
          @change="setActiveOrganisation"
        />
      </v-row>
      <div :key="workspaceItem.id" v-for="workspaceItem in workspaceItems">
        <NavigationListGroup v-if="workspaceItem.items" :group="workspaceItem" />
        <NavigationListItem v-else :item="workspaceItem" />
      </div>
    </v-list>
    <v-list class="footer_nav">
      <div :key="footerItem.id" v-for="footerItem in footerItems">
        <NavigationListGroup v-if="footerItem.items" :group="footerItem" />
        <NavigationListItem v-else :item="footerItem" />
      </div>
    </v-list>
  </div>
</template>

<script>
import NavigationListGroup from "./TheNavigationList/NavigationListGroup";
import NavigationListItem from "./TheNavigationList/NavigationListItem";
import { mapGetters } from "vuex";
import OrganisationModal from "./OrganisationModal";
import NewProject from "../../Authorized/Project/New";
import OpenProject from "../../Authorized/Project/Open";

export default {
  name: "TheNavigationList",
  components: {
    NavigationListGroup,
    NavigationListItem
  },
  mounted() {
    this.$root.$on("updateNavigation", () => {
      this.updateRecentProjects();
    });
    this.fetchOrganisation();

    this.api.Organisation.getCurrent()
      .then(res => this.activeOrganisation = res.body || this.individual);
  },
  data() {
    return {
      recentProjects: [],
      organisation: null,
      activeOrganisation: null,
      individual: {
        id: 0,
        name: "Individual Account"
      },
      addOrganisation: {
        id: -1,
        name: "+ Add Workspace"
      }
    };
  },
  computed: {
    ...mapGetters({
      visibleName: "user/visibleName"
    }),
    organisations() {
      return [this.individual, ...this.currentUser.organisations, this.addOrganisation];
    },
    items() {
      return [
        // {
        //   name: "New Project",
        //   icon: "create_new_folder",
        //   type: "button",
        //   callback: () => {
        //     this.$modal.show(NewProject, {}, {});
        //   }
        // },
        {
          name: "Home",
          icon: "home",
          callback: () => {
            this.$router.push({ name: "dashboard" });
          }
        },
        
        {
          name: "Open Project",
          icon: "folder_open",
          callback: () => {
            this.$modal.show(OpenProject, {}, {});
          }
        },
        {
          name: "Recent Projects",
          icon: "folder_special",
          items: this.recentProjectsList
        }        
      ].filter(e => Object.keys(e).length);
    },
    workspaceItems() {
      return [
        { ...this.organisationMenu }
      ].filter(e => Object.keys(e).length);
    },
    organisationMenu() {
      if (
        this.organisation &&
        this.organisation.current_user_roles.includes("admin")
      ) {
        return {
          name: "Workspace Settings",
          icon: "account_balance",
          callback: () => {
            this.$router.push({ name: "organisation" });
          }
        };
      } else {
        return null;
      }
    },
    footerItems() {
      return [
        {
          name: "Components Library",
          icon: "extension",
          callback: () => {
            this.$router.push({ name: "components_library" });
          }
        },
        {
          name: "Chatbot Tester",
          icon: "chat",
          callback: () => {
            window.open('https://vulcan.beach.io/chatbot-tester', "_blank", "location=yes,height=570,width=1000,scrollbars=yes,status=yes");
          }
        },
        {
          name: "Docs",
          icon: "library_books",
          callback: () => {
            this.goToDocs();
          }
        },
        {
          name: "Settings",
          icon: "settings",
          callback: () => {
            this.$router.push({ name: "settings" });
          }
        },
        {
          name: "Sign Out",
          icon: "exit_to_app",
          callback: () => {
            this.$store.dispatch('application/logOut');
          }
        }
      ];
    },
    recentProjectsList() {
      return this.recentProjects.map(project => {
        return {
          ...project,
          callback: () => {
            const projectId = project.id,
              chartId = project.charts[0].id;
            this.$router.push({
              name: "editor",
              params: { projectId, chartId }
            });
          }
        };
      });
    },
  },
  methods: {
    showNewProjectModal() {
      this.$modal.show(NewProject, {}, {});
    },
    goToDocs() {
      window.open("https://docs.vulcanapp.com", "_blank");
    },
    fetchOrganisation() {
      this.api.Organisation.getCurrent().then(response => {
        this.organisation = response.body;
      });
    },
    updateRecentProjects() {
      this.api.Project.recent().then(response => {
        this.recentProjects = response.body;
      });
    },
    toggleOrganisationList() {
      this.organisationListIsActive = !this.organisationListIsActive;
    },
    hideOrganisationList() {
      this.organisationListIsActive = false;
    },
    getActiveOrganisation() {
      this.api.Organisation.getCurrent().then(res => {
        this.activeOrganisation = res.body || this.individual;
      });
    },
    showAddOrganisationModal() {
      this.$modal.show(OrganisationModal, {
        callback: (newOrganisation) => {
          this.activeOrganisation = newOrganisation || this.individual;
          this.$router.go({ name: "dashboard" });
        }
      });
    },
    setActiveOrganisation(id) {
      if (id === -1)
        this.showAddOrganisationModal();
      else
        this.api.Organisation.setCurrent({ id }, { id })
          .then(res => this.$router.go({ name: "dashboard" }));
    }
  }
};
</script>

<style scoped>
.header_nav {
  margin-top: 20px;
}
.footer_nav {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.border-radius {
  border-radius: 8px;
}
</style>
