<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="data">Data</v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="models">Response models</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";
import * as _ from "underscore";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_DialogflowObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  components: {
    draggable
  },

  data() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-select
            v-model="settings.agent"
            item-value="id"
            item-text="name"
            :items="agents"
            label="Agent"
            :readonly="readonly"
            class="pr-3"
          />
          <Autocompleter v-model="settings.context" label="Context" :readonly="readonly" />
          <v-text-field v-model="settings.example" label="Signal Example" :readonly="readonly" />
          <Autocompleter v-model="settings.signal" label="Outcoming Signal" :readonly="readonly" class="pb-1" />
        </div>
      `,
      props: ["settings", "readonly"],
      computed: {
        agents() {
          const creds = this.activeProject.settings.credentials;
          if (creds && creds.dialogflow) {
            creds.dialogflow.forEach(agent => {
              const obj = this.$store.getters["object/findById"](agent.id);
              agent.name = obj ? obj.name : agent.id;
            });
            return creds.dialogflow;
          }
          return [];
        }
      }
    });

    const cmpModels = Vue.extend({
      template: `
        <div>
          <draggable v-model="settings.models" handle=".drag" :disabled="readonly">
            <div class="mb-4" v-for="(option, index) in settings.models" :key="index">
              <v-layout align-center>
                <v-flex xs1 class="option-icon">
                  <v-icon class="drag" :style="dragStyle">drag_handle</v-icon>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="option.name"
                    placeholder="Model Name"
                    hide-details
                    solo
                    :readonly="readonly"
                  />
                </v-flex>
                <v-flex xs1 />
                <v-flex xs6>
                  <Autocompleter
                    :style="{zIndex: 1000 - index}"
                    solo
                    v-model="option.value"
                    label="Model Value"
                    :schema="schema"
                    :readonly="readonly"
                  />
                </v-flex>

                <v-btn text class="button-icon" @click="deleteOption(option.id)" :disabled="readonly">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-layout>
            </div>
          </draggable>
          <div class="add" @click="addOption" v-if="!readonly">Add Model</div>
        </div>
      `,
      props: ["settings", "readonly"],
      data() {
        return {
          schema: {}
        };
      },
      computed: {
        dragStyle() {
          if (this.readonly)
            return {
              opacity: 0.5,
              cursor: "auto"
            };
          else return {};
        }
      },
      methods: {
        addOption() {
          this.settings.models.push({ id: this.guid() });
        },
        deleteOption(id) {
          let ind = -1;
          for (let i = 0; i < this.settings.models.length; i++) {
            if (this.settings.models[i].id == id) ind = i;
          }
          if (ind != -1) this.settings.models.splice(ind, 1);
        },
        updateSchema: _.debounce(function() {
          this.api.Chatbot.dialogflow({
            id: this.baseObjectId,
            agent: this.settings.agent,
            context: this.settings.context,
            signal: this.settings.example
          }).then(response => {
            this.schema = { result: response.body.result };
          });
        }, 1000)
      },
      created() {
        this.$watch(
          () => [
            this.settings.agent,
            this.settings.context,
            this.settings.example
          ],
          () => this.updateSchema(),
          { deep: true }
        );

        this.updateSchema();
      }
    });

    return {
      cmpData,
      cmpModels,
      openedData: "data"
    };
  },

  methods: {
    onOpenData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Data"
      });
    },
    onOpenModels() {
      this.localSettings = { models: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: this.cmpModels,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Save Response to Models"
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case "data":
          this.onOpenData();
          break;
        case "models":
          this.onOpenModels();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenData();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>

<style scoped>
.drag {
  cursor: move;
  text-align: left;
  padding: 20px 5px;
}
</style>
