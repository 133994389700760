<template>
  <g :svg-class="classes" v-if="fromObject || toObject || true">
    <defs>
      <linearGradient :id="linearGradientId" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" v-bind="{'stop-color': fromColor}" />
        <stop offset="100%" v-bind="{'stop-color': toColor}" />
      </linearGradient>
    </defs>
    <!-- <defs>
      <marker
        id="arrow"
        viewBox="0 -5 10 10"
        refX="5"
        refY="0"
        markerWidth="4"
        markerHeight="4"
        orient="auto"
      >
        <path fill="#e44e9d" d="M0,-5L10,0L0,5" />
      </marker>
    </!-->
    -->
    <text
      @dblclick.stop="setPriority"
      v-if="settings.priority"
      style="font-size: 12; cursor: pointer;"
      transform="translate(0, -10)"
    >
      <textPath
        startOffset="50%"
        text-anchor="middle"
        :href="`#connection_${id}`"
      >Priority: {{settings.priority}}</textPath>
    </text>
    <text
      @dblclick.stop="setDelay"
      v-if="settings.delay"
      style="font-size: 12px; cursor: pointer;"
      transform="translate(0, 17)"
    >
      <textPath
        startOffset="50%"
        text-anchor="middle"
        :href="`#connection_${id}`"
      >Delay: {{settings.delay}}</textPath>
    </text>
    <path
      :id="`connection_${id}`"
      @click.stop="openPropertyEditor"
      ref="path"
      v-bind="pathAttributes"
    ></path>
  </g>
</template>

<script>
import * as d3 from "d3";

import { defaultProperties } from "./Object/Types";
import { deepClone } from "@/lib";
import * as _ from "underscore";
import ConnectionPropertyEditor from "./Connection/ConnectionPropertyEditor";
export default {
  name: "BaseConnection",
  props: {
    id: [Number, String],
    from: [Number, String, Object],
    to: [Number, String, Object],
    settings: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {
    const svg = d3.select(document.getElementById("arrow-defs"));

    svg
      .append("marker")
      .attr("id", `arrow-${this.id}`)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", "5")
      .attr("refY", "0")
      .attr("markerWidth", "4")
      .attr("markerHeight", "4")
      .attr("orient", "auto")
      .append("path")
      .attr("fill", this.fromColor)
      .attr("d", "M0,-5L10,0L0,5");
  },
  data() {
    const linearGradientId = `linear_gradient_${this.guid()}`;

    return {
      points: [],
      mounted: false,
      linearGradientId,
      fromColor: "#605da5",
      toColor: "#df4e9e",
      rand: Math.random() * 50
    };
  },
  methods: {
    openPropertyEditor() {
      this.$root.$emit("PropertyEditor.open", {
        component: ConnectionPropertyEditor,
        props: {
          baseConnection: deepClone(this.baseConnection),
          settings: deepClone(this.settings)
        }
      });
    },
    setPriority() {
      const priority = prompt("Priority", this.settings.priority);
      if (priority) {
        this.$store.dispatch("connection/update", {
          ...this.baseConnection,
          settings: { ...this.settings, priority: parseInt(priority) }
        });
      }
    },
    setDelay() {
      const delay = prompt("Delay", this.settings.delay);
      if (delay) {
        this.$store.dispatch("connection/update", {
          ...this.baseConnection,
          settings: { ...this.settings, delay: parseInt(delay) }
        });
      }
    },
    horizontalLayoutPathPoints(fromX, fromY, toX, toY, fromObjectHeight) {
      let points = [];
      if (
        typeof (fromX && fromY && toX && toY) != "undefined" &&
        !isNaN(fromX && fromY && toX && toY)
      ) {
        if (fromX < toX) {
          this.fromColor =
            (this.fromObject &&
              (this.fromObject.info.settings.color ||
                defaultProperties[this.fromObject.type].outColor)) ||
            "#605da5";
          this.toColor =
            (this.toObject &&
              (this.toObject.info.settings.color ||
                defaultProperties[this.toObject.type].inColor)) ||
            "#df4e9e";
        } else {
          this.toColor =
            (this.fromObject &&
              (this.fromObject.info.settings.color ||
                defaultProperties[this.fromObject.type].outColor)) ||
            "#605da5";
          this.fromColor =
            (this.toObject &&
              (this.toObject.info.settings.color ||
                defaultProperties[this.toObject.type].inColor)) ||
            "#df4e9e";
        }

        if (fromX < toX || !this.toObject) {
          const dx = Math.abs(toX - fromX) < 1 ? 1 : toX - fromX,
            dy = Math.abs(toY - fromY) < 1 ? 1 : toY - fromY;

          points = [
            [fromX, fromY],
            [fromX + dx / 4, fromY],
            [fromX + dx / 2, fromY + dy / 2],
            [fromX + (dx * 3) / 4, toY],
            [toX, toY]
          ];
        } else {
          let distance = 50;

          if (fromY < toY) {
            // distance = distance + this.rand
            const top = fromY - (distance + fromObjectHeight / 2);
            points = [
              [fromX, fromY],
              [fromX + distance, fromY],
              [fromX + distance, fromY - (distance + fromObjectHeight / 2) / 2],
              [fromX + distance, top],
              [fromX, top],
              [toX, top],
              [toX - distance, top],
              [toX - distance, fromY - (distance + fromObjectHeight / 2) / 2],
              [toX - distance, toY - distance],
              [toX - distance, toY],
              [toX, toY]
            ];
          } else {
            // distance = distance + this.rand
            const top = fromY + (distance + fromObjectHeight / 2);
            points = [
              [fromX, fromY],
              [fromX + distance, fromY],
              [fromX + distance, fromY + (distance + fromObjectHeight / 2) / 2],
              [fromX + distance, top],
              [fromX, top],
              [toX, top],
              [toX - distance, top],
              [toX - distance, fromY + (distance + fromObjectHeight / 2) / 2],
              [toX - distance, toY + distance],
              [toX - distance, toY],
              [toX, toY]
            ];
          }
        }
      }
      return points;
    },
    verticallLayoutPathPoints(fromX, fromY, toX, toY, fromObjectWidth) {
      let points = [];
      if (
        typeof (fromX && fromY && toX && toY) != "undefined" &&
        !isNaN(fromX && fromY && toX && toY)
      ) {
        if (fromY < toY) {
          this.fromColor =
            (this.fromObject &&
              (this.fromObject.info.settings.color ||
                defaultProperties[this.fromObject.type].outColor)) ||
            "#605da5";
          this.toColor =
            (this.toObject &&
              (this.toObject.info.settings.color ||
                defaultProperties[this.toObject.type].inColor)) ||
            "#df4e9e";
        } else {
          this.toColor =
            (this.fromObject &&
              (this.fromObject.info.settings.color ||
                defaultProperties[this.fromObject.type].outColor)) ||
            "#605da5";
          this.fromColor =
            (this.toObject &&
              (this.toObject.info.settings.color ||
                defaultProperties[this.toObject.type].inColor)) ||
            "#df4e9e";
        }

        if (fromY < toY || !this.toObject) {
          const dx = Math.abs(toX - fromX) < 1 ? 1 : toX - fromX,
            dy = Math.abs(toY - fromY) < 1 ? 1 : toY - fromY;

          points = [
            [fromX, fromY],
            [fromX, fromY + dy / 4],
            [fromX + dx / 2, fromY + dy / 2],
            [toX, fromY + (dy * 3) / 4],
            [toX, toY]
          ];
        } else {
          let distance = 50;

          if (fromX < toX) {
            // distance = distance + this.rand
            const left = fromX - (distance + fromObjectWidth / 2);
            points = [
              [fromX, fromY],
              [fromX, fromY + distance],
              [fromX - (distance + fromObjectWidth / 2) / 2, fromY + distance],
              [left, fromY + distance],
              [left, fromY],
              [left, toY],
              [left, toY - distance],
              [fromX - (distance + fromObjectWidth / 2) / 2, toY - distance],
              [toX - distance, toY - distance],
              [toX, toY - distance],
              [toX, toY]
            ];
          } else {
            // distance = distance + this.rand
            const left = fromX + (distance + fromObjectWidth / 2);
            points = [
              [fromX, fromY],
              [fromX, fromY + distance],
              [fromX + (distance + fromObjectWidth / 2) / 2, fromY + distance],
              [left, fromY + distance],
              [left, fromY],
              [left, toY],
              [left, toY - distance],
              [fromX + (distance + fromObjectWidth / 2) / 2, toY - distance],
              [toX + distance, toY - distance],
              [toX, toY - distance],
              [toX, toY]
            ];
          }
        }
      }
      return points;
    }
  },
  computed: {
    classes() {
      return `from-${this.fromObject ? this.fromObject.type : "none"} to-${
        this.toObject ? this.toObject.type : "none"
      }`;
    },
    baseConnection() {
      return {
        id: this.id,
        from: this.from,
        to: this.to
      };
    },
    fromObject() {
      return this.$store.getters["object/findById"](this.from);
    },
    toObject() {
      return this.$store.getters["object/findById"](this.to);
    },
    transform() {
      if (!this.points.length) return ``;
      const x = this.middlePoint.x || 0,
        y = this.middlePoint.y || 0;

      const dx = _.last(this.points)[0] - _.first(this.points)[0],
        dy = _.first(this.points)[1] - _.last(this.points)[1];

      const rotate = -Math.atan(dy / dx) * 57.2958;

      return `translate(${x}, ${y}) rotate(${rotate})`;
    },
    pathAttributes() {
      return {
        "marker-end": `url(#arrow-${this.id})`,
        stroke: `url(#${this.linearGradientId})`,
        d: this.pathLine,
        "stroke-dasharray": "5,5"
      };
    },
    pathLine() {
      try {
        let fromObjectWidth, fromObjectHeight, toObjectWidth, toObjectHeight;
        let layout = "horizontal";
        if (this.fromObject) {
          fromObjectWidth =
            this.fromObject.size && this.fromObject.size.width
              ? this.fromObject.size.width
              : defaultProperties[this.fromObject.type].size.width;
          fromObjectHeight =
            this.fromObject.size && this.fromObject.size.height
              ? this.fromObject.size.height
              : defaultProperties[this.fromObject.type].size.height;
          layout = this.fromObject.layout;
        }
        if (this.toObject) {
          toObjectWidth =
            this.toObject.size && this.toObject.size.width
              ? this.toObject.size.width
              : defaultProperties[this.toObject.type].size.width;
          toObjectHeight =
            this.toObject.size && this.toObject.size.height
              ? this.toObject.size.height
              : defaultProperties[this.toObject.type].size.height;
        }
        
        const isHorizontalLayout = !layout || layout === "horizontal";

        let fromX = this.fromObject
            ? isHorizontalLayout
              ? this.fromObject.position.x + fromObjectWidth
              : this.fromObject.position.x + fromObjectWidth / 2
            : this.from.x,
          fromY = this.fromObject
            ? isHorizontalLayout
              ? this.fromObject.position.y + fromObjectHeight / 2
              : this.fromObject.position.y + fromObjectHeight
            : this.from.y,
          toX = this.toObject
            ? isHorizontalLayout
              ? this.toObject.position.x - 10
              : this.toObject.position.x + toObjectWidth / 2
            : this.to.x,
          toY = this.toObject
            ? isHorizontalLayout
              ? this.toObject.position.y + toObjectHeight / 2
              : this.toObject.position.y
            : this.to.y;

        this.points =
          isHorizontalLayout
            ? this.horizontalLayoutPathPoints(
                fromX,
                fromY,
                toX,
                toY,
                fromObjectHeight
              )
            : this.verticallLayoutPathPoints(
                fromX,
                fromY,
                toX,
                toY,
                fromObjectWidth
              );

        return d3.line().curve(d3.curveBasis)(this.points);
      } catch (e) {
        return null;
      }
    }
  }
};
</script>

<style scoped>
.connection {
  position: absolute;
}
path {
  fill: none;
  stroke-width: 5px;
  cursor: pointer;
}
</style>
