<template>
  <v-app>
    <router-view />
    <Snackbar />
  </v-app>
</template>

<script>
  export default {
    name: "TheNotAuthorizedLayout",
  }
</script>