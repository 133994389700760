<template>
  <div>
    <v-select
      full-width
      :items="botMessages"
      label="BotMessages"
      v-model="localSettings.botMessage"
      item-value="id"
      :item-text="(item) => item.info.settings.title"
      solo
      :readonly="readonly"
    />
    <Autocompleter solo v-model="localSettings.signal" label="Signal" :readonly="readonly" />
  </div>
</template>

<script>
  import componentExtends from './_extends/Component'

  export default {
    name: 'Chatbot_RouterObjectPropertyEditor_SEND_BOT_MESSAGE',
    extends: componentExtends,
    computed: {
      botMessages () {
        return this.$store.getters['object/list'].filter(o => o.type == 'Chatbot_BotMessageObject' && o.info.settings.title)
      }
    }
  }
</script>
