<template>
  <v-list-item @click="callback">
    <v-list-item-icon v-if="item.icon">
      <v-icon color="#2f2f2f">{{item.icon}}</v-icon>
    </v-list-item-icon>
    <v-list-item-title class="font-weight-black">{{item.name}}</v-list-item-title>
  </v-list-item>
</template>

<script>
  export default {
    name: "NavigationListItem",
    props: {
      item: Object
    },
    methods: {
      callback () {
        if (this.item.callback) {
          this.item.callback();
          this.$store.dispatch('navigation/setDrawer', false);
        }
      }
    }
  }
</script>
