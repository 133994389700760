<template>
  <div class="comment-object" :class="{opened: 'opened'}">
    <div ref="point" @click="toggle" class="comment-object__target-point" :style="pointStyle">
      <div class="comment-object__target-points__text">{{settings.comments.length}}</div>
    </div>
    <div class="comment-object__target-point__line" v-show="opened" :style="lineStyle">
      <svg width="100%" height="100%">
        <line
          :x1="lineParams.x1"
          :y1="lineParams.y1"
          :x2="lineParams.x2"
          :y2="lineParams.y2"
          :stroke="settings.color || '#605da5'"
          stroke-width="3"
          class="comment-object__target-point__line-svg"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_CommentObject",
  extends: ExtendsComponent,
  created() {
    this.$root.$on("closeAllComments", this.onCloseAllComments);
  },
  beforeDestroy() {
    this.$root.$off("closeAllComments", this.onCloseAllComments);
  },
  data() {
    return {
      width: 0,
      height: 0,
      opened: false,
      x: 0,
      y: 0,
      lineParams: { x1: 12, x2: 150, y1: 12, y2: 2 },
    };
  },
  computed: {
    pointStyle() {
      return {
        color: this.settings.color,
        background: this.settings.color,
        filter: this.selected ? `brightness(105%)` : null
      };
    },
    lineStyle() {
      this.calcLine();

      return {
        width: this.width + "px",
        height: this.height + "px",
        transform: `translate(${this.x}px, ${this.y}px)`
      };
    }
  },
  methods: {
    onCloseAllComments() {
      this.opened = false;
      this.$store.commit('chart/closeComment', this.baseObject);
    },
    toggle() {
      this.opened = !this.opened;
      if (this.opened) {
        this.$store.commit('chart/openComment', this.baseObject);
        this.calcLine();
      } else {
        this.$store.commit('chart/closeComment', this.baseObject);
      }
    },

    calcLine() {
      const { x2, y2 } = this.settings;
      let width, height, x, y;

      const dx = 20,
        containerWidth = 300,
        minWidth = 82;

      if (x2 > 0 && y2 > 0) {
        width = x2;
        height = y2 + 20;
        x = dx;
        y = - 10;
        this.lineParams = { x1: 0, x2: width, y1: 10, y2: height - 10 };
      }

      if (x2 > 0 && y2 < 0) {
        width = x2;
        height = - y2 + 20;
        x = dx;
        y = y2;
        this.lineParams = {
          x1: 0,
          x2: width,
          y1: height - 20,
          y2: 2
        };
      }

      if (x2 < 0 && y2 < 0) {
        width = - x2 - (containerWidth + dx);
        height = - y2 + 20;
        x = x2 + dx + containerWidth;
        y = y2 - 10;

        this.lineParams = {
          x1: 0,
          x2: width,
          y1: 12,
          y2: height - 10
        };
      }

      if (x2 < 0 && y2 > 0) {
        width = - x2 - (containerWidth + dx);
        height = y2;
        x = x2 + dx + containerWidth;
        y = 0;

        this.lineParams = {
          x1: 0,
          x2: width,
          y1: height + 1,
          y2: 2
        };
      }

      if (width < 0) {
        width = minWidth;
        if (y2 > 0) {
          x = x2 + 15;
          height = y2 - 35;
          width = 310;

          this.lineParams = {
            x1: width / 2,
            x2: - x2 - 2,
            y1: height,
            y2: 0
          };
        }
        if (y2 < 0) {
          const commentsContainer = this.$refs.commentsContainer;

          let diffHeight = commentsContainer
            ? commentsContainer.offsetHeight
            : 0;
          y += -35 + diffHeight;
          height -= diffHeight - 15;
          x = x2 + 15;
          width = 310;

          this.lineParams = {
            x1: - x2 - 2,
            x2: width / 2,
            y1: height,
            y2: 0
          };
        }
      }

      if (height < 120)
        height = 120;

      this.width = width;
      this.height = height;
      this.x = x;
      this.y = y;
    },
  }
};
</script>

<style scoped>
.comment-object {
  box-shadow: none !important;
}

.comment-object.opened >>> .comment-object__target-point__line {
  width: 190px;
}

.comment-object__target-point {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  border: 2px solid white;
  margin-top: -3px;
}

.comment-object__target-point:hover {
  filter: brightness(105%);
}

.comment-object__target-point:before {
  display: block;
  content: "";
  width: 28px;
  height: 28px;
  border: 2px solid;
  border-radius: 25px;
  position: relative;
  top: -4px;
  left: -4px;
}

.comment-object__target-points__text {
  position: absolute;
  top: -2px;
  color: white;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-weight: bold;
}

.comment-object__target-point__line >>> svg {
  display: block;
}

.comment-object__target-point__line >>> line {
  pointer-events: auto;
}

.comment-object__target-point__line {
  pointer-events: none;
  position: absolute;
  top: 8px;
  left: 100%;
  width: 0px;
  height: 4px;
  background: inherit;
  z-index: 1;
}

</style>

<style>
.Base_CommentObject .v-list--three-line .v-list__tile {
  height: auto;
  padding: 20px;
  min-height: 88px;
}
</style>