<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="data">
        Data
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="actions">
        Actions
      </v-btn>
    </v-btn-toggle>
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ControlPropertyEditorExtend from "../_extends/ControlPropertyEditor";

export default {
  name: "ControlElement_ButtonControlPropertyEditor",

  extends: ControlPropertyEditorExtend,

  data: function() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-layout>
            <v-flex xs8>
              <Autocompleter
                v-model="settings.showCondition"
                label="Show Condition, default true"
                :readonly="readonly"
              />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs3>
              <v-text-field type="number" v-model="settings.width" label="Width, %" :readonly="readonly" />
            </v-flex>
          </v-layout>

          <Autocompleter v-model="settings.label" label="Label" :readonly="readonly" class="pb-1" />
          <v-text-field type="text" v-model="settings.imageLink" placeholder="https://vulcanapp.com" label="Link" :readonly="readonly" />
        </div>
      `,
      props: ['settings', 'readonly']
    });

    const cmpActions = Vue.extend({
      template: `
        <div>
          <div class="py-2 mb-2" v-for="action in settings.actions" :key="action.id">
            <v-layout align-center>
              <v-flex>
                <Autocompleter v-model="action.signal" label="Emit Signal" solo :readonly="readonly" />
              </v-flex>
              <v-flex xs0 class="px-3" />
              <v-flex>
                <Autocompleter v-model="action.if" label="If Expression" solo :readonly="readonly" />
              </v-flex>
              <v-btn text class="button-icon" @click="deleteAction(action.id)" :disabled="readonly">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-layout>
          </div>
          <div class="add" @click="addAction" v-if="!readonly">Add Action</div>
        </div>
      `,
      methods: {
        addAction() {
          this.settings.actions.push({ id: this.guid() });
        },
        deleteAction(actionId) {
          this.settings.actions = this.settings.actions.filter(
            action => action.id != actionId
          );
        }
      },
      props: ['settings', 'readonly']
    });

    return {
      cmpData,
      cmpActions,
      openedData: 'data'
    };
  },

  methods: {
    onOpenData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Data'
      });
    },
    onOpenActions() {
      this.localSettings = { actions: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: this.cmpActions,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Actions'
      });
    },
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 'data':    this.onOpenData(); break;
        case 'actions': this.onOpenActions(); break;
        default:        this.$root.$emit("DataEditor.close");
      }
    },
    id() {
      this.openedData = 'data';
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenData();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>