function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
}

function setCurrentCursorPosition(el, chars) {
  if (chars >= 0) {
    var selection = window.getSelection();

    var range = createRange(el.parentNode, {
      count: chars
    });

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      try {
        selection.addRange(range);
      } catch (e) {}
    }
  }
}

function isChildOf(node, parentId) {
  while (node !== null) {
    if (node.id === parentId) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}

function getCurrentCursorPosition(parentId) {
  var selection = window.getSelection(),
    charCount = -1,
    node;

  if (selection.focusNode) {
    if (isChildOf(selection.focusNode, parentId)) {
      node = selection.focusNode;
      charCount = selection.focusOffset;

      while (node) {
        if (node.id === parentId) {
          break;
        }

        if (node.previousSibling) {
          node = node.previousSibling;
          charCount += node.textContent.length;
        } else {
          node = node.parentNode;
          if (node === null) {
            break;
          }
        }
      }
    }
  }

  return charCount;
}


function getCurrentCursorPositionInHTML(parentId) {
  var selection = window.getSelection(),
    charCount = -1,
    node;

  if (selection.focusNode) {
    if (isChildOf(selection.focusNode, parentId)) {
      node = selection.focusNode;
      charCount = selection.focusOffset;

      while (node) {
        if (node.id === parentId) {
          break;
        }

        if (node.previousSibling) {
          node = node.previousSibling;
          charCount += node.outerHTML ? node.outerHTML.length : node.textContent.length;
        } else {
          node = node.parentNode;
          if (node === null) {
            break;
          }
        }
      }
    }
  }

  return charCount;
}

function fixhtml(html) {
  var div = document.createElement("div");
  div.innerHTML = html;
  return div.innerHTML;
}

function getLinkAttributeFromHTML(html) {
  var div = document.createElement("div");
  div.innerHTML = html;
  return div.querySelector('a');
}

function htmlToText(html) {
  var div = document.createElement("div");
  div.innerHTML = html;
  return div.innerText;
}

function execute(ctx, command) {
  let shortcut = command.slice(1);
  const props = {};
  const match = command.match(/\/([^\s]+)\s/);
  if (match) {
    shortcut = match[1];
    const propsString = shortcut
      ? command
          .split(new RegExp(`${shortcut}\\s+`))[1]
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      : "";
    if (propsString) {
      const propsArr = propsString
        .match(/[^\s]+=["'][^"']*["']/g)
        .filter(i => i);

      propsArr.forEach(prop => {
        const [key, value] = prop.split("=");
        props[key] = value.replace(/"|'/g, "");
      });
    }
  }

  ctx.$parent.$emit("addLine", {
    type: "CustomComponent",
    id: ctx.line.id,
    content: "",
    params: { shortcut, props, ref: ctx.line.id }
  });
}

export {
  getCurrentCursorPosition,
  getCurrentCursorPositionInHTML,
  setCurrentCursorPosition,
  getLinkAttributeFromHTML,
  fixhtml,
  htmlToText,
  execute
};
