import Vue from "vue";
import VueResource from "vue-resource";
import { ROOT_URL, APPLICATION_ID, APPLICATION_SECRET } from "@/env";
import { guid } from "@/lib";
import app from "@/main";
import store from '@/store';
import router from "@/router";

Vue.use(VueResource);

Vue.http.options.root = ROOT_URL;

Vue.http.interceptors.push((request, next) => {
  if (!request.headers.get("Authorization")) {
    const {token} = store.state.user;
    request.headers.set("Authorization", `Bearer ${token}`);
  }

  if (!request.headers.get("CollaborationSession")) {
    if (sessionStorage.sessionId === undefined) {
      sessionStorage.setItem("sessionId", guid());
    }
    request.headers.set("Collaboration-Session", sessionStorage.sessionId);
  }

  request.headers.set("Accept", "application/json");

  next(response => {
    if (response.status !== 200 && response.body.error && response.body.error.message) {
      return response.body.error.message;
    }

    if (response.data && response.data.msg) {
      app.$root.$emit("showSnack", {
        message: response.data.msg,
        color: response.status == 200 ? "green" : "red"
      });
    }

    if (response.status === 401 && request.url !== 'vulcan/v1/users/current') {
      let requiresAuth = router.currentRoute?.matched?.some(record => record.meta.requiresAuth);
      if (router.history?.pending)
        requiresAuth = router.history.pending.matched?.some(record => record.meta.requiresAuth);
      if (!requiresAuth)
        return;

      store.dispatch("application/logOut");
    }
  });
});

Vue.http.interceptors.push((request, next) => {
  if (request.url.match(/^https?:\/\//)) {
    const target = encodeURIComponent(request.url);
    const url = `vulcan/v1/proxy`;

    let headers = {};
    Object.keys(request.headers.map).forEach(h => {
      headers[h] = request.headers.get(h);
    });

    request.url = url;

    if (request.method == "GET" || request.method == "DELETE") {
      request.params = {
        target,
        headers,
        params: request.params
      };
    } else {
      request.body = {
        target,
        headers,
        payload: request.body
      };
    }
  }
  next();
});

const OAuthFixtures = Vue.resource("vulcan/v1/oauth_service_reference");

const Project = Vue.resource(
  "vulcan/v1/projects{/id}",
  {},
  {
    set_cursor: { method: "POST", url: "vulcan/v1/projects{/id}/set_cursor" },
    recent: { method: "GET", url: "vulcan/v1/projects/recent" },
    entries: { method: "GET", url: "vulcan/v1/projects{/id}/entries" },
    addMember: { method: "POST", url: "vulcan/v1/projects{/id}/add_member" },
    removeMember: { method: "DELETE", url: "vulcan/v1/projects{/id}/remove_member" },
  }
);

const Chart = Vue.resource("vulcan/v1/charts{/id}");

const Team = Vue.resource(
  "v1/teams{/id}",
  {},
  {
    members: { method: "GET", url: "v1/teams{/id}/members" },
    deleteMember: { method: "DELETE", url: "v1/teams{/id}/delete_member" }
  }
);

const Invitation = Vue.resource(
  "v1/invitations{/id}",
  {},
  {
    accept: { method: "POST", url: "v1/invitations{/id}/accept" }
  }
);

const ChartObject = Vue.resource(
  "vulcan/v1/chart_objects{/id}",
  {},
  {
    duplicate: {
      method: "POST",
      url: "vulcan/v1/chart_objects{/id}/duplicate"
    },
    createSymbol: {
      method: "POST",
      url: "vulcan/v1/chart_objects{/id}/create_symbol"
    },
    symbols: {
      method: "GET",
      url: "vulcan/v1/chart_objects{/id}/symbols"
    },
    associateWithSymbol: {
      method: "POST",
      url: "vulcan/v1/chart_objects{/id}/associate_with_symbol"
    }
  }
);

const Widget = Vue.resource("vulcan/v1/widgets{/id}", {});

const ChartTemplate = Vue.resource(
  "vulcan/v1/chart_templates{/id}",
  {},
  {
    publish: { method: "POST", url: "vulcan/v1/chart_templates{/id}/publish" }
  }
);

const Category = Vue.resource("vulcan/v1/categories{/id}");

const Connection = Vue.resource("vulcan/v1/connections{/id}");
const Component = Vue.resource(
  "vulcan/v1/components{/id}",
  {},
  {
    fork: { method: "POST", url: "vulcan/v1/components{/id}/fork" },
    submit_general: {
      method: "POST",
      url: "vulcan/v1/components{/id}/submit_general"
    }
  }
);

const User = Vue.resource(
  "v1/users{/id}",
  {},
  {
    current: { method: "GET", url: "vulcan/v1/users/current" },
    updateCurrent: { method: "PUT", url: "vulcan/v1/user" },
    acceptInvite: { method: "POST", url: "vulcan/v1/users/accept_invite" },
    invites: { method: "GET", url: "vulcan/v1/users/invites" },
    rejectInvite: { method: "DELETE", url: "vulcan/v1/users/reject_invite" },
    search: { method: "GET", url: "vulcan/v1/users/search" },
    authorize: {
      method: "POST",
      url: "v1/auth",
      headers: {
        AUTHORIZATION: `application_id ${APPLICATION_ID}, client_secret ${APPLICATION_SECRET}`
      }
    },
    register: {
      method: "POST",
      url: "v1/users",
      headers: {
        AUTHORIZATION: `application_id ${APPLICATION_ID}, client_secret ${APPLICATION_SECRET}`
      }
    },
    muralAuth: {
      method: "POST",
      url: "vulcan/v1/users/mural_auth",
      headers: {
        AUTHORIZATION: `application_id ${APPLICATION_ID}, client_secret ${APPLICATION_SECRET}`
      }
    },
    getEnvironmentVariables: {
      method: "GET",
      url: "vulcan/v1/users/get_environment_variables"
    },
    setEnvironmentVariables: {
      method: "PUT",
      url: "vulcan/v1/users/set_environment_variables"
    },
    getOauthTokens: {
      method: "GET",
      url: "vulcan/v1/users/get_oauth_tokens"
    },
    setOauthTokens: {
      method: "PUT",
      url: "vulcan/v1/users/set_oauth_tokens"
    }
  }
);

const Chatbot = Vue.resource(
  "vulcan/v1/chatbot{/id}",
  {},
  {
    dialogflow: { method: "POST", url: "vulcan/v1/chatbot{/id}/dialogflow" },
    endpoint: { method: "POST", url: "vulcan/v1/chatbot{/id}/endpoint" }
  }
);

const Callback = Vue.resource(
  `vulcan/v1/callbacks{/id}`,
  {},
  {
    completed: { method: 'GET', url: `vulcan/v1/callbacks{/id}/completed` },
  }
);

const Search = Vue.resource(
  "vulcan/v1/search",
  {},
  {
    navigateTo: { method: "GET", url: "vulcan/v1/search/navigate_to" }
  }
);

const Organisation = Vue.resource(
  "v1/organisations{/id}",
  {},
  {
    setCurrent: {
      method: "PUT",
      url: "v1/organisations{/id}/current"
    },
    getCurrent: {
      method: "GET",
      url: "v1/organisations/get_current"
    },
    members: {
      method: "GET",
      url: "v1/organisations/users"
    },
    deleteMember: {
      method: "DELETE",
      url: "v1/memberships{/id}"
    },
    createAssignment: {
      method: "POST",
      url: "v1/assignments"
    },
    deleteAssignment: {
      method: "DELETE",
      url: "v1/assignments{/id}"
    },
    invitations: {
      method: "GET",
      url: "v1/invitations"
    },
    deleteInvitation: {
      method: "DELETE",
      url: "v1/invitations{/id}"
    },
    getSubscription: {
      method: "GET",
      url: "v1/organisations{/id}/subscription"
    },
    getAllCards: {
      method: "GET",
      url: "v1/organisations{/organisation_id}/all_cards"
    },
    createCard: {
      method: "POST",
      url: "v1/organisations{/organisation_id}/add_card"
    },
    updateCard: {
      method: "PUT",
      url: "v1/organisations{/organisation_id}/update_card{/id}"
    },
    deleteCard: {
      method: "DELETE",
      url: "v1/organisations{/organisation_id}/delete_card{/id}"
    },
    getInvoices: {
      method: "GET",
      url: "v1/organisations{/organisation_id}/subscriptions{/id}/show_invoices"
    },
    getCustomer: {
      method: "GET",
      url: "v1/organisations{/organisation_id}/get_customer"
    },
    createCustomer: {
      method: "POST",
      url: "v1/organisations{/organisation_id}/create_customer"
    },
    setDefaultPaymentMethod: {
      method: "POST",
      url: "v1/organisations{/organisation_id}/set_default_payment_method"
    }
  }
);

const Subscription = Vue.resource(
  "v1/subscriptions{/id}",
  {}  
);

const Role = Vue.resource("v1/roles", {}, {});

const Plan = Vue.resource("v1/plans", {}, {});

Vue.prototype.api = {
  Project,
  Team,
  Invitation,
  OAuthFixtures,
  Chart,
  ChartObject,
  Category,
  Connection,
  User,
  Chatbot,
  Callback,
  Component,
  Organisation,
  Subscription,
  Role,
  Plan,
  ChartTemplate,
  Search,
  Widget
};

export {
  Project,
  Team,
  Invitation,
  OAuthFixtures,
  Chart,
  ChartObject,
  Category,
  Connection,
  User,
  Chatbot,
  Callback,
  Component,
  Organisation,
  Subscription,
  Role,
  Plan,
  ChartTemplate,
  Search,
  Widget
};
