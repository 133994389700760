<template>
  <svg width="100%" height="100%" :style="containerStyles">
    <line
      v-for="(line, index) in horizontalLines"
      :key="`x_${index}`"
      :x1="line.x1"
      :y1="line.y1"
      :x2="line.x2"
      :y2="line.y2"
      :stroke="strokeColor"
      stroke-width="2"
      class="horizontal-line"
    />
    <line
      v-for="(line, index) in verticalLines"
      :key="`y_${index}`"
      :x1="line.x1"
      :y1="line.y1"
      :x2="line.x2"
      :y2="line.y2"
      :stroke="strokeColor"
      stroke-width="2"
      class="horizontal-line"
    />
  </svg>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "GridRuler",
  props: {
    viewportDimension: Object,
    scale: Number,
    translate: {
      type: Object,
      default() {
        return { x: 0, y: 0 };
      }
    },
    gridSize: String,
    gridColor: String
  },
  data() {
    return {
      localScale: this.scale,
      localTranslate: this.translate,
      visibleRegion: this.visibleRegion,
      horizontalLines: [],
      verticalLines: [],
      containerStyles: {}
    };
  },
  mounted() {
    this.setZoom();
  },
  watch: {
    scale() {
      if (this.localScale != this.scale)
        this.setZoom();
    },
    translate() {
      if (this.localScale != this.scale)
        this.setZoom();
    },
    gridSize() {
      this.setZoom();
    },
    viewportDimension: {
      handler() {
        this.setZoom();
      },
      deep: true
    }
   
  },
  computed: {
    scaleFactor() {
      if (this.gridSize === "Small") return 20;
      if (this.gridSize === "Large") return 120;
      return 60; // default: "Medium"
    },
    strokeColor() {
      if (this.gridColor === "Black") return "#333"; 
      return "#e6e6e6"; // default: "Gray"
    }
  },
  methods: {
    setZoom() {
      const currentScale = this.scaleFactor * this.scale;
      const offsetX = this.translate.x % currentScale;
      const offsetY = this.translate.y % currentScale;
      const horizontalLines = [];
      const verticalLines = [];
      let i;
      const stepX = currentScale; 
      const stepY = currentScale;
      this.horizontalLines = [];
      for (i = -stepX; i < this.viewportDimension.width + stepX; i = i + stepX) {
        horizontalLines.push({ x1: i + offsetX, y1: -stepY, x2: i + offsetX, y2: this.viewportDimension.height + stepY });
      }
      for (i = -stepY; i < this.viewportDimension.height + stepY; i = i + stepY) {
        verticalLines.push({ x1: -stepX, y1: i + offsetY, x2: this.viewportDimension.width + stepX, y2: i + offsetY });
      }
      this.horizontalLines = horizontalLines;
      this.verticalLines = verticalLines;
      this.containerStyles = {'transform': `translate3d(${(this.translate.x)}px, ${(this.translate.y)}px, 0})`};
    }
  }
};
</script>

<style scoped>

</style>

<style>
</style>
