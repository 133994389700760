<template>
  <div>
    <div class="record-webcam mt-2" v-if="status != 'stopped'">
      <video
        muted
        width="100%"
        height="240"
        ref="video"
        autoplay
        playsinline
      />
      <div class="record-webcam__actions">
        <div
          @click="stopRecording"
          class="record-webcam__action"
          v-if="status == 'started' || status == 'paused'"
        >
          <v-icon color="white">stop</v-icon>
          <div class="record-webcam__action-title">Stop</div>
        </div>
        <div
          @click="pauseRecording"
          class="record-webcam__action"
          v-if="status == 'started'"
        >
          <v-icon color="white">pause</v-icon>
          <div class="record-webcam__action-title">Pause</div>
        </div>
        <div
          @click="status == 'paused' ? resumeRecording() : startRecording()"
          class="record-webcam__action"
          v-if="status == null || status == 'paused'"
        >
          <v-icon color="white">fiber_manual_record</v-icon>
          <div class="record-webcam__action-title">Start Recording</div>
        </div>
      </div>
    </div>
    <div class="record-webcam mt-2" v-if="blobUrl">
      <video :src="blobUrl" width="100%" height="240" controls></video>
    </div>
    <v-layout justify-space-between class="mt-2">
      <v-btn v-if="blobUrl" @click="clear">Clear</v-btn>
      <v-btn v-else-if="!loading" @click="close">Cancel</v-btn>

      <v-btn v-if="blobUrl" color="primary" @click="done" :disabled="loading">{{
        loading ? "Processing..." : "Done"
      }}</v-btn>
    </v-layout>
  </div>
</template>

<script>
import { RecordRTCPromisesHandler } from "recordrtc";

export default {
  data() {
    return {
      stream: null,
      recorder: null,
      status: null,
      blobUrl: null,
      blob: null,
      loading: false
    };
  },
  mounted() {
    this.prepareStream();
  },
  methods: {
    async prepareStream() {
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });

      this.recorder = new RecordRTCPromisesHandler(this.stream, {
        type: "video",
        mimeType: 'video/webm;codecs=vp8'
      });

      this.$refs.video.srcObject = this.stream;
      window.recorder = this.recorder;
    },
    async startRecording() {
      if (!this.stream) {
        await this.prepareStream();
      }
      await this.recorder.startRecording();
      this.recorder.stream = this.stream;
      this.status = "started";
    },
    async resumeRecording() {
      await this.recorder.resumeRecording();
      this.status = "started";
    },
    async pauseRecording() {
      await this.recorder.pauseRecording();
      this.status = "paused";
    },
    async stopRecording() {
      if (this.status) {
        await this.recorder.stopRecording();
        this.blob = await this.recorder.getBlob();
        this.blobUrl = URL.createObjectURL(this.blob);
        await this.recorder.reset();
        await this.recorder.destroy();
        this.status = "stopped";
      }

      if (this.stream) {
        this.stream.stop();
        this.stream = null;
      }
    },
    done() {
      this.loading = true;
      const formData = new FormData();
      formData.append("attachment", this.blob, "video.webm");

      this.$http.post("vulcan/v1/uploads/video", formData).then(response => {
        const result = response.body;
        this.loading = false;
        this.$emit("input", result);
        this.$emit("close");
      });
    },
    close() {
      this.$emit("close");
    },
    clear() {
      this.status = null;
      this.blobUrl = null;
      this.prepareStream();
    }
  },
  beforeDestroy() {
    this.stopRecording();
  }
};
</script>

<style scoped>
.record-webcam {
  position: relative;
  padding: 0;
}

.record-webcam__actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.record-webcam__action {
  width: 50px;
  height: 50px;
  border: 4px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
  background: #df4e9e66;
  position: relative;
}

.record-webcam__action-title {
  position: absolute;
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
  bottom: -40px;
  white-space: nowrap;
}

</style>
