<template>
  <div>
    <v-toolbar dense no-padding>
      <ColorPickerDialog
          v-model="localSettings.lineColor"
          title="Select Colour"
          :presetColors="projectColors"
          :disabled="readonly"
          tooltip="Line color"
      />
      <v-divider class="mr-2" vertical/>
      <v-select
          class="pa-0"
          hide-details
          :items="strokeWidths"
          :style="'width: 60px'"
          v-model="localSettings.strokeWidth"
          :menu-props="{ maxHeight: '400' }"
          :readonly="readonly"
      />

      <v-divider class="mr-2" vertical/>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-select
              class="pa-0"
              :style="'width: 120px'"
              hide-details
              :items="linestyles"
              :readonly="readonly"
              v-model="localSettings.lineStyle"
              :menu-props="{ maxHeight: '400' }"
              v-on="on"
          />
        </template>
        <span>Line style</span>
      </v-tooltip>

      <v-divider ml-1 vertical/>

      <v-btn-toggle group borderless v-model="openedData" v-if="localSettings.notionEnabled">
        <v-btn text style="opacity: 1" class="ma-0">Notes</v-btn>

        <v-divider vertical/>
      </v-btn-toggle>

      <slot name="menumore"/>
    </v-toolbar>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";

export default {
  name: "Base_DrawingObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  data() {
    return {
      openedData: undefined,
      linestyles: ["None", "Solid", "Dotted", "Dashed"],
      strokeWidths: [1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 18, 36, 48],
    };
  },
  methods: {
    onOpenNotion() {
      this.localSettings = {notesLines: [], ...this.localSettings};

      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    }
  },
  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenNotion();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      if (this.localSettings.notionEnabled)
        this.openedData = 0;
    }
  },
  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    if (this.localSettings.notionEnabled)
      this.openedData = 0;
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>

<style scoped>
.input-subtext {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
</style>
