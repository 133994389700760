<template>
  <component
    :is="wrapper"
    @submit="submit"
    title="New Team"
    :showSubmit="!!params.name"
    submitText="Create"
  >
    <ProjectForm
      :showActions="showActions"
      mode="new"
      :params.sync="params"
      @submit="submit"
      @close="close"
    />
  </component>
</template>

<script>
import ProjectForm from "./_Form";

export default {
  name: "NewTeam",
  components: {
    ProjectForm
  },
  props: {
    wrapper: {
      type: String,
      default: "Modal"
    },
    showActions: Boolean,
    callback: Function
  },
  data() {
    return {
      params: {}
    };
  },
  methods: {
    submit() {
      this.api.Team.save({ team: this.params }).then(response => {
        if (this.callback) this.callback(response.body);
        this.close();
      });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style>
</style>
