<template>
  <div class="comp">
    <div class="controls">
      <div
        class="control"
        v-for="(control, index) in controls"
        v-if="checkCondition(control.settings.showCondition)"
        :key="index"
      >
        <component
          :is="control.type"
          :settings="control.settings"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Controls from './BodyControls';

  export default {
    name: 'WayfinderBody',
    components: {
      ...Controls
    },
    props: {
      controls: Array,
    },
    methods: {
      checkCondition (string) {
        return this.$store.getters[`wayfinder/checkCondition`](string);
      }
    }
  }
</script>

<style scoped>
  .comp {
    margin-bottom: 20px;
    overflow: hidden;
  }

  .controls {
    padding-top: 10px;
    clear: both;
  }
  .control {
    margin-top: 20px;
  }
</style>