<template>
  <v-row no-gutters>
    <v-col class="settings-container d-flex flex-column">
      <div class="form-header">
        <Uploader
          class="form-header-item"
          :disabled="type == 'fork'"
          label="Icon"
          icon="image"
          v-model="localSettings.icon"
        />
        <v-text-field
          class="form-header-item"
          :disabled="type == 'fork'"
          v-model="localSettings.name"
          :autofocus="type == 'new'"
          label="Name"
        />
      </div>
      <div class="component-settings-tabs">
        <v-tabs grow background-color="white" v-model="activeTab">
          <v-tabs-slider color="primary" />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-tab v-on="on">
                <v-icon>mdi-table-edit</v-icon>
              </v-tab>
            </template>
            <span>Component Details</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-tab v-on="on">
                <v-icon>mdi-eye</v-icon>
              </v-tab>
            </template>
            <span>Live Preview</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-tab v-on="on">
                <v-icon>mdi-server-security</v-icon>
              </v-tab>
            </template>
            <span>Publishing Options</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-tab v-on="on">
                <v-icon>widgets</v-icon>
              </v-tab>
            </template>
            <span>Object settings</span>
          </v-tooltip>
        </v-tabs>
      </div>

      <div class="flex-grow-1 mt-4 px-2" style="overflow-y: auto">
        <div v-if="activeTab == 0">
          <v-textarea
            readonly
            label="ID"
            rows="1"
            v-model="localSettings.id"
            filled
          />

          <label class="label">Card image</label>
          <br />
          <Uploader
            width="160px"
            height="100px"
            :disabled="type == 'fork'"
            label="Thumbnail"
            icon="image"
            v-model="localSettings.image"
          />
          <br />
          <v-text-field
            :disabled="type == 'fork'"
            v-model="localSettings.shortcut"
            label=""
            hint="Slash Command Setting"
            persistent-hint
            filled
            prefix="/"
          />
          <br />
          <v-textarea
            :disabled="type == 'fork'"
            label="Short description"
            rows="2"
            auto-grow
            v-model="localSettings.short_description"
            filled
          />
          <v-textarea
            :disabled="type == 'fork'"
            label="Description"
            rows="4"
            auto-grow
            v-model="localSettings.description"
            filled
          />
          <v-switch
            inset
            v-model="localSettings.is_public"
            class="pl-3"
            label="Public access"
            :disabled="type == 'fork'"
          />
        </div>

        <div v-if="activeTab == 1">
          <div v-if="localSettings.shortcut">
            <div class="component-view-tabs">
              <div
                class="component-view-tab"
                :class="{active: cView == 'editor'}"
                @click="cView = 'editor'"
              >Editor</div>
              <div
                class="component-view-tab"
                :class="{active: cView == 'tabs'}"
                @click="cView = 'tabs'"
              >Tabs</div>
            </div>
            <div class="component-view-editor" v-if="cView == 'editor'">{{editorLine}}</div>
          </div>
          <div class="compiled-template">
            <component v-if="componentName" :ENV="ENV" :OAUTH="OAUTH" :is="componentName || 'div'" />
            <div v-if="!componentName">Compiling...</div>
            <div class="error" v-if="error">{{error}}</div>
          </div>
        </div>

        <div v-if="activeTab == 2">
          <v-banner two-line class="mb-4">
            <v-avatar
              v-slot:icon
              color="deep-purple accent-4"
              size="40"
            >
              <v-icon
                icon="mdi-lock"
                color="white"
              >
                mdi-lock
              </v-icon>
            </v-avatar>

            When publishing components be careful not to expose sensitive information in your code, such as API keys. Use Environment Variables instead.

            <template v-slot:actions>
              <v-btn text color="primary">Docs</v-btn>
            </template>
          </v-banner>
          <Variables
            :disabled="localSettings.type == 'General'"
            @save="localSettings.vars = $event"
            :vars="localSettings.vars"
          >
            <template slot-scope="slotProps" v-if="localSettings.type != 'General'">
              <v-layout style="margin-top: -40px">
                <v-flex xs5>
                  <v-checkbox
                    @change="$event ? null : slotProps.variable.publish_value=false"
                    v-model="slotProps.variable.publish_name"
                    label="Publish Name"
                  />
                </v-flex>
                <v-flex xs1 />
                <v-flex xs5>
                  <v-checkbox
                    :disabled="!slotProps.variable.publish_name"
                    v-model="slotProps.variable.publish_value"
                    label="Hidden Publish"
                  />
                </v-flex>
                <v-flex xs1 />
              </v-layout>
            </template>
          </Variables>
        </div>

        <div v-if="activeTab == 3">
          <v-switch
            inset
            v-model="localSettings.is_object"
            class="pl-1"
            label="Can be chart object"
            :disabled="type == 'fork'"
          />

          <div v-if="localSettings.is_object">
            <div style="color: rgba(0, 0, 0, 0.6)" class="mt-2">Object category:</div>
            <v-radio-group
              class="mt-0 pt-0"
              hide-details
              v-model="localSettings.object_settings.category"
            >
              <v-radio
                class="mt-2 mb-0 pt-1"
                hide-details
                v-for="category in categories"
                :key="category"
                :label="category"
                :value="category"
              />
              <v-row align="center" class="mx-0 mt-1">
                <v-radio
                  hide-details
                  :value="NEW_CATEGORY"
                  class="mb-0"
                />
                <v-text-field
                  class="pt-0"
                  hide-details
                  :disabled="localSettings.object_settings.category != NEW_CATEGORY"
                  placeholder="New category"
                  v-model="newCategory"
                />
              </v-row>
            </v-radio-group>

            <div style="color: rgba(0, 0, 0, 0.6)" class="mt-8" >Object properties:</div>
            <div class="mt-1">
              <PropertiesList v-model="localSettings.object_settings.properties" :readonly="type == 'fork'" />
            </div>

            <v-checkbox
              v-model="localSettings.object_settings.manual_props"
              label="Let user to enter properties manually"
              class="mt-6"
            />
            <v-checkbox
              v-model="localSettings.object_settings.resizing_enabled"
              label="Enable resizing"
              class="mt-0"
            />
          </div>
        </div>
      </div>

      <v-divider class="mb-4" />

      <div class="ma-5">
        <div v-if="type == 'fork'">
          <v-btn color="primary" @click="fork">
            <v-icon>mdi-source-fork</v-icon>
            Fork
          </v-btn>
        </div>
        <div v-if="type == 'new'">
          <v-btn color="primary" @click="create" :disabled="!localSettings.name">
            Create
          </v-btn>
        </div>

        <div v-if="type == 'edit'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                @click="submitGeneral"
                :disabled="!localSettings.name"
                v-on="on"
                outlined
              >
                Publish
              </v-btn>
            </template>
            <span>Submit for Review</span>
          </v-tooltip>

          <v-btn color="primary" class="ml-3" @click="save" :disabled="!localSettings.name">Save & Exit</v-btn>
          <v-btn class="pull-right mr-3" @click="destroy">Delete</v-btn>
        </div>
      </div>
    </v-col>

    <v-col class="d-flex flex-column">
      <div>
        <v-layout justify-space-between>
          <v-flex xs12>
            <div
              v-if="!liveCoding"
              style="padding: 22px 10px;"
              v-shortkey="{cmd: ['meta', 's'], ctrl: ['ctrl', 's']}"
              @shortkey="compile()"
              class="compileLabel"
            >Compile on Ctrl+S</div>
          </v-flex>
          <v-flex style="white-space: nowrap; padding: 0 20px">
            <v-switch inset v-model="liveCoding" label="Live Preview" />
          </v-flex>
        </v-layout>
      </div>
      <div class="flex-grow-1">
        <editor
          v-model="localSettings.template"
          @init="editorInit"
          ref="component-editor"
          lang="html"
          theme="dracula"
          height="100%"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {deepClone} from '@/lib';
import Vue from "vue";
import * as _ from "underscore";

import {compileTemplate, getOAUTH, getENV} from "@/libData";
import PropertiesList from "./PropertiesList";

export default {
  name: "ComponentForm",
  components: {
    editor: require("vue2-ace-editor"),
    PropertiesList
  },
  props: {
    type: String,
    settings: Object,
    actionsBlock: String
  },
  data() {
    const template =
`<template>
  <div>
    <!-- Template here -->
  <\/div>
<\/template>

<script>
  module.exports = {
    name: 'ComponentName',
    props: {},
    data: function () {
      return {}
    },
    methods: {},
    computed: {}
  }
<\/script>

<style scoped>
  /* styles here */
<\/style>`;

    const categories = (['Base', 'Analysis', 'Chatbot', 'Presentation', 'Tech'])
      .concat(this.$store.state.component.categories);

    let object_settings = {
      category: "Base",
      properties: [],
      manual_props: true,
      resizing_enabled: false
    };

    if (this.settings && this.settings.object_settings && this.settings.object_settings != "{}")
      object_settings = {
        ...object_settings,
        ...this.settings.object_settings
      };

    return {
      categories,
      localSettings: deepClone( {
        template,
        name: "",
        is_public: false,
        is_object: false,
        ...this.settings,
        object_settings
      }),
      activeTab: 0,
      compiled: false,
      error: null,
      componentName: null,
      cView: "editor",
      componentProps: [],
      liveCoding: true,
      valid: false,
      newCategory: '',
      NEW_CATEGORY: "__NEW__CATEGORY"
    };
  },
  mounted() {
    const watchHandler = () => {
      if (this.liveCoding)
        this.compile();
    };
    this.$watch(
      () => this.localSettings.template,
      _.debounce(watchHandler, 300),
      { immediate: true }
    );
  },
  methods: {
    editorInit(editor) {
      require("brace/ext/language_tools");
      require("brace/mode/html");
      require("brace/mode/javascript");
      require("brace/theme/dracula");
      require("brace/snippets/javascript");

      editor.setOptions({
        selectionStyle: "text",
        enableBasicAutocompletion: true,
        highlightSelectedWord: true,
        highlightActiveLine: false,
        showPrintMargin: false,
        tabSize: 2
      });
    },
    compile() {
      try {
        compileTemplate({
          template: this.localSettings.template
        }).then(({ component }) => {
          try {
            this.error = null;
            delete Vue.options.components[this.componentName];
            this.componentProps = Object.keys(component.props).filter(
              p => !component.props[p].hidden
            );
            this.componentName = component.name + "_" + this.guid();
            Vue.component(this.componentName, component);

            Vue.config.errorHandler = (err, vm, info) => {
              console.error(err);
              this.error = err.toString();
            };
            Vue.config.warningHandler = (err, vm, info) => {
              console.error(err);
              this.error = err.toString();
            };
          } catch (err) {
            console.error(err);
            this.error = err.toString();
          }
        });
      } catch (err) {
        this.error = err.toString();
      }
    },
    checkCategory() {
      if (this.localSettings.object_settings.category != this.NEW_CATEGORY)
        return;

      if (this.newCategory) {
        this.localSettings.object_settings.category = this.newCategory;
        this.$store.dispatch('component/createCategory', this.newCategory);
      } else {
        this.localSettings.object_settings.category = "Base";
      }
    },
    create() {
      this.checkCategory();
      this.$store.dispatch('component/createComponent', this.localSettings);
      this.$emit('close');
    },
    save() {
      this.checkCategory();
      this.$store.dispatch('component/updateComponent', this.localSettings);
      this.$emit('close');
    },
    fork() {
      this.$store.dispatch('component/forkComponent', this.localSettings.id);
      this.$emit('close');
    },
    submitGeneral() {
      this.$store.dispatch('component/updateComponent', this.localSettings);
      this.$store.dispatch('component/publishComponent', this.localSettings.id);
      this.$emit('close');
    },
    destroy() {
      if (confirm("Are you sure?")) {
        this.$store.dispatch('component/deleteComponent', this.localSettings.id);
        this.$emit('close');
      }
    }
  },
  computed: {
    showDestroy() {
      return (
        this.localSettings &&
        this.localSettings.id &&
        this.activeTab == 0 &&
        this.allowDestroy
      );
    },
    editorLine() {
      if (!this.localSettings.shortcut)
        return "";
      return `/${this.localSettings.shortcut} ${this.componentProps
        .map(p => p + '=""')
        .join(" ")}`;
    },
    ENV() {
      return getENV(this.localSettings.vars);
    },
    OAUTH() {
      return getOAUTH();
    }
  },
  watch: {
    localSettings: {
      handler() {
        if (JSON.stringify(this.localSettings) != JSON.stringify(this.settings))
          this.$emit("update:settings", this.localSettings);
      },
      deep: true
    },
    settings: {
      handler() {
        this.localSettings = this.settings;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.settings-container {
  overflow: auto;
  height: calc(87vh - 50px);
  position: relative;
}

.component-settings-tabs {
  border-bottom: 1px solid #e7e7e7;
}

.component-view-tabs {
  margin: 15px auto;
  display: flex;
  width: 150px;
}

.component-view-tab {
  padding: 5px 15px;
  color: #ccc;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  width: 50%;
  text-align: center;
}

.component-view-tab:first-child {
  border-radius: 15px 0 0 15px;
}

.component-view-tab:last-child {
  border-radius: 0 15px 15px 0;
}

.component-view-tab.active {
  background: #f2f2f2;
}

.component-view-editor {
  background: rgb(247, 247, 247);
  padding: 5px 15px;
  font-size: 16px;
  white-space: pre-wrap;
  word-break: break-word;
}

</style>


<style>
.form-header {
  display: flex;
}

.form-header-item {
  margin: 20px 20px;
  font-size: 24px;
  font-weight: 600;
}

.compiled-template {
  padding: 10px;
  background: white;
}
.compiled-template .error {
  padding: 10px;
  color: white;
}

.compileLabel {
  text-transform: uppercase;
  font-size: 12px;
  color: grey;
}
</style>
