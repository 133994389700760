<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0">
        Data
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <Uploader
      width="40px"
      height="40px"
      :disabled="readonly"
      label="Upload Image"
      icon="image"
      class="mx-2"
      v-model="localSettings.image"
    />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ControlPropertyEditorExtend from '../_extends/ControlPropertyEditor';

export default {
  name: 'ControlElement_ImageControlPropertyEditor',

  extends: ControlPropertyEditorExtend,

  data() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-layout>
            <v-flex xs8>
              <Autocompleter solo v-model="settings.showCondition" label="Show Condition, default true" :readonly="readonly" />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs3>
              <v-text-field type="number" v-model="settings.width" label="Width, %" :readonly="readonly" />
            </v-flex>
          </v-layout>

          <Autocompleter v-model="settings.label" label="Label" :readonly="readonly" class="pb-1" />
          <v-text-field type="text" v-model="settings.imageLink" placeholder="https://vulcanapp.com" label="Link" :readonly="readonly" />
          <v-text-field type="text" v-model="settings.imageSourceURL" placeholder="https://pathtoimage.jpg" label="Image URL" :readonly="readonly" />
        </div>
      `,
      props: ['settings', 'readonly']
    });

    return {
      cmpData,
      openedData: 0
    };
  },

  methods: {
    onOpenData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Data'
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0: this.onOpenData(); break;
        default: this.$root.$emit("DataEditor.close");
      }
    },
    id() {
      this.openedData = 'data';
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenData();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
}
</script>