<template>
  <div>
    <v-data-table :headers="headers" :items="list">
      <template v-slot:header="props">
        <tr>
          <th style="width: 1px">
            <v-checkbox
              v-model="props.all"
              :indeterminate="props.indeterminate"
              primary
              hide-details
              @click.native="toggleAll(props.all)"
            />
          </th>
          <th v-for="header in props.headers" :key="header.text" colspan="2">{{ header.text }}</th>
        </tr>
      </template>

      <template v-slot:item="{item}">
        <tr>
          <td style="width: 1px">
            <v-checkbox
              @click.native="selectProject(item)"
              :value="item.id"
              v-model="selectedProjects"
              primary
              hide-details
            />
          </td>
          <td colspan="2">{{ item.name }}</td>
        </tr>
        <!-- <tbody> -->
        <tr v-for="chart in item.charts" :key="chart.id">
          <td/>
          <td style="width: 1px;">
            <v-checkbox
              @click.native="selectChart(chart, item)"
              :value="chart.id"
              v-model="selectedCharts"
              primary
              hide-details
            />
          </td>
          <td>{{ chart.name }}</td>
        </tr>
        <!-- </tbody> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as _ from "underscore";
import { deepClone } from "@/lib";

export default {
  name: "AccessLevel",
  props: {
    team: Object
  },
  created() {
    this.setList();

    this.$watch(
      () => {
        return {
          projects: this.selectedProjects,
          charts: this.selectedCharts
        };
      },
      () => {
        this.$emit("update:team", {
          ...this.team,
          project_ids: this.selectedProjects,
          chart_ids: this.selectedCharts
        });
      },
      { deep: true }
    );
  },
  data() {
    return {
      list: [],
      selected: [],
      selectedProjects: deepClone(this.team.project_ids || []),
      selectedCharts: deepClone(this.team.chart_ids || []),
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name"
        }
      ]
    };
  },
  methods: {
    setList() {
      this.api.Project.get().then(response => (this.list = response.body));
    },
    selectProject(project) {
      const chartIds = _.pluck(project.charts, "id");

      if (this.selectedProjects.includes(project.id)) {
        this.selectedCharts = _.union(this.selectedCharts, chartIds);
      } else {
        this.selectedCharts = _.difference(this.selectedCharts, chartIds);
      }
    },
    selectChart(chart, project) {
      if (this.selectedProjects.includes(project.id)) {
        this.selectedProjects = this.selectedProjects.filter(
          p => p != project.id
        );
      }

      const chartIds = project.charts.map(c => c.id);
      const allChartsSelected =
        _.intersection(this.selectedCharts, chartIds).length == chartIds.length;

      if (allChartsSelected) {
        this.selectedProjects.push(project.id);
      }
    },
    toggleAll(val) {
      if (!val) {
        this.selectedProjects = [];
        this.selectedCharts = [];
      } else {
        this.selectedProjects = _.pluck(this.list, "id");
        this.selectedCharts = this.list.reduce((acc, project) => {
          acc = acc.concat(_.pluck(project.charts, "id"));
          return acc;
        }, []);
      }
    }
  }
};
</script>