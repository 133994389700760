<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-layout align-center>
        <v-btn text style="opacity: 1; border-radius: 0" class="ma-0" value="data">Data</v-btn>

        <v-divider vertical />

        <v-btn text style="opacity: 1; border-radius: 0" class="ma-0" value="models">Response models</v-btn>

        <v-divider vertical />

        <v-checkbox
          v-model="localSettings.activateCallback"
          label="Activate Callback"
          hide-details
          class="pa-0 mx-2"
          :disabled="readonly"
        />

        <v-divider vertical />

        <v-btn
          text
          :disabled="!localSettings.activateCallback"
          style="opacity: 1; border-radius: 0"
          class="ma-0"
          value="callback"
        >Callback models</v-btn>
      </v-layout>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import * as _ from "underscore";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import Components from "./Components";

export default {
  name: "Chatbot_EndpointObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  created() {
    this.$watch(
      () => [this.localSettings.endpoint, this.localSettings.example],
      () => {
        if (this.localSettings.endpoint) this.updateSchema();
      },
      { deep: true, immediate: true }
    );
  },

  data() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-select
            v-model="settings.endpoint"
            item-value="id"
            item-text="name"
            :items="endpoints"
            label="Endpoint"
            :readonly="readonly"
            class="pr-3"
          />
          <v-textarea v-model="settings.params" label="Params" :readonly="readonly" />
          <v-textarea v-model="settings.headers" label="Headers" :readonly="readonly" />
          <Autocompleter :schema="schema" solo v-model="settings.signal" label="Outcoming Signal" :readonly="readonly" />
        </div>
      `,
      props: ["settings", "schema", "readonly"],
      computed: {
        endpoints() {
          return this.activeProject.settings.endpoints || [];
        }
      }
    });

    return {
      schema: {},
      cmpData,
      openedData: "data"
    };
  },

  methods: {
    onOpenData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          schema: this.schema,
          readonly: this.readonly
        },
        title: "Data"
      });
    },
    onOpenModels() {
      this.localSettings = { models: [], ...this.localSettings };
      this.$root.$emit("DataEditor.open", {
        component: Components.SaveToModel,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          schema: this.schema,
          readonly: this.readonly
        },
        dataField: 'models',
        title: "Save Response to Models"
      });
    },
    onOpenCallbackModels() {
      this.localSettings = { callbackModels: [], ...this.localSettings };
      this.$root.$emit("DataEditor.open", {
        component: Components.SaveToModel,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          schema: { Request: {} },
          readonly: this.readonly
        },
        dataField: 'callbackModels',
        title: "Save Callback Request to Models"
      });
    },
    updateSchema: _.debounce(function() {
      this.schema = { ...this.schema, ...{ Response: {} } };
      this.api.Chatbot.endpoint(
        { id: this.baseObject.id },
        {
          endpoint: this.localSettings.endpoint,
          params: this.localSettings.params
        }
      ).then(response => {
        if (_.isObject(response.body))
          this.schema = { ...this.schema, ...response.body };
        else this.schema = { ...this.schema, ...{ Response: response.body } };
      });
    }, 1000)
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case "data":
          this.onOpenData();
          break;
        case "models":
          this.onOpenModels();
          break;
        case "callback":
          this.onOpenCallbackModels();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    "localSettings.activateCallback": function(newV, oldV) {
      if (oldV && !newV && this.openedData == "callback")
        this.$root.$emit("DataEditor.close");
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenData();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>