const defaultSettings = {
  name: "Set Value",
  icon: 'mdi-equal',
  showLabel: true,
  size: {
    width: 'auto',
    height: 'auto'
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#4CAF50",
  outColor: "#4CAF50",
  layout: "horizontal",
  components: [/Chatbot_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
