<template>
  <div v-show="opened && !hidden" class="property-editor" :style="styleEl">
    <component
      @updateSize="updateSize"
      ref="component"
      :is="component"
      v-bind.sync="props"
      :baseObject="baseObject"
      :settings="settings"
      :readonly="localReadonly"
      @updateSymbol="onUpdateSymbol"
    >
      <template v-slot:menumore v-if="!readonly">
        <MenuMore :baseObject="baseObject" :readonly="localReadonly" :settings="settings" />
      </template>
    </component>
  </div>
</template>

<script>
import { ObjectPropertyEditors } from "../Object/Types";
import MenuMore from "./MenuMore";
import {getObjPosition, getObjSize} from "./lib";

export default {
  name: "PropertyEditor",
  components: { MenuMore },
  props: {
    viewport: Object,
    scale: Number,
    translate: Object,
    readonly: Boolean
  },

  data() {
    return {
      component: null,
      props: {},
      width: 0,
      readyToShow: false,
      hidden: false
    };
  },

  computed: {
    opened() {
      return !!this.component;
    },
    baseObject() {
      return this.$store.getters["object/findById"](this.props.baseObjectId);
    },
    settings() {
      if (this.props.settings)
        return this.props.settings;
      if (this.baseObject && this.baseObject.info)
        return this.baseObject.info.settings;
      return {};
    },
    localReadonly() {
      return this.readonly || this.props.readonly;
    },
    styleEl() {
      if (this.readyToShow)
        return {
          left: this.position.x + "px",
          top: this.position.y + "px"
        };
      else
        return {
          visibility: "hidden"
        };
    },
    position() {
      const position = { x: 100, y: 100 };

      const obj = this.baseObject;
      const connection = this.props.baseConnection;

      const objPosition = getObjPosition(obj, connection),
        objSize = getObjSize(obj, connection);

      if (!objPosition || !objSize)
        return position;

      const viewportRect = this.viewport.$el.getBoundingClientRect();

      //top-left corner of element
      position.x = this.translate.x + viewportRect.x + objPosition.x * this.scale;
      position.y = this.translate.y + objPosition.y * this.scale;

      //x - center of element
      this.width = this.$el.clientWidth;
      position.x += (objSize.width * this.scale) / 2 - (this.width ? this.width / 2 : 0);
      position.x = Math.max(position.x, 20);
      if (this.width)
        position.x = Math.min(position.x, viewportRect.width - this.width - 20);

      //y - above/below the element
      this.height = this.$el.clientHeight;
      if (position.y - 80 >= 20) {
        position.y = position.y - 80;
      } else {
        position.y = position.y + objSize.height * this.scale + 30;
        if (position.y + this.height + 20 > viewportRect.height)
          position.y = 20;
      }

      position.x = Math.round(position.x);
      position.y = Math.round(position.y);
      return position;
    }
  },

  mounted() {
    this.$root.$on("PropertyEditor.open", this.onOpen);
    this.$root.$on("PropertyEditor.close", this.onClose);
    this.$root.$on("PropertyEditor.hide", this.onHide);
    this.$root.$on("PropertyEditor.unhide", this.onUnhide);
  },

  updated() {
    // Костыль
    if (Math.abs(this.width - this.$el.clientWidth) > 3)
      this.width = this.$el.clientWidth;
  },

  beforeDestroy() {
    this.$root.$off("PropertyEditor.open", this.onOpen);
    this.$root.$off("PropertyEditor.close", this.onClose);
    this.$root.$off("PropertyEditor.hide", this.onHide);
    this.$root.$off("PropertyEditor.unhide", this.onUnhide);
  },

  methods: {
    async onOpen({ component, props }) {
      this.$root.$emit("DataEditor.close");

      this.props = props;
      this.component = component;
      if (
        this.baseObject &&
        this.baseObject.type == "Base_SymbolObject" &&
        this.baseObject.symbol &&
        this.baseObject.symbol.type
      )
        this.component = ObjectPropertyEditors[this.baseObject.symbol.type];

      this.hidden = false;
      await this.$nextTick();
      this.readyToShow = true;
    },
    onClose() {
      this.$root.$emit("DataEditor.close");
      this.component = null;
      this.props = {};
      this.readyToShow = false;
    },
    onHide() {
      if (!this.opened)
        return;
      this.hidden = true;
      this.readyToShow = false;
    },
    async onUnhide() {
      if (!this.opened)
        return;
      this.hidden = false;
      await this.$nextTick();
      this.readyToShow = true;
    },

    save() {
      this.$refs.component.save();
    },
    updateSize() {
      this.width = this.$el.clientWidth;
    },
    onUpdateSymbol(body) {
      this.component = ObjectPropertyEditors[body.symbol.type];
    }
  }
};
</script>

<style scoped lang="scss">
.property-editor {
  position: absolute;
  z-index: 3;
  background: white;
}
</style>
