<template>
  <v-layout justify-center>
    <v-flex xs8>
      <v-toolbar color="#f4f3f3">
        <v-toolbar-title>Invitations</v-toolbar-title>
        <v-spacer />
        <v-btn color="primary" @click="showInviteModal">New Invitation</v-btn>
        <v-spacer />
        <v-text-field
          solo
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-toolbar>
      <v-card>
        <v-container container--fluid>
          <v-data-table :headers="headers" :items="invitations" :search="search">
            <template v-slot:item="{item}">
              <tr>
                <td style="width: 1px">{{ item.id }}</td>
                <td style="text-align: left">{{ item.email }}</td>
                <td style="text-align: left">
                  <span
                    class="member-role"
                    v-for="(role, index) in item.roles"
                    :key="index"
                  >{{role.name}}</span>
                </td>
                <td class="justify-center layout px-0" style="text-align: right">
                  <v-icon small @click="deleteInvitation(item.id)">delete</v-icon>
                </td>
              </tr>
            </template>
            <v-alert
              v-slot:no-results
              color="error"
              icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert>
          </v-data-table>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import InvitationModal from "./InvitationModal";

export default {
  name: "Members",
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email"
        },
        {
          text: "Roles",
          align: "left",
          sortable: false
        },
        {
          sortable: false
        }
      ],
      invitations: [],
      search: "",
      organisation: null
    };
  },
  created() {
    this.fetchOrganisation().then(() => {
      this.fetchInvitations();
    });
  },
  methods: {
    fetchOrganisation() {
      return this.api.Organisation.getCurrent().then(response => {
        this.organisation = response.body;
      });
    },
    fetchInvitations() {
      this.api.Organisation.invitations({
        group_type: "Organisation",
        group_id: this.organisation.id
      }).then(res => {
        this.invitations = res.body;
      });
    },
    deleteInvitation(id) {
      if (confirm("Are you sure?")) {
        this.api.Organisation.deleteInvitation({ id }).then(response => {
          this.fetchInvitations();
        });
      }
    },
    showInviteModal() {
      this.$modal.show(InvitationModal, {
        organisation: this.organisation,
        callback: () => {
          this.fetchInvitations();
        }
      });
    }
  }
};
</script>
