const defaultSettings = {
  name: 'Audio Space',
  icon: 'mdi-shape-rectangle-plus',
  size: {
    width: 150,
    height: 100
  },
  resizable: true,
  draggable: true,
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: true,
  notionOption: true,
  settings: {
    backgroundColor: "#e7e7e7",
    borderRadius: 0,
    borderStyle: "None",
    borderColor: "none",

    textColor: "#333",
    textAlign: "center",
    verticalAlign: "center",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "open sans",

    locked: false,
    order: 0,
    notionEnabled: false
  }
};

export default defaultSettings;
