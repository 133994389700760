<script>
import Text from "./Text.vue";
export default {
  extends: Text,
  name: "NotionTypeCode",
  props: {
    editorType: {
      type: String,
      default: "Code"
    }
  },
  computed: {
    contentStyle() {
      return {
        background: "#f7f7f7",
        padding: "5px 15px",
        fontSize: "16px",
        fontFamily: "monospace"
      };
    }
  }
};
</script>

<style lang="css">
</style>
