const defaultSettings = {
  name: "ObjectPicker",
  showLabel: false,
  size: {
    width: 315,
    height: "auto"
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: false,
    settings: true
  },
  layout: "horizontal",
  inColor: "#e44e9d",
  openPropertyEditorByClick: true
};

export default defaultSettings;
