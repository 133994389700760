<template>
  <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
    <TheNavigationHeader />
    <v-divider />
    <TheNavigationList />
  </v-navigation-drawer>
</template>

<script>
  import TheNavigationHeader from './TheNavigation/TheNavigationHeader'
  import TheNavigationList from './TheNavigation/TheNavigationList'
  export default {
    name: 'TheNavigation',
    components: {
      TheNavigationHeader,
      TheNavigationList
    },
    computed: {
      drawer: {
        get() {
          return this.$store.getters["navigation/drawer"];
        },
        set(value) {
          this.$store.dispatch("navigation/setDrawer", value);
        }
      }
    },
    watch: {
      drawer() {
        if (this.drawer)
          this.$root.$emit("updateNavigation");
      }
    }
  }
</script>

<style>
  .v-navigation-drawer {
    background-color: #f5f5f5 !important;
    z-index: 8 !important;
  }
</style>
