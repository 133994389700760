<template>
  <v-toolbar dense no-padding>
    <Autocompleter
      class="mx-2"
      v-model="localSettings.message"
      placeholder="Message"
      remove-padding
      :readonly="readonly"
    />

    <v-divider vertical />

    <ColorPickerDialog
      v-model="localSettings.color"
      title="Select a Color"
      :presetColors="projectColors"
      :disabled="readonly"
    />

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_UserMessageObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend
};
</script>
