<template>
  <div class="viewport-preview" :style="styles">
    <div class="viewport-preview__content" :style="contentStyles">
      <div
          class="viewport-preview__object"
          :class="`viewport-preview__object-` + object.type"
          :style="{
          left: (object.position ? object.position.x : 0) + viewRectStyles.offsetX + (viewRectStyles.rectRight / 2) + 'px',
          top: (object.position ? object.position.y : 0) + viewRectStyles.offsetY + 'px',
          width: `${object.size && object.size.width || 100}px`,
          height: `${object.size && object.size.height || 100}px`}"
          v-for="object in objects"
          v-if="isAllowed(object)"
          :key="object.id"
      >
        <component
            :is="object.type"
            :settings="object.info.settings"
            :baseObject="object"
            :position.sync="object.position"
        />
        <div class="viewport-preview__object-name">{{ object.info.settings.title }}</div>
      </div>
      <div
          class="viewport-preview__connection"
          v-for="connection in connections"
          :key="connection.id"
      >
        <svg width="100%" height="100%">
          <line
              :x1="connectionPoints[connection.id].x1"
              :y1="connectionPoints[connection.id].y1"
              :x2="connectionPoints[connection.id].x2"
              :y2="connectionPoints[connection.id].y2"
              :stroke="'#605da5'"
              :stroke-width="3"
          />
        </svg>
      </div>
      <div class="viewport-preview__view-rect" :style="viewRectStyles.rect" ref="viewportRect">
      </div>
      <div class="viewport-preview__view-rect-resizer" :style="viewRectStyles.resizer" ref="viewportRectResizer">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import * as d3 from "d3";
import * as _ from "underscore";
import {
  ObjectComponents,
  ObjectPropertyEditors,
  defaultProperties
} from "@/components/Layout/Authorized/TheEditor/Object/Types";

const resizerSize = 50;

export default {
  name: "ViewportPreview",
  components: {...ObjectComponents},
  props: {
    objects: {
      type: Array,
      default: () => []
    },
    connections: {
      type: Array,
      default: () => []
    },
    settings: Object,
    width: {
      type: Number,
      default: 400
    },
    allowedObjectTypes: Object,
    viewport: Object
  },
  data() {
    return {};
  },

  mounted() {
    this.d3ViewportRect = d3.select(this.$refs.viewportRect);
    this.d3ViewportRect.call(this.dragHandler());
    this.d3ViewportRectResizer = d3.select(this.$refs.viewportRectResizer);
    this.d3ViewportRectResizer.call(this.dragResizerHandler())
  },

  computed: {
    ...mapGetters({
      scale: "chart/scale",
      translate: "chart/translate"
    }),
    height() {
      if (!this.viewport) return this.width;

      const rect = this.viewport.$el.getBoundingClientRect();

      return this.width * rect.height / rect.width;
    },
    connectionPoints() {
      const points = {};
      const objectsById = _.indexBy(this.objects, "id");

      this.connections.forEach(c => {
        const from = objectsById[c.from];
        const to = objectsById[c.to];
        if (from && to) {
          const x1 =
              (from.position ? from.position.x : 0) +
              this.viewRectStyles.offsetX +
              ((from.size && from.size.width) || 100);
          const y1 =
              (from.position ? from.position.y : 0) +
              this.viewRectStyles.offsetY +
              ((from.size && from.size.height) || 100) / 2;

          const x2 = (to.position ? to.position.x : 0) + this.viewRectStyles.offsetX;
          const y2 =
              (to.position ? to.position.y : 0) +
              this.viewRectStyles.offsetY +
              ((to.size && to.size.height) || 100) / 2;

          points[c.id] = {
            x1,
            y1,
            x2,
            y2
          };
        } else {
          points[c.id] = {};
        }
      });
      return points;
    },
    contentStyles() {
      const calcX = Math.max(this.viewRectStyles.offsetX, this.translate.x);
      const calcY = Math.max(this.viewRectStyles.offsetY, this.translate.y);
      const widths = this.objects.map(
          o =>
              parseInt(o.position ? o.position.x : 0) +
              this.viewRectStyles.offsetX +
              ((o.size && o.size.width) || 100) +
              30
      );
      const heights = this.objects.map(
          o =>
              parseInt(o.position ? o.position.y : 0) +
              this.viewRectStyles.offsetY +
              ((o.size && o.size.height) || 100) +
              30
      );

      const rect = this.viewport ? this.viewport.$el.getBoundingClientRect() : {width: 0, height: 0};
      const width = Math.round(Math.max(_.max(widths), this.viewRectStyles.rectRight));
      const height = Math.round(Math.max(_.max(heights), this.viewRectStyles.rectBottom));

      const scaleW = width > this.width ? this.width / width  : 1;
      const scaleH = height > this.height ? this.height / height : 1;

      const scale = scaleW > scaleH ? scaleH : scaleW;

      return {
        transform: `scale(${scale})`,
        transformOrigin: "0 0",
        //scale,
        width: width + "px",
        height: height + "px"
      };
    },
    styles() {
      return {
        background: this.settings.background,
        width: this.width + "px",
        height: this.height + "px"
      };
    },
    viewRectStyles() {
      const rect = this.viewport ? this.viewport.$el.getBoundingClientRect() : {width: 0, height: 0};
      const x = this.objects.map(o => (o.position ? o.position.x : 0));
      const y = this.objects.map(o => (o.position ? o.position.y : 0));
      const minX = _.min(x);
      const minY = _.min(y);
      const calcX = minX < 0 ? -minX : 0;
      const calcY = minY < 0 ? -minY : 0;
      const offsetX = Math.max(calcX, this.translate.x / this.scale);
      const offsetY = Math.max(calcY, this.translate.y / this.scale);
      const newX = (-this.translate.x / this.scale + offsetX);
      const newY = (-this.translate.y / this.scale + offsetY);
      const rectRight = (rect.width / this.scale) + newX;
      const rectBottom = (rect.height / this.scale) + newY;
      return {
        rect: {
          transform: `translate(${newX}px, ${newY}px)`,
          width: (rect.width / this.scale) + "px",
          height: (rect.height / this.scale) + "px",
          "pointerEvents": "visible"
        },
        resizer: {
          transform: `translate(${rectRight - resizerSize / 2}px, ${rectBottom - resizerSize / 2}px)`,
          width: resizerSize + "px",
          height: resizerSize + "px",
          "pointerEvents": "visible"
        },
        offsetX,
        offsetY,
        newX,
        newY,
        rectRight,
        rectBottom,
      };
    },
  },

  methods: {
    isAllowed(object) {
      return !this.allowedObjectTypes || this.allowedObjectTypes[object.type];
    },

    dragHandler() {
      let startX, startY;
      const dragHandler = d3
          .drag()
          .on("start", () => {
            startX = d3.event.x;
            startY = d3.event.y;
          })
          .on("drag", () => {
            const offsetX = (d3.event.x - startX) / this.scale;
            const offsetY = (d3.event.y - startY) / this.scale;
            this.$store.commit("chart/setTranslate", {
              x: this.translate.x - offsetX,
              y: this.translate.y - offsetY
            });
          })
          .on("end", () => {
            const offsetX = (d3.event.x - startX) / this.scale;
            const offsetY = (d3.event.y - startY) / this.scale;
            this.$store.commit("chart/setTranslate", {
              x: this.translate.x - offsetX,
              y: this.translate.y - offsetY
            });
          });
      return dragHandler;
    },
    dragResizerHandler() {
      let startX, startY, startScale, startWidth;
      const dragHandler = d3
          .drag()
          .on("start", () => {
            startX = d3.event.x;
            startY = d3.event.y;
            startScale = this.scale;
            startWidth = this.viewRectStyles.rectRight - this.viewRectStyles.newX;
          })
          .on("drag", () => {
            const offsetX = (startX - d3.event.x) / this.contentStyles.scale;
            this.$store.commit("chart/setScale", startScale * (startWidth + offsetX) / startWidth);
          })
          .on("end", () => {
            const offsetX = (startX - d3.event.x) / this.contentStyles.scale;
            this.$store.commit("chart/setScale", startScale * (startWidth + offsetX) / startWidth);
          });
      return dragHandler;
    }
  }
};
</script>

<style scoped>
.viewport-preview {
  background: #e7e7e7;
  position: relative;
  pointer-events: none;
  overflow: hidden;
}

.viewport-preview__object {
  position: absolute;
  z-index: 2;
}

.viewport-preview__object-Base_GroupObject {
  z-index: 1;
}

.viewport-preview__object-name {
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  text-align: center;
}

.viewport-preview__connection {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.viewport-preview__view-rect {
  border: 3px solid #4c0e53;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.viewport-preview__view-rect-resizer {
  position: absolute;
  background: #4c0e53;
  z-index: 4;
}
</style>
