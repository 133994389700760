const defaultSettings = {
  brushType: "Brush",
  Brush: {
    lineStyle: "Solid",
    strokeWidth: 10,
    lineColor: "#333",
    opacity: 1,
    iconSvg: "drawing.svg",
    offsetX: 20,
    offsetY: 32
  },
  Highlighter: {
    lineStyle: "Solid",
    strokeWidth: 8,
    lineColor: "#FDED8699",
    opacity: 0.6,
    iconSvg: "marker-pen.svg",
    offsetX: 8,
    offsetY: 35
  },
  Eraser: {
    strokeWidth: 1,
    iconSvg: "eraser.svg",
    lineColor: "#FF0",
    offsetX: 20,
    offsetY: 32
  }
};

export default defaultSettings;