const defaultSettings = {
  name: "Person",
  icon: 'mdi-account-circle',
  hotkey: `modkey + P`,
  showLabel: true,
  size: {
    width: 100,
    height: 100,
    proportion: 1,
    minHeight: 100,
    minWidth: 100,
    maxHeight: 1000,
    maxWidth: 1000
  },
  resizable: true,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  layout: "horizontal",
  inColor: "#e44e9d",
  outColor: "#505fa6",
  components: [/AnalysisTools_.*/, 'Base_SymbolObject'],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0
  }
};

export default defaultSettings;
