<template>
  <div class="rect-object" :style="rectObjectStyles" @click="onClick">
    <Base_TextObject
        :settings.sync="localSettings"
        :baseObject="baseObject"
        :readonly="readonly"
        ref="textObj"/>
  </div>
</template>

<script>
import Base_TextObject from "../TextObject/Component";
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_StickyObject",
  props: ['selected'],
  extends: ExtendsComponent,
  components: {
    Base_TextObject
  },
  computed: {
    rectObjectStyles() {
      return {
        background: this.localSettings.backgroundColor || "#e7e7e7",
        borderRadius: (this.localSettings.borderRadius || 0) + "px",
        fontSize: (this.localSettings.fontSize || 0) + "px",
        borderStyle: this.localSettings.borderStyle || "none",
        borderColor: this.localSettings.borderColor || "none",
        textAlign: this.localSettings.textAlign || "center"
      };
    },
    contentEditable() {
      return this.$refs.textObj.contentEditable;
    }
  },
  methods: {
    onClick() {
      if (!this.$refs.textObj.contentEditable)
        this.$store.commit('object/setContentEditable', false);
    }
  }
};
</script>

<style scoped>
.rect-object {
  height: 100%;
  width: 100%;
  overflow: hidden;
  transform: none;
}

.rect-object::before, .rect-object::after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 4px;
  opacity: .3;
  bottom: -21px;
  width: 230px;
  height: 22px;
  background: rgba(0, 0, 0, 0);
  filter: blur(3px);
  transform: rotate(-1deg);
  background-image: radial-gradient(ellipse at top right, rgb(28, 26, 26) 15%, transparent 50%);
}


.rect-object::after {
  width: 10px;
  height: 80px;
  transform: none;
  right: -10px;
  bottom: -16px;
  background: rgba(0, 0, 0, 0);
  background: radial-gradient(farthest-corner at -2px 50px, rgba(0, 0, 0, 0.6) 3%,
  rgba(27, 27, 27, 0.3) 25%, rgba(75, 75, 75, 0) 40%, rgba(75, 75, 75, 0) 65%);
}
</style>

<style>
.Base_StickyObject > .base-object__content {
  height: 100%;
}
</style>
