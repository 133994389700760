<template>
  <Modal
    :showSubmit="!!organisation.name"
    @submit="submit"
    title="Create an Organisation"
    submitText="Create"
    contentClass="organisation-modal"
  >
    <v-container fluid grid-list-md>
      <v-layout>
        <v-flex class="d-flex justify-center">
          <v-sheet color="pink" class="px-4" dark>Create your Organisation account with a free trial.</v-sheet>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex class="d-flex justify-center">
          <v-card class="ma-4" width="600px">
            <v-card-title>
            <v-flex class="d-flex justify-center">
              <Uploader
                label="Logo"
                icon="image"
                v-model="logo"
                count-base64
                :base64.sync="logoBase64"
                class="mr-4"
              />
              <v-text-field label="Name" v-model="organisation.name" />
            </v-flex>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </Modal>
</template>

<script>
const ADMIN_ROLE_ID = 1;
export default {
  name: "OrganisationModal",
  props: {
    callback: Function
  },
  data() {
    return {
      organisation: {
        name: ''
      },
      logo: null,
      logoBase64: null
    };
  },
  methods: {
    submit() {
      let image = this.logoBase64? this.logoBase64 : null
      var organisation
      if (image) {
        organisation = {
            ...this.organisation,
            logo_image_attributes: {
              base64: image
            }
          }
      } else {
        organisation = this.organisation
      }
      this.api.Organisation.save(
        {
          organisation
        }
      ).then(response => {
        this.api.Organisation.setCurrent({ id: response.body.id }, { id: response.body.id }).then(res => {
          this.api.Organisation.createAssignment({
            role_id: ADMIN_ROLE_ID,
            user_id: this.currentUser.id
          }).then(() => {
            this.callback(response.body);
          });
        });
      });
    }
  }
};
</script>

<style>
.organisation-modal {
  overflow: visible;
  max-width: 900px;
}
.assignment-icon {
  padding: 20px 10px;
}
</style>
