<template>
  <div class="endpoints-list">
    <draggable v-model="localValue" handle=".drag">
      <div class="form-row" v-for="option in localValue" :key="option.id">
        <v-layout>
          <v-flex>
            <v-text-field label="Name" v-model="option.name" />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs1 class="option-icon">
            <v-icon class="drag">drag_handle</v-icon>
          </v-flex>
          <v-flex xs3>
            <v-select :items="methods" v-model="option.method" label="Method" />
          </v-flex>
          <v-flex xs1 />
          <v-flex xs9>
            <v-text-field v-model="option.url" label="URL" />
          </v-flex>

          <v-flex xs1 class="option-icon">
            <v-icon class="delete" @click="deleteEndpoint(option.id)">delete</v-icon>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-textarea solo label="Default params" v-model="option.defaultParams" />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-textarea solo label="Default headers" v-model="option.defaultHeaders" />
          </v-flex>
        </v-layout>
      </div>
    </draggable>
    <div class="add" @click="addEndpoint">Add Endpoint</div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { deepClone } from "@/lib";

export default {
  name: "Endpoints",
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    draggable
  },
  data() {
    const localValue = deepClone(this.value || []);
    return {
      localValue,
      methods: ["GET", "POST", "PUT", "PATCH", "DELETE"]
    };
  },
  methods: {
    addEndpoint() {
      this.localValue.push({ id: this.guid() });
      this.$emit("input", this.localValue);
    },
    deleteEndpoint(optionId) {
      this.localValue = this.localValue.filter(option => option.id != optionId);
      this.$emit("input", this.localValue);
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue = deepClone(this.value || []);
        }
      },
      deep: true
    },
    localValue: {
      handler() {
        this.$emit("input", this.localValue);
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.endpoints-list {
  margin: 10px 0;

  .option-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    box-sizing: content-box;
    min-width: 34px;
  }

  .drag {
    cursor: move;
    text-align: left;
    flex: auto;
  }

  .delete {
    text-align: right;
    flex: auto;
  }
}
</style>
