<template>
  <div
    class="select"
    @click="nextOption"
    v-html="activeOption.value"
  />
</template>

<script>
  export default {
    name: 'ControlElement_Select',
    props: {
      settings: Object
    },
    data () {
      return {
        activeOptionIndex: 0
      };
    },
    computed: {
      activeOption () {
        return this.settings.options[this.activeOptionIndex];
      }
    },
    created() {
      this.updateCurrentScope(0);
    },
    methods: {
      nextOption () {
        if (this.activeOptionIndex >= this.settings.options.length - 1)
          this.activeOptionIndex = 0;
        else
          this.activeOptionIndex++;

        this.updateCurrentScope(this.activeOptionIndex);
      },
      updateCurrentScope (activeOptionIndex) {
        if (!this.settings.model)
          return;

        const value = this.settings.options[activeOptionIndex];
        this.$store.commit(`wayfinder/updateCurrentScope`, {
          [this.settings.model]: {id: value.name, display_name: value.value}
        });
      }
    }
  }
</script>

<style scoped>
  .select {
    cursor: pointer;
    width: 100%;
    background-color: #f5f5f5;
    height: 36px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0px rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 3px;
    user-select: none;
  }
</style>
