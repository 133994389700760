<template>
  <div>
    <Autocompleter solo v-model="localSettings.signal" label="Signal" :readonly="readonly" />
  </div>
</template>

<script>
import componentExtends from "./_extends/Component";

export default {
  name: "Chatbot_RouterObjectPropertyEditor_CONTINUE_PARENT_FLOW",
  extends: componentExtends
};
</script>
