<template>
  <Modal
    @submit="submit"
    title="Audio Person Setting Modal"
    submitText="Save"
  >
    <v-container class="px-9 pt-9">
      <v-row class="mx-0">
        <v-col>
          Avatar
        </v-col>
        <v-col class="pa-0">
          <Uploader
            class=""
            label="Background Image"
            icon="image"
            v-model="audioAvatar"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col align-self="center" cols="4">
          <v-subheader>Input device</v-subheader>
        </v-col>
        <v-col class="pa-0" cols="8">
          <v-select
            hide-details
            :items="inputDevices"
            :disabled="!inputDevices.length"
            item-text="label"
            item-value="deviceId"
            v-model="localData.deviceId"
          />
        </v-col>
      </v-row>
      <v-row
        justify="space-between"
      >
        <v-col class="text-left">
          <span class="subheading font-weight-light mr-2">Input Volume</span>
        </v-col>
        <v-col class="text-right">
          <span class="subheading font-weight-light">
            {{ hiFiGainPercentage }} %
          </span>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col class="py-0">
          <v-slider
            v-model="localData.hiFiGain"
            track-color="grey"
            always-dirty
            min="0.1"
            max="3"
            step="0.1"
          >
            <template v-slot:prepend>
              <v-icon @click="decrement">
                mdi-minus
              </v-icon>
            </template>

            <template v-slot:append>
              <v-icon @click="increment">
                mdi-plus
              </v-icon>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-switch
            v-model="localData.echoCancellation"
            label="Echo Cancellation"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-switch
            v-model="localData.autoGainControl"
            label="Auto Gain Control"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-switch
            v-model="localData.noiseSuppression"
            label="Noise Suppression"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-switch
            v-model="muted"
            label="Muted"
          />
        </v-col>
      </v-row>
    </v-container>

  </Modal>
</template>

<script>
import {mapGetters} from "vuex";

const HIFI_GAIN_OFFSET = 0.1;

export default {
  name: "AudioSettingsModal",
  props: {
    params: Object,
    callback: Function
  },
  data() {
    return {
      localData: {
        hiFiGain: 1.0,
        echoCancellation: false,
        noiseSuppression: false,
        autoGainControl: false,
        deviceId: null
      },

      muted: false,
      audioAvatar: null,

      inputDevices: []
    };
  },
  computed: {
    ...mapGetters({
      audioSettings: "user/audioSettings",
    }),
    hiFiGainPercentage() {
      return Math.round(this.localData.hiFiGain * 100);
    },
  },

  async created() {
    this.localData = {...this.localData, ...this.audioSettings};
    this.muted = this.$store.state.audio.muted;

    try {
      // === Just for get mic permissions
      await navigator.mediaDevices.getUserMedia({audio: true, video: false});

      // === Get input devices
      try {
        let devices = await navigator.mediaDevices.enumerateDevices();
        this.inputDevices = devices.filter(device => device.kind == 'audioinput');

        let inputDevice = this.inputDevices.find(device => device.deviceId == this.localData.deviceId);
        if (!inputDevice)
          inputDevice = this.inputDevices[0];
        this.localData.deviceId = inputDevice.deviceId;
      } catch (e) {
        console.error(`Error in \`enumerateDevices()\`:\n${e}`);
      }
    } catch(err) {
      console.error("Can't access audio input:");
      console.error(err);
    }
  },

  methods: {
    async submit() {
      this.$store.dispatch('audio/updateInputAudioMediaStream', this.localData);
      this.$store.dispatch('audio/setMuted', this.muted);
      this.close();
    },
    close() {
      this.$emit("close");
    },
    decrement() {
      this.localData.hiFiGain = this.localData.hiFiGain - HIFI_GAIN_OFFSET;
    },
    increment() {
      this.localData.hiFiGain = this.localData.hiFiGain + HIFI_GAIN_OFFSET;
    }
  },

  watch: {
    //TODO: audio avatar
/*    'localParams.info.settings.audioAvatar': {
      handler(newVal) {
        const audioAvatar = newVal ? newVal.body.url : null;
        const currentUser = this.$store.getters["user/current"];
        currentUser.profile.audio_avatar_url = audioAvatar || 'https://www.wonenmeerssen.nl/wp-content/uploads/2016/12/placeholder-employee.jpg';
        //this.$store.dispatch('user/update', {...currentUser, profile_attributes: currentUser.profile});
      }
    }*/
  }
};
</script>

<style scoped>
.sharing-options-wrap {
  background: #2c2c54;
  color: #FFF;
}

.sharing-options-header {
  padding: 20px;
  font-size: 24px;
}

.sharing-options-content {
  padding: 20px;
}

.embed-snippet {
  font-family: monospace !important;
}

.copy-btn {
  font-family: "Roboto", sans-serif !important;
}

.embed-switch {
  max-width: 60px;
}
</style>
