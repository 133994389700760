<template>
  <v-menu bottom origin="center center" transition="scale-transition" v-if="menu && menu.length">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">
        more_vert
      </v-icon>
    </template>

    <v-list>
      <v-list-item v-for="(item, i) in menu" :key="i" @click="item.callback ? item.callback() : null">
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'AppHeaderMenu',
    props: {
      menu: Array
    }
  }
</script>