const defaultSettings = {
  name: "Drawing",
  icon: "mdi-lead-pencil",
  size: {
    width: 0,
    height: 0
  },
  resizable: true,
  draggable: true,
  has: {
    in: false,
    out: false,
    settings: true
  },
  openPropertyEditorByClick: true,
  subToolbar: true,
  notionOption: true,
  settings: {
    lineStyle: "Solid",
    lineColor: "#333",
    strokeWidth: 1,
    locked: false,
    order: 0,
    notionEnabled: false
  }
};

export default defaultSettings;
