<template>
  <div class="page">
    <app-header
      title="Settings"
      :tabs="tabs"
      :activeTabInd="activeTab"
      @activeTabChanged="setActiveTab"
    />

    <v-main class="page-content">
      <div>
        <Profile v-if="activeTab == 0" />
        <Configuration v-if="activeTab == 1" />
        <OAuthConfig v-if="activeTab == 2" />
      </div>
    </v-main>
  </div>
</template>

<script>
  import AppHeader from './AppHeader';
  import Profile from './TheSettings/Profile';
  import Configuration from './TheSettings/Configuration';
  import OAuthConfig from "./TheSettings/OAuthConfig";

  export default {
    name: 'TheSettings',
    components: {
      OAuthConfig,
      AppHeader,
      Configuration,
      Profile
    },
    data () {
      return {
        activeTab: 0,
        tabs: [{name: 'Profile'}, {name: 'Configuration'}, {name: 'Connected Apps'}]
      };
    },
    methods: {
      setActiveTab (value) {
        this.activeTab = value;
      }
    },
    mounted() {
      window.analytics.page('Settings');
    }
  }
</script>