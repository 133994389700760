<template>
  <div class="autocompleter-wrapper" :class="{invalid: invalid}">
    <v-text-field
      :class="removePadding ? 'pa-0' : ''"
      @focus="hidden = false"
      @blur="hidden = true"
      @keydown="updateCarretPosition"
      v-model="localValue"
      :label="label"
      :placeholder="placeholder"
      :solo="solo"
      :filled="filled"
      hide-details
      :readonly="readonly"
    />
    <div class="autocompleter-suggestions" v-if="!hidden && solo">
      <div
        :class="{active: activeIndex == index}"
        class="autocompleter-suggestion"
        v-for="(i, index) in filteredSuggestionList"
        :key="index"
        @mousedown="activeIndex = index; paste()"
      >{{i}}</div>
    </div>
  </div>
</template>

<script>
  import * as _ from "underscore";

  export default {
    name: "Autocompleter",
    props: {
      value: String,
      solo: {
        type: Boolean,
        default: false
      },
      filled: {
        type: Boolean,
        default: false
      },
      removePadding: {
        type: Boolean,
        default: false
      },
      label: String,
      placeholder: String,
      schema: {
        type: Object,
        default() {
          return {};
        }
      },
      readonly: Boolean
    },
    data() {
      return {
        hidden: true,
        localValue: this.value || "",
        activeIndex: 0,
        currentPosition: this.currentPosition
      };
    },
    computed: {
      invalid() {
        try {
          if (!this.localValue) return false;

          if (this.solo || this.filled) {
            const template = `{{${this.localValue}}}`;
            return !_.template(template)(this.suggestionList);
          } else {
            const template = this.localValue;
            const commands = template.match(/\{\{([^}]+)\}\}/g) || [];
            const correct = commands.reduce((res, current) => {
              return (
                res &&
                typeof _.template(current)(this.suggestionList) !== "undefined"
              );
            }, true);

            return !_.template(template)(this.suggestionList) || !correct;
          }
        } catch (e) {
          return true;
        }
      },
      filteredSuggestionList() {
        this.hidden = false;
        this.activeIndex = 0;

        const chain = this.localValue.split(".");
        let suggestionList = this.suggestionList;

        if (chain.length > 1) {
          _.initial(chain).forEach(c => {
            if (c) {
              suggestionList = suggestionList[c] || {};
            }
          });
        }

        const list = Object.keys(suggestionList).map(s => {
          if (typeof s == "object") {
            return Object.keys(s)[0];
          }
          return s;
        });

        const result = list.filter(s => {
          const item = (s || "").toLowerCase();
          const input = (_.last(this.localValue.split(".")) || "").toLowerCase();
          return item.indexOf(input) != -1 && item != input;
        });

        return result;
      },
      suggestionList() {
        return {
          signal: true,
          Model: this.$store.getters["intellisense/Model"],
          ...this.$store.getters["intellisense/currentState"],
          ...this.schema
        };
      }
    },
    watch: {
      localValue() {
        this.$emit("input", this.localValue);
      }
    },
    methods: {
      moveDown() {
        if (this.activeIndex < this.filteredSuggestionList.length - 1) {
          this.activeIndex += 1;
          if (this.activeIndex >= 4) this.scrollTo();
        }
      },
      moveUp() {
        if (this.activeIndex > 0) {
          this.activeIndex -= 1;
          this.scrollTo();
        }
      },
      paste() {
        const element = this.$el.querySelector("input");
        const text = this.filteredSuggestionList[this.activeIndex];
        if (text) {
          this.localValue = _.compact([
            _.initial(element.value.split(".")).join("."),
            text
          ]).join(".");
        }
      },
      scrollTo() {
        this.$nextTick(function() {
          this.$scrollTo(`.autocompleter-suggestion.active`, 100, {
            container: `.autocompleter-suggestions`,
            duration: 100,
            easing: "ease",
            offset: 0,
            force: true,
            cancelable: true,
            x: false,
            y: true
          });
        });
      },
      hide() {
        this.hidden = true;
      },
      updateCarretPosition(event) {
        const down = event.keyCode == 40,
          up = event.keyCode == 38,
          enter = event.keyCode == 13,
          esc = event.keyCode == 27;

        if (down) {
          this.moveDown();
        } else if (up) {
          this.moveUp();
        } else if (enter) {
          this.paste();
        } else if (esc) {
          this.hide();
        }

        if (!down && !up && !esc) {
          this.$nextTick(function() {
            this.currentPosition = this.$el.querySelector("input").selectionStart;
          });
        } else {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    }
  };
</script>

<style>
  .autocompleter-wrapper.invalid .v-input__slot {
    background: #ff000033 !important;
  }

  .autocompleter-wrapper {
    position: relative;
    z-index: 2;
  }

  .autocompleter-suggestions {
    position: absolute;
    top: 48px;
    background: white;
    min-width: 150px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    max-height: 164px;
    overflow: auto;
    width: 100%;
    z-index: 10;
  }

  .autocompleter-suggestion {
    padding: 10px;
    cursor: pointer;
  }

  .autocompleter-suggestion:hover,
  .autocompleter-suggestion.active {
    background: rgba(0, 0, 0, 0.05);
  }
</style>
