<template>
  <div class="drawing-toolbar">
    <v-toolbar dense no-padding class="rounded">
      <v-tooltip top v-for="(brush, index) in brushes" :key="index">
        <template v-slot:activator="{ on }">
          <v-btn class="mx-0 px-0 brush-button" v-on="on" icon @click="setBrushType(brush)"
                 :class="activeBrushTypeClass(brush)">
            <div :class="brush.class"/>
          </v-btn>
        </template>
        <span>{{ brush.value }}</span>
      </v-tooltip>
      <ColorPickerDialog
          v-model="localOptions.lineColor"
          title="Select Colour"
          :presetColors="projectColors"
          tooltip="Line color"
          v-if="brushType != 'Eraser'"
      />

      <v-divider class="mr-2" vertical/>

      <v-select
          class="pa-0"
          hide-details
          :items="strokeWidths"
          v-model="localOptions.strokeWidth"
          :menu-props="{ maxHeight: '400' }"
      />

      <v-divider class="mr-2" vertical v-if="brushType != 'Eraser'"/>

      <v-tooltip top v-if="brushType != 'Eraser'">
        <template v-slot:activator="{ on }">
          <v-select
              class="pa-0"
              hide-details
              :items="linestyles"
              item-value="value"
              item-label="icon"
              single-line
              v-model="localOptions.lineStyle"
              :menu-props="{ maxHeight: '400' }"
              v-on="on"
          >
            <template v-slot:selection="{ item }">
              <v-btn class="mx-0 px-0" icon v-on="on">
                <div :class="item.class" class="line-style-icon"/>
              </v-btn>
            </template>
            <template v-slot:item="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-0 px-0" icon v-on="on">
                    <div :class="item.class" class="line-style-icon"/>
                  </v-btn>
                </template>
                <span>{{ item.value }}</span>
              </v-tooltip>
            </template>
          </v-select>
        </template>
        <span>Line style</span>
      </v-tooltip>
    </v-toolbar>
  </div>
</template>

<script>
import defaultSettings from "./default";

export default {
  name: "DrawingToolbar",
  data() {
    const activeChart = this.$store.getters["chart/active"];
    const options =
        activeChart && activeChart.options ? activeChart.options : {};

    if (!options.activeTool.name || !defaultSettings[options.activeTool.name]) {
      this.setBrushType({value: "Brush", class: "brush-icon"})
      this.$store.dispatch('chart/setActiveTool', this.brushType, defaultSettings[this.brushType])
    }

    const brushType = options.activeTool.name || defaultSettings.brushType;

    return {
      brushType,
      openedData: undefined,
      linestyles: [
        {value: "Solid", class: "solid-line"},
        {value: "Dotted", class: "dotted-line"},
        {value: "Dashed", class: "dashed-line"},
      ],
      strokeWidths: [1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 18, 36, 48],
      brushes: [
        {value: "Brush", class: "brush-icon"},
        {value: "Highlighter", class: "marker-pen-icon"},
        {value: "Eraser", class: "eraser-icon"}
      ],
      localOptions: defaultSettings[brushType]
    };
  },
  methods: {
    // Brush Type buttons click event handler
    setBrushType(brush) {
      this.brushType = brush.value;
      const activeChart = this.$store.getters["chart/active"];

      if (!activeChart.options.activeTool.options) {
        activeChart.options.activeTool.options = defaultSettings[brush.value]
      }

      let tool = {
        name: brush.value,
        options: defaultSettings[brush.value]
      }

      activeChart.options.activeTool = tool
      this.localOptions = tool.options

      if (defaultSettings[this.brushType].iconSvg) {
        activeChart.options.mouseCursor = `url(/cursors/${this.localOptions.iconSvg}), pointer`;
      } else {
        activeChart.options.mouseCursor = "auto";
      }
      this.$store.dispatch("chart/update", activeChart);
    },
    // class utility to indicate active brush type
    activeBrushTypeClass(brush) {
      return this.brushType == brush.value ? 'active' : '';
    }
  },
  watch: {
    localOptions: {
      handler() {
        const activeChart = this.$store.getters["chart/active"];
        const tool = {
          name: this.brushType,
          options: {...activeChart.options.activeTool.options, ...this.localOptions}
        };

        activeChart.options.activeTool = tool
        this.$store.dispatch("chart/update", activeChart);
      },
      deep: true,
      immediate: true,
    }
  },
  computed: {
    projectColors() {
      if (
          !this.activeProject ||
          !this.activeProject.settings ||
          !this.activeProject.settings.colors ||
          !this.activeProject.settings.colors.custom
      )
        return [];
      return this.activeProject.settings.colors.custom.map((a) => a.value);
    },
  },

};
</script>

<style scoped>
.drawing-toolbar {
  position: absolute;
  left: 55px;
  bottom: 145px;
  z-index: 2000;
}

.input-subtext {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.brush-button.active {
  background-color: pink;
}

.brush-button div {
  width: 24px;
  height: 24px;
  background-size: contain;
}

.line-style-icon {
  width: 32px;
  height: 32px;
}

.solid-line {
  background-image: url("./solid-line.svg");
}

.dashed-line {
  background-image: url("./dashed-line.svg");
}

.dotted-line {
  background-image: url("./dotted-line.svg");
}

.brush-icon {
  background-image: url("/cursors/drawing.svg");
}

.eraser-icon {
  background-image: url("/cursors/eraser.svg");
}

.marker-pen-icon {
  background-image: url("/cursors/marker-pen.svg");
}
</style>
