<template>
  <div class="page">
    <app-header
      title="Workspace Settings"
      :tabs="tabs"
      :activeTabInd="activeTab"
      @activeTabChanged="setActiveTab"
    />

    <v-main class="page-content">
      <Info :organisation="organisation" v-if="activeTab == 0" />
      <div v-if="activeTab == 1" class="pt-3">
        <Members :organisation="organisation"  />
        <Invitations :organisation="organisation" class="mt-4" />
      </div>
      <div v-if="activeTab == 2" class="pt-3">
        <Subscriptions :organisation="organisation"  />
        <Cards :organisation="organisation" class="mt-3" />
      </div>
    </v-main>
  </div>
</template>

<script>
import AppHeader from "./AppHeader";
import Info from "./TheOrganisation/Info";
import Members from "./TheOrganisation/Members";
import Invitations from "./TheOrganisation/Invitations";
import Subscriptions from "./TheOrganisation/Subscriptions";
import Cards from "./TheOrganisation/Cards";

export default {
  name: "TheTeams",
  components: {
    AppHeader,
    Info,
    Members,
    Invitations,
    Subscriptions,
    Cards
  },
  data() {
    return {
      organisation: null,
      activeTab: 0,
      tabs: [{ name: "Info" }, { name: "Members" }, { name: "Billing" }]
    };
  },
  methods: {
    setActiveTab(value) {
      this.activeTab = value;
    }
  },
  mounted() {
    window.analytics.page('Organisation');
  }
};
</script>

<style scoped>
</style>
