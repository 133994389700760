<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="data">
        Data
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="options">
        Options
      </v-btn>
    </v-btn-toggle>
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import ControlPropertyEditorExtend from '../_extends/ControlPropertyEditor';

export default {
  name: 'ControlElement_CheckboxListControlPropertyEditor',

  extends: ControlPropertyEditorExtend,

  data() {
    const cmpData = Vue.extend({
      template: `
        <div>
          <v-layout>
            <v-flex xs8>
              <Autocompleter solo v-model="settings.showCondition" label="Show Condition, default true" :readonly="readonly" />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs3>
              <v-text-field type="number" v-model="settings.width" label="Width, %" :readonly="readonly" />
            </v-flex>
          </v-layout>

          <v-text-field type="text" v-model="settings.model" label="Model (for use in expressions)" :readonly="readonly" />
          <Autocompleter v-model="settings.title" label="Title" :readonly="readonly" class="pb-1" />
        </div>
      `,
      props: ['settings', 'readonly']
    });

    const cmpOptions = Vue.extend({
      template: `<OptionsList v-model="settings.options" :dimension="2" nameLabel="ID" valueLabel="Display Value" :readonly="readonly" />`,
      props: ['settings', 'readonly']
    });

    return {
      cmpData,
      cmpOptions,
      openedData: 'data'
    };
  },

  methods: {
    onOpenData() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpData,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Data'
      });
    },
    onOpenOptions() {
      this.localSettings = {options: [], ...this.localSettings};

      this.$root.$emit("DataEditor.open", {
        component: this.cmpOptions,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Options'
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 'data':    this.onOpenData(); break;
        case 'options': this.onOpenOptions(); break;
        default:        this.$root.$emit("DataEditor.close");
      }
    },
    id() {
      this.openedData = 'data';
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenData();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
}
</script>