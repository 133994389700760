<template>
  <div>
    <v-layout>
      <v-flex xs>
        <v-autocomplete
          v-model="user"
          :search-input.sync="userSearch"
          :items="users"
          color="primary"
          hide-selected
          item-text="name"
          item-value="id"
          label="User"
          prepend-icon="mdi-database-search"
          return-object
        />
      </v-flex>
      <v-flex xs>
        <v-btn color="primary" @click="sendInvite">Send Invite</v-btn>
      </v-flex>
    </v-layout>

    <v-data-table :headers="headers" :items="list" :search="search">
      <template v-slot:item="{item}">
        <tr>
          <td>{{ item.name }}</td>
          <td class="justify-center layout px-0">
            <v-icon small @click="deleteInvite(item)">delete</v-icon>
          </td>
        </tr>
      </template>
      <v-alert
        v-slot:no-results
        color="error"
        icon="warning"
      >Your search for "{{ search }}" found no results.</v-alert>
    </v-data-table>
  </div>
</template>

<script>
import * as _ from "underscore";

export default {
  name: "Participants",
  props: {
    team: Object
  },
  created() {
    this.setList();
  },
  data() {
    return {
      headers: [],
      search: "",
      user: "",
      userSearch: "",
      users: [],
      list: []
    };
  },
  methods: {
    deleteParticipant({ id }) {},
    setList() {
      this.api.Team.invites({ id: this.team.id }).then(
        response => (this.list = response.body)
      );
    },
    sendInvite() {
      this.api.Team.sendInvite(
        { id: this.team.id },
        { user_id: this.user.id }
      ).then(response => this.list.push(response.body));
      this.user = null;
      this.userSearch = "";
    },
    deleteInvite({ id }) {
      this.api.Team.removeInvite({ id: this.team.id }, { user_id: id }).then(
        response => {
          this.list = this.list.filter(item => item.id != id);
        }
      );
    }
  },
  watch: {
    userSearch(q) {
      this.api.User.search({ q }).then(response => {
        this.users = response.body;
      });
    }
  }
};
</script>