<template>
  <SignFormsWrapper :loading="loading" subtitle="Forgot your password?">
    <v-form v-if="!finished" @submit.prevent="reset">
      <v-text-field
        v-model="email"
        @input="onInputEmail"
        :error-messages="errorVisibleEmail"
        :disabled="loading"
        label="Enter your email"
        type="email"
        required
        autofocus
      />
      <v-btn
        type="submit"
        :disabled="!dirtyForm || loading"
        class="mt-6"
        color="primary"
        large
        rounded
      >Reset</v-btn>
    </v-form>
    <div v-else>
      You will receive an email with instructions about how to reset your password in a few minutes.
    </div>
    <template v-slot:footer>
      <div class="mt-8 text-center">
        Back to <router-link class="weight-500" to="/sign-in">Sign In</router-link>
      </div>
    </template>
  </SignFormsWrapper>
</template>

<script>
  import SignFormsWrapper from "./SignFormsWrapper";

  export default {
    name: 'TheForgotPassword',
    components: {
      SignFormsWrapper
    },
    data () {
      return {
        email: '',
        errorVisibleEmail: null,
        loading: false,
        finished: false,
        dirtyForm: false
      }
    },
    computed: {
      errorEmail() {
        if (!this.email)
          return 'Email can not be empty';
        if (!this.email.match(/.+@.+/))
          return 'Email must be valid';
        return null;
      }
    },
    mounted() {
      window.analytics.page('Forgot Password');
    },
    methods: {
      onInputEmail() {
        this.errorVisibleEmail = null;
        this.dirtyForm = true;
      },
      reset() {
        this.dirtyForm = false;
        if (this.errorEmail) {
          this.errorVisibleEmail = this.errorEmail;
          return;
        }

        this.loading = true;
        
        //todo
        Promise.resolve()
          .then(res => {
            this.loading = false;
            this.finished = true;
          })
          .catch(error => {
            this.loading = false;
            if (!error)
              return;

            let message = '';
            error.forEach(_message => {
              message = message + _message + '<br>';
            });
            message = message.substr(0, message.length - 4);
            this.$root.$emit("showSnack", {message});
          });
      }
    }
  }
</script>