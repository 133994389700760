<template>
  <transition name="flip">
    <div class="objects-filter" v-if="opened">
      <v-card class="border px-4 d-flex flex-column" style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>Filters</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click.native="$emit('update:opened', false)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider />

        <div class="list flex-grow-1">
          <v-card flat v-for="tab in objectTypesTabs" :key="tab">
            <v-card-title class="overline">{{ tab }}</v-card-title>
            <v-card-text>
              <v-switch
                dense
                v-for="objectType in tabbedObjectTypesList(tab)"
                v-model="allowedObjectTypes[objectType.value]"
                :label="objectType.label"
                :key="objectType.value"
              />
            </v-card-text>
            <v-divider class="mt-2" />
          </v-card>
        </div>
        <v-card-actions>
          <v-list-item ripple @click="toggleAllowedObjectTypes">
            <v-list-item-action>
              <v-icon :color="objectTypesList.length > 0 ? 'primary' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="select-all-text">Select All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </div>
  </transition>
</template>

<script>
  import {defaultProperties} from "@/components/Layout/Authorized/TheEditor/Object/Types";
  import * as _ from "underscore";

  export default {
    name: "ObjectsFilter",
    props: [
      'objects', 'allowedObjectTypes', 'opened'
    ],
    computed: {
      objectTypesList() {
        return _.uniq(this.objects.map(o => o.type)).sort();
      },
      objectTypesTabs() {
        return _.uniq(
          Object.keys(this.allowedObjectTypes).map(type => defaultProperties[type].tab)
        );
      },
      truelyAllowed() {
        return _.filter(this.allowedObjectTypes, allowed => allowed);
      },
      includesAllAllowedTypes() {
        return this.allowedObjectTypes &&
          this.objectTypesList.length === this.truelyAllowed.length;
      },
      includesSomeAllowedTypes() {
        return this.allowedObjectTypes &&
          this.truelyAllowed.length > 0 &&
          !this.includesAllAllowedTypes;
      },
      icon() {
        if (this.includesAllAllowedTypes) return "mdi-check-all";
        if (this.includesSomeAllowedTypes) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      }
    },
    methods: {
      tabbedObjectTypesList(tab) {
        return this.objectTypesList
          .filter(objectType => defaultProperties[objectType].tab === tab)
          .map(objectType => {
            return {
              value: objectType,
              label: defaultProperties[objectType].name
            };
          });
      },
      toggleAllowedObjectTypes() {
        this.objectTypesList.forEach(
          type => this.allowedObjectTypes[type] = !this.includesAllAllowedTypes);
      },
    }
  };
</script>

<style scoped>
.objects-filter {
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
  height: 100%;
  padding: 15px;
  z-index: 10;
  transition-duration: 300ms;
  transition-property: transform, opacity;
}

.border {
  border-radius: 6px 20px 20px 20px;
}

.v-input--selection-controls {
  margin-top: 4px;
}

.list {
  overflow-y: auto;
}

.flip-enter {
  opacity: .01;
  transform: translateX(100%);
}

.flip-enter-to {
  opacity: 1;
  transform: translateX(0%);
}

.flip-leave {
  opacity: 1;
}

.flip-leave-to {
  opacity: .01;
  transform: translateX(100%);
}
</style>