import Wayfinder from "./Wayfinder";
import WayfinderContentObject from "./WayfinderContentObject";
import WayfinderFilterObject from "./WayfinderFilterObject";
import WayfinderEndpointObject from "./WayfinderEndpointObject";
import WayfinderRouterObject from "./WayfinderRouterObject";


export default {
  objects: {
    Wayfinder,
    WayfinderContentObject,
    WayfinderFilterObject,
    WayfinderEndpointObject,
    WayfinderRouterObject
  },
  tab: "Presentation"
};
