<template>
  <div>
    <v-select
      full-width
      :items="entries"
      label="Entries"
      v-model="localSettings.entry"
      item-value="id"
      item-text="title"
      solo
      :readonly="readonly"
    />
    <Autocompleter solo v-model="localSettings.signal" label="Signal" :readonly="readonly" />
  </div>
</template>

<script>
import componentExtends from "./_extends/Component";

export default {
  name: "Chatbot_RouterObjectPropertyEditor_SEND_BOT_MESSAGE",
  extends: componentExtends,
  data() {
    return {
      entries: []
    };
  },
  created() {
    this.entries = this.$store.getters['object/list']
      .filter(o => o.type == "Presentation_Wayfinder")
      .filter(o => o.info.settings.wayfinder_title || o.info.settings.title)
      .map(o => ({
        title: o.info.settings.wayfinder_title || o.info.settings.title,
        id: o.id
      }));
  }
};
</script>
