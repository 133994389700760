<template>
  <div>
    <slot :openSettings="openSettings" />
    <div class="control-element__radiolist">
      <div class="control-element__radiolist__title" v-html="localSettings.title" />
      <v-radio-group v-model="model" v-if="localSettings.options && localSettings.options.length">
        <v-radio
          :key="option.id"
          v-for="option in localSettings.options"
          :label="option.value"
          :value="option.value"
        />
      </v-radio-group>
    </div>
  </div>
</template>

<script>
  import ControlComponent from '../_extends/ControlComponent'
  
  export default {
    name: 'ControlElement_RadioListControl',
    extends: ControlComponent,
    data () {
      return {
        model: null
      }
    }
  }
</script>

<style scoped>
  .control-element__radiolist__title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
  .control-element__radiolist {
    display: block;
    margin-top: 20px;
  }
</style>