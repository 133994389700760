<template>
  <v-tooltip top>
    <template v-slot:activator="{ on: tooltip }">
      <div
        class="active-member mr-3"
        :class="classObj"
        v-on="tooltip"
      >
        <div class="avatar" :style="{ color }">
          <v-badge
            :value="showBadge && badgeBlink"
            :icon="volumeIcon"
            offset-x="14"
            offset-y="14"
          >
            <v-avatar size="32" color="#e6e6e6">
              <img v-if="avatar" :src="avatar" />
              <h3 v-else style="margin-bottom: 1px">{{capital}}</h3>
            </v-avatar>
          </v-badge>

          <div class="menu-hover d-flex justify-center align-center">
            <v-menu offset-y offset-x>
              <template v-slot:activator="{ on: menu }">
                <v-icon v-on="menu" >more_horiz</v-icon>
              </template>

              <v-list>
                <v-list-item
                  v-if="cursor"
                  @click="goToCollaborator"
                >
                  <v-list-item-content>
                    <v-list-item-title>Go to user</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="cursor"
                  @click="followUser"
                  disabled
                >
                  <v-list-item-content>
                    <v-list-item-title>Follow user</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider v-if="false" />

                <v-list-item
                  v-if="!cursor && hifiStatus === HFSA_STATUS_DISCONNECTED"
                  @click="connectHiFiAudio"
                >
                  <v-list-item-content>
                    <v-list-item-title>Enable spatial audio</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!cursor && hifiStatus === HFSA_STATUS_CONNECTING"
                  disabled
                >
                  <v-list-item-content>
                    <v-list-item-title>Connecting to audio...</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!cursor && hifiStatus == HFSA_STATUS_CONNECTED"
                  @click="disconnectFromHiFiAudio"
                >
                  <v-list-item-content>
                    <v-list-item-title>Disable spatial audio</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!cursor"
                  @click="openAudioSettings"
                >
                  <v-list-item-content>
                    <v-list-item-title>Audio settings...</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!cursor"
                  @click="toggleMute"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{!muted ? 'Mute input' : 'Unmute input'}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <span>{{ name }}</span>
  </v-tooltip>

</template>

<script>
import {mapGetters, mapState} from "vuex";

import {generateColor, isInactiveCursor, HFSA_STATUS_DISCONNECTED, HFSA_STATUS_CONNECTING, HFSA_MAPPING_COEF,
  HFSA_STATUS_CONNECTED, HFSA_THRESHOLD_DEFAULT} from '@/lib';
import {detectSpaceId} from '@/libData';
import AudioSettingsModal from "@/components/Layout/Authorized/TheEditor/_Global/AudioSettingsModal";
import {defaultProperties} from "@/components/Layout/Authorized/TheEditor/Object/Types";


export default {
  name: "ActiveMember",
  props: {
    cursor: Object,
    viewport: Object,
    now: Date
  },
  data() {
    return {
      HFSA_STATUS_CONNECTING,
      HFSA_STATUS_CONNECTED,
      HFSA_STATUS_DISCONNECTED,
      badgeBlink: true,
      badgeBlinkInterval: 0
    }
  },
  computed: {
    ...mapGetters({
      visibleName: "user/visibleName",
    }),
    ...mapState({
      hifiStatus: state => state.audio.hifiStatus,
      muted: state => state.audio.muted,
      scale: state => state.chart.scale,
      translate: state => state.chart.translate
    }),
    classObj() {
      if (this.cursor)
        return {inactive: isInactiveCursor(this.now, this.cursor.update_at)};
      return {};
    },
    color() {
      if (this.cursor)
        return generateColor(this.cursor.user.username);
      return null;
    },
    name() {
      if (this.cursor) {
        const {user} = this.cursor;
        if (user.first_name && user.last_name)
          return user.first_name + ' ' + user.last_name;
        else if (user.first_name)
          return user.first_name;
        else if (user.last_name)
          return user.last_name;
        return user.username;

      } else {
        return this.visibleName + ' (me)';
      }
    },
    avatar() {
      if (this.cursor) {
        if (this.cursor.user.avatar_url)
          return 'https://developers.beach.io' + this.cursor.user.avatar_url;
        return null;
      }
      if (this.currentUser.profile)
        return this.currentUser.profile.avatar_url;
      return null;
    },
    capital() {
      return this.name.charAt(0).toUpperCase();
    },
    volumeIcon() {
      return this.muted ? 'mdi-volume-off' : 'mdi-volume-high';
    },
    showBadge() {
      if (this.cursor) {
        const username = this.cursor ? this.cursor.user.username : null;
        const audioData = this.$store.getters['audio/getUserData'](username);
        return !!audioData;
      }
      return this.hifiStatus !== HFSA_STATUS_DISCONNECTED;
    }
  },
  watch: {
    hifiStatus() {
      if (this.hifiStatus == HFSA_STATUS_CONNECTING) {
        this.badgeBlinkInterval = setInterval(
          () => this.badgeBlink = !this.badgeBlink,
          500);
      } else {
        clearInterval(this.badgeBlinkInterval);
        this.badgeBlink = true;
      }
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    onDblClick(e) {
      this.$emit('dblclick', e);
    },
    followUser() {

    },

    goToCollaborator() {
      this.$root.$emit("PropertyEditor.close");

      if (!this.cursor.params.x || !this.cursor.params.y)
        return;

      const rect = this.viewport.$el.getBoundingClientRect();

      this.$store.commit("chart/setTranslate", {
        x: - this.cursor.params.x * this.scale + rect.width / 2,
        y: - this.cursor.params.y * this.scale + rect.height / 2,
      });
    },

    async connectHiFiAudio() {
      let audioPerson = this.$store.getters['object/audioPersonByUsername'](this.currentUser.username);
      if (!audioPerson) {
        const rect = this.viewport.$el.getBoundingClientRect();
        const position = {
          x: Math.round((-this.translate.x + rect.width / 2) / this.scale),
          y: Math.round((-this.translate.y + rect.height / 2) / this.scale),
        };
        audioPerson = {
          type: "SpatialAudio_AudioPersonObject",
          position,
          size: defaultProperties["SpatialAudio_AudioPersonObject"].size,
          info: {
            settings: {
              username: this.currentUser.username,
              angle: 0,
              locked: false
            }
          }
        };
        await this.$store.dispatch('object/create', audioPerson);
      }

      await this.$store.dispatch('audio/connectToHiFiAudio',
        {
          spaceId: detectSpaceId(audioPerson.position, audioPerson.size),
          spaceName: this.activeChart.name,
          userData: {
            position: {
              x: audioPerson.position.x / HFSA_MAPPING_COEF,
              y: 0,
              z: audioPerson.position.y / HFSA_MAPPING_COEF
            },
            orientationEuler: {
              yawDegrees: - audioPerson.info.settings.angle
            },
            hiFiGain: audioPerson.info.settings.hiFiGain,
            volumeThreshold: audioPerson.info.settings.muted ? 0 : HFSA_THRESHOLD_DEFAULT
          }
        }
      );
    },

    async disconnectFromHiFiAudio() {
      await this.$store.dispatch('audio/disconnectFromHiFiAudio');
    },

    openAudioSettings() {
      this.$modal.show(
        AudioSettingsModal,
        { scrollable: true, height: "auto" }
      );
    },

    toggleMute() {
      this.$store.dispatch('audio/setMuted', !this.muted);
    }
  },
  beforeDestroy() {
    clearInterval(this.badgeBlinkInterval);
  }
}

</script>

<style scoped>
.active-member {
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
}

.active-member.inactive {
  opacity: 0.5;
}

.avatar {
  border-radius: 50%;
}

.menu-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  opacity: .01;
  z-index: 3;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .7);
}

.active-member:hover .menu-hover {
  opacity: 1;
}

</style>
