<template>
  <div class="mt-5" v-if="src">
    <h3>{{settings.label}}</h3>
    <img width="100%" :src="src" alt="">
  </div>
</template>

<script>
  export default {
    name: 'ControlElement_Image',
    props: {
      settings: Object
    },
    computed: {
      src () {
        if (this.settings.image)
          return this.settings.image.body.thumb.url;
        return null;
      }
    }
  }
</script>