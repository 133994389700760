<template>
  <v-app>
    <TheNavigation />
    <router-view />
    <modals-container />
    <Snackbar />
  </v-app>
</template>

<script>
import "./Authorized/_Global";
import TheNavigation from "./Authorized/TheNavigation";
import actioncable from "actioncable";
import { WEBSOCKETS_URL } from "@/env";

export default {
  name: "TheAuthorizedLayout",
  components: {
    TheNavigation
  },
  beforeMount() {
    const token = localStorage.token;
    const sessionId = sessionStorage.sessionId;
    const url = `${WEBSOCKETS_URL}?token=${token}&session_id=${sessionId}`;
    const consumer = actioncable.createConsumer(url);
    this.$cable._cable = consumer;

    this.$store.dispatch('component/load');
  },
  methods: {
    setHeader(header) {
      this.header = header;
    }
  }
};
</script>

