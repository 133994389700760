<template>
  <v-toolbar dense no-padding>
    <Uploader
      width="40px"
      height="40px"
      :disabled="readonly"
      label="Image"
      icon="image"
      class="mx-2"
      v-model="localSettings.image"
    />

    <v-divider class="mr-2" vertical />

    <v-text-field
      class="pa-0"
      hide-details
      :readonly="readonly"
      placeholder="Name"
      v-model="localSettings.title"
    />

    <v-btn-toggle
      group
      borderless
      v-model="openedData"
      v-if="localSettings.notionEnabled || localSettings.audioEnabled"
    >
      <v-divider
        v-if="localSettings.notionEnabled"
        vertical
        class="ml-2"
      />
      <v-btn
        v-if="localSettings.notionEnabled"
        :value="NOTION"
        text
        class="ma-0"
        style="opacity: 1"
      >
        Notes
      </v-btn>
      <v-divider
        v-if="localSettings.audioEnabled"
        vertical
      />
      <v-btn
        v-if="localSettings.audioEnabled"
        :value="AUDIO"
        text
        class="ma-0"
        style="opacity: 1"
      >
        Audio Settings
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";
import ObjectAudioSettingsModal from "@/components/Layout/Authorized/TheEditor/_Global/ObjectAudioSettingsModal";

const NOTION = 'notion';
const AUDIO = 'audio';

export default {
  name: "Base_ImagePropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    return {
      NOTION,
      AUDIO,
      openedData: undefined
    };
  },

  methods: {
    onOpenNotion() {
      this.localSettings = { notesLines: [], ...this.localSettings };
      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    },
    onOpenAudioSettingsModal() {
      this.localSettings = { audioSetting: {}, ...this.localSettings };
      this.$root.$emit("DataEditor.open", {
        component: ObjectAudioSettingsModal,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        }
      });
    },
    onStart() {
      if (this.localSettings.notionEnabled)
        this.openedData = NOTION;
      else if (this.localSettings.audioEnabled)
        this.openedData = AUDIO;
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case NOTION:
          this.onOpenNotion();
          break;
        case AUDIO:
          this.onOpenAudioSettingsModal();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.onStart();
    },
    'localSettings.notionEnabled'() {
      if (!this.localSettings.notionEnabled && this.openedData == NOTION)
        this.$root.$emit("DataEditor.close");
    },
    'localSettings.audioEnabled'() {
      if (!this.localSettings.notionEnabled && this.openedData == AUDIO)
        this.$root.$emit("DataEditor.close");
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onStart();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  },
};
</script>
