<template>
  <div class="screen-position-selector">
    <div
      :class="['screen-position-selector__item', position, {active: value == position}]"
      :key="position"
      v-for="position in positions"
      @click="$emit('input', position)"
    >
      <v-icon size="30">desktop_windows</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenPosition",
  props: {
    value: {
      type: String,
      default: "right-bottom"
    }
  },
  data() {
    return {
      positions: ["right-bottom", "right-top", "left-bottom", "left-top"]
    };
  }
};
</script>

<style scoped>
.screen-position-selector {
  display: flex;
}
.screen-position-selector__item {
  position: relative;
  padding: 10px;
  line-height: 0;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
}
.screen-position-selector__item.active {
  border-color: pink;
}
.screen-position-selector__item:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background: pink;
}
.left-bottom:after {
  margin-left: 4px;
}
.right-bottom:after {
  margin-left: 16px;
}
.left-top:after {
  margin-left: 4px;
  margin-top: -10px;
}
.right-top:after {
  margin-top: -10px;
  margin-left: 16px;
}
</style>