const defaultSettings = {
  name: "Bot Message",
  icon: "message",
  showLabel: true,
  size: {
    width: 380,
    height: "auto"
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#505fa6",
  outColor: "#505fa6",
  layout: "horizontal",
  components: [/Chatbot_.*/],
  openPropertyEditorByClick: true,
  settings: {
    messages: [],
    locked: false,
    order: 0
  }
};

export default defaultSettings;
