<template>
  <div class="object-collaborators">
    <div
      class="object-collaborator"
      v-for="collaborator in collaborators"
      :key="collaborator.id"
      :style="{color: generateColor(collaborator.user.username)}"
      :class="{inactive: isInactiveCursor(now, collaborator.update_at)}"
    >{{collaborator.user.username}}</div>
  </div>
</template>

<script>
import { generateColor, isInactiveCursor } from "@/lib";

export default {
  name: "ObjectCollaborators",
  props: {
    collaborators: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      generateColor,
      isInactiveCursor,
      now: new Date()
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.now = new Date();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style>
.object-collaborators {
  position: absolute;
  top: 0;
  right: 100%;
  white-space: nowrap;
  margin-right: 15px;
}

.object-collaborator {
  border: 3px solid;
  padding: 5px 15px;
  border-radius: 50px;
  color: #828282;
  cursor: pointer;
  margin-bottom: 10px;
}

.object-collaborator.inactive {
  opacity: 0.5;
}
</style>
