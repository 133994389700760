<template>
  <div class="section">
    <div class="section-label">DialogFlow Credentials</div>
    <OptionsList
      nameLabel="Agent"
      valueLabel="Client Access Token"
      v-model="localValue"
      itemName="Credential"
      :dimension="2"
    />
  </div>
</template>

<script>
  import { deepClone } from '@/lib'

  export default {
    name: 'DialogflowCredential',
    props: {
      value: Array
    },
    data () {
      return {
        localValue: deepClone(this.value || [])
      }
    },
    watch: {
      localValue: {
        handler () {
          if (JSON.stringify(this.value) != JSON.stringify(this.localValue)) {
            this.$emit('input', this.localValue)
          }
        },
        deep: true
      },
      value: {
        handler () {
          this.localValue = deepClone(this.value)
        },
        deep: true
      }
    }
  }
</script>